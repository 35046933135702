import type NewPasswordFormValues from '../../models/Settings/NewPasswordFormValues';
import type NewPasswordRequest from '../../models/Settings/NewPasswordRequest';

export default function toNewPasswordRequest(
  data: NewPasswordFormValues
): NewPasswordRequest {
  const { password, passwordConfirmation } = data;

  return {
    password,
    password_confirmation: passwordConfirmation,
  };
}
