import React from 'react';
import styled, { css } from 'styled-components';

interface ListProps {
  children: React.ReactNode;
  column?: boolean;
}

export default function List(props: ListProps) {
  const { children, column } = props;

  return (
    <StyledWrapper>
      <StyledContainer column={column}>{children}</StyledContainer>
    </StyledWrapper>
  );
}

const StyledContainer = styled.div<{ column?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin: -5px;

  ${(props) => {
    const { column } = props;

    if (!column) {
      return css`
        flex-direction: row;
      `;
    }

    return css`
      flex-direction: column;
    `;
  }}
`;

const StyledWrapper = styled.div`
  overflow: hidden;
`;
