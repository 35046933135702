enum SessionStorageKey {
  TOKEN = '__TOKEN__',
  ROLE = '__ROLE__',
  NAME = '__NAME__',
  EMAIL = '__EMAIL__',
  ID = '__ID__',
  USER = '__USER__',
}

export default SessionStorageKey;
