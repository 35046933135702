import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  InfoIcon,
  LabelWrapper,
  StyledDescription,
  StyledErrorWrapper,
  StyledWrapper,
} from 'src/components/Shared/InfoMessage';
import Dropdown from '../Shared/Dropdown';

interface InfoMessageProps {
  label?: string;
  message: string;
}

export default function InfoMessage(props: InfoMessageProps) {
  const { label, message } = props;
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (!wrapperRef?.current?.contains(event.target as Node)) {
        close();
      }
    };

    document.addEventListener('click', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
    };
  }, [close]);

  return (
    <StyledWrapper tariffView>
      <LabelWrapper onMouseEnter={open} onMouseLeave={close} ref={wrapperRef}>
        {label && <StyledDescription tariffView>{label}</StyledDescription>}
        <InfoIcon className='pa-info' />
      </LabelWrapper>
      {isDropdownOpen && (
        <Dropdown close={close}>
          <StyledErrorWrapper tariffView>{message}</StyledErrorWrapper>
        </Dropdown>
      )}
    </StyledWrapper>
  );
}
