import type LotEntryUpdateParams from 'src/models/Parking/Anpr/LotEntries/LotEntryUpdateParams';
import type LotEntryUpdateValues from 'src/models/Parking/Anpr/LotEntries/LotEntryUpdateValues';

export default function toLotEntryUpdateParams(
  data: LotEntryUpdateValues
): LotEntryUpdateParams {
  const { licensePlate } = data;

  return {
    license_plate: licensePlate,
  };
}
