import React, { useCallback, useState } from 'react';
import DeviceTypes from 'src/constants/Parking/DeviceTypes';
import ProductIcon from 'src/constants/Parking/ProductIcon';
import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';
import type ZoneData from 'src/models/Parking/Zone/ZoneData';
import GlobalModal from '../Shared/Modals/GlobalModal';
import {
  ProductP,
  StyledDiv,
  StyledIcon,
  StyledSpan,
  StyledWrapper,
} from '../Tariffs/ToggleCard';
import BarrierDetailsModal from './Modals/BarrierDetailsModal';
import BollardDetailsModal from './Modals/BollardDetailsModal';
import CameraDetailsModal from './Modals/CameraDetailsModal';
import ChainDetailsModal from './Modals/ChainDetailsModal';
import GateDetailsModal from './Modals/GateDetailsModal';
import ProductTableIcon from './ProductTableIcon';

interface ZonesProductsProps {
  zone: ZoneData;
  onCameraNameChange: (name: string, id: number, zoneId?: number) => void;
  onProductNameChange: (name: string, id: number, zoneId?: number) => void;
}

export default function ZonesProducts(props: ZonesProductsProps) {
  const { zone, onCameraNameChange, onProductNameChange } = props;
  const { isAnyGatewayOnline, cameras, products } = zone;
  const [toggle, setToggle] = useState(true);
  const [activeId, setActiveId] = useState<number | undefined>();
  const [activeProductId, setActiveProductId] = useState<number | undefined>(
    undefined
  );

  const onClick = useCallback(() => {
    setToggle((oldToggle) => !oldToggle);
  }, []);

  const [openBarrierDetailsModal, closeBarrierDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <BarrierDetailsModal
          closeParentModal={closeBarrierDetailsModal}
          id={activeId}
          productId={activeProductId}
          onProductNameChange={onProductNameChange}
          isAnyGatewayOnline={isAnyGatewayOnline}
          hideSystemControl
        />
      </GlobalModal>
    )
  );

  const [openGateDetailsModal, closeGateDetailsModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <GateDetailsModal
        closeParentModal={closeGateDetailsModal}
        id={activeId}
        productId={activeProductId}
        onProductNameChange={onProductNameChange}
        isAnyGatewayOnline={isAnyGatewayOnline}
        hideSystemControl
      />
    </GlobalModal>
  ));

  const [openChainDetailsModal, closeChainDetailsModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <ChainDetailsModal
        closeParentModal={closeChainDetailsModal}
        id={activeId}
        productId={activeProductId}
        onProductNameChange={onProductNameChange}
        isAnyGatewayOnline={isAnyGatewayOnline}
      />
    </GlobalModal>
  ));

  const [openBollardDetailsModal, closeBollardDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <BollardDetailsModal
          closeParentModal={closeBollardDetailsModal}
          id={activeId}
          productId={activeProductId}
          onProductNameChange={onProductNameChange}
          isAnyGatewayOnline={isAnyGatewayOnline}
        />
      </GlobalModal>
    )
  );

  const [openCameraDetailsModal, closeCameraDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <CameraDetailsModal
          closeParentModal={closeCameraDetailsModal}
          id={activeId}
          onCameraNameChange={onCameraNameChange}
          hideSystemControl
        />
      </GlobalModal>
    )
  );

  return (
    <StyledWrapper widthSize='39%'>
      <StyledDiv>
        <StyledSpan>Products</StyledSpan>
        <StyledIcon
          className={toggle ? 'pa-chevron-up' : 'pa-chevron-down'}
          onClick={onClick}
        />
      </StyledDiv>
      {toggle && (
        <div>
          {cameras.map((data) => {
            const { id, name, connectedDeviceType } = data;

            const showIcon =
              connectedDeviceType !== DeviceTypes.OTHER &&
              connectedDeviceType !== DeviceTypes.STANDALONE;

            const openModal = () => {
              setActiveId(id);
              openCameraDetailsModal();
            };

            return (
              <ProductP key={id} onClick={openModal}>
                {showIcon && (
                  <ProductTableIcon iconString={connectedDeviceType} />
                )}
                <ProductTableIcon icon={ProductIcon.LPR} />
                {name}
              </ProductP>
            );
          })}
          {products.map((data) => {
            const { id, name, productTypeId, barrier, gate, chain, bollard } =
              data;

            const openModal = () => {
              if (barrier) {
                const { id: barrierId } = barrier;
                setActiveId(barrierId);
                setActiveProductId(id);
                openBarrierDetailsModal();
              } else if (gate) {
                const { id: gateId } = gate;
                setActiveId(gateId);
                setActiveProductId(id);
                openGateDetailsModal();
              } else if (chain) {
                const { id: chainId } = chain;
                setActiveId(chainId);
                setActiveProductId(id);
                openChainDetailsModal();
              } else if (bollard) {
                const { id: bollardId } = bollard;
                setActiveId(bollardId);
                setActiveProductId(id);
                openBollardDetailsModal();
              }
            };
            return (
              <ProductP key={id} onClick={openModal}>
                <ProductTableIcon iconType={productTypeId} />
                {name}
              </ProductP>
            );
          })}
        </div>
      )}
    </StyledWrapper>
  );
}
