import styled from 'styled-components';

const DropdownFilter = styled.div`
  width: 10%;
  min-width: 130px;
  margin-right: 15px;

  @media (max-width: 520px) {
    margin: 15px 15px 15px 0;
  }
`;

export default DropdownFilter;
