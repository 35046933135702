import { useMemo } from 'react';
import { io } from 'socket.io-client';
import type SocketPath from '../../constants/Shared/SocketPath';
import useAuthContext from '../Auth/useAuthContext';

export default function useSocket(path: SocketPath) {
  const { sessionData } = useAuthContext();
  const socketUrl = useMemo(() => process.env.REACT_APP_SOCKET_URL || '', []);

  return useMemo(() => {
    if (!sessionData) {
      return undefined;
    }

    return io(socketUrl, {
      path,
      query: {
        token: sessionData.token,
      },
    });
  }, [socketUrl, sessionData, path]);
}
