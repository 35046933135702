import type RemoteControlStatisticsParams from 'src/models/Statistics/RemoteControl/RemoteControlStatisticsParams';
import type RemoteControlStatisticsQueryParams from 'src/models/Statistics/RemoteControl/RemoteControlStatisticsQueryParams';
import getTimeRange from 'src/utils/getTimeRange';

export default function toRemoteControlStatisticsQueryParams(
  data: RemoteControlStatisticsParams,
  productId?: number
): RemoteControlStatisticsQueryParams {
  const { timeRange, accountUserId, operation } = data;

  return {
    product_id: productId,
    timerange: getTimeRange(timeRange),
    account_user_id: accountUserId,
    operation,
  };
}
