import React, { useCallback, useEffect, useState } from 'react';
import DeleteModal from 'src/components/Parking/DeleteModal';
import DetectLotTable from 'src/components/Settings/DetectLotTable';
import Button from 'src/components/Shared/Button';
import GlobalModal from 'src/components/Shared/Modals/GlobalModal';
import StyledHeader from 'src/components/Shared/StyledHeader';
import Title from 'src/components/Shared/Title';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';
import type Lot from 'src/models/Parking/Lot/Lot';
import useLotService from 'src/services/Parking/useLotService';
import * as yup from 'yup';
import Form from '../../components/Shared/Form';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../components/Shared/Modals/GlobalSuccessModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import LotModal from './LotModal';

interface Props {
  accountId: number;
}

const deleteLotValidationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
});

export default function DetectLotList(props: Props) {
  const { accountId } = props;
  const [messageSuccess, setMessageSuccess] = useState('');
  const [data, setData] = useState<Lot[]>([]);
  const [activeLot, setActiveLot] = useState<Lot | undefined>(undefined);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const isMounted = useIsMounted();
  const { findAllLotsParklioInternal, removeLot } = useLotService();

  const [openLotModal, closeLotModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <LotModal
        closeParentModal={closeEditLotModal}
        activeLot={activeLot}
        accountId={accountId}
        onUpdate={onUpdate}
      />
    </GlobalModal>
  ));

  const [openDeleteModal, closeDeleteModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <Form
        name='DeleteLot'
        initialValues={{ name: '' }}
        validationSchema={deleteLotValidationSchema}
        onSubmit={onRemove}
      >
        <DeleteModal
          closeParentModal={closeDeleteModal}
          type='lot'
          name={activeLot?.name}
        />
      </Form>
    </GlobalModal>
  ));

  const onUpdate = useCallback((updatedValues: Lot) => {
    setData((oldData) => {
      const index = oldData.findIndex((data) => data.id === updatedValues.id);

      if (index === -1) return oldData;

      oldData[index] = updatedValues;
      return [...oldData];
    });
  }, []);

  const openRemoveModal = useCallback(
    (data: Lot) => {
      openDeleteModal();
      setActiveLot(data);
    },
    [openDeleteModal]
  );

  const { openGlobalSuccessModal: deleteLotSuccessModal } =
    useGlobalSuccessModal({
      message: messageSuccess,
      closeParentModal: closeDeleteModal,
    });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const getData = async () => {
      try {
        if (!accountId) {
          return;
        }
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data } = await findAllLotsParklioInternal({ accountId });

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [accountId, findAllLotsParklioInternal, isMounted]);

  const onRemove = useCallback(
    async (values: { name: string }) => {
      try {
        if (activeLot?.id) {
          const { name } = values;
          if (activeLot.name !== name && isMounted()) {
            setMessage({
              code: 'Undefined',
              message: "Name doesn't match. Please try again.",
            });
            openGlobalFailureModal();
          } else {
            await removeLot(activeLot.id);

            if (isMounted()) {
              setData((oldData) =>
                oldData.filter((data) => data.id !== activeLot.id)
              );
              setActiveLot(undefined);
              setMessageSuccess('Lot deleted successfully!');
              deleteLotSuccessModal();
            }
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      activeLot,
      deleteLotSuccessModal,
      isMounted,
      openGlobalFailureModal,
      removeLot,
      setMessage,
    ]
  );

  const openEditModal = useCallback(
    (lot: Lot) => {
      setActiveLot(lot);
      openLotModal();
    },
    [openLotModal]
  );

  const closeEditLotModal = useCallback(() => {
    setActiveLot(undefined);
    closeLotModal();
  }, [closeLotModal]);

  return (
    <>
      <StyledHeader>
        <Title>Parking Lots</Title>
        <Button primary size={ButtonSize.LARGE} onClick={openLotModal}>
          + Add Detect Lot
        </Button>
      </StyledHeader>
      <DetectLotTable
        isLoading={isDataLoading}
        openEditModal={openEditModal}
        openRemoveModal={openRemoveModal}
        data={data}
      />
    </>
  );
}
