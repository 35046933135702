import React, { useCallback } from 'react';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import Section from '../Shared/Section';
import Title from '../Shared/Title';

interface FinishProps {
  closeParentModal: () => void;
  refreshPage?: boolean;
}

export default function Finish(props: FinishProps) {
  const { closeParentModal, refreshPage } = props;

  const closeModal = useCallback(() => {
    closeParentModal();
    if (refreshPage) window.location.reload();
  }, [closeParentModal, refreshPage]);

  return (
    <>
      <Line />
      <Section>
        <Title bold center>
          Your account was successfully created!
        </Title>
      </Section>
      <Control center>
        <Button onClick={closeModal} size={ButtonSize.LARGE} type='button'>
          Close
        </Button>
      </Control>
    </>
  );
}
