import type LprCameraQuery from 'src/models/Parking/Anpr/LprCamera/LprCameraQuery';
import type LprCameraQueryParams from 'src/models/Parking/Anpr/LprCamera/LprCameraQueryParams';

export default function toLprCameraQuery(
  data: LprCameraQueryParams
): LprCameraQuery {
  const { page, size, lotId, zoneId, deviceType, accountId } = data;

  return {
    page,
    size,
    lot_id: lotId,
    zone_id: zoneId,
    device_type: deviceType,
    account_id: accountId,
  };
}
