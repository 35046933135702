import { useCallback } from 'react';
import ParkingDetectionIntegrations from 'src/constants/Parking/ParkingDetectionIntegrations';
import ContentType from 'src/constants/Shared/ContentType';
import toLot from 'src/mappers/Parking/Lot/toLot';
import toLotCreateRequest from 'src/mappers/Parking/Lot/toLotCreateRequest';
import toLotQuery from 'src/mappers/Parking/Lot/toLotQuery';
import toLotUpdateParams from 'src/mappers/Parking/Lot/toLotUpdateParams';
import type Lot from 'src/models/Parking/Lot/Lot';
import type LotQuery from 'src/models/Parking/Lot/LotQuery';
import type LotQueryParams from 'src/models/Parking/Lot/LotQueryParams';
import type LotResponse from 'src/models/Parking/Lot/LotResponse';
import type LotSyncRequest from 'src/models/Parking/Lot/LotSyncRequest';
import type LotSyncResponse from 'src/models/Parking/Lot/LotSyncResponse';
import toMeta from '../../mappers/Shared/toMeta';
import type LotCreateRequest from '../../models/Parking/Lot/LotCreateRequest';
import type LotCreateResponse from '../../models/Parking/Lot/LotCreateResponse';
import type LotFormValues from '../../models/Parking/Lot/LotFormValues';
import type LotRemoveResponse from '../../models/Parking/Lot/LotRemoveResponse';
import type LotUpdateParams from '../../models/Parking/Lot/LotUpdateParams';
import type LotUpdateValues from '../../models/Parking/Lot/LotUpdateValues';
import type GetAllDetails from '../../models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const lotBaseURL = '/lots';
interface LotService {
  findOneLot: (id: number) => Promise<Lot>;
  findAllLots: () => Promise<GetAllDetails<Lot>>;
  findAllLotsParklioInternal: (
    queryParams: LotQueryParams
  ) => Promise<GetAllDetails<Lot>>;
  createLot: (
    values: LotFormValues,
    targetAccountId?: number
  ) => Promise<LotCreateResponse>;
  updateLot: (id: number, values: LotUpdateValues) => Promise<Lot>;
  removeLot: (id: number) => Promise<LotRemoveResponse>;
  syncLot: (id: number) => Promise<LotSyncResponse>;
}

export default function useLotService(): LotService {
  const { get, post, patch, remove } = useApiService();

  const { get: getParklioInternal } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: true,
  });

  const findOneLot = useCallback(
    async (id: number) => {
      const response = await get<LotResponse>(`${lotBaseURL}/${id}`);

      const data = toLot(response.data);

      return data;
    },
    [get]
  );

  const findAllLots = useCallback(async () => {
    const response = await get<LotResponse[]>(`${lotBaseURL}`);

    const data = response.data.map(toLot);
    const meta = toMeta(response.meta);

    return { data, meta };
  }, [get]);

  const findAllLotsParklioInternal = useCallback(
    async (queryParams: LotQueryParams) => {
      const query = toLotQuery(queryParams);
      const response = await getParklioInternal<LotResponse[], LotQuery>(
        `${lotBaseURL}`,
        query
      );

      const data = response.data.map(toLot);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [getParklioInternal]
  );

  const createLot = useCallback(
    async (values: LotFormValues, targetAccountId?: number) => {
      const integration = values.isDetect
        ? ParkingDetectionIntegrations.FLOW_DETECT
        : undefined;
      const requestBody = toLotCreateRequest(
        values,
        targetAccountId,
        integration
      );
      const response = await post<LotCreateRequest, LotCreateResponse>(
        `${lotBaseURL}`,
        requestBody
      );

      return response;
    },
    [post]
  );

  const updateLot = useCallback(
    async (id: number, values: LotUpdateValues) => {
      const integration = values.isDetect
        ? ParkingDetectionIntegrations.FLOW_DETECT
        : undefined;
      const params = toLotUpdateParams(values, integration);
      const response = await patch<LotUpdateParams, LotResponse>(
        `${lotBaseURL}/${id}`,
        params
      );

      return toLot(response.data);
    },
    [patch]
  );

  const removeLot = useCallback(
    async (id: number) => {
      const response = await remove<LotRemoveResponse>(`${lotBaseURL}/${id}`);

      return response;
    },
    [remove]
  );

  const syncLot = useCallback(
    async (id: number) => {
      const response = await post<LotSyncRequest, LotSyncResponse>(
        `${lotBaseURL}/${id}/sync`,
        {}
      );

      return response;
    },
    [post]
  );

  return {
    findAllLots,
    findOneLot,
    findAllLotsParklioInternal,
    createLot,
    updateLot,
    removeLot,
    syncLot,
  };
}
