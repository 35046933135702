import { useCallback, useMemo } from 'react';
import SessionStorageKey from 'src/constants/Shared/SessionStorageKey';
import toAccountInfo from 'src/mappers/Settings/toAccountInfo';
import type AcceptAutoExtendRequest from 'src/models/Home/AcceptAutoExtendRequest';
import type AcceptAutoExtendResponse from 'src/models/Home/AcceptAutoExtendResponse';
import type AccountRemoveResponse from 'src/models/Home/AccountRemoveResponse';
import AppRole from '../../constants/Shared/AppRole';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';
import toAccount from '../../mappers/Home/toAccount';
import toAccountCreateRequest from '../../mappers/Home/toAccountCreateRequest';
import toAccountQuery from '../../mappers/Home/toAccountQuery';
import toAccountUpdateRequest from '../../mappers/Home/toAccountUpdateRequest';
import toMeta from '../../mappers/Shared/toMeta';
import type Account from '../../models/Home/Account';
import type AccountCreateRequest from '../../models/Home/AccountCreateRequest';
import type AccountCreateResponse from '../../models/Home/AccountCreateResponse';
import type AccountQuery from '../../models/Home/AccountQuery';
import type AccountQueryParams from '../../models/Home/AccountQueryParams';
import type AccountResponse from '../../models/Home/AccountResponse';
import type AccountUpdateRequest from '../../models/Home/AccountUpdateRequest';
import type AccountUpdateResponse from '../../models/Home/AccountUpdateResponse';
import type AccountUpdateValues from '../../models/Home/AccountUpdateValues';
import type AccountWizardValues from '../../models/Home/AccountWizardValues';
import type AccountInfo from '../../models/Settings/AccountInfo';
import type AccountInfoResponse from '../../models/Settings/AccountInfoResponse';
import type ApiResponse from '../../models/Shared/ApiResponse';
import type GetAllDetails from '../../models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const accountBaseURL = '/accounts';
export interface AccountService {
  createAccount: (
    values: AccountWizardValues
  ) => Promise<AccountCreateResponse>;
  updateAccount: (
    values: AccountUpdateValues,
    accountId: number
  ) => Promise<AccountUpdateResponse>;
  findAllAccounts: (
    params: AccountQueryParams
  ) => Promise<GetAllDetails<Account>>;
  findAccountById: (id: number) => Promise<AccountInfo>;
  removeAccount: (id: number) => Promise<AccountRemoveResponse>;
  acceptAutoExtend: () => Promise<AcceptAutoExtendResponse>;
}

export default function useAccountService(): AccountService {
  const { get, post, patch, remove } = useApiService();
  const userHasRole = useUserHasRole();

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const findAllAccounts = useCallback(
    async (queryParams: AccountQueryParams) => {
      const query = toAccountQuery(queryParams);
      const response = await get<AccountResponse[], AccountQuery>(
        `${accountBaseURL}`,
        query
      );
      const data = response.data.map(toAccount);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const createAccount = useCallback(
    async (values: AccountWizardValues) => {
      const requestBody = toAccountCreateRequest(values);

      const response = await post<AccountCreateRequest, AccountCreateResponse>(
        `${accountBaseURL}`,
        requestBody
      );

      return response.data;
    },
    [post]
  );

  const updateAccount = useCallback(
    async (values: AccountUpdateValues, id: number) => {
      const requestBody = toAccountUpdateRequest(values);
      let response: ApiResponse<AccountUpdateResponse>;

      if (
        userIsParklioInternal &&
        id !== parseInt(sessionStorage.getItem(SessionStorageKey.ID) || '')
      ) {
        response = await patch<AccountUpdateRequest, AccountUpdateResponse>(
          `${accountBaseURL}/${id}`,
          requestBody
        );
      } else {
        response = await patch<AccountUpdateRequest, AccountUpdateResponse>(
          `${accountBaseURL}`,
          requestBody
        );
      }

      return response.data;
    },
    [patch, userIsParklioInternal]
  );

  const findAccountById = useCallback(
    async (id: number) => {
      const response = await get<AccountInfoResponse>(
        `${accountBaseURL}/${id}`
      );

      const data = toAccountInfo(response.data);

      return data;
    },
    [get]
  );

  const removeAccount = useCallback(
    async (id: number) => {
      const response = await remove<AccountRemoveResponse>(
        `${accountBaseURL}/${id}`
      );

      return response.data;
    },
    [remove]
  );

  const acceptAutoExtend = useCallback(async () => {
    const response = await post<
      AcceptAutoExtendRequest,
      AcceptAutoExtendResponse
    >(`${accountBaseURL}/auto-extend`, {});

    return response.data;
  }, [post]);

  return {
    findAllAccounts,
    createAccount,
    updateAccount,
    findAccountById,
    removeAccount,
    acceptAutoExtend,
  };
}
