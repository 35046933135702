import React, { useCallback, useState } from 'react';
import Dropdown from 'src/components/Shared/Dropdown';
import GatewayStatus from 'src/constants/Parking/GatewayStatus';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import formatDates from 'src/utils/formatDates';
import { CardWrapper, Name, Status, UpdateInfo } from '../CardStyle';

interface CardProps {
  id: number;
  name: string;
  status: GatewayStatus;
  updatedAt: string;
  onNameClick: (id: number) => void;
}

export default function DetectGatewayCard(props: CardProps) {
  const { id, name, status, updatedAt, onNameClick } = props;

  const offline = status === GatewayStatus.OFFLINE;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onClick = useCallback(() => {
    onNameClick(id);
  }, [onNameClick, id]);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  return (
    <CardWrapper onClick={onClick} onMouseEnter={open} onMouseLeave={close}>
      <Name>{name}</Name>
      <Status offline={offline}>{capitalizeFirstLetter(status)}</Status>
      {isDropdownOpen && (
        <Dropdown close={close} bottomLeftAlign>
          <UpdateInfo>Last updated: {formatDates(updatedAt, true)}</UpdateInfo>
        </Dropdown>
      )}
    </CardWrapper>
  );
}
