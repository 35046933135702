import type LotWhitelistLog from 'src/models/Logs/LotWhitelistLog';
import type LotWhitelistLogResponse from 'src/models/Logs/LotWhitelistLogResponse';
import toAccountUser from '../Settings/toAccountUser';

export default function toLotWhitelistLog(
  data: LotWhitelistLogResponse
): LotWhitelistLog {
  const {
    id,
    account_id,
    account_user_id,
    lot_id,
    license_plate,
    operation,
    start_time,
    end_time,
    is_unlimited,
    created_at,
    updated_at,
    account_user,
  } = data;

  return {
    id,
    accountId: account_id,
    accountUserId: account_user_id,
    lotId: lot_id,
    licensePlate: license_plate,
    operation,
    startTime: start_time,
    endTime: end_time,
    isUnlimited: is_unlimited,
    createdAt: created_at === null ? null : new Date(created_at),
    updatedAt: updated_at === null ? null : new Date(updated_at),
    accountUser: toAccountUser(account_user),
  };
}
