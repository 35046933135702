import { useDay } from '@datepicker-react/hooks';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import Color from '../../../constants/Shared/Color';
import useDatePickerContext from '../../../hooks/Shared/useDatePickerContext';

interface DayProps {
  dayLabel: string;
  date: Date;
}

export default function Day(props: DayProps) {
  const { date, dayLabel } = props;
  const dayRef = useRef(null);

  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useDatePickerContext();
  const {
    isSelected,
    isSelectedStartOrEnd,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  return (
    <StyledButton
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onKeyDown={onKeyDown}
      tabIndex={-1}
      isSelected={isSelected || isSelectedStartOrEnd}
      disabledDate={disabledDate}
      ref={dayRef}
      type='button'
    >
      {dayLabel}
    </StyledButton>
  );
}

const StyledButton = styled.button<{
  isSelected: boolean;
  disabledDate: boolean;
}>`
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 30px;
  width: calc(100% / 7);
  border: none;
  border-radius: 5px;
  cursor: pointer;

  ${(props) => {
    const { disabledDate, isSelected } = props;
    if (disabledDate) {
      return css`
        cursor: not-allowed;
        color: ${Color.TEXT_LIGHT};
        background-color: ${Color.BACKGROUND_LIGHTEST};
        font-weight: normal;
      `;
    }
    if (!isSelected) {
      return css`
        color: ${Color.TEXT_DARKER};
        background-color: ${Color.BACKGROUND_LIGHTEST};
        font-weight: normal;

        :hover {
          background-color: ${Color.BACKGROUND_LIGTH};
        }
      `;
    }

    return css`
      background-color: ${Color.PRIMARY_BRAND};
      color: ${Color.TEXT_LIGHTEST};
      font-weight: bold;

      :hover {
        background-color: ${Color.PRIMARY_HOVER};
      }
    `;
  }}

  :focus {
    outline: none;
  }
`;
