import type LicensePurchaseFormValues from 'src/models/License/LicensePurchaseFormValues';
import type LicensePurchaseUpdateParams from 'src/models/License/LicensePurchaseUpdateParams';
import toLicenseInfoUpdate from './toLicenseInfoUpdate';

export default function toLicensePurchaseUpdateParams(
  data: LicensePurchaseFormValues,
  accountId: number
): LicensePurchaseUpdateParams {
  const { licenseInfo } = data;

  return {
    license_info: licenseInfo?.map((license) => toLicenseInfoUpdate(license)),
    account_id: accountId,
  };
}
