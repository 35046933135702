import type ProductChangeSettings from 'src/models/Parking/ProductChangeSettings';
import type ProductChangeSettingsResponse from 'src/models/Parking/ProductChangeSettingsResponse';

export default function toProductChangeSettings(
  data: ProductChangeSettingsResponse
): ProductChangeSettings {
  const { success, event_id } = data;

  return { success, eventId: event_id };
}
