import type Option from 'src/models/Shared/Option';

export default function getBinaryDates(days: Option[] | undefined) {
  if (!days) {
    return undefined;
  }
  let binary = '0000000';

  for (let i = 0; i < days.length; i++) {
    binary = `${binary.substring(0, days[i].key)}1${binary.substring(
      days[i].key + 1
    )}`;
  }

  return binary;
}
