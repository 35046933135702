import styled from 'styled-components';

const LoadingWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LoadingWrapper;
