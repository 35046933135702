import type LprEvent from 'src/models/Parking/Anpr/LprEvent';
import type LprEventPayload from 'src/models/Parking/Anpr/LprEventPayload';
import toLotEntry from './toLotEntry';

export default function toLprEvent(eventData: LprEventPayload): LprEvent {
  const { lot_id, data } = eventData;

  return {
    lotId: lot_id,
    data: toLotEntry(data),
  };
}
