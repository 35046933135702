import type LotWhitelist from 'src/models/Parking/Anpr/LotWhitelist/LotWhitelist';
import type LotWhitelistResponse from 'src/models/Parking/Anpr/LotWhitelist/LotWhitelistResponse';

export default function toLotWhitelist(
  data: LotWhitelistResponse
): LotWhitelist {
  const {
    id,
    lot_id,
    license_plate,
    name,
    status,
    start_time,
    end_time,
    deleted_at,
    created_at,
    updated_at,
  } = data;

  return {
    id,
    lotId: lot_id,
    licensePlate: license_plate,
    name,
    status,
    startTime: start_time,
    endTime: end_time,
    deletedAt: deleted_at,
    createdAt: created_at,
    updatedAt: updated_at,
  };
}
