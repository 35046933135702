import type AccountUser from 'src/models/Settings/AccountUser';
import type AccountUserFormValues from '../../models/Settings/AccountUserFormValues';

export default function toAccountUserFormValues(
  data: AccountUser
): AccountUserFormValues {
  const { email, name, role, phone } = data;

  return { email, name, role, phone: phone || '' };
}
