import React, { useEffect, useMemo, useState } from 'react';
import AppRole from 'src/constants/Shared/AppRole';
import SessionStorageKey from 'src/constants/Shared/SessionStorageKey';
import useUserHasRole from 'src/hooks/Shared/useUserHasRole';
import type Country from 'src/models/Shared/Country';
import type Currency from 'src/models/Shared/Currency';
import useCountryService from 'src/services/Shared/useCountryService';
import useCurrencyService from 'src/services/Shared/useCurrencyService';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type Option from '../../models/Shared/Option';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Control from '../Shared/Control';
import Section from '../Shared/Section';
import SelectField from '../Shared/SelectField/index';
import Title from '../Shared/Title';

interface InvoiceSetupFormProps {
  accountId: number;
}

export default function InvoiceSetupForm(props: InvoiceSetupFormProps) {
  const { accountId } = props;
  const [countries, setCountries] = useState<Country[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [searchCountry, setSearchCountry] = useState('');
  const [searchCurrency, setSearchCurrency] = useState('');
  const { findAllCountries } = useCountryService();
  const { findAllCurrencies } = useCurrencyService();
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();

  const userIsParklioInternalSuperAdmin = useMemo(() => {
    const roles = AppRole.PARKLIO_INTERNAL_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const nonOwnerParklioInternal = useMemo(
    () =>
      userIsParklioInternalSuperAdmin &&
      accountId !==
        parseInt(sessionStorage.getItem(SessionStorageKey.ID) || ''),
    [accountId, userIsParklioInternalSuperAdmin]
  );

  useEffect(() => {
    const getData = async () => {
      const countries = findAllCountries();
      const currencies = findAllCurrencies();

      const response = await Promise.all([countries, currencies]);

      const [countryData, currencyData] = response;

      if (isMounted()) {
        setCountries(countryData);
        setCurrencies(currencyData);
      }
    };

    getData();
  }, [findAllCountries, findAllCurrencies, isMounted]);

  const countryOptions: Option[] = useMemo(
    () =>
      countries
        .filter(({ name }) =>
          name.toLowerCase().includes(searchCountry.toLowerCase())
        )
        .map(({ id, name }) => ({
          key: id,
          label: name,
        })),
    [countries, searchCountry]
  );

  const currencyOptions: Option[] = useMemo(
    () =>
      currencies
        .filter(({ name }) =>
          name.toLowerCase().includes(searchCurrency.toLowerCase())
        )
        .map(({ id, name }) => ({
          key: id,
          label: name,
        })),
    [currencies, searchCurrency]
  );

  return (
    <>
      <Title>Invoice Setup</Title>
      <Card>
        <Section>
          <SelectField
            label='Select Country'
            placeholder='Select Country'
            name='invoiceCountry'
            options={countryOptions}
            short
            onSearch={setSearchCountry}
            search={searchCountry}
            readonly={nonOwnerParklioInternal}
          />
          <SelectField
            label='Select Currency'
            placeholder='Select Currency'
            name='invoiceCurrency'
            options={currencyOptions}
            short
            onSearch={setSearchCurrency}
            search={searchCurrency}
            readonly={nonOwnerParklioInternal}
          />
        </Section>
        <Control>
          {!nonOwnerParklioInternal && (
            <Button size={ButtonSize.LARGE} type='submit' primary>
              Save
            </Button>
          )}
        </Control>
      </Card>
    </>
  );
}
