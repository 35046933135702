import { useCallback } from 'react';
import toRotaryParkingPlace from 'src/mappers/Rotaries/RotaryParkingPlaces/toRotaryParkingPlace';
import toRotaryParkingPlaceCreateRequest from 'src/mappers/Rotaries/RotaryParkingPlaces/toRotaryParkingPlaceCreateRequest';
import toRotaryParkingPlaceQuery from 'src/mappers/Rotaries/RotaryParkingPlaces/toRotaryParkingPlaceQuery';
import toRotaryParkingPlaceUpdateParams from 'src/mappers/Rotaries/RotaryParkingPlaces/toRotaryParkingPlaceUpdateParams';
import toMeta from 'src/mappers/Shared/toMeta';
import type RotaryParkingPlace from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlace';
import type RotaryParkingPlaceCreateRequest from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceCreateRequest';
import type RotaryParkingPlaceCreateResponse from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceCreateResponse';
import type RotaryParkingPlaceFormValues from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceFormValues';
import type RotaryParkingPlaceQuery from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceQuery';
import type RotaryParkingPlaceQueryParams from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceQueryParams';
import type RotaryParkingPlaceRemoveResponse from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceRemoveResponse';
import type RotaryParkingPlaceResponse from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceResponse';
import type RotaryParkingPlaceUpdateParams from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceUpdateParams';
import type RotaryParkingPlaceUpdateResponse from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceUpdateResponse';
import type RotaryParkingPlaceUpdateValues from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceUpdateValues';
import type GetAllDetails from '../../models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const rotaryParkingPlaceBaseURL = '/rotaries/parking-places';
interface RotaryParkingPlaceService {
  findAllRotaryParkingPlaces: (
    queryParams: RotaryParkingPlaceQueryParams
  ) => Promise<GetAllDetails<RotaryParkingPlace>>;
  findOneRotaryParkingPlace: (id: number) => Promise<RotaryParkingPlace>;
  createRotaryParkingPlace: (
    values: RotaryParkingPlaceFormValues,
    rotaryId: number
  ) => Promise<RotaryParkingPlaceCreateResponse>;
  updateRotaryParkingPlace: (
    id: number,
    values: RotaryParkingPlaceUpdateValues,
    rotaryId?: number
  ) => Promise<RotaryParkingPlaceUpdateResponse>;
  removeRotaryParkingPlace: (
    id: number
  ) => Promise<RotaryParkingPlaceRemoveResponse>;
}

export default function useRotaryParkingPlaceService(): RotaryParkingPlaceService {
  const { post, get, remove, patch } = useApiService();

  const findAllRotaryParkingPlaces = useCallback(
    async (queryParams: RotaryParkingPlaceQueryParams) => {
      const query = toRotaryParkingPlaceQuery(queryParams);
      const response = await get<
        RotaryParkingPlaceResponse[],
        RotaryParkingPlaceQuery
      >(`${rotaryParkingPlaceBaseURL}`, query);

      const data = response.data.map(toRotaryParkingPlace);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findOneRotaryParkingPlace = useCallback(
    async (id: number) => {
      const response = await get<RotaryParkingPlaceResponse>(
        `${rotaryParkingPlaceBaseURL}/${id}`
      );

      return toRotaryParkingPlace(response.data);
    },
    [get]
  );

  const createRotaryParkingPlace = useCallback(
    async (values: RotaryParkingPlaceFormValues, rotaryId: number) => {
      const requestBody = toRotaryParkingPlaceCreateRequest(values, rotaryId);
      const response = await post<
        RotaryParkingPlaceCreateRequest,
        RotaryParkingPlaceCreateResponse
      >(`${rotaryParkingPlaceBaseURL}`, requestBody);

      return response;
    },
    [post]
  );

  const updateRotaryParkingPlace = useCallback(
    async (
      id: number,
      values: RotaryParkingPlaceUpdateValues,
      rotaryId?: number
    ) => {
      const params = toRotaryParkingPlaceUpdateParams(values, rotaryId);
      const response = await patch<
        RotaryParkingPlaceUpdateParams,
        RotaryParkingPlaceUpdateResponse
      >(`${rotaryParkingPlaceBaseURL}/${id}`, params);

      return response;
    },
    [patch]
  );

  const removeRotaryParkingPlace = useCallback(
    async (id: number) => {
      const response = await remove<RotaryParkingPlaceRemoveResponse>(
        `${rotaryParkingPlaceBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return {
    findAllRotaryParkingPlaces,
    findOneRotaryParkingPlace,
    createRotaryParkingPlace,
    updateRotaryParkingPlace,
    removeRotaryParkingPlace,
  };
}
