enum ProductTypes {
  GATEWAY = 0,
  LEGACY_BARRIER = 1,
  BARRIER = 2,
  GATE = 3,
  BRAIN = 4,
  CHAIN = 5,
  BOLLARD = 6,
}

export default ProductTypes;
