import type LotChangeEventData from 'src/models/Parking/Lot/SocketEvents/LotChangeEvent/LotChangeEventData';
import type LotChangeEventDataPayload from 'src/models/Parking/Lot/SocketEvents/LotChangeEvent/LotChangeEventDataPayload';
import toLotChangeEventBarrier from './toLotChangeEventBarrier';
import toLotChangeEventBollard from './toLotChangeEventBollard';
import toLotChangeEventBrain from './toLotChangeEventBrain';
import toLotChangeEventChain from './toLotChangeEventChain';
import toLotChangeEventGate from './toLotChangeEventGate';
import toLotChangeEventProductError from './toLotChangeEventProductError';

export default function toLotChangeEventData(
  data: LotChangeEventDataPayload
): LotChangeEventData {
  const {
    id,
    status,
    battery_status,
    product_error_id,
    rssi,
    barrier,
    gate,
    chain,
    bollard,
    product_error,
    brain,
  } = data;

  return {
    id,
    status,
    batteryStatus: battery_status,
    productErrorId: product_error_id,
    rssi,
    barrier: barrier ? toLotChangeEventBarrier(barrier) : undefined,
    gate: gate ? toLotChangeEventGate(gate) : undefined,
    chain: chain ? toLotChangeEventChain(chain) : undefined,
    bollard: bollard ? toLotChangeEventBollard(bollard) : undefined,
    productError: product_error
      ? toLotChangeEventProductError(product_error)
      : undefined,
    brain: brain ? toLotChangeEventBrain(brain) : undefined,
  };
}
