import type { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmEmailModal from 'src/components/Auth/ConfirmEmailModal';
import GlobalModal from 'src/components/Shared/Modals/GlobalModal';
import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';
import * as yup from 'yup';
import Container from '../../components/Auth/Container';
import Form from '../../components/Auth/Form';
import LoginForm from '../../components/Auth/LoginForm';
import ParklioSign from '../../components/Auth/ParklioSign';
import Title from '../../components/Auth/Title';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type LoginFormValues from '../../models/Auth/LoginFormValues';
import useAuthService from '../../services/Auth/useAuthService';
import { isNotString } from '../../utils/checks';

const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is a required field')
    .email('Enter a valid email address'),
  password: yup.string().required('Password is a required field'),
});

export default function Login() {
  const { logIn } = useAuthService();
  const isMounted = useIsMounted();
  const history = useHistory<{ goBackLocation?: string }>();
  const [email, setEmail] = useState('');

  const [openConfirmEmailModal, closeConfirmEmailModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <ConfirmEmailModal
        email={email}
        closeParentModal={closeConfirmEmailModal}
      />
    </GlobalModal>
  ));

  const onSubmit = useCallback(
    async (
      values: LoginFormValues,
      { setErrors }: FormikHelpers<LoginFormValues>
    ) => {
      try {
        if (isMounted()) {
          setEmail(values.email);
        }

        await logIn(values);

        const location = history.location.state?.goBackLocation || '/';

        history.push(location);
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          openConfirmEmailModal();
        }
      }
    },
    [logIn, history, isMounted, openConfirmEmailModal]
  );

  return (
    <Container>
      <ParklioSign />
      <Title>Login</Title>
      <Form
        name='login'
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <LoginForm />
      </Form>
    </Container>
  );
}
