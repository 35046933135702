import React, { useCallback, useMemo } from 'react';
import SessionStorageKey from 'src/constants/Shared/SessionStorageKey';
import ActionIcon from '../../constants/Shared/ActionIcon';
import type AccountUser from '../../models/Settings/AccountUser';
import Card from '../Shared/Card';
import StyledNoData from '../Shared/StyledNoData';
import Table from '../Shared/Table';
import Title from '../Shared/Title';

interface AccountUserTableProps {
  openEditModal: (id: number) => void;
  openRemoveModal: (id: number) => void;
  data: AccountUser[];
  isLoading?: boolean;
  accountId: number;
}

export default function AccountUserTable(props: AccountUserTableProps) {
  const { data, openEditModal, openRemoveModal, isLoading, accountId } = props;

  const isDisabled = useMemo(
    () =>
      accountId !==
      parseInt(sessionStorage.getItem(SessionStorageKey.ID) || ''),
    [accountId]
  );

  const removeItem = useCallback(
    (data?: AccountUser) => {
      if (
        !data ||
        accountId !==
          parseInt(sessionStorage.getItem(SessionStorageKey.ID) || '') ||
        data.id ===
          parseInt(sessionStorage.getItem(SessionStorageKey.USER) || '')
      ) {
        return;
      }

      const { id } = data;

      openRemoveModal(id);
    },
    [openRemoveModal, accountId]
  );

  const editItem = useCallback(
    (data?: AccountUser) => {
      if (
        !data ||
        accountId !==
          parseInt(sessionStorage.getItem(SessionStorageKey.ID) || '')
      ) {
        return;
      }

      const { id } = data;

      openEditModal(id);
    },
    [openEditModal, accountId]
  );

  return (
    <>
      <Title>Parking Managers</Title>
      <Card>
        <Table isLoading={isLoading}>
          <Table.Head>
            <Table.Row>
              <Table.Header>USER</Table.Header>
              <Table.Header>ROLE</Table.Header>
              <Table.Header>EMAIL</Table.Header>
              <Table.Header>PHONE</Table.Header>
              <Table.Header colSpan={2} />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.length > 0 ? (
              data.map((accountUserFindByIdDetails) => {
                const { name, role, email, phone, id } =
                  accountUserFindByIdDetails;

                const loggedUser =
                  id ===
                  parseInt(
                    sessionStorage.getItem(SessionStorageKey.USER) || ''
                  );

                return (
                  <Table.Row key={id}>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{role.label}</Table.Cell>
                    <Table.Cell>{email}</Table.Cell>
                    <Table.Cell>{phone}</Table.Cell>
                    <Table.Cell
                      center
                      action={removeItem}
                      primary={!isDisabled && !loggedUser}
                      data={accountUserFindByIdDetails}
                      disabled={isDisabled || loggedUser}
                    >
                      <Table.Icon
                        icon={ActionIcon.DELETE}
                        disabled={isDisabled || loggedUser}
                      />
                    </Table.Cell>
                    <Table.Cell
                      center
                      action={editItem}
                      primary={!isDisabled}
                      data={accountUserFindByIdDetails}
                      disabled={isDisabled}
                    >
                      <Table.Icon
                        icon={ActionIcon.EDIT}
                        disabled={isDisabled}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell merged={4}>
                  <StyledNoData>No operators added.</StyledNoData>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card>
    </>
  );
}
