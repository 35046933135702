import type LicensePlateAllow from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateAllow';
import type LicensePlateAllowResponse from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateAllowResponse';

export default function toLicensePlateAllow(
  data: LicensePlateAllowResponse
): LicensePlateAllow {
  const { success, whitelist_id, license_id, plate } = data;

  return {
    success,
    whitelistId: whitelist_id,
    licenseId: license_id,
    plate,
  };
}
