import type RotaryParkingPlace from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlace';
import type RotaryParkingPlaceResponse from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceResponse';

export default function toRotaryParkingPlace(
  data: RotaryParkingPlaceResponse
): RotaryParkingPlace {
  const { id, rotary_id, name, is_occupied, created_at, updated_at } = data;

  return {
    id,
    rotaryId: rotary_id,
    name,
    isOccupied: is_occupied,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
  };
}
