import type LotDetectionChangeEvent from 'src/models/Parking/Lot/SocketEvents/LotDetectionChangeEvent/LotDetectionChangeEvent';
import type LotDetectionChangeEventPayload from 'src/models/Parking/Lot/SocketEvents/LotDetectionChangeEvent/LotDetectionChangeEventPayload';
import toLotDetectionChangeEventData from './toLotDetectionChangeEventData';

export default function toLotDetectionChangeEvent(
  data: LotDetectionChangeEventPayload
): LotDetectionChangeEvent {
  const { lots } = data;

  return {
    lots: lots.map(toLotDetectionChangeEventData),
  };
}
