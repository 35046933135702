import styled from 'styled-components';

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
`;

export default Actions;
