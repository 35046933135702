import styled, { css } from 'styled-components';

interface ControlProps {
  center?: boolean;
  left?: boolean;
  reverse?: boolean;
  scrollOverflow?: boolean;
  justifyContent?: string;
}

const Control = styled.div<ControlProps>`
  display: flex;
  align-items: center;
  min-height: 40px;
  ${(props) => {
    const { center, left, justifyContent } = props;

    if (center) {
      return css`
        justify-content: center;
      `;
    }
    if (left) {
      return css`
        justify-content: left;
      `;
    }
    if (justifyContent) {
      return css`
        justify-content: ${justifyContent};
      `;
    }
    return css`
      justify-content: flex-end;
    `;
  }}

  ${(props) => {
    const { scrollOverflow } = props;

    if (scrollOverflow) {
      return css`
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { reverse } = props;

    if (reverse) {
      return css`
        flex-direction: row-reverse;
        justify-content: flex-start;
      `;
    }

    return css`
      flex-direction: row;
    `;
  }}

    @media (max-width: 520px) {
    display: inline-block;
  }
`;

export default Control;
