import React, { useCallback } from 'react';
import { GetLicenseDescription } from 'src/components/License/GetLicenseDescription';
import WizardIcon from 'src/constants/Shared/WizardIcon';
import type LicensePricing from 'src/models/License/LicensePricing';
import Card from '../Shared/Card';
import Table from '../Shared/Table';

interface LicenseTableProps {
  data: LicensePricing[];
  isLoading: boolean;
  openEditLicenseModal: (licensePricingId: number, licenseId: number) => void;
}

export default function LicenseTable(props: LicenseTableProps) {
  const { data, isLoading, openEditLicenseModal } = props;

  const openEditModal = useCallback(
    (data?: LicensePricing) => {
      if (!data || !data.license) {
        return;
      }

      const { id: licensePricingId, license } = data;
      const { id: licenseId } = license;

      openEditLicenseModal(licensePricingId, licenseId);
    },
    [openEditLicenseModal]
  );

  return (
    <Card>
      <Table isLoading={isLoading}>
        <Table.Colgroup>
          <col span={1} style={{ width: '30%' }} />
          <col span={1} style={{ width: '50%' }} />
          <col span={1} style={{ width: '20%' }} />
        </Table.Colgroup>
        <Table.Head>
          <Table.Row>
            <Table.Header>NAME</Table.Header>
            <Table.Header>DESCRIPTION</Table.Header>
            <Table.Header center>PUBLISHED</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map((licenseData) => {
            const { id, name, status, license } = licenseData;

            return (
              <Table.Row key={id}>
                <Table.Cell primary action={openEditModal} data={licenseData}>
                  {name}
                </Table.Cell>
                <Table.Cell>{GetLicenseDescription(license)}</Table.Cell>
                <Table.Cell center>
                  <i
                    data-testid={status ? WizardIcon.YES : WizardIcon.NO}
                    className={status ? WizardIcon.YES : WizardIcon.NO}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Card>
  );
}
