import Color from 'src/constants/Shared/Color';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  font-family: Montserrat;
  align-items: flex-start;

  @media (min-width: 1000px) {
    margin: 20px 0;
  }

  @media (max-width: 850px) {
    align-items: center;
    width: 100%;
  }

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const StyledLabel = styled.p`
  font-weight: bold;
  color: ${Color.TEXT_LIGHT};
  margin: 10px 0;
`;

const StyledLabelTotal = styled.p`
  font-weight: bold;
  color: ${Color.BACKGROUND_DARKEST_TRANSPARENT};
  margin: 20px 0;
`;

const StyledFree = styled.p`
  font-weight: bold;
  color: ${Color.PARKING_PLACE_FREE};
  margin: 10px 0;
`;

const StyledOccupied = styled.p`
  font-weight: bold;
  color: ${Color.PARKING_PLACE_OCCUPIED};
  margin: 10px 0;
`;

const StyledTotal = styled.p`
  font-weight: bold;
  color: ${Color.BACKGROUND_DARKEST_TRANSPARENT};
  margin: 20px 0;
`;

const StyledUnknown = styled.p`
  font-weight: bold;
  color: ${Color.PARKING_PLACE_UNKNOWN};
  margin: 10px 0;
`;

export {
  StyledWrapper,
  StyledLabel,
  StyledLabelTotal,
  StyledFree,
  StyledOccupied,
  StyledTotal,
  StyledUnknown,
};
