import type { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import PasswordCreationErrorMessages from 'src/constants/Shared/PasswordCreationErrorMessages';
import * as yup from 'yup';
import UserSecurityForm from '../../components/Settings/UserSecurityForm';
import Form from '../../components/Shared/Form';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../components/Shared/Modals/GlobalSuccessModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type UserSecurityFormValues from '../../models/Settings/UserSecurityFormValues';
import useAccountUserService from '../../services/Settings/useAccountUserService';
import { isNotString } from '../../utils/checks';

const initialValues: UserSecurityFormValues = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const validationSchema = yup.object({
  oldPassword: yup.string().required('Old Password is a required field'),
  newPassword: yup
    .string()
    .required(PasswordCreationErrorMessages.REQUIRED)
    .min(8, PasswordCreationErrorMessages.MIN_LEN)
    .matches(/^(?=.*[a-z])/, PasswordCreationErrorMessages.LOWERCASE)
    .matches(/^(?=.*[A-Z])/, PasswordCreationErrorMessages.UPPERCASE)
    .matches(/^(?=.*[0-9])/, PasswordCreationErrorMessages.NUMBER)
    .matches(
      /[!?@#$%^&*()\-/_"'=+{};:,<.>]/,
      PasswordCreationErrorMessages.SPECIAL_CHAR
    ),
  newPasswordConfirmation: yup
    .string()
    .required('Confirm New Password is a required field')
    .oneOf([yup.ref('newPassword'), ''], 'Passwords must match'),
});

export default function UserSecurityEdit() {
  const { updateProfileAccountUser } = useAccountUserService();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: 'Successfuly updated user password!',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (
      values: UserSecurityFormValues,
      { setErrors, resetForm }: FormikHelpers<UserSecurityFormValues>
    ) => {
      try {
        setIsLoading(true);
        await updateProfileAccountUser(values);

        if (isMounted()) {
          openGlobalSuccessModal();
          resetForm();
          setIsLoading(false);
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsLoading(false);
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      updateProfileAccountUser,
      isMounted,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  return (
    <Form
      name='userSecurity'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      isLoading={isLoading}
      autoComplete='new-password'
    >
      <UserSecurityForm />
    </Form>
  );
}
