import type DetectGatewayChangeData from 'src/models/Parking/DetectGateway/DetectGatewayChangeData';
import type DetectGatewayChangePayloadData from 'src/models/Parking/DetectGateway/DetectGatewayChangePayloadData';

export default function toDetectGatewayChangeData(
  data: DetectGatewayChangePayloadData
): DetectGatewayChangeData {
  const { id, status } = data;

  return {
    id,
    status,
  };
}
