import React from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../Spinner';
import Body from './Body';
import Cell from './Cell';
import Colgroup from './Colgroup';
import Head from './Head';
import Header from './Header';
import Icon from './Icon';
import Row from './Row';

interface TableProps {
  children: React.ReactNode;
  isLoading?: boolean;
  margin?: boolean;
  customWidth?: boolean;
}

export default function Table(props: TableProps) {
  const { children, isLoading, margin, customWidth } = props;

  return (
    <>
      {isLoading ? (
        <StyledLoadingWrapper>
          <Spinner primary data-testid='tableSpinner' />
        </StyledLoadingWrapper>
      ) : (
        <StyledTableWrapper customWidth={customWidth}>
          <StyledTable margin={margin}>{children}</StyledTable>
        </StyledTableWrapper>
      )}
    </>
  );
}

Table.Head = Head;
Table.Body = Body;
Table.Row = Row;
Table.Header = Header;
Table.Cell = Cell;
Table.Icon = Icon;
Table.Colgroup = Colgroup;

const StyledLoadingWrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTable = styled.table<{ margin?: boolean }>`
  width: inherit;
  border-collapse: collapse;

  ${(props) => {
    const { margin } = props;

    if (!margin) {
      return css``;
    }

    return css`
      margin: 20px 0px;
    `;
  }}
`;

const StyledTableWrapper = styled.div<{ customWidth?: boolean }>`
  width: inherit;

  ${(props) => {
    const { customWidth } = props;

    if (customWidth) {
      return css`
        width: 100%;
      `;
    }
    return css``;
  }}

  @media (max-width: 1700px) {
    overflow: auto;
  }
`;
