import React, { useMemo } from 'react';
import StyledLink from './Link';

interface ParklioPhoneNumberProps {
  callingCode: string;
  phoneNumber: string;
}

export default function ParklioPhoneNumber(props: ParklioPhoneNumberProps) {
  const { callingCode, phoneNumber } = props;

  const removeZeroFromNumber = useMemo(() => {
    if (phoneNumber[0] === '0') {
      return phoneNumber.slice(1);
    }

    return phoneNumber;
  }, [phoneNumber]);

  const displayNumber = useMemo(
    () =>
      `${callingCode} ${removeZeroFromNumber.slice(
        0,
        2
      )} ${removeZeroFromNumber.slice(2, 6)} ${removeZeroFromNumber.slice(6)}`,
    [callingCode, removeZeroFromNumber]
  );

  return (
    <StyledLink href={`tel:${callingCode}${removeZeroFromNumber}`}>
      {displayNumber}
    </StyledLink>
  );
}
