import React, { useMemo } from 'react';
import OccupancyStatuses from 'src/constants/Parking/OccupancyStatuses';
import ParkingDetectionStatus from 'src/constants/Parking/ParkingDetectionStatus';
import Color from 'src/constants/Shared/Color';
import type CameraParkingPlaces from 'src/models/Parking/DetectCamera/CameraParkingPlaces';
import addZeroBeforeNumber from 'src/utils/addZeroBeforeNumber';
import { getDetectionStatusColor } from 'src/utils/getDetectionStatusColor';
import getTimeFromMiliseconds from 'src/utils/getTimeFromMiliseconds';
import styled, { css } from 'styled-components';

interface ParkingPlaceInfoProps {
  data: CameraParkingPlaces | undefined;
  lotDetectionStatus: ParkingDetectionStatus | undefined;
}

export default function ParkingPlaceInfo(props: ParkingPlaceInfoProps) {
  const { data, lotDetectionStatus } = props;

  const getBackgroundColor = useMemo(() => {
    if (lotDetectionStatus !== ParkingDetectionStatus.ONLINE) {
      return Color.DETECTION_BACKGROUND_UNKNOWN;
    }
    switch (data?.occupancyStatus) {
      case OccupancyStatuses.FREE:
        return Color.DETECTION_BACKGROUND_FREE;
      case OccupancyStatuses.OCCUPIED:
        return Color.DETECTION_BACKGROUND_OCCUPIED;
      case OccupancyStatuses.UNKNOWN:
        return Color.DETECTION_BACKGROUND_UNKNOWN;
      default:
        return Color.DETECTION_BACKGROUND_UNKNOWN;
    }
  }, [data, lotDetectionStatus]);

  const formatTime = useMemo(() => {
    if (!data || !data.updatedAt) return undefined;
    const miliseconds =
      new Date().getTime() - new Date(data.updatedAt).getTime();
    const time = getTimeFromMiliseconds(miliseconds);
    const { days, hours, minutes, seconds } = time;
    const day = () => {
      if (days <= 0) return undefined;
      if (days === 1) {
        return `${days} day `;
      }
      return `${days} days `;
    };

    const res = day() ? day() : '';

    return (
      res +
      `${addZeroBeforeNumber(hours)}:${addZeroBeforeNumber(
        minutes
      )}:${addZeroBeforeNumber(seconds)}`
    );
  }, [data]);

  const isLotOnline = useMemo(
    () => lotDetectionStatus === ParkingDetectionStatus.ONLINE,
    [lotDetectionStatus]
  );

  return (
    <StyledWrapper background={getBackgroundColor}>
      <h1>{data?.name}</h1>
      <div>
        <svg width='20' height='20' viewBox='0 0 40 40'>
          <circle
            cx='20'
            cy='20'
            r='20'
            fill={
              data?.occupancyStatus &&
              getDetectionStatusColor(data?.occupancyStatus, isLotOnline)
            }
            fillOpacity='0.5'
          />
          <circle
            cx='20'
            cy='20'
            r='15'
            fill={
              data?.occupancyStatus &&
              getDetectionStatusColor(data?.occupancyStatus, isLotOnline)
            }
            strokeLinejoin='round'
            strokeWidth='2'
            strokeLinecap='round'
            stroke='#000'
          />
        </svg>
        <span>
          {isLotOnline
            ? data?.occupancyStatus?.toUpperCase()
            : OccupancyStatuses.UNKNOWN.toUpperCase()}
        </span>
      </div>
      <p>
        Status duration <span>{formatTime}</span>
      </p>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ background: string }>`
  all: unset;
  color: ${Color.TEXT_DARKER};
  font-family: Open Sans;

  & > h1 {
    font-family: Montserrat;
    font-style: normal;
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    margin: 0;
    text-wrap: wrap;
  }

  & > div {
    padding: 10px;
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 15px 0;
    border-radius: 5px;

    ${(props) => {
      const { background } = props;

      if (background) {
        return css`
          background-color: ${background};
        `;
      }
      return css``;
    }}

    & > span {
      padding: 0 10px;
      font-weight: 600;
      color: ${Color.TEXT_LIGHTER};
    }
  }

  & > p {
    all: unset;
    font-size: 16px;
    font-weight: 600;
    text-wrap: wrap;

    & > span {
      margin-left: 20px;
    }
  }
`;
