import type AccountUserQuery from 'src/models/Home/AccountUserQuery';
import type AccountUserQueryParams from 'src/models/Home/AccountUserQueryParams';

export default function toAccountUserQuery(
  data: AccountUserQueryParams
): AccountUserQuery {
  const { page, size, accountRoleId, name, email } = data;

  return {
    page,
    size,
    account_role_id: accountRoleId,
    name,
    email,
  };
}
