import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Content from '../../components/Shared/Content';
import Main from '../../components/Shared/Main';
import SideNavigation from '../../components/Shared/SideNavigation';
import UserProfileEdit from '../../screens/Settings/UserProfileEdit';
import UserSecurityEdit from '../../screens/Settings/UserSecurityEdit';
import Accounts from './Accounts';

export default function Settings() {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}/user/profile`}>
          <Breadcrumb to='/' small>
            <Breadcrumb.Link to='/'>Dashboard</Breadcrumb.Link>
            <Breadcrumb.Link to={`${path}/user/profile`} last>
              User Profile
            </Breadcrumb.Link>
          </Breadcrumb>
        </Route>
        <Route exact path={`${path}/user/security`}>
          <Breadcrumb to='/' small>
            <Breadcrumb.Link to='/'>Dashboard</Breadcrumb.Link>
            <Breadcrumb.Link to={`${path}/user/security`} last>
              User Security
            </Breadcrumb.Link>
          </Breadcrumb>
        </Route>
      </Switch>
      <Main small>
        <Switch>
          <Route path={`${path}/user`}>
            <SideNavigation>
              <SideNavigation.Link to='/settings/user/profile' exact>
                User Profile
              </SideNavigation.Link>
              <SideNavigation.Link to='/settings/user/security' exact>
                User Security
              </SideNavigation.Link>
            </SideNavigation>
            <Content>
              <Switch>
                <Route exact path={`${path}/user/profile`}>
                  <UserProfileEdit />
                </Route>
                <Route exact path={`${path}/user/security`}>
                  <UserSecurityEdit />
                </Route>
                <Route>
                  <h3>404 PAGE</h3>
                </Route>
              </Switch>
            </Content>
          </Route>
          <Route path={`${path}/account/:accountId`}>
            <Accounts />
          </Route>
          <Route>
            <h3>404 PAGE</h3>
          </Route>
        </Switch>
      </Main>
    </>
  );
}
