import type AccountUserUpdateParams from '../../models/Settings/AccountUserUpdateParams';
import type AccountUserUpdateValues from '../../models/Settings/AccountUserUpdateValues';

export default function toAccountUserUpdateParams(
  data: AccountUserUpdateValues
): AccountUserUpdateParams {
  const {
    name,
    email,
    role,
    phone,
    oldPassword,
    newPassword,
    newPasswordConfirmation,
  } = data;

  return {
    name,
    email,
    phone:
      phone && phone !== ''
        ? phone.replace(/\s/g, '')
        : phone === null
        ? null
        : undefined,
    account_user_role_id: role?.key,
    old_password: oldPassword,
    new_password: newPassword,
    new_password_confirmation: newPasswordConfirmation,
  };
}
