import styled, { css } from 'styled-components';

interface DetailsRowProps {
  small?: boolean;
  wider?: boolean;
  addMargin?: boolean;
  changeFlexDirection?: boolean;
}

const DetailsRow = styled.div<DetailsRowProps>`
  width: max-content;
  display: flex;
  flex-direction: row;

  ${(props) => {
    const { small } = props;

    if (small) {
      return css`
        width: 60%;
        margin-top: 30px;
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { changeFlexDirection } = props;

    if (changeFlexDirection) {
      return css`
        @media (max-width: 600px) {
          flex-direction: column;
        }
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { wider } = props;

    if (wider) {
      return css`
        width: 100%;
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { addMargin } = props;

    if (addMargin) {
      return css`
        margin-top: 30px;
      `;
    }
    return css``;
  }};

  @media (max-width: 750px) {
    width: 100%;
  }
`;

export default DetailsRow;
