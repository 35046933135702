import React, { useCallback, useEffect, useState } from 'react';
import LprImageSubscriptionParklioInternalTable from 'src/components/Parking/LprImageSubscriptionParklioInternalTable';
import Spinner from 'src/components/Shared/Spinner';
import StyledNoData from 'src/components/Shared/StyledNoData';
import Title from 'src/components/Shared/Title';
import LprImageStorageSubscriptionStatuses from 'src/constants/Parking/LprImageStorageSubscriptionStatuses';
import type LprImageStorageSubscription from 'src/models/Parking/Anpr/ImageStorageSubscription/LprImageStorageSubscription';
import type LprImageStorageSubscriptionHistory from 'src/models/Parking/Anpr/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistory';
import type LprCamera from 'src/models/Parking/Anpr/LprCamera/LprCamera';
import useLprCamerasService from 'src/services/Lpr/useLprCamerasService';
import useLprImageStorageSubscriptionHistoriesService from 'src/services/Lpr/useLprImageStorageHistoryService';
import Content from '../../components/Shared/Content';
import Main from '../../components/Shared/Main';
import useIsMounted from '../../hooks/Shared/useIsMounted';

interface Props {
  accountId: number;
}

export default function AnprImageStorageParklioInternalList(props: Props) {
  const { accountId } = props;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [cameraData, setCameraData] = useState<LprCamera[]>([]);
  const [historyData, setHistoryData] = useState<
    LprImageStorageSubscriptionHistory[]
  >([]);
  const { listAllLprCameras } = useLprCamerasService();
  const { listAllLprImageStorageSubscriptionHistories } =
    useLprImageStorageSubscriptionHistoriesService();
  const isMounted = useIsMounted();

  const getData = useCallback(async () => {
    try {
      if (isMounted()) {
        setIsDataLoading(true);
      }

      const cameras = listAllLprCameras({ accountId });
      const history = listAllLprImageStorageSubscriptionHistories({
        accountId,
      });

      const response = await Promise.all([cameras, history]);
      const [{ data: cameraData }, { data: historyData }] = response;

      if (isMounted()) {
        setCameraData(cameraData);
        setHistoryData(historyData);
        setIsDataLoading(false);
      }
    } catch (error) {
      if (isMounted()) {
        setIsDataLoading(false);
      }
      throw error;
    }
  }, [
    isMounted,
    listAllLprCameras,
    accountId,
    listAllLprImageStorageSubscriptionHistories,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onChangeState = useCallback(
    (id: number, subscription: LprImageStorageSubscription) => {
      setCameraData((oldData) => {
        const cameraIndex = oldData.findIndex((data) => data.id === id);

        if (cameraIndex === -1) return oldData;

        oldData[cameraIndex].storageSubscriptionPlan = subscription;

        return [...oldData];
      });
    },
    []
  );

  return (
    <>
      <Title anpr>ANPR Image Storage</Title>
      <Main>
        <Content>
          {isDataLoading ? (
            <Spinner primary anpr />
          ) : cameraData.length > 0 ? (
            cameraData.map((camera) => {
              const { id, storageSubscriptionPlan } = camera;
              const cameraHistories = historyData.filter(
                (history) => history.lprCameraId === id
              );
              const histories =
                cameraHistories.length <= 1 &&
                storageSubscriptionPlan?.status !==
                  LprImageStorageSubscriptionStatuses.EXPIRED
                  ? []
                  : cameraHistories;

              return (
                <LprImageSubscriptionParklioInternalTable
                  key={id}
                  data={camera}
                  histories={histories}
                  onChangeState={onChangeState}
                  getData={getData}
                />
              );
            })
          ) : (
            <StyledNoData>No ANPR image subscription added.</StyledNoData>
          )}
        </Content>
      </Main>
    </>
  );
}
