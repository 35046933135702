import type LicenseFormValues from 'src/models/License/LicenseFormValues';
import type LicensePricing from 'src/models/License/LicensePricing';
import toLicenseUpdatedValues from './toLicenseUpdatedValues';

export default function toLicensePricingUpdatedValues(
  values: LicenseFormValues,
  licenseId: number,
  licensePricingId: number
): LicensePricing {
  const { name, status, price } = values;

  return {
    id: licensePricingId,
    name,
    status,
    price,
    currencyId: 2,
    license: toLicenseUpdatedValues(values, licenseId),
  };
}
