import Color from 'src/constants/Shared/Color';
import styled from 'styled-components';

const StyledBlockIcon = styled.i`
  font-size: 18px;
  cursor: pointer;
  color: ${Color.PRIMARY_BRAND};
`;

export default StyledBlockIcon;
