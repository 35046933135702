import React, { useCallback, useMemo, useState } from 'react';
import Button from 'src/components/Shared/Button';
import Control from 'src/components/Shared/Control';
import Description from 'src/components/Shared/Description';
import Line from 'src/components/Shared/Line';
import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/components/Shared/Modals/GlobalSuccessModal';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import Color from 'src/constants/Shared/Color';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import useDetectCameraService from 'src/services/Parking/useDetectCameraService';
import styled from 'styled-components';
import DetailsColumn from '../DetailsColumn';
import DetailsRow from '../DetailsRow';

interface DetectCameraUpdateParkingSchemaProps {
  id: number | undefined;
  closeParentModal: () => void;
}

const MAX_SIZE_BYTES = 5 * 1000000;

export default function DetectCameraUpdateParkingSchema(
  props: DetectCameraUpdateParkingSchemaProps
) {
  const { id, closeParentModal } = props;

  const [file, setFile] = useState<File | null>(null);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState('');
  const { uploadSvg } = useDetectCameraService();
  const isMounted = useIsMounted();

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: 'File was successfully uploaded!',
  });

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFile(event.target.files ? event.target.files[0] : null);
    },
    []
  );

  const onUpload = useCallback(async () => {
    try {
      if (!file || !id) return;

      if (isMounted()) {
        setInProgress(true);
      }

      await uploadSvg(id, file);

      if (isMounted()) {
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setInProgress(false);
        if (error.file) {
          setError(error.file);
          return;
        }
        setMessage({
          code: 'Undefined',
          message:
            'Sorry for the inconvenience! Something went wrong, please try again.',
        });
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    uploadSvg,
    file,
  ]);

  const isFileTooLarge = useMemo(() => {
    if (!file) {
      return false;
    }

    if (file.size > MAX_SIZE_BYTES) {
      return true;
    }

    return false;
  }, [file]);

  return (
    <>
      <DetailsRow>
        <DetailsColumn>
          <StyledInput type='file' onChange={onFileChange} accept='.svg' />
          {isFileTooLarge && (
            <Description color={Color.ERROR} errorMessage>
              *File is too large. Maximum size is 5 MB.
            </Description>
          )}
          {error && (
            <Description color={Color.ERROR} errorMessage>
              *{error}
            </Description>
          )}
        </DetailsColumn>
      </DetailsRow>
      <Line />
      <Control>
        <Button
          type='button'
          primary
          size={ButtonSize.LARGE}
          onClick={onUpload}
          disabled={inProgress}
          isLoading={inProgress}
        >
          Upload File
        </Button>
      </Control>
    </>
  );
}

const StyledInput = styled.input`
  color: ${Color.TEXT_DARKER};
  font-size: 14px;
  display: flex;
  margin: 15px 0;
  cursor: pointer;

  :last-child {
    margin-bottom: 40px;
  }
`;
