import type RotaryCreateRequest from 'src/models/Rotaries/RotaryCreateRequest';
import type RotaryFormValues from 'src/models/Rotaries/RotaryFormValues';

export default function toRotaryCreateRequest(
  data: RotaryFormValues,
  lotId?: number
): RotaryCreateRequest {
  const { name, address } = data;

  return {
    name,
    address,
    lot_id: lotId,
  };
}
