enum ProductIcon {
  GATEWAY = 'pa-gateway',
  BARRIER = 'pa-barrier',
  GATE = 'pa-gate',
  CHAIN = 'pa-chain',
  BOLLARD = 'pa-bollard',
  LPR = 'pa-LPR',
  BRAIN = 'pa-brain',
}

export default ProductIcon;
