import React from 'react';

interface ColgroupProps {
  children: React.ReactNode;
}

export default function Colgroup(props: ColgroupProps) {
  const { children } = props;
  return <colgroup>{children}</colgroup>;
}
