import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import LotList from '../../screens/Parking/LotList';
import Lots from './Lots';

export default function Parking() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/lots`} />
      </Route>
      <Route exact path={`${path}/lots`}>
        <LotList />
      </Route>
      <Route path={`${path}/lots/:lotId`}>
        <Lots />
      </Route>
      <Route>
        <h3>404 PAGE</h3>
      </Route>
    </Switch>
  );
}
