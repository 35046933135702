import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';
import LicenseStatus from 'src/constants/License/LicenseStatus';
import PaginationSize from 'src/constants/Shared/DataSize';
import type AccountCreateResponse from 'src/models/Home/AccountCreateResponse';
import useLicenseManagementService from 'src/services/License/useLicenseManagementService';
import accountRequiresLicense from 'src/utils/accountRequiresLicense';
import * as yup from 'yup';
import Finish from '../../components/Home/FinishStep';
import InfoStep from '../../components/Home/InfoStep';
import LicenseStep from '../../components/Home/LicenseStep';
import UserStep from '../../components/Home/UserStep';
import Wizard from '../../components/Shared/Wizard';
import AccountRole from '../../constants/Shared/AccountRole';
import PhoneValidation from '../../constants/Shared/PhoneValidation';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type AccountWizardValues from '../../models/Home/AccountWizardValues';
import useAccountService from '../../services/Home/useAccountService';
import { isNotString } from '../../utils/checks';

interface WizzardProps {
  closeParentModal: () => void;
  refreshPage?: boolean;
}

const defaultValues: AccountWizardValues = {
  name: '',
  accountRole: { key: AccountRole.PMS, label: AccountRole[2] },
  contactName: '',
  contactEmail: '',
  contactPhone: '',

  loginEmail: '',
  loginName: '',

  licenseInfo: [
    {
      pricingId: 0,
      purchaseId: 0,
      counter: 0,
    },
  ],
};

const infoValidationSchema = yup.object().shape({
  name: yup.string().required('Account is a required field'),
  accountRole: yup
    .object()
    .required('Account Type is a required field')
    .nullable(),
  contactName: yup.string().notRequired(),
  contactEmail: yup.string().notRequired().email('Enter a valid email address'),
  contactPhone: yup
    .string()
    .trim()
    .matches(PhoneValidation, 'Enter a valid phone number'),
});

const userValidationSchema = yup.object().shape({
  loginEmail: yup
    .string()
    .email('Enter a valid email address')
    .required('Email is a required field'),
  loginName: yup.string().required('Name is a required field'),
});

const licenseValidationSchema = yup.object().shape({
  licenseInfo: yup
    .array()
    .of(
      yup.object().shape({
        pricingId: yup.number(),
        purchaseId: yup.number(),
        counter: yup.number().min(0).required(),
      })
    )
    .compact((value) => value.counter === 0)
    .min(1, 'Account should have at least one license'),
});

export default function AddAccountWizzard(props: WizzardProps) {
  const { closeParentModal, refreshPage } = props;
  const { createAccount } = useAccountService();
  const isMounted = useIsMounted();
  const { addLicensePurchaseManagement, listAllLicensePricingsManagement } =
    useLicenseManagementService();
  const [initialValues, setInitialValues] =
    useState<AccountWizardValues>(defaultValues);
  const [accountId, setAccountId] = useState<number | undefined>(undefined);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { counterArray } = await listAllLicensePricingsManagement({
          page: 1,
          size: PaginationSize.STANDARD,
          status: LicenseStatus.AVAILABLE,
        });

        if (isMounted()) {
          setInitialValues((initial) => ({
            ...initial,
            licenseInfo: counterArray,
          }));
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [listAllLicensePricingsManagement, isMounted]);

  const linkToNewAccount = useCallback(() => {
    window.open(`/settings/account/${accountId}/licenses`);
  }, [accountId]);

  const { openGlobalFailureModal: licenseFailed, setMessage } =
    useGlobalFailureModal({
      closeParentModal,
      action: linkToNewAccount,
    });

  const {
    openGlobalFailureModal: accountFailed,
    setMessage: setAccountFailedMessage,
  } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (
      values: AccountWizardValues,
      { setErrors }: FormikHelpers<AccountWizardValues>
    ) => {
      let response: AccountCreateResponse;
      try {
        response = await createAccount(values);
        setAccountId(response.id);
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            setAccountFailedMessage({
              code: 'Unknown',
              message: `${Object.keys(error)[0]}: ${Object.values(error)[0]}`,
            });
          } else {
            setAccountFailedMessage(error);
          }
          accountFailed();
        }
        throw error;
      }
      try {
        if (!accountRequiresLicense(values.accountRole)) {
          return;
        }

        await addLicensePurchaseManagement(
          { licenseInfo: values.licenseInfo },
          response.id
        );
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            setMessage({
              code: 'Unknown',
              message:
                'Sorry for the inconvenience. Account is created but you need to add license!',
            });
          } else {
            setMessage(error);
          }
          licenseFailed();
        }
      }
    },
    [
      createAccount,
      isMounted,
      addLicensePurchaseManagement,
      setMessage,
      accountFailed,
      licenseFailed,
      setAccountFailedMessage,
    ]
  );

  return (
    <Wizard
      name='addAccount'
      initialValues={initialValues}
      onSubmit={onSubmit}
      isLoading={isDataLoading}
    >
      <Wizard.Step
        name='info'
        label='INFO'
        validationSchema={infoValidationSchema}
      >
        <InfoStep closeParentModal={closeParentModal} />
      </Wizard.Step>
      <Wizard.Step
        name='licenseInfo'
        label='LICENSES'
        validationSchema={licenseValidationSchema}
      >
        <LicenseStep initialValues={initialValues.licenseInfo} />
      </Wizard.Step>
      <Wizard.Step
        name='user'
        label='USER'
        validationSchema={userValidationSchema}
      >
        <UserStep />
      </Wizard.Step>
      <Wizard.Step name='finish' label='FINISH'>
        <Finish closeParentModal={closeParentModal} refreshPage={refreshPage} />
      </Wizard.Step>
    </Wizard>
  );
}
