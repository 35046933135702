import React from 'react';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import Section from '../Shared/Section';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';

export default function UserSecurityForm() {
  return (
    <>
      <Title>User Security</Title>
      <Card>
        <Section>
          <TextField
            label='Old Password'
            placeholder='Old Password'
            name='oldPassword'
            type='password'
            short
            autoComplete='new-password'
          />
          <TextField
            label='New Password'
            placeholder='New Password'
            name='newPassword'
            type='password'
            short
            autoComplete='new-password'
          />
          <TextField
            label='Confirm New Password'
            placeholder='Confirm New Password'
            name='newPasswordConfirmation'
            type='password'
            short
            autoComplete='new-password'
          />
        </Section>
        <Line />
        <Control>
          <Button size={ButtonSize.LARGE} type='submit' primary>
            Save
          </Button>
        </Control>
      </Card>
    </>
  );
}
