import type KeyLogResendParams from 'src/models/KeySharing/KeyLog/KeyLogResendParams';
import type KeyLogResendValues from 'src/models/KeySharing/KeyLog/KeyLogResendValues';

export default function toKeyLogResendParams(
  data: KeyLogResendValues
): KeyLogResendParams {
  const { ids } = data;

  return { ids };
}
