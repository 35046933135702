import type { FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import type DetectCamera from 'src/models/Parking/DetectCamera/DetectCamera';
import type DetectCameraFormValues from 'src/models/Parking/DetectCamera/DetectCameraFormValues';
import type DetectGateway from 'src/models/Parking/DetectGateway/DetectGateway';
import useDetectCameraService from 'src/services/Parking/useDetectCameraService';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import { isNotString } from '../../../utils/checks';
import ExitButton from '../../Shared/Buttons/ExitButton';
import Form from '../../Shared/Form';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import DetectCameraAddForm from '../Detection/DetectCameraAddForm';

interface AddNewDetectCameraModalProps {
  closeParentModal: () => void;
  onAddNewDetectCamera: (data: DetectCamera) => void;
  gateways: DetectGateway[];
}

const initialValues: DetectCameraFormValues = {
  name: '',
  gateway: null,
};

const validationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
  gateway: yup.object().nullable().required('Gateway is a required field'),
});

export default function AddNewDetectCameraModal(
  props: AddNewDetectCameraModalProps
) {
  const { closeParentModal, onAddNewDetectCamera, gateways } = props;
  const { createDetectCamera } = useDetectCameraService();
  const isMounted = useIsMounted();

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: 'Successfuly added new Detect Camera!',
  });

  const onSubmit = useCallback(
    async (
      values: DetectCameraFormValues,
      { setErrors }: FormikHelpers<DetectCameraFormValues>
    ) => {
      try {
        const response = await createDetectCamera(values);

        if (isMounted()) {
          openGlobalSuccessModal();
          onAddNewDetectCamera(response);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      onAddNewDetectCamera,
      openGlobalFailureModal,
      setMessage,
      openGlobalSuccessModal,
      isMounted,
      createDetectCamera,
    ]
  );

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Form
        name='addDetectCamera'
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <DetectCameraAddForm
          closeParentModal={closeParentModal}
          gateways={gateways}
        />
      </Form>
    </>
  );
}
