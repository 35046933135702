import React, { useCallback, useEffect, useRef, useState } from 'react';
import MultipleErrors from 'src/constants/Shared/MultipleErrors';
import styled from 'styled-components';
import AllProductStatuses from '../../constants/Parking/AllProductStatuses';
import Dropdown from '../Shared/Dropdown';
import {
  InfoIcon,
  LabelWrapper,
  StyledDescription,
  StyledError,
  StyledErrorWrapper,
  StyledWrapper,
} from '../Shared/InfoMessage';

interface ProductStatusesProps {
  statuses: string;
  errors: string[];
  productType: string;
}

export default function ProductStatuses(props: ProductStatusesProps) {
  const { statuses, errors, productType } = props;
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [deviceStatuses, setDeviceStatuses] = useState<string[]>([]);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const open = useCallback(() => {
    if (statuses === AllProductStatuses.ONLINE && errors.length === 0) {
      return;
    }
    setIsDropdownOpen(true);
  }, [statuses, errors]);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (!wrapperRef?.current?.contains(event.target as Node)) {
        close();
      }
    };

    let activeDeviceStatuses = [];
    if (errors.length > 0 && statuses === AllProductStatuses.ONLINE)
      activeDeviceStatuses = errors;
    else activeDeviceStatuses.push(statuses);

    setDeviceStatuses(activeDeviceStatuses);

    document.addEventListener('click', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
    };
  }, [close, statuses, errors]);

  return (
    <StyledWrapper onMouseEnter={open} onMouseLeave={close} ref={wrapperRef}>
      <LabelWrapper>
        <StyledSpan>
          {deviceStatuses.length < MultipleErrors.LENGTH && deviceStatuses[0]}
        </StyledSpan>
        {deviceStatuses.length >= MultipleErrors.LENGTH && (
          <StyledSpan>{MultipleErrors.TEXT}</StyledSpan>
        )}
        {deviceStatuses[0] !== AllProductStatuses.ONLINE && (
          <InfoIcon className='pa-info' />
        )}
      </LabelWrapper>
      {isDropdownOpen && (
        <Dropdown close={close} infoMessages>
          {deviceStatuses.map((status, key) => (
            <StyledErrorWrapper key={key}>
              <StyledError>{status}</StyledError>
              <StyledDescription>
                {status === AllProductStatuses.OFFLINE
                  ? `There is no connection between ${productType} and Gateway`
                  : status === AllProductStatuses.RTCC_INVALID
                  ? `Connect with the ${productType} on the spot and update time`
                  : `Check ${productType} on the spot`}
              </StyledDescription>
            </StyledErrorWrapper>
          ))}
        </Dropdown>
      )}
    </StyledWrapper>
  );
}

const StyledSpan = styled.span`
  width: 55%;
`;
