import LicensePlatesStatuses from 'src/constants/Parking/LicensePlatesStatuses';
import LicensePlatesStatusValues from 'src/constants/Parking/LicensePlatesStatusValues';

export const getLicensePlateStatus = (status?: LicensePlatesStatusValues) => {
  switch (status) {
    case LicensePlatesStatusValues.ACTIVE:
      return LicensePlatesStatuses.ACTIVE;
    case LicensePlatesStatusValues.EXPIRED:
      return LicensePlatesStatuses.EXPIRED;
    case LicensePlatesStatusValues.DELETED:
      return LicensePlatesStatuses.DELETED;
    default:
      return undefined;
  }
};
