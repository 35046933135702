import React, {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import Dropdown from '../Dropdown';

interface AdditionalNavigationLinkContainerProps {
  children: React.ReactNode;
  visibleItems: number;
}

const mobileDeviceView = 1050;

function AdditionalNavigationLinkContainer(
  props: AdditionalNavigationLinkContainerProps
) {
  const { children, visibleItems } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [size, setSize] = useState(window.innerWidth);
  const [items, setitems] = useState(visibleItems);
  const wrapperRef = useRef<React.ElementRef<'i'>>(null);

  const numberOfChildren = useMemo(
    () => Children.toArray(children).length,
    [children]
  );

  const visibleChildren = useMemo(
    () => Children.toArray(children).slice(0, items),
    [children, items]
  );

  const dropdownChildren = useMemo(
    () => Children.toArray(children).slice(items),
    [children, items]
  );

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const setWindowWidth = useCallback(() => {
    setSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setWindowWidth);
    return () => window.removeEventListener('resize', setWindowWidth);
  }, [setWindowWidth]);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (!wrapperRef?.current?.contains(event.target as Node)) {
        close();
      }
    };
    document.addEventListener('click', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
    };
  }, [close, isDropdownOpen]);

  useEffect(() => {
    if (size > 1050) {
      setitems(6);
      return;
    }
    if (size > 950) {
      setitems(5);
      return;
    }
    if (size > 800) {
      setitems(4);
      return;
    }

    if (size > 700) {
      setitems(3);
      return;
    }

    if (size > 600) {
      setitems(2);
      return;
    }
    setitems(1);
  }, [size, visibleItems]);

  return (
    <StyledWrapper>
      <>
        {visibleChildren}
        {numberOfChildren > items && (
          <StyledIcon
            onMouseEnter={open}
            className='pa-options'
            ref={wrapperRef}
          >
            {isDropdownOpen && (
              <Dropdown close={close} navigationView>
                {dropdownChildren.map((child) => child)}
              </Dropdown>
            )}
          </StyledIcon>
        )}
      </>
    </StyledWrapper>
  );
}

export default AdditionalNavigationLinkContainer;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 2000px;
  justify-content: center;
  align-items: center;
  margin-left: -100px;

  @media (max-width: 1300px) {
    margin-left: 0;
  }

  @media (max-width: ${mobileDeviceView}px) {
    justify-content: unset;
  }
`;

const StyledIcon = styled.i`
  position: relative;
  margin-left: 10px;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
