import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SessionStorageKey from 'src/constants/Shared/SessionStorageKey';
import * as yup from 'yup';
import AccountInfoForm from '../../components/Settings/AccountInfoForm';
import Form from '../../components/Shared/Form';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../components/Shared/Modals/GlobalSuccessModal';
import AppRole from '../../constants/Shared/AppRole';
import PhoneValidation from '../../constants/Shared/PhoneValidation';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';
import toAccountInfoFormValuesId from '../../mappers/Settings/toAccountInfoByIdFormValues';
import toAccountInfoFormValues from '../../mappers/Settings/toAccountInfoFormValues';
import type AccountInfoFormValues from '../../models/Settings/AccountInfoFormValues';
import useAccountService from '../../services/Home/useAccountService';
import useAccountUserService from '../../services/Settings/useAccountUserService';
import { isNotString } from '../../utils/checks';

const defaultValues: AccountInfoFormValues = {
  name: '',
  accountRole: null,
  contactName: '',
  contactEmail: '',
  contactPhone: '',
};

const validationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
  accountRole: yup
    .object()
    .nullable()
    .required('Account Type is a required field'),
  contactName: yup.string().notRequired(),
  contactEmail: yup.string().email().notRequired(),
  contactPhone: yup
    .string()
    .notRequired()
    .trim()
    .matches(PhoneValidation, 'Enter a valid contact phone number'),
});

interface Props {
  accountId: number;
}

export default function AccountInfoEdit(props: Props) {
  const { accountId } = props;
  const [initialValues, setInitialValues] =
    useState<AccountInfoFormValues>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const { findProfileAccountUser } = useAccountUserService();
  const { findAccountById, updateAccount } = useAccountService();
  const userHasRole = useUserHasRole();

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: 'Successfuly updated account info!',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  useEffect(() => {
    const apiCall = async () => {
      try {
        if (isMounted()) {
          setIsLoading(true);
        }

        let formValues: AccountInfoFormValues | undefined;

        if (
          userIsParklioInternal &&
          accountId !==
            parseInt(sessionStorage.getItem(SessionStorageKey.ID) || '')
        ) {
          const dataInternal = await findAccountById(accountId);
          formValues = toAccountInfoFormValuesId(dataInternal);
        } else {
          const data = await findProfileAccountUser();
          formValues = toAccountInfoFormValues(data);
        }

        if (isMounted()) {
          setIsLoading(false);
          setInitialValues((values) => formValues || values);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };

    apiCall();
  }, [
    findProfileAccountUser,
    findAccountById,
    userIsParklioInternal,
    isMounted,
    setIsLoading,
    accountId,
  ]);

  const getChangedValues = useCallback(
    (values: AccountInfoFormValues) => ({
      name: values.name !== initialValues.name ? values.name : undefined,
      accountRole:
        values.accountRole?.key !== initialValues.accountRole?.key
          ? values.accountRole
          : undefined,
      contactName:
        values.contactName !== initialValues.contactName
          ? values.contactName
          : undefined,
      contactEmail:
        values.contactEmail !== initialValues.contactEmail
          ? values.contactEmail
          : undefined,
      contactPhone:
        values.contactPhone !== initialValues.contactPhone
          ? values.contactPhone === ''
            ? null
            : values.contactPhone
          : undefined,
    }),
    [initialValues]
  );

  const onSubmit = useCallback(
    async (
      values: AccountInfoFormValues,
      { setErrors }: FormikHelpers<AccountInfoFormValues>
    ) => {
      try {
        const valuesToUpdate = getChangedValues(values);
        if (isMounted()) {
          setIsLoading(true);
        }

        await updateAccount(valuesToUpdate, accountId);

        if (isMounted()) {
          setInitialValues(values);
          setIsLoading(false);
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsLoading(false);
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      updateAccount,
      getChangedValues,
      isMounted,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      accountId,
      setMessage,
    ]
  );

  return (
    <Form
      name='accountInfo'
      initialValues={initialValues}
      validationSchema={validationSchema}
      isLoading={isLoading}
      onSubmit={onSubmit}
    >
      <AccountInfoForm />
    </Form>
  );
}
