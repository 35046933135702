import styled from 'styled-components';
import Color from '../../constants/Shared/Color';

const Subtitle = styled.h3`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${Color.TEXT_DARKER};
  margin: 35px 0px 0px 0px;

  @media (max-width: 900px) {
    color: ${Color.TEXT_LIGHTER};
  }
`;

export default Subtitle;
