import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import RotaryParkingPlaceTable from 'src/components/Rotaries/RotaryParkingPlaceTable';
import { StyledIcon } from 'src/components/Shared/Breadcrumb';
import StyledLink from 'src/components/Shared/BreadcrumbLink';
import Content from 'src/components/Shared/Content';
import Main from 'src/components/Shared/Main';
import StyledHeader from 'src/components/Shared/StyledHeader';
import Title from 'src/components/Shared/Title';
import type RotaryParkingPlace from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlace';
import useRotaryParkingPlaceService from 'src/services/Rotaries/useRotaryParkingPlaceService';
import styled from 'styled-components';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import RotarySummary from './RotarySummary';

export default function RotaryParkingPlaces() {
  const [data, setData] = useState<RotaryParkingPlace[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { findAllRotaryParkingPlaces } = useRotaryParkingPlaceService();
  const isMounted = useIsMounted();
  const { params } = useRouteMatch<{ rotaryId?: string }>();
  const { rotaryId } = params;
  const id = rotaryId ? parseInt(rotaryId) : undefined;

  useEffect(() => {
    const getData = async () => {
      try {
        if (!id) {
          return;
        }

        if (isMounted()) {
          setIsLoading(true);
        }

        const { data } = await findAllRotaryParkingPlaces({ rotaryId: id });
        if (isMounted()) {
          setData(data);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [id, findAllRotaryParkingPlaces, isMounted]);

  return (
    <Main left>
      <Content>
        <StyledHeader>
          <StyledLink to='/rotaries'>
            <StyledIcon className='pa-chevron-left' small />
            <Title addTopMargin>Rotary Places</Title>
          </StyledLink>
        </StyledHeader>
        <StyledWrapper>
          <StyledContentWrapper>
            <RotarySummary data={data} />
          </StyledContentWrapper>
          <RotaryParkingPlaceTable isLoading={isLoading} data={data} />
        </StyledWrapper>
      </Content>
    </Main>
  );
}

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  margin-right: 30px;

  @media (max-width: 1400px) {
    flex-direction: column;
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
