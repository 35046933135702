import Color from 'src/constants/Shared/Color';
import styled, { css } from 'styled-components';

const StyledSpan = styled.span`
  color: ${Color.TEXT_DARKER};
  padding: 5px 10px;
  align-self: center;
  font-size: 14px;
  font-weight: 600;
`;

const ProductP = styled.p`
  color: ${Color.PRIMARY_BRAND};
  padding: 5px 15px;
  align-self: center;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  width: max-content;
`;

const StyledDiv = styled.div`
  background-color: ${Color.BACKGROUND_LIGTH};
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 5px;
  margin: 15px 0;
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const StyledIcon = styled.i`
  display: flex;
  width: 60px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  color: ${Color.TEXT_DARKER};
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
`;

const StyledWrapper = styled.div<{ widthSize?: string }>`
  margin-bottom: 20px;

  ${(props) => {
    const { widthSize } = props;

    if (widthSize)
      return css`
        width: ${widthSize};
      `;
    return css`
      width: 100%;
    `;
  }};
`;

const ButtonDiv = styled.div`
  width: 40%;
  margin-left: 60%;
  margin-top: 30px;
`;

export {
  StyledSpan,
  ProductP,
  StyledDiv,
  StyledIcon,
  StyledWrapper,
  ButtonDiv,
};
