import addZeroBeforeNumber from './addZeroBeforeNumber';

export default function formatDate(
  date: string,
  reverse?: boolean,
  addYear?: boolean
) {
  const newDate = new Date(date);
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const year = newDate.getFullYear();

  if (reverse) {
    return `${addZeroBeforeNumber(month)}-${addZeroBeforeNumber(day)}`;
  }

  if (addYear) {
    return `${addZeroBeforeNumber(day)}.${addZeroBeforeNumber(month)}.${year}.`;
  }

  return `${addZeroBeforeNumber(day)}.${addZeroBeforeNumber(month)}.`;
}
