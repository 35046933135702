import type KeyLogUpdateParams from 'src/models/KeySharing/KeyLog/KeyLogUpdateParams';
import type KeyLogUpdateValues from 'src/models/KeySharing/KeyLog/KeyLogUpdateValues';

export default function toKeyLogUpdateParams(
  data: KeyLogUpdateValues
): KeyLogUpdateParams {
  const { ids, status } = data;

  return { ids, status };
}
