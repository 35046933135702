import { useCallback } from 'react';
import toBarrierQuery from 'src/mappers/Parking/Barrier/toBarrierQuery';
import toBarrierUpdateParams from 'src/mappers/Parking/Barrier/toBarrierUpdateParams';
import toBarrier from '../../mappers/Parking/Barrier/toBarrier';
import toMeta from '../../mappers/Shared/toMeta';
import type Barrier from '../../models/Parking/Barrier/Barrier';
import type BarrierQuery from '../../models/Parking/Barrier/BarrierQuery';
import type BarrierQueryParams from '../../models/Parking/Barrier/BarrierQueryParams';
import type BarrierRemoveResponse from '../../models/Parking/Barrier/BarrierRemoveResponse';
import type BarrierResponse from '../../models/Parking/Barrier/BarrierResponse';
import type BarrierUpdateFormValues from '../../models/Parking/Barrier/BarrierUpdateFormValues';
import type BarrierUpdateParams from '../../models/Parking/Barrier/BarrierUpdateParams;';
import type BarrierUpdateResponse from '../../models/Parking/Barrier/BarrierUpdateResponse';
import type GetAllDetails from '../../models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const barrierBaseURL = '/barriers';
interface BarrierService {
  findAllBarriers: (
    queryParams: BarrierQueryParams
  ) => Promise<GetAllDetails<Barrier>>;
  findOneBarrier: (id: number) => Promise<Barrier>;
  updateBarrier: (
    id: number,
    values: BarrierUpdateFormValues
  ) => Promise<BarrierUpdateResponse>;
  removeBarrier: (id: number) => Promise<BarrierRemoveResponse>;
}

export default function useBarrierService(): BarrierService {
  const { get, remove, patch } = useApiService();

  const findAllBarriers = useCallback(
    async (queryParams: BarrierQueryParams) => {
      const query = toBarrierQuery(queryParams);
      const response = await get<BarrierResponse[], BarrierQuery>(
        `${barrierBaseURL}`,
        query
      );

      const data = response.data.map(toBarrier);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findOneBarrier = useCallback(
    async (id: number) => {
      const response = await get<BarrierResponse>(`${barrierBaseURL}/${id}`);

      return toBarrier(response.data);
    },
    [get]
  );

  const updateBarrier = useCallback(
    async (id: number, values: BarrierUpdateFormValues) => {
      const params = toBarrierUpdateParams(values);
      const response = await patch<BarrierUpdateParams, BarrierUpdateResponse>(
        `${barrierBaseURL}/${id}`,
        params
      );

      return response;
    },
    [patch]
  );

  const removeBarrier = useCallback(
    async (id: number) => {
      const response = await remove<BarrierRemoveResponse>(
        `${barrierBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return { findAllBarriers, findOneBarrier, updateBarrier, removeBarrier };
}
