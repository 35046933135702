import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PieController,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import ProductOperations from 'src/constants/Parking/ProductOperations';
import Color from 'src/constants/Shared/Color';
import LotEntryDirections from 'src/constants/Shared/LotEntryDirections';
import ReportsColors from 'src/constants/Shared/ReportsColors';
import TimeRangeValues from 'src/constants/Shared/TimeRangeValues';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import useLicenseContext from 'src/hooks/Shared/useLicenseContext';
import type KeyInvitationStatistics from 'src/models/Statistics/KeyInvitation/KeyInvitationStatistics';
import type LotEntryStatistics from 'src/models/Statistics/LotEntry/LotEntryStatistics';
import type LotWhitelistStatistics from 'src/models/Statistics/LotWhitelist/LotWhitelistStatistics';
import type OperationKeyStatistics from 'src/models/Statistics/OperationKey/OperationKeyStatistics';
import type RemoteControlStatistics from 'src/models/Statistics/RemoteControl/RemoteControlStatistics';
import type WeblinkStatistics from 'src/models/Statistics/Weblink/WeblinkStatistics';
import useStatisticsService from 'src/services/Statistics/useStatisticsService';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import styled, { css } from 'styled-components';
import Card from '../../components/Shared/Card';
import Content from '../../components/Shared/Content';
import Main from '../../components/Shared/Main';

Chart.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  LineElement,
  LineController,
  PieController,
  PointElement,
  Title,
  Legend,
  TimeScale,
  Tooltip
);

const bgColors = [
  ReportsColors.LIGHTEST_BLUE,
  ReportsColors.LIGHTER_BLUE,
  ReportsColors.MEDIUM_BLUE,
  ReportsColors.BLUE,
  ReportsColors.LIGHT_GREY,
  ReportsColors.MEDIUM_GREY,
  ReportsColors.DARK_GREY,
];

interface Directions {
  in: number;
  out: number;
}

interface LotEntries extends Directions {
  day: string;
  counter: number;
}

const InitialDirections = {
  in: 0,
  out: 0,
};

export default function Reports() {
  const [remoteControlStatisticsData, setRemoteControlStatisticsData] =
    useState<RemoteControlStatistics[]>([]);
  const [operationKeysStatisticsData, setOperationKeysStatisticsData] =
    useState<OperationKeyStatistics>();
  const [keyInvitationsStatisticsData, setKeyInvitationsStatisticsData] =
    useState<KeyInvitationStatistics>();
  const [weblinksStatisticsData, setWeblinksStatisticsData] =
    useState<WeblinkStatistics>();
  const [lotWhitelistSStatisticsData, setLotWhitelistSStatisticsData] =
    useState<LotWhitelistStatistics>();
  const [lotEntriesStatisticsData, setLotEntriesStatisticsData] = useState<
    LotEntryStatistics[]
  >([]);
  const [lotEntriesAverageStatisticsData, setLotEntriesAverageStatisticsData] =
    useState<LotEntries[]>([]);
  const [timeRangeId, setTimeRangeId] = useState(TimeRangeValues.DAY);
  const {
    remoteControlStatistics,
    operationKeysStatistics,
    keyInvitationsStatistics,
    weblinksStatistics,
    lotWhitelistStatistics,
    lotEntriesStatistics,
  } = useStatisticsService();
  const isMounted = useIsMounted();
  const { purchasedLicenses } = useLicenseContext();
  const userHasCamera = useMemo(() => {
    const numberOfLprCameras = purchasedLicenses.filter(
      (data) => data.lprCameras
    );
    return numberOfLprCameras.length > 0;
  }, [purchasedLicenses]);

  const timeRangeData = [
    { key: TimeRangeValues.DAY, label: '24h' },
    { key: TimeRangeValues.WEEK, label: '7 days' },
    { key: TimeRangeValues.TWO_WEEKS, label: '14 days' },
    { key: TimeRangeValues.MONTH, label: '30 days' },
  ];

  const getAverageNumberOfEntries = useCallback(
    (data: LotEntryStatistics[]) => {
      const lotEntryData = data.map((entry) => {
        const { scanDay, directions } = entry;
        const value: Directions = directions.reduce((acc, item) => {
          const { direction, total } = item;
          return {
            ...acc,
            [direction]: total,
          };
        }, InitialDirections);

        return {
          day: scanDay,
          in: value.in,
          out: value.out,
          counter: 1,
        };
      });

      const reducedData = lotEntryData.reduce((acc: LotEntries[], item) => {
        const result = acc.find((entry: LotEntries) => entry.day === item.day);
        if (result) {
          result.in += item.in;
          result.out += item.out;
          result.counter += item.counter;
          return acc;
        }
        acc.push(item);
        return acc;
      }, []);

      const result = reducedData.map((data) => ({
        ...data,
        in: data.in / data.counter,
        out: data.out / data.counter,
      }));

      setLotEntriesAverageStatisticsData(result);
    },
    []
  );

  useEffect(() => {
    const getData = async () => {
      const remoteControl = remoteControlStatistics({
        timeRange: timeRangeId,
        operation: ProductOperations.OPEN,
      });
      const operationKeys = operationKeysStatistics({
        timeRange: timeRangeId,
      });
      const keyInvitations = keyInvitationsStatistics({
        timeRange: timeRangeId,
      });
      const weblinks = weblinksStatistics({ timeRange: timeRangeId });
      const lotWhitelist = lotWhitelistStatistics({ timeRange: timeRangeId });
      const lotEntries = lotEntriesStatistics({ timeRange: timeRangeId });

      const response = await Promise.all([
        remoteControl,
        operationKeys,
        keyInvitations,
        weblinks,
        lotWhitelist,
        lotEntries,
      ]);

      const [
        remoteControlData,
        operationKeysData,
        keyInvitationsData,
        weblinksData,
        lotWhitelistData,
        lotEntriesData,
      ] = response;

      if (isMounted()) {
        setRemoteControlStatisticsData(remoteControlData);
        setOperationKeysStatisticsData(operationKeysData);
        setKeyInvitationsStatisticsData(keyInvitationsData);
        setWeblinksStatisticsData(weblinksData);
        setLotWhitelistSStatisticsData(lotWhitelistData);
        setLotEntriesStatisticsData(lotEntriesData);
        getAverageNumberOfEntries(lotEntriesData);
      }
    };

    getData();
  }, [
    remoteControlStatistics,
    isMounted,
    timeRangeId,
    operationKeysStatistics,
    keyInvitationsStatistics,
    weblinksStatistics,
    lotWhitelistStatistics,
    lotEntriesStatistics,
    getAverageNumberOfEntries,
  ]);

  const numberOfOpenOperationsOptions = {
    plugins: {
      title: {
        display: false,
        text: '',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },
          },
        ],
      },
    },
    aspectRatio: 2,
  };

  const barNumberOfOpenOperationsData = {
    labels: remoteControlStatisticsData.map((data) => data.product.name),
    datasets: [
      {
        label: 'Number of open operations',
        data: remoteControlStatisticsData.map((data) => data.total),
        backgroundColor: `${ReportsColors.BLUE}`,
        borderColor: `${ReportsColors.DARK_GREY}`,
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const lotEntriesAverageOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Average IN/OUT entries by day in a week',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
    aspectRatio: 2,
  };

  const barLotEntriesAverageStatisticsData = {
    labels: lotEntriesAverageStatisticsData.map((data) => data.day),
    datasets: [
      {
        label: 'IN',
        data: lotEntriesAverageStatisticsData.map((data) => data.in),
        backgroundColor: `${ReportsColors.BLUE}`,
        borderColor: `${ReportsColors.DARK_GREY}`,
        borderWidth: 1,
        fill: false,
      },
      {
        label: 'OUT',
        data: lotEntriesAverageStatisticsData.map((data) => data.out),
        backgroundColor: `${ReportsColors.MEDIUM_GREY}`,
        borderColor: `${ReportsColors.DARK_GREY}`,
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const operationKeysOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Shared keys',
      },
    },
    aspectRatio: 2,
  };

  const pieOperationKeysData = {
    labels: Object.keys(operationKeysStatisticsData || '').map((key) =>
      capitalizeFirstLetter(key)
    ),
    datasets: [
      {
        data: Object.values(operationKeysStatisticsData || ''),
        backgroundColor: bgColors,
      },
    ],
  };

  const keyInvitationsOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Pending keys',
      },
    },
    aspectRatio: 2,
  };

  const pieKeyInvitationsData = {
    labels: Object.keys(keyInvitationsStatisticsData || '').map((key) =>
      capitalizeFirstLetter(key)
    ),
    datasets: [
      {
        data: Object.values(keyInvitationsStatisticsData || ''),
        backgroundColor: bgColors,
      },
    ],
  };

  const weblinksOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Weblinks',
      },
    },
    aspectRatio: 2,
  };

  const pieWeblinksData = {
    labels: Object.keys(weblinksStatisticsData || '').map((key) =>
      capitalizeFirstLetter(key)
    ),
    datasets: [
      {
        data: Object.values(weblinksStatisticsData || ''),
        backgroundColor: bgColors,
      },
    ],
  };

  const lotWhitelistOptions = {
    plugins: {
      title: {
        display: true,
        text: 'License plates',
      },
    },
    aspectRatio: 2,
  };

  const pieLotWhiteListData = {
    labels: Object.keys(lotWhitelistSStatisticsData || '').map((key) =>
      capitalizeFirstLetter(key)
    ),
    datasets: [
      {
        data: Object.values(lotWhitelistSStatisticsData || ''),
        backgroundColor: [
          `${ReportsColors.LIGHTER_BLUE}`,
          `${ReportsColors.BLUE}`,
          `${ReportsColors.MEDIUM_GREY}`,
        ],
      },
    ],
  };

  const lotEntriesByDateOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Number of IN/OUT entries by day',
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 15,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
    aspectRatio: 2,
  };

  const lineLotEntriesByDate = {
    labels: lotEntriesStatisticsData.map((entry) => entry.scanDate),
    datasets: [
      {
        label: 'IN',
        data: lotEntriesStatisticsData.map(
          (entry) =>
            entry.directions.find(
              (direction) => direction.direction === LotEntryDirections.IN
            )?.total
        ),
        fill: false,
        borderColor: `${ReportsColors.BLUE}`,
      },
      {
        label: 'OUT',
        data: lotEntriesStatisticsData.map(
          (entry) =>
            entry.directions.find(
              (direction) => direction.direction === LotEntryDirections.OUT
            )?.total
        ),
        fill: false,
        borderColor: `${ReportsColors.MEDIUM_GREY}`,
      },
    ],
  };

  return (
    <>
      <Main left reportsView>
        <Content>
          <Card reportsView>
            <StyledSpan>Last</StyledSpan>
            {timeRangeData.map((range) => {
              const getRange = () => {
                setTimeRangeId(range.key);
              };
              return (
                <StyledDiv
                  key={range.key}
                  onClick={getRange}
                  active={timeRangeId === range.key}
                >
                  {range.label}
                </StyledDiv>
              );
            })}
          </Card>
        </Content>
      </Main>
      <Main left reportsView>
        <Content widthSize='49%'>
          {userHasCamera && (
            <Card paddingForSmallScreens>
              <Pie data={pieLotWhiteListData} options={lotWhitelistOptions} />
            </Card>
          )}
          <Card paddingForSmallScreens>
            <Bar
              data={barLotEntriesAverageStatisticsData}
              options={lotEntriesAverageOptions}
            />
          </Card>
          <Card paddingForSmallScreens>
            <Pie data={pieKeyInvitationsData} options={keyInvitationsOptions} />
          </Card>
          <Card paddingForSmallScreens>
            <Bar
              data={barNumberOfOpenOperationsData}
              options={numberOfOpenOperationsOptions}
            />
          </Card>
        </Content>
        <Content widthSize='49%'>
          {userHasCamera && (
            <Card paddingForSmallScreens>
              <Line
                data={lineLotEntriesByDate}
                options={lotEntriesByDateOptions}
              />
            </Card>
          )}
          <Card paddingForSmallScreens>
            <Pie data={pieOperationKeysData} options={operationKeysOptions} />
          </Card>
          <Card paddingForSmallScreens>
            <Pie data={pieWeblinksData} options={weblinksOptions} />
          </Card>
        </Content>
      </Main>
    </>
  );
}

const StyledDiv = styled.div<{ active?: boolean }>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: 0px 20px;
  text-decoration: none;
  color: ${Color.TEXT_DARKER};
  height: 40px;
  cursor: pointer;

  ${(props) => {
    const { active } = props;
    if (active) {
      return css`
        border-radius: 5px;
        background-color: ${Color.BACKGROUND_LIGTH};
        color: ${Color.TEXT_BRAND};
        :hover {
          color: ${Color.PRIMARY_HOVER};
        }
      `;
    }
    return css``;
  }}
`;

const StyledSpan = styled.span`
  align-items: center;
  display: flex;
  margin-right: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: ${Color.TEXT_DARKER};
`;
