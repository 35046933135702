import type ProductChangeStateRequest from 'src/models/Parking/ProductChangeStateRequest';
import type ProductChangeStateValues from 'src/models/Parking/ProductChangeStateValues';

export default function toProductChangeStateRequest(
  data: ProductChangeStateValues
): ProductChangeStateRequest {
  const { operation } = data;

  return { operation };
}
