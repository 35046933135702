import React from 'react';
import BatteryIcon from 'src/constants/Parking/BatteryIcon';
import PowerTypes from 'src/constants/Parking/PowerTypes';
import styled from 'styled-components';
import Color from '../../constants/Shared/Color';
import BatteryPowerIcon, { StyledIcon } from './BatteryPowerIcon';

interface DetailsDataProps {
  label: string;
  batteryStatus: number;
  powerType: PowerTypes;
}

export default function BatteryDetailsData(props: DetailsDataProps) {
  const { label, batteryStatus, powerType } = props;

  return (
    <StyledWrapper>
      <StyledLabel>{label}</StyledLabel>
      <StyledValue>
        {powerType === PowerTypes.BATTERY ? (
          <BatteryPowerIcon batteryStatus={batteryStatus} />
        ) : (
          <StyledIcon className={BatteryIcon.POWER} />
        )}
      </StyledValue>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const StyledLabel = styled.div`
  text-align: left;
  padding-right: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${Color.TEXT_DARKER};
  margin: 0px;
`;

const StyledValue = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 0px;
  color: ${Color.TEXT_DARKER};
`;
