import type ResetPasswordFormValues from '../../models/Settings/ResetPasswordFormValues';
import type ResetPasswordRequest from '../../models/Settings/ResetPasswordRequest';

export default function toResetPasswordRequest(
  data: ResetPasswordFormValues
): ResetPasswordRequest {
  const { email } = data;

  return {
    email: email.replace(/\s/g, ''),
  };
}
