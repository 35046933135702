import type KeyManagementQuery from '../../models/KeySharing/KeyManagementQuery';
import type KeyManagementQueryParams from '../../models/KeySharing/KeyManagementQueryParams';

export default function toKeyManagementQuery(
  data: KeyManagementQueryParams
): KeyManagementQuery {
  const {
    page,
    size,
    lotId,
    productId,
    receiverIdentifier,
    dateFrom,
    dateTo,
    status,
  } = data;

  return {
    page,
    size,
    lot_id: lotId,
    product_id: productId,
    receiver_identifier: receiverIdentifier,
    date_from: dateFrom,
    date_to: dateTo,
    status,
  };
}
