import type DetectGatewayFormValues from 'src/models/Parking/DetectGateway/DetectGatewayFormValues';
import type DetectGatewayRequest from 'src/models/Parking/DetectGateway/DetectGatewayRequest';

export default function toDetectGatewayCreateRequest(
  values: DetectGatewayFormValues,
  lotId: number
): DetectGatewayRequest {
  const { name, uuid, externalId } = values;

  return {
    name,
    uuid,
    external_id: externalId,
    lot_id: lotId,
  };
}
