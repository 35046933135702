import React from 'react';
import Spinner from '../Shared/Spinner';
import TextField from '../Shared/TextField';
import DetailsColumn from './DetailsColumn';
import DetailsRow from './DetailsRow';

interface SetupCameraCredentialsFormProps {
  id?: number;
  isLoading: boolean;
}

export default function SetupCameraCredentialsForm(
  props: SetupCameraCredentialsFormProps
) {
  const { id, isLoading } = props;

  const shouldRenderDetails = !isLoading && id;

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='URL'
              placeholder='Url'
              type='text'
              name='serverUrl'
              stacked
              short
              disabled
            />
            <TextField
              label='PORT'
              placeholder='Port'
              type='text'
              name='serverPort'
              stacked
              short
              disabled
            />
            <TextField
              label='PASSWORD'
              placeholder='Password'
              type='password'
              name='password'
              autoComplete='cp-number'
              stacked
              short
              disabled
              addEyeIcon
            />
          </DetailsColumn>
        </DetailsRow>
      )}
    </>
  );
}
