import AllProductStatuses from '../../constants/Parking/AllProductStatuses';
import ProductStatus from '../../constants/Parking/ProductStatus';
import type ConcreteProduct from '../../models/Parking/ConcreteProduct';

export default function useChainStatusLabel(
  product: ConcreteProduct | undefined,
  isAnyGatewayOnline: boolean
) {
  const statuses = [];

  if (!product) return undefined;

  const { status, productError } = product;

  if (status === ProductStatus.OFFLINE || !isAnyGatewayOnline) {
    statuses.push(AllProductStatuses.OFFLINE);
    return statuses;
  }

  if (productError === null) {
    statuses.push(AllProductStatuses.ONLINE);
    return statuses;
  }

  const { jammed, rtccInvalid, masterKeyNotSet } = productError;

  if (jammed) statuses.push(AllProductStatuses.JAMMED);

  if (rtccInvalid) statuses.push(AllProductStatuses.RTCC_INVALID);

  if (masterKeyNotSet) statuses.push(AllProductStatuses.MASTER_KEY_NOT_SET);

  if (statuses.length === 0) {
    statuses.push(AllProductStatuses.UNKNOWN);
  }

  return statuses;
}
