import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';

const Error = styled.div<{ large?: boolean; bold?: boolean }>`
  color: ${Color.ERROR};
  font-family: Open Sans;
  font-style: normal;

  & > p {
    margin-top: 0;
    margin-bottom: 5px;

    :last-child {
      margin-bottom: 30px;
    }
  }

  ${(props) => {
    const { large } = props;

    if (large) {
      return css`
        font-size: 18px;
        line-height: 150%;
        text-align: center;

        :last-child {
          margin: 30px 0px 40px 0px;
        }
      `;
    }

    return css`
      font-size: 14px;
      line-height: 18px;
      margin: 5px 0px 0px 0px;
    `;
  }}

  ${(props) => {
    const { bold } = props;

    if (bold) {
      return css`
        font-weight: bold;
      `;
    }

    return css`
      font-weight: normal;
    `;
  }}
`;

export default Error;
