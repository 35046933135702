import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../../components/Auth/Container';
import ParklioSign from '../../components/Auth/ParklioSign';
import Title from '../../components/Auth/Title';
import Spinner from '../../components/Shared/Spinner';
import Color from '../../constants/Shared/Color';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import useAccountUserService from '../../services/Settings/useAccountUserService';

export default function EmailConfirmation() {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { emailConfirmation } = useAccountUserService();
  const isMounted = useIsMounted();
  const location = useLocation();
  const { search } = location;

  const { token, signature } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as { [key: string]: string };

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsLoading(true);
        }

        await emailConfirmation(token, signature);

        if (isMounted()) {
          setSuccess(true);
        }
      } catch {
        if (isMounted()) {
          setSuccess(false);
        }
      } finally {
        if (isMounted()) {
          setIsLoading(false);
        }
      }
    };
    getData();
  }, [emailConfirmation, isMounted, token, signature]);

  return (
    <Container removeText>
      <ParklioSign />
      <Title>Email confirmation</Title>
      <StyledDiv>
        {isLoading ? (
          <Spinner primary data-testid='spinner' />
        ) : (
          <StyledText>
            {success ? (
              <>
                Success!<p>Email successfully confirmed.</p>
              </>
            ) : (
              <>
                Error!<p>Invalid URL signature.</p>
              </>
            )}
          </StyledText>
        )}
      </StyledDiv>
    </Container>
  );
}

const StyledText = styled.div`
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: ${Color.TEXT_DARKER};
  margin: 10px 0 30px 0;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: normal;
  }
`;

const StyledDiv = styled.div`
  width: 80%;
  max-width: 500px;
  padding: 35px 30px;
  background-color: ${Color.BACKGROUND_LIGHTEST};
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 10px;
  display: flex;
  min-height: 250px;
  text-align: center;
  align-items: center;
  margin-top: 2%;
  justify-content: center;
`;
