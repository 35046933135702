import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalConfirmationModal } from 'src/components/Shared/Modals/GlobalConfirmationModal';
import type ParkingDetectionStatus from 'src/constants/Parking/ParkingDetectionStatus';
import SocketEvents from 'src/constants/Shared/SocketEvents';
import SocketPath from 'src/constants/Shared/SocketPath';
import useSocket from 'src/hooks/Shared/useSocket';
import toParkingPlaceChange from 'src/mappers/Parking/ParkingPlace/toParkingPlaceChange';
import toParkingPlaceFormValues from 'src/mappers/Parking/ParkingPlace/toParkingPlaceFormValues';
import type ParkingPlace from 'src/models/Parking/ParkingPlace/ParkingPlace';
import type ParkingPlaceChange from 'src/models/Parking/ParkingPlace/ParkingPlaceChange';
import type ParkingPlaceChangePayload from 'src/models/Parking/ParkingPlace/ParkingPlaceChangePayload';
import type ParkingPlaceFormValues from 'src/models/Parking/ParkingPlace/ParkingPlaceFormValues';
import type Zone from 'src/models/Parking/Zone/Zone';
import useParkingPlaceService from 'src/services/Parking/useParkingPlaceService';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import { isNotString } from '../../../utils/checks';
import ExitButton from '../../Shared/Buttons/ExitButton';
import Form from '../../Shared/Form';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import Tabs from '../../Shared/Tabs';
import ParkingPlaceUpdateForm from '../Detection/ParkingPlaceUpdateForm';

interface ParkingPlaceDetailsModalProps {
  id: number | undefined;
  closeParentModal: () => void;
  onPlaceNameChange: (name: string, id: number) => void;
  lotDetectionStatus: ParkingDetectionStatus | undefined;
  zones: Zone[];
  onRemoveParkingPlace?: (id: number) => void;
  userIsParklioInternal?: boolean;
}

const defaultFormValues: ParkingPlaceFormValues = {
  name: '',
  zone: null,
  uuid: '',
};

const validationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
  zone: yup.object().nullable().required('Zone is a required field'),
  uuid: yup.string().required('UUID is a required field'),
});

export default function ParkingPlaceDetailsModal(
  props: ParkingPlaceDetailsModalProps
) {
  const {
    id,
    closeParentModal,
    onPlaceNameChange,
    onRemoveParkingPlace,
    lotDetectionStatus,
    zones,
    userIsParklioInternal,
  } = props;

  const [placeDetails, setPlaceDetails] = useState<ParkingPlace | undefined>();
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const { findOneParkingPlace, updateParkingPlace, removeParkingPlace } =
    useParkingPlaceService();
  const isMounted = useIsMounted();
  const socket = useSocket(SocketPath.SOCKETIO);

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
  });

  useEffect(() => {
    const handleParkingPlaceChange = (payload: string) => {
      try {
        const eventPayload: ParkingPlaceChangePayload = JSON.parse(payload);
        const eventData: ParkingPlaceChange =
          toParkingPlaceChange(eventPayload);

        const { places } = eventData;

        setPlaceDetails((oldValue) => {
          if (!oldValue) return undefined;
          const value = places.find((place) => place.id === oldValue.id);
          if (!value) return oldValue;

          return {
            ...oldValue,
            ...value,
          };
        });
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };
    socket?.on(SocketEvents.PARKING_PLACE_CHANGE, handleParkingPlaceChange);

    return () => {
      socket?.off(SocketEvents.PARKING_PLACE_CHANGE, handleParkingPlaceChange);
    };
  }, [socket, openGlobalFailureModal, setMessage]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!id) {
          return;
        }
        if (isMounted()) {
          setAreDetailsLoading(true);
        }

        const data = await findOneParkingPlace(id);
        const zone = zones.find((zone) => zone.id === data.zoneId);
        const values = toParkingPlaceFormValues(data, zone?.name || '');

        if (isMounted()) {
          setInitialValues(values);
          setPlaceDetails(data);
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    getData();
  }, [id, isMounted, findOneParkingPlace, zones]);

  const getChangedValues = useCallback(
    (values: ParkingPlaceFormValues) => ({
      name: values.name !== initialValues.name ? values.name : undefined,
      uuid: values.uuid !== initialValues.uuid ? values.uuid : undefined,
      zoneId:
        values.zone?.key !== initialValues.zone?.key
          ? values.zone?.key
          : undefined,
    }),
    [initialValues]
  );

  const onSubmit = useCallback(
    async (
      values: ParkingPlaceFormValues,
      { setErrors }: FormikHelpers<ParkingPlaceFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }

        const valuesToUpdate = getChangedValues(values);

        await updateParkingPlace(id, valuesToUpdate);

        if (isMounted()) {
          setMessageSuccess('Successfuly updated Parking Place!');
          openGlobalSuccessModal();
          onPlaceNameChange(values.name, id);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      id,
      onPlaceNameChange,
      openGlobalFailureModal,
      setMessage,
      openGlobalSuccessModal,
      isMounted,
      updateParkingPlace,
      getChangedValues,
    ]
  );

  const onParkingPlaceRemove = useCallback(async () => {
    try {
      if (!id || !onRemoveParkingPlace) {
        return;
      }
      await removeParkingPlace(id);

      if (isMounted()) {
        setMessageSuccess('Parking Place deleted successfully!');
        onRemoveParkingPlace(id);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    removeParkingPlace,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    onRemoveParkingPlace,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onParkingPlaceRemove,
    message: 'Are you sure you want to remove this parking place?',
  });

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='parkingPlace'
            initialValues={initialValues}
            validationSchema={validationSchema}
            isLoading={areDetailsLoading}
            onSubmit={onSubmit}
          >
            <ParkingPlaceUpdateForm
              details={placeDetails}
              isLoading={areDetailsLoading}
              closeParentModal={closeParentModal}
              lotDetectionStatus={lotDetectionStatus}
              zones={zones}
              userIsParklioInternal={userIsParklioInternal}
              openRemoveModal={openGlobalConfirmationModal}
            />
          </Form>
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
