enum DaysOfWeek {
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN',
}

export default DaysOfWeek;
