import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface MapProps extends google.maps.MapOptions {
  children: React.ReactNode;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

export default function Map(props: MapProps) {
  const { onClick, onIdle, children, center, zoom } = props;
  const [map, setMap] = React.useState<google.maps.Map>();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, { center, zoom }));
    }
  }, [ref, map, center, zoom]);

  useEffect(() => {
    if (center) {
      map?.panTo(center);
    }
  }, [center, map]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        const onMapClick = (e: google.maps.MapMouseEvent) => {
          if (!e.latLng) return;
          onClick(e);
          map.panTo({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        };

        map.addListener('click', onMapClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <StyledMapDiv ref={ref} id='map' />
      {React.isValidElement(children) &&
        React.cloneElement(children, { map } as Partial<unknown>)}
    </>
  );
}

const StyledMapDiv = styled.div`
  flexgrow: 1;
  height: 100%;
`;
