import React, { useEffect, useState } from 'react';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import type CameraCredentials from 'src/models/Parking/Anpr/LprCamera/CameraCredentials';
import useLprCamerasService from 'src/services/Lpr/useLprCamerasService';
import * as yup from 'yup';
import Form from '../Shared/Form';
import SetupCameraCredentialsForm from './SetupCameraCredentialsForm';

interface SetupCameraCredentialsProps {
  id?: number;
}

const defaultValues: CameraCredentials = {
  serverUrl: '',
  serverPort: 0,
  password: '',
};

export default function SetupCameraCredentials(
  props: SetupCameraCredentialsProps
) {
  const { id } = props;

  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const { getCameraCredentials } = useLprCamerasService();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getSetupCameraCredentials = async () => {
      if (!id) {
        return;
      }
      try {
        if (isMounted()) {
          setIsLoading(true);
        }
        const data = await getCameraCredentials(id);
        if (isMounted()) {
          setInitialValues(data);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }

        throw error;
      }
    };
    getSetupCameraCredentials();
  }, [id, isMounted, getCameraCredentials]);

  return (
    <Form
      name='cameraCredentials'
      onSubmit={async () => {
        /* this form is never submitted therefore there is no need for onSubmit func*/
      }}
      initialValues={initialValues}
      validationSchema={yup.object()}
      isLoading={isLoading}
    >
      <SetupCameraCredentialsForm id={id} isLoading={isLoading} />
    </Form>
  );
}
