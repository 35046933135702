import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonSize from '../../../constants/Shared/ButtonSize';
import { useGlobalModal } from '../../../hooks/Shared/useGlobalModal';
import Button from '../Button';
import Control from '../Control';
import Line from '../Line';
import Text from '../Text';
import GlobalModal from './GlobalModal';

interface GlobalSuccessModalProps {
  closeParentModal?: () => void;
  message: string;
  refreshPage?: boolean;
  redirect?: boolean;
}

const closeAllModals = (parentModal?: () => void, childModal?: () => void) => {
  if (parentModal) parentModal();
  if (childModal) childModal();
};

export function useGlobalSuccessModal(props: GlobalSuccessModalProps) {
  const { message, closeParentModal, refreshPage, redirect } = props;
  const history = useHistory();

  const Close = () => {
    closeAllModals(closeParentModal, closeGlobalSuccessModal);
    if (refreshPage) window.location.reload();
    if (redirect) history.push('/');
  };

  const [openGlobalSuccessModal, closeGlobalSuccessModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen popup>
        <>
          <Text>{message}</Text>
          <Line />
          <Control>
            <Button
              autofocus
              onClick={Close}
              size={ButtonSize.LARGE}
              type='button'
              primary
            >
              Ok
            </Button>
          </Control>
        </>
      </GlobalModal>
    )
  );

  return { openGlobalSuccessModal, closeGlobalSuccessModal };
}
