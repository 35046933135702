export default function toAccountRole(role: number): string {
  switch (role) {
    case 1:
      return 'Parklio Internal';
    case 2:
      return 'PMS';
    case 3:
      return 'Connect';
    case 4:
      return 'API';
    default:
      return 'Unknown';
  }
}
