import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Color from '../../../constants/Shared/Color';
import Link from './Link';

interface BreadcrumbProps {
  children: React.ReactNode;
  left?: boolean;
  to: string;
  small?: boolean;
}

export default function Breadcrumb(props: BreadcrumbProps) {
  const { to, children, left, small } = props;

  return (
    <StyledWrapper left={left} small={small}>
      <StyledButtonLink to={to}>
        <StyledIcon className='pa-chevron-left' />
      </StyledButtonLink>
      {children}
    </StyledWrapper>
  );
}

Breadcrumb.Link = Link;

const StyledWrapper = styled.div<{ left?: boolean; small?: boolean }>`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 10px;

  ${(props) => {
    const { left, small } = props;

    if (left) {
      return css`
        align-self: flex-start;
        padding: 0;
        width: 200px;
        max-width: 30%;
      `;
    }

    if (small) {
      return css`
        max-width: 1400px;
        padding: 0px 20px;
      `;
    }

    return css`
      max-width: 2000px;
    `;
  }}
`;

export const StyledIcon = styled.i<{ small?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-right: 10px;
  color: ${Color.TEXT_DARKER};

  ${(props) => {
    const { small } = props;

    if (small) {
      return css`
        font-size: 20px;
      `;
    }

    return css`
      font-size: 24px;
    `;
  }}
`;

const StyledButtonLink = styled(NavLink)`
  text-decoration: none;
  line-height: 14px;
`;
