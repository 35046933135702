import AccountUserRole from '../../constants/Shared/AccountUserRole';
import type AccountUserCreateRequest from '../../models/Settings/AccountUserCreateRequest';
import type AccountUserFormValues from '../../models/Settings/AccountUserFormValues';

export default function toAccountUserCreateRequest(
  data: AccountUserFormValues
): AccountUserCreateRequest {
  const { name, email, role, phone } = data;

  return {
    name,
    email,
    phone: phone ? phone.replace(/\s/g, '') : null,
    account_user_role_id: role ? role.key : AccountUserRole.OPERATOR,
  };
}
