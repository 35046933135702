import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LprImageSubscriptionTable from 'src/components/Parking/LprImageSubscriptionTable';
import Spinner from 'src/components/Shared/Spinner';
import StyledNoData from 'src/components/Shared/StyledNoData';
import Title from 'src/components/Shared/Title';
import type LprCamera from 'src/models/Parking/Anpr/LprCamera/LprCamera';
import useLprCamerasService from 'src/services/Lpr/useLprCamerasService';
import Content from '../../components/Shared/Content';
import Main from '../../components/Shared/Main';
import useIsMounted from '../../hooks/Shared/useIsMounted';

interface Props {
  id?: number;
}

export default function AnprImageStorageList(props: Props) {
  const { id } = props;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [cameraData, setCameraData] = useState<LprCamera[]>([]);
  const { listAllLprCameras } = useLprCamerasService();
  const { params } = useRouteMatch<{ lotId?: string }>();
  const isMounted = useIsMounted();
  const lotId = useMemo(() => {
    if (!params.lotId) {
      return id;
    }
    return parseInt(params.lotId);
  }, [params, id]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data } = await listAllLprCameras({ lotId });

        if (isMounted()) {
          setCameraData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };
    getData();
  }, [isMounted, listAllLprCameras, lotId]);

  return (
    <>
      <Title anpr>ANPR Image Storage</Title>
      <Main>
        <Content>
          {isDataLoading ? (
            <Spinner primary anpr />
          ) : cameraData.length > 0 ? (
            cameraData.map((camera) => (
              <LprImageSubscriptionTable key={camera.id} data={camera} />
            ))
          ) : (
            <StyledNoData>No ANPR image subscription added.</StyledNoData>
          )}
        </Content>
      </Main>
    </>
  );
}
