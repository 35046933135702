import React from 'react';
import styled from 'styled-components';
import Color from '../../../constants/Shared/Color';
import Link from './Link';

interface AdditionalNavigationProps {
  children: React.ReactNode;
}

export default function AdditionalNavigation(props: AdditionalNavigationProps) {
  const { children } = props;

  return <StyledWrapper>{children}</StyledWrapper>;
}

const StyledWrapper = styled.nav`
  width: 100%;
  height: 75px;
  background-color: ${Color.BACKGROUND_LIGHTER};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5%;
`;

AdditionalNavigation.Link = Link;
