import type Zone from 'src/models/Parking/Zone/Zone';
import type ZoneResponse from 'src/models/Parking/Zone/ZoneResponse';

export default function toZone(data: ZoneResponse): Zone {
  const {
    name,
    id,
    description,
    lot_id,
    account_id,
    is_private,
    status,
    has_priority,
    created_at,
    updated_at,
  } = data;

  return {
    name,
    id,
    description,
    lotId: lot_id,
    accountId: account_id,
    isPrivate: is_private,
    status,
    hasPriority: has_priority,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
  };
}
