import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ConnectScreen from '../screens/Auth/ConnectScreen';

export default function Connect() {
  return (
    <Switch>
      <Route path='/connect-user'>
        <ConnectScreen />
      </Route>
      <Route>
        <Redirect to='/connect-user' />
      </Route>
    </Switch>
  );
}
