import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import Color from '../../../constants/Shared/Color';
import WizardIcon from '../../../constants/Shared/WizardIcon';

interface BreadcrumbProps {
  index: number;
  label: string;
  isActive: boolean;
  isBeforeActive: boolean;
}

export default function Breadcrumb(props: BreadcrumbProps) {
  const { index, label, isActive, isBeforeActive } = props;
  const itemNumber = useMemo(() => index + 1, [index]);

  return (
    <StyledWrapper>
      <StyledCircleWrapper isActive={isActive}>
        <StyledCircleContainer isActive={isActive}>
          {!isBeforeActive && <StyledNumber>{itemNumber}</StyledNumber>}
          {isBeforeActive && <StyledIcon className={WizardIcon.YES} />}
        </StyledCircleContainer>
      </StyledCircleWrapper>
      <StyledText isActive={isActive}>{label}</StyledText>
    </StyledWrapper>
  );
}

const StyledIcon = styled.i`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  color: ${Color.TEXT_LIGHTEST};
`;

const StyledNumber = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${Color.TEXT_LIGHTEST};
`;

const StyledText = styled.h3<{ isActive: boolean }>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin: 15px 0px 30px 0px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  ${(props) => {
    const { isActive } = props;

    if (!isActive) {
      return css`
        color: ${Color.BACKGROUND_LIGTH};
      `;
    }

    return css`
      color: ${Color.PRIMARY_BRAND};
      :hover {
        color: ${Color.PRIMARY_HOVER};
      }
    `;
  }};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StyledCircleContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  ${(props) => {
    const { isActive } = props;

    if (!isActive) {
      return css`
        background-color: ${Color.BACKGROUND_LIGTH};
      `;
    }

    return css`
      background-color: ${Color.PRIMARY_BRAND};
      :hover {
        background-color: ${Color.PRIMARY_HOVER};
      }
    `;
  }};
`;

const StyledCircleWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Color.BACKGROUND_LIGHTER};
  border: 2px solid ${Color.PRIMARY_BRAND};
  border-radius: 30px;
  width: 60px;
  height: 60px;

  ${(props) => {
    const { isActive } = props;

    if (!isActive) {
      return css`
        border: 2px solid ${Color.BACKGROUND_LIGTH};
      `;
    }

    return css`
      border: 2px solid ${Color.PRIMARY_BRAND};
    `;
  }}
`;
