import styled from 'styled-components';

const DateTimeDiv = styled.div`
  display: block;
  justify-content: space-between;

  :last-child {
    margin-bottom: 40px;
  }

  @media (max-width: 520px) {
    flex-direction: column;
  }
`;

export default DateTimeDiv;
