import React from 'react';
import styled, { css } from 'styled-components';
import Color from '../../../constants/Shared/Color';
import type SearchSelectIcon from '../../../constants/Shared/SearchSelectIcon';

interface IconProps {
  icon: SearchSelectIcon;
  onClick?: () => void;
  dateTimeView?: boolean;
  tariffView?: boolean;
}

export default function Icon(props: IconProps) {
  const { icon, onClick, dateTimeView, tariffView } = props;
  return (
    <StyledWrapper
      onClick={onClick}
      dateTimeView={dateTimeView}
      tariffView={tariffView}
    >
      <StyledIcon className={icon} />
    </StyledWrapper>
  );
}

const StyledIcon = styled.i`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${Color.TEXT_DARK};
`;

const StyledWrapper = styled.div<{
  dateTimeView?: boolean;
  tariffView?: boolean;
}>`
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  background-color: ${Color.BACKGROUND_DARK};
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    const { dateTimeView, tariffView } = props;

    if (dateTimeView) {
      return css`
        height: 25px;
        width: 32px;
      `;
    }

    if (tariffView) {
      return css`
        height: 36px;
        width: 32px;
      `;
    }

    return css`
      width: 43px;
      height: 36px;
    `;
  }}
`;
