import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/components/Shared/Modals/GlobalSuccessModal';
import Pagination from 'src/components/Shared/Pagination';
import PaginationSize from 'src/constants/Shared/DataSize';
import InitialMetaData from 'src/constants/Shared/InitialMetaData';
import PaginationItemDisplay from 'src/constants/Shared/PaginationItemDisplay';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import type LicensePricing from 'src/models/License/LicensePricing';
import type Meta from 'src/models/Shared/Meta';
import useLicenseManagementService from 'src/services/License/useLicenseManagementService';
import LicenseTable from '../../components/Home/LicenseTable';
import Button from '../../components/Shared/Button';
import Content from '../../components/Shared/Content';
import Main from '../../components/Shared/Main';
import GlobalModal from '../../components/Shared/Modals/GlobalModal';
import Title from '../../components/Shared/Title';
import ButtonSize from '../../constants/Shared/ButtonSize';
import { useGlobalModal } from '../../hooks/Shared/useGlobalModal';
import LicenseModal from './LicenseModal';

export default function License() {
  const { listAllLicensePricingsManagement, removeLicensePricingManagement } =
    useLicenseManagementService();
  const isMounted = useIsMounted();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [activeLicensePricingId, setActiveLicensePricingId] = useState<
    number | undefined
  >(undefined);
  const [activeLicenseId, setActiveLicenseId] = useState<number | undefined>(
    undefined
  );
  const [data, setData] = useState<LicensePricing[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);

  const getAllLicensePricings = useCallback(
    async (page: number) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data, meta } = await listAllLicensePricingsManagement({
          page,
          size: PaginationSize.STANDARD,
        });

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);

          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [listAllLicensePricingsManagement, isMounted]
  );

  useEffect(() => {
    getAllLicensePricings(1);
  }, [getAllLicensePricings]);

  const [openLicenseModal, closeLicenseModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <LicenseModal
        closeParentModal={closeEditLicenseModal}
        activeLicensePricingId={activeLicensePricingId}
        activeLicenseId={activeLicenseId}
        onLicneseUpdate={onLicneseUpdate}
        onRemove={onRemove}
      />
    </GlobalModal>
  ));

  const onLicneseUpdate = useCallback((updatedValues: LicensePricing) => {
    setData((oldData) => {
      const index = oldData.findIndex((data) => data.id === updatedValues.id);

      if (index === -1) return oldData;

      oldData[index] = updatedValues;
      return [...oldData];
    });
  }, []);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal: closeLicenseModal,
    message: 'License was successfully deleted!',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onRemove = useCallback(async () => {
    try {
      if (activeLicensePricingId) {
        await removeLicensePricingManagement(activeLicensePricingId);
        if (isMounted()) {
          getAllLicensePricings(1);
        }

        openGlobalSuccessModal();
        setActiveLicensePricingId(undefined);
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    removeLicensePricingManagement,
    isMounted,
    activeLicensePricingId,
    getAllLicensePricings,
    openGlobalFailureModal,
    setMessage,
    openGlobalSuccessModal,
  ]);

  const openEditLicenseModal = useCallback(
    (licensePricingId: number, licenseId: number) => {
      setActiveLicensePricingId(licensePricingId);
      setActiveLicenseId(licenseId);
      openLicenseModal();
    },
    [openLicenseModal]
  );

  const closeEditLicenseModal = useCallback(() => {
    setActiveLicensePricingId(undefined);
    setActiveLicenseId(undefined);
    closeLicenseModal();
  }, [closeLicenseModal]);

  return (
    <Main left>
      <Content widthSize='70%'>
        <Title>Parklio License</Title>
        <LicenseTable
          data={data}
          isLoading={isDataLoading}
          openEditLicenseModal={openEditLicenseModal}
        />
        {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
          <Pagination meta={meta} getData={getAllLicensePricings} />
        )}
      </Content>
      <Content widthSize='25%' licenseView>
        <Button
          size={ButtonSize.LARGE}
          type='button'
          primary
          onClick={openLicenseModal}
          licenseView
        >
          New License
        </Button>
      </Content>
    </Main>
  );
}
