import { useCallback } from 'react';
import toLotEntry from 'src/mappers/Parking/Anpr/toLotEntry';
import toLotEntryQuery from 'src/mappers/Parking/Anpr/toLotEntryQuery';
import toLotEntryUpdateParams from 'src/mappers/Parking/Anpr/toLotEntryUpdateParams';
import toMeta from 'src/mappers/Shared/toMeta';
import type LotEntry from 'src/models/Parking/Anpr/LotEntries/LotEntry';
import type LotEntryQuery from 'src/models/Parking/Anpr/LotEntries/LotEntryQuery';
import type { LotEntryQueryValues } from 'src/models/Parking/Anpr/LotEntries/LotEntryQueryValues';
import type LotEntryResponse from 'src/models/Parking/Anpr/LotEntries/LotEntryResponse';
import type LotEntryUpdateParams from 'src/models/Parking/Anpr/LotEntries/LotEntryUpdateParams';
import type LotEntryUpdateResponse from 'src/models/Parking/Anpr/LotEntries/LotEntryUpdateResponse';
import type LotEntryUpdateValues from 'src/models/Parking/Anpr/LotEntries/LotEntryUpdateValues';
import type GetAllDetails from 'src/models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const lotEntriesURL = '/lot-entries';

interface LotEntriesService {
  listAllLotEntries: (
    values: LotEntryQueryValues
  ) => Promise<GetAllDetails<LotEntry>>;
  getLotEntryById: (id: number) => Promise<LotEntry>;
  updateLotEntry: (
    id: number,
    values: LotEntryUpdateValues
  ) => Promise<LotEntryUpdateResponse>;
}

export default function useLotEntriesService(): LotEntriesService {
  const { get, patch } = useApiService();

  const listAllLotEntries = useCallback(
    async (values: LotEntryQueryValues) => {
      const requestBody = toLotEntryQuery(values);

      const response = await get<LotEntryResponse[], LotEntryQuery>(
        `${lotEntriesURL}`,
        requestBody
      );

      const data = response.data.map(toLotEntry);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const getLotEntryById = useCallback(
    async (id: number) => {
      const response = await get<LotEntryResponse>(`${lotEntriesURL}/${id}`);

      const data = toLotEntry(response.data);

      return data;
    },
    [get]
  );

  const updateLotEntry = useCallback(
    async (id: number, values: LotEntryUpdateValues) => {
      const params = toLotEntryUpdateParams(values);
      const response = await patch<
        LotEntryUpdateParams,
        LotEntryUpdateResponse
      >(`${lotEntriesURL}/${id}`, params);

      return response;
    },
    [patch]
  );

  return { listAllLotEntries, getLotEntryById, updateLotEntry };
}
