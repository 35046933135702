import type BollardFormValues from 'src/models/Parking/Bollard/BollardFormValues';
import type BollardUpdateParams from 'src/models/Parking/Bollard/BollardUpdateParams';

export default function toBollardUpdateParams(
  data: BollardFormValues
): BollardUpdateParams {
  return {
    name: data.name,
  };
}
