import React from 'react';
import styled from 'styled-components';
import Color from '../../../constants/Shared/Color';
import SideMenuContext from '../../../contexts/Shared/SideMenuContext';
import Link from './Link';
import Navigation from './Navigation';

interface SideMenuProps {
  close: () => void;
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}

export default function SideMenu(props: SideMenuProps) {
  const { children, title, subtitle, close } = props;

  return (
    <SideMenuContext.Provider value={{ close }}>
      <StyledWrapper>
        <StyledContainer>
          <StyledHeader>
            <StyledColumn>
              <StyledTitle>{title}</StyledTitle>
              {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
            </StyledColumn>
            <StyledButton onClick={close}>
              <StyledIcon className='pa-iconNo' />
            </StyledButton>
          </StyledHeader>
          {children}
        </StyledContainer>
      </StyledWrapper>
    </SideMenuContext.Provider>
  );
}

SideMenu.Link = Link;
SideMenu.Navigation = Navigation;

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${Color.BACKGROUND_DARKEST_TRANSPARENT};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 300;
`;

const StyledContainer = styled.div`
  width: 372px;
  height: 100%;
  background-color: ${Color.BACKGROUND_LIGHTER};
  padding: 20px;

  @media (max-width: 500px) {
    width: 75%;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledColumn = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StyledButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 5px;
  border: none;
  background-color: ${Color.BACKGROUND_LIGHTER};
  border: 1px solid ${Color.BORDER_BRAND};
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

const StyledIcon = styled.div`
  font-family: ParklioIcons;
  font-style: normal;
  font-weight: normal;
  color: ${Color.TEXT_BRAND};
  font-size: 14px;
  line-height: 14px;
  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;

const StyledTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  color: ${Color.TEXT_DARKER};
`;

const StyledSubtitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${Color.TEXT_DARKER};
`;
