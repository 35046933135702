import type ZoneUpdateParams from 'src/models/Parking/Zone/ZoneUpdateParams';
import type ZoneUpdateValues from 'src/models/Parking/Zone/ZoneUpdateValues';

export default function toZoneUpdateParams(
  data: ZoneUpdateValues
): ZoneUpdateParams {
  const {
    name,
    lotId,
    description,
    isPrivate,
    status,
    hasPriority,
    tariffIds,
  } = data;

  return {
    name,
    lot_id: lotId,
    description,
    is_private: isPrivate,
    status,
    has_priority: hasPriority,
    tariff_ids: getActivatedTariffIds(tariffIds),
  };
}

const getActivatedTariffIds = (
  tariffIds?: { id: number; activated: boolean }[]
) => {
  if (!tariffIds) {
    return undefined;
  }
  const activated = tariffIds.filter((tariff) => tariff.activated === true);
  return activated.map((tariff) => tariff.id);
};
