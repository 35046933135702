import type AccountQuery from '../../models/Home/AccountQuery';
import type AccountQueryParams from '../../models/Home/AccountQueryParams';

export default function toAccountQuery(data: AccountQueryParams): AccountQuery {
  const { page, size, accountRoleId, name } = data;

  return {
    page,
    size,
    account_role_id: accountRoleId,
    name,
  };
}
