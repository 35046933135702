import React from 'react';
import styled from 'styled-components';
import Color from '../../../constants/Shared/Color';
import useWizardContext from '../../../hooks/Shared/useWizardContext';
import Breadcrumb from './Breadcrumb';

export default function Breadcrumbs() {
  const { activeIndex, breadcrumbsData } = useWizardContext();

  return (
    <BreadcrumbsWrapper>
      {breadcrumbsData.map((item, index) => {
        const { label, name } = item;
        const shouldRenderSpacer = index !== 0;
        const isActive = index === activeIndex;
        const isBeforeActive = index < activeIndex;

        return (
          <React.Fragment key={name}>
            {shouldRenderSpacer && <BreadcrumbsSpacer />}
            <Breadcrumb
              isActive={isActive}
              isBeforeActive={isBeforeActive}
              index={index}
              label={label}
            />
          </React.Fragment>
        );
      })}
    </BreadcrumbsWrapper>
  );
}

const BreadcrumbsSpacer = styled.div`
  width: 47px;
  height: 2px;
  background-color: ${Color.BACKGROUND_LIGTH};
  margin-top: 29px;
`;

const BreadcrumbsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
