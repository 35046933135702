import styled from 'styled-components';

const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  height: 44px;
  align-self: flex-end;
  margin-left: 40px;

  @media (max-width: 550px) {
    margin: 0 10px;
  }
`;

export default Navigation;
