import { useFormikContext } from 'formik';
import React from 'react';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import ExitButton from '../Shared/Buttons/ExitButton';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Section from '../Shared/Section';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';

interface ZoneAddModalProps {
  closeParentModal: () => void;
}

export default function ZoneAddModal(props: ZoneAddModalProps) {
  const { closeParentModal } = props;
  const { dirty } = useFormikContext();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Section>
        <Title>Create a new zone</Title>
        <TextField
          autofocus
          label='Name'
          placeholder='Enter zone name'
          type='text'
          name='name'
          stacked
          short
        />
      </Section>
      <Line />
      <Control>
        <Button
          size={ButtonSize.LARGE}
          type='button'
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
        >
          Cancel
        </Button>
        <Button size={ButtonSize.LARGE} type='submit' primary addZoneSize>
          Create a new zone
        </Button>
      </Control>
    </>
  );
}
