import React, { useCallback, useState } from 'react';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import type Tariff from 'src/models/Parking/Tariff/Tariff';
import type TariffId from 'src/models/Parking/Tariff/TariffId';
import type ZoneData from 'src/models/Parking/Zone/ZoneData';
import Button from '../Shared/Button';
import { useGlobalModal } from '../Shared/GlobalModal';
import GlobalModal from '../Shared/Modals/GlobalModal';
import TariffTable from '../Tariffs/TariffTable';
import {
  ButtonDiv,
  StyledDiv,
  StyledIcon,
  StyledSpan,
  StyledWrapper,
} from '../Tariffs/ToggleCard';
import TariffsModal from './Modals/TariffsModal';

interface ZonesTariffsProps {
  data: ZoneData;
  findCurrencySymbol: (id: number) => void;
  invoiceCurrency:
    | { id: number | undefined; symbol: string | undefined }
    | undefined;
  accountId: number | undefined;
  onTariffChange: (tariffs: TariffId[] | Tariff, zoneId: number) => void;
}

export default function ZonesTariffs(props: ZonesTariffsProps) {
  const {
    data,
    findCurrencySymbol,
    invoiceCurrency,
    accountId,
    onTariffChange,
  } = props;
  const { tariffs } = data;
  const [toggle, setToggle] = useState(true);

  const onClick = useCallback(() => {
    setToggle((oldToggle) => !oldToggle);
  }, []);

  const [openTariffModal, closeTariffModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <TariffsModal
        closeParentModal={closeTariffModal}
        zoneId={data.id}
        findCurrencySymbol={findCurrencySymbol}
        invoiceCurrency={invoiceCurrency}
        accountId={accountId}
        onTariffChange={onTariffChange}
      />
    </GlobalModal>
  ));

  return (
    <StyledWrapper widthSize='59%'>
      <StyledDiv>
        <StyledSpan>Tariffs</StyledSpan>
        <StyledIcon
          className={toggle ? 'pa-chevron-up' : 'pa-chevron-down'}
          onClick={onClick}
        />
      </StyledDiv>
      {toggle && tariffs.length > 0 && (
        <TariffTable
          data={tariffs}
          findCurrencySymbol={findCurrencySymbol}
          zoneView
        />
      )}
      <ButtonDiv>
        <Button
          type='button'
          size={ButtonSize.MEDIUM}
          onClick={openTariffModal}
        >
          Add Tariff
        </Button>
      </ButtonDiv>
    </StyledWrapper>
  );
}
