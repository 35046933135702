import { useMemo } from 'react';
import LprCameraStatus from 'src/constants/Parking/LprCameraStatus';
import AllProductStatuses from '../../constants/Parking/AllProductStatuses';

export default function useCameraStatusLabel(status: string | undefined) {
  return useMemo(() => {
    if (status === LprCameraStatus.OFFLINE) {
      return AllProductStatuses.OFFLINE;
    }

    if (status === LprCameraStatus.ONLINE) {
      return AllProductStatuses.ONLINE;
    }

    return AllProductStatuses.UNKNOWN;
  }, [status]);
}
