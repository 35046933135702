import styled, { css } from 'styled-components';
import Color from '../../../constants/Shared/Color';

interface HeaderProps {
  center?: boolean;
  right?: boolean;
  show?: string;
}

const Header = styled.th<HeaderProps>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-bottom: 13px;
  color: ${Color.TEXT_DARKER};

  ${(props) => {
    const { center, right } = props;

    if (center) {
      return css`
        text-align: center;
        padding-right: initial;

        @media (max-width: 600px) {
          padding-left: 5px;
          padding-right: 5px;
        }
      `;
    }

    if (right) {
      return css`
        text-align: right;
        padding-right: 20px;
      `;
    }

    return css`
      text-align: left;
      padding-right: 20px;
    `;
  }}

  ${(props) => {
    const { show } = props;

    if (show === 'smallDevices') {
      return css`
        @media (max-width: 600px) {
          display: table-cell;
        }

        display: none;
      `;
    }

    if (show === 'largeDevices') {
      return css`
        @media (max-width: 600px) {
          display: none;
        }

        display: table-cell;
      `;
    }

    return css``;
  }}
`;

export default Header;
