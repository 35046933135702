import { useField } from 'formik';
import React from 'react';
import {
  GetLicenseDescription,
  GetLicensePurchaseDescription,
} from 'src/components/License/GetLicenseDescription';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import type LicensePricing from 'src/models/License/LicensePricing';
import type LicensePurchase from 'src/models/License/LicensePurchase';
import type LicensePurchaseFormValues from 'src/models/License/LicensePurchaseFormValues';
import type Counter from 'src/models/Shared/Counter';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Control from '../Shared/Control';
import CounterField from '../Shared/CounterField';
import Line from '../Shared/Line';
import Table from '../Shared/Table';
import Title from '../Shared/Title';

interface LicenseInfoTableProps {
  data: LicensePricing[];
  purchasedData: LicensePurchase[];
  isLoading?: boolean;
  initialValues: LicensePurchaseFormValues;
}

export default function LicenseInfoTable(props: LicenseInfoTableProps) {
  const { data, purchasedData, isLoading, initialValues } = props;

  const [licenseInfo] = useField({ name: 'licenseInfo' });

  return (
    <>
      <Title>Licenses</Title>
      <Card>
        <Table isLoading={isLoading}>
          <Table.Body>
            {licenseInfo.value.map((licenseData: Counter, index: number) => {
              const purchase = purchasedData.find(
                (data) => data.id === licenseData.purchaseId
              );
              const pricing = data.find(
                (pricingData) => pricingData.id === licenseData.pricingId
              );
              const initial = initialValues.licenseInfo.find(
                (value) => value.pricingId === licenseData.pricingId
              );
              return (
                <Table.Row key={index}>
                  <Table.Cell>
                    <CounterField
                      name={`licenseInfo.${index}`}
                      label={
                        purchase
                          ? purchase.licensePricing.name
                          : pricing
                          ? pricing.name
                          : 'Label'
                      }
                      initialValue={initial}
                      invisible={!pricing?.status}
                    >
                      {purchase
                        ? GetLicensePurchaseDescription(purchase)
                        : GetLicenseDescription(pricing?.license)}
                    </CounterField>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Line />
        <Control>
          <Button size={ButtonSize.LARGE} type='submit' primary>
            Save
          </Button>
        </Control>
      </Card>
    </>
  );
}
