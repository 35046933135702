import AllProductStatuses from 'src/constants/Parking/AllProductStatuses';
import ProductState from 'src/constants/Parking/ProductState';
import ProductStatus from 'src/constants/Parking/ProductStatus';
import type ConcreteProductError from 'src/models/Parking/ConcreteProductError';
import type Product from 'src/models/Parking/Product';

export const getDeviceStatuses = (product: Product) => {
  const { status } = product;

  if (status === ProductStatus.OFFLINE) {
    return AllProductStatuses.OFFLINE;
  }

  return AllProductStatuses.ONLINE;
};

export const getErrors = (productError: ConcreteProductError | null) => {
  const errors = [];

  if (!productError) return [];

  const {
    jammed,
    sensorDirty,
    pinBroken,
    rtccInvalid,
    batteryEmpty,
    masterKeyNotSet,
  } = productError;

  if (jammed) errors.push(AllProductStatuses.JAMMED);

  if (sensorDirty) errors.push(AllProductStatuses.SENSOR_DIRTY);

  if (pinBroken) errors.push(AllProductStatuses.PIN_BROKEN);

  if (batteryEmpty) errors.push(AllProductStatuses.BATTERY_EMPTY);

  if (rtccInvalid) errors.push(AllProductStatuses.RTCC_INVALID);

  if (masterKeyNotSet) errors.push(AllProductStatuses.MASTER_KEY_NOT_SET);

  return errors;
};

export const getOpenCloseOperationLabel = (state: string) => {
  switch (state) {
    case ProductState.CLOSED:
      return AllProductStatuses.OPEN;
    case ProductState.OPENED:
      return AllProductStatuses.CLOSE;
    case ProductState.DISABLED:
      return AllProductStatuses.DISABLED;
    default:
      return AllProductStatuses.NO_OPERATION;
  }
};
