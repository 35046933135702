import Color from 'src/constants/Shared/Color';
import styled, { css } from 'styled-components';

const StyledModalIcon = styled.i<{ size: string }>`
  display: flex;
  justify-content: center;
  color: ${Color.BACKGROUND_LIGTH};
  margin-top: 30px;

  ${(props) => {
    const { size } = props;

    if (size) {
      return css`
        font-size: ${size};
      `;
    }
    return css``;
  }}
`;

export default StyledModalIcon;
