import Color from 'src/constants/Shared/Color';
import styled, { css } from 'styled-components';

const InfoIcon = styled.i`
  background-color: ${Color.BORDER_LIGHT};
  color: ${Color.TEXT_LIGHTEST};
  width: 24px;
  height: 24px;
  margin-left: 12px;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWrapper = styled.div<{
  zoneView?: boolean;
  tariffView?: boolean;
  customWidth?: boolean;
  alignCenter?: boolean;
}>`
  position: relative;

  ${(props) => {
    const { zoneView, tariffView, customWidth, alignCenter } = props;

    if (zoneView) {
      return css`
        width: 39%;
        padding-left: 5px;
        @media (max-width: 550px) {
          width: 100%;
        }
      `;
    }

    if (tariffView) {
      return css`
        width: 150px;
        height: 36px;
        display: flex;
        align-items: center;
      `;
    }

    if (customWidth) {
      return css`
        width: 200px;
        height: 36px;
        margin-top: 30px;
      `;
    }

    if (alignCenter) {
      return css`
        width: 100%;
        display: flex;
        justify-content: center;
      `;
    }

    return css`
      width: 100%;
    `;
  }}
`;

const LabelWrapper = styled.div<{ addStatus?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${(props) => {
    const { addStatus } = props;

    if (addStatus) {
      return css`
        justify-content: space-between;
        @media (max-width: 500px) {
          flex-direction: column;
          align-items: flex-start;
        }
      `;
    }

    return css`
      justify-content: flex-start;
    `;
  }}
`;

const StyledDescription = styled.p<{ tariffView?: boolean }>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${Color.TEXT_DARKER};
  margin: 0px;
  text-align: left;

  ${(props) => {
    const { tariffView } = props;

    if (tariffView) {
      return css`
        font-size: 14px;
      `;
    }
    return css``;
  }}
`;

const StyledErrorWrapper = styled.div<{ tariffView?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 20px;

  ${(props) => {
    const { tariffView } = props;

    if (tariffView) {
      return css`
        font-size: 14px;
        line-height: 18px;
        color: #58595b;
      `;
    }
    return css``;
  }}
`;

const StyledError = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin: 0px;
`;

export {
  InfoIcon,
  StyledWrapper,
  LabelWrapper,
  StyledDescription,
  StyledErrorWrapper,
  StyledError,
};
