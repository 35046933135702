import type LotQuery from 'src/models/Parking/Lot/LotQuery';
import type LotQueryParams from 'src/models/Parking/Lot/LotQueryParams';

export default function toLotQuery(data: LotQueryParams): LotQuery {
  const { accountId } = data;

  return {
    account_id: accountId,
  };
}
