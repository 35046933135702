import type Account from '../../models/Home/Account';
import type AccountResponse from '../../models/Home/AccountResponse';

export default function toAccount(data: AccountResponse): Account {
  const { name, account_role_id, id } = data;

  return {
    name,
    accountRole: account_role_id,
    id,
  };
}
