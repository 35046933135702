import type ProductBollard from 'src/models/Parking/ProductBollard';
import type ProductBollardResponse from 'src/models/Parking/ProductBollardResponse';

export default function toProductBollard(
  data: ProductBollardResponse
): ProductBollard {
  const { id, state } = data;

  return {
    id,
    state,
  };
}
