export default function createUniqueChecker() {
  const checkMap: { [key: string]: boolean } = {};

  return (key: string) => {
    if (checkMap[key]) {
      return false;
    }

    checkMap[key] = true;

    return true;
  };
}
