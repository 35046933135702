import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  StyledFree,
  StyledLabel,
  StyledLabelTotal,
  StyledOccupied,
  StyledTotal,
} from 'src/components/Rotaries/StyledSummary';
import Card from 'src/components/Shared/Card';
import Line from 'src/components/Shared/Line';
import Color from 'src/constants/Shared/Color';
import type RotaryParkingPlace from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlace';
import styled from 'styled-components';

interface RotarySummaryProps {
  data: RotaryParkingPlace[];
}

export default function RotarySummary(props: RotarySummaryProps) {
  const { data } = props;

  const totalPlaces = useMemo(() => data.length, [data]);

  const occupiedPlaces = useMemo(
    () => data.filter((place) => place.isOccupied).length,
    [data]
  );

  const freePlaces = useMemo(
    () => totalPlaces - occupiedPlaces,
    [totalPlaces, occupiedPlaces]
  );

  const summaryInfo = {
    labels: ['Occupied', 'Free'],
    datasets: [
      {
        data: [occupiedPlaces, freePlaces],
        backgroundColor: [
          `${Color.PARKING_PLACE_OCCUPIED}`,
          `${Color.PARKING_PLACE_FREE}`,
        ],
      },
    ],
  };

  const summaryOptions = {
    responsive: true,
    legend: {
      display: false,
    },
  };

  return (
    <Card>
      <StyledInfoContainer>
        <StyledPieContainer>
          <Pie data={summaryInfo} options={summaryOptions} />
        </StyledPieContainer>
        <StyledWrapper>
          <StyledInfo>
            <StyledLabel>Free</StyledLabel>{' '}
            <StyledFree>{freePlaces}</StyledFree>
          </StyledInfo>
          <StyledInfo>
            <StyledLabel>Occupied</StyledLabel>{' '}
            <StyledOccupied>{occupiedPlaces}</StyledOccupied>
          </StyledInfo>
          <Line customWidth='70%' />
          <StyledInfo>
            <StyledLabelTotal>Total</StyledLabelTotal>{' '}
            <StyledTotal>{totalPlaces}</StyledTotal>
          </StyledInfo>
        </StyledWrapper>
      </StyledInfoContainer>
    </Card>
  );
}

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const StyledPieContainer = styled.div`
  max-width: 350px;
  width: 100%;
  margin: 20px 0;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Montserrat;
  align-items: center;
  margin: 20px 0;
`;

const StyledInfo = styled.div`
  display: inline-flex;
  justify-content: space-between;
  margin: 0 5%;
  width: 60%;
`;
