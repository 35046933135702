import { useCallback } from 'react';
import toLicense from 'src/mappers/License/toLicense';
import toLicenseCreateRequest from 'src/mappers/License/toLicenseCreateRequest';
import toLicenseUpdateParams from 'src/mappers/License/toLicenseUpdateParams';
import type License from 'src/models/License/License';
import type LicenseCreateRequest from 'src/models/License/LicenseCreateRequest';
import type LicenseCreateResponse from 'src/models/License/LicenseCreateResponse';
import type LicenseFormValues from 'src/models/License/LicenseFormValues';
import type LicenseResponse from 'src/models/License/LicenseResponse';
import type LicenseUpdateParams from 'src/models/License/LicenseUpdateParams';
import type LicenseUpdateValues from 'src/models/License/LicenseUpdateValues';
import useApiService from '../Shared/useApiService';

const licenseBaseURL = '/licenses';

interface LicenseService {
  addLicense: (values: LicenseFormValues) => Promise<LicenseCreateResponse>;
  updateLicenseById: (
    id: number,
    values: LicenseUpdateValues
  ) => Promise<License>;
  getLicenseById: (id: number) => Promise<License>;
}

export default function useLicenseService(): LicenseService {
  const { post, get, patch } = useApiService();

  const addLicense = useCallback(
    async (values: LicenseFormValues) => {
      const requestValues = toLicenseCreateRequest(values);
      const response = await post<LicenseCreateRequest, LicenseCreateResponse>(
        `${licenseBaseURL}`,
        requestValues
      );

      return response.data;
    },
    [post]
  );

  const getLicenseById = useCallback(
    async (id: number) => {
      const response = await get<LicenseResponse>(`${licenseBaseURL}/${id}`);

      return toLicense(response.data);
    },
    [get]
  );

  const updateLicenseById = useCallback(
    async (id: number, values: LicenseUpdateValues) => {
      const params = toLicenseUpdateParams(values);
      const response = await patch<LicenseUpdateParams, LicenseResponse>(
        `${licenseBaseURL}/${id}`,
        params
      );

      return toLicense(response.data);
    },
    [patch]
  );

  return {
    addLicense,
    getLicenseById,
    updateLicenseById,
  };
}
