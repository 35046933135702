import { useCallback } from 'react';
import toGateway from 'src/mappers/Parking/Gateway/toGateway';
import toGatewayQuery from 'src/mappers/Parking/Gateway/toGatewayQuery';
import toGatewayUpdateParams from 'src/mappers/Parking/Gateway/toGatewayUpdateParams';
import toMeta from 'src/mappers/Shared/toMeta';
import type Gateway from 'src/models/Parking/Gateway/Gateway';
import type GatewayQuery from 'src/models/Parking/Gateway/GatewayQuery';
import type GatewayQueryParams from 'src/models/Parking/Gateway/GatewayQueryParams';
import type GatewayRebootRequest from 'src/models/Parking/Gateway/GatewayRebootRequest';
import type GatewayRebootResponse from 'src/models/Parking/Gateway/GatewayRebootResponse';
import type GatewayRemoveResponse from 'src/models/Parking/Gateway/GatewayRemoveResponse';
import type GatewayResponse from 'src/models/Parking/Gateway/GatewayResponse';
import type GatewayUpdateParams from 'src/models/Parking/Gateway/GatewayUpdateParams';
import type GatewayUpdateResponse from 'src/models/Parking/Gateway/GatewayUpdateResponse';
import type GatewayUpdateValues from 'src/models/Parking/Gateway/GatewayUpdateValues';
import type GetAllDetails from '../../models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const gatewayBaseURL = '/gateways';
interface GatewayService {
  findAllGateways: (
    queryParams: GatewayQueryParams
  ) => Promise<GetAllDetails<Gateway>>;
  findOneGateway: (id: number) => Promise<Gateway>;
  updateGateway: (
    id: number,
    values: GatewayUpdateValues
  ) => Promise<GatewayUpdateResponse>;
  removeGateway: (id: number) => Promise<GatewayRemoveResponse>;
  rebootGateway: (id: number) => Promise<GatewayRebootResponse>;
}

export default function useGatewayService(): GatewayService {
  const { post, get, remove, patch } = useApiService();

  const findAllGateways = useCallback(
    async (queryParams: GatewayQueryParams) => {
      const query = toGatewayQuery(queryParams);
      const response = await get<GatewayResponse[], GatewayQuery>(
        `${gatewayBaseURL}`,
        query
      );

      const data = response.data.map(toGateway);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findOneGateway = useCallback(
    async (id: number) => {
      const response = await get<GatewayResponse>(`${gatewayBaseURL}/${id}`);

      return toGateway(response.data);
    },
    [get]
  );

  const updateGateway = useCallback(
    async (id: number, values: GatewayUpdateValues) => {
      const params = toGatewayUpdateParams(values);
      const response = await patch<GatewayUpdateParams, GatewayUpdateResponse>(
        `${gatewayBaseURL}/${id}`,
        params
      );

      return response;
    },
    [patch]
  );

  const removeGateway = useCallback(
    async (id: number) => {
      const response = await remove<GatewayRemoveResponse>(
        `${gatewayBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  const rebootGateway = useCallback(
    async (id: number) => {
      const response = await post<GatewayRebootRequest, GatewayRebootResponse>(
        `${gatewayBaseURL}/${id}/operation`,
        { operation: 'reboot' }
      );

      return response;
    },
    [post]
  );

  return {
    findAllGateways,
    findOneGateway,
    removeGateway,
    updateGateway,
    rebootGateway,
  };
}
