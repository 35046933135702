import type OperationKeyStatistics from 'src/models/Statistics/OperationKey/OperationKeyStatistics';
import type OperationKeyStatisticsResponse from 'src/models/Statistics/OperationKey/OperationKeyStatisticsResponse';

export default function toOperationKeyStatistics(
  data: OperationKeyStatisticsResponse
): OperationKeyStatistics {
  const { active, reactived, blocked, invalid, deleted, expired } = data;

  return {
    active,
    reactived,
    blocked,
    invalid,
    deleted,
    expired,
  };
}
