import { createContext } from 'react';

interface LoadingContextData {
  isLoading?: boolean;
}

const initialLoadingContextData: LoadingContextData = {
  isLoading: false,
};

const LoadingContext = createContext(initialLoadingContextData);

export default LoadingContext;
