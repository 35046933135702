import CameraFiltersDropdownValues from 'src/constants/Parking/CameraValues';
import LprPositions from 'src/constants/Parking/LprPositions';

export const getCameraPosition = (
  camera: CameraFiltersDropdownValues | undefined
) => {
  switch (camera) {
    case CameraFiltersDropdownValues.IN:
      return LprPositions.IN;
    case CameraFiltersDropdownValues.OUT:
      return LprPositions.OUT;
    default:
      return undefined;
  }
};
