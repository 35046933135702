import type LicensePurchase from 'src/models/License/LicensePurchase';
import type LicensePurchaseResponse from 'src/models/License/LicensePurchaseResponse';
import toLicensePricing from './toLicensePricing';

export default function toLicensePurchase(
  data: LicensePurchaseResponse
): LicensePurchase {
  const {
    id,
    purchased_count,
    barriers,
    lpr_cameras,
    gates,
    brains,
    chains,
    bollards,
    license_pricing,
  } = data;

  return {
    id,
    purchasedCount: purchased_count,
    barriers,
    lprCameras: lpr_cameras,
    gates,
    brains,
    chains,
    bollards,
    licensePricing: toLicensePricing(license_pricing),
  };
}
