import toAccountUser from 'src/mappers/Settings/toAccountUser';
import type Manager from '../../../models/Parking/Manager/Manager';
import type ManagerResponse from '../../../models/Parking/Manager/ManagerResponse';

export default function toManager(data: ManagerResponse): Manager {
  const { id, account_user } = data;

  return {
    id,
    accountUser: toAccountUser(account_user),
  };
}
