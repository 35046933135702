import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import DeleteButton from '../Shared/Buttons/DeleteButton';
import CheckBoxField from '../Shared/CheckBoxField';
import Control from '../Shared/Control';
import InlineDiv from '../Shared/InlineDiv';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import RadioButtonField from '../Shared/RadioButtonField';
import Section from '../Shared/Section';
import SelectField from '../Shared/SelectField';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';

interface LicenseFormProps {
  closeParentModal: () => void;
  removeItem: () => void;
  activeId?: number | undefined;
}

export default function LicenseForm(props: LicenseFormProps) {
  const { closeParentModal, activeId, removeItem } = props;
  const { isSubmitting, dirty } = useFormikContext();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      <Section>
        <StyledPublishDiv>
          <Title bold>License</Title>
          <CheckBoxField name='status' label='Publish' reverse />
        </StyledPublishDiv>
        <Line />
        <TextField
          autofocus
          label='License Name'
          placeholder='License Name'
          type='text'
          name='name'
          short
          stacked
        />
        <RadioButtonField
          label='Products'
          name='licenseTypeId'
          value={1}
          defaultChecked
        />
        <TextField
          label='LPR Cameras'
          placeholder=''
          type='number'
          name='lprCamerasCount'
          reverse
          testId='lpr'
        />
        <TextField
          label='Gates/Chains/Bollards'
          placeholder=''
          type='number'
          name='brainModulesCount'
          reverse
          testId='gate'
        />
        <TextField
          label='Barriers'
          placeholder=''
          type='number'
          name='barriersCount'
          reverse
          testId='barrier'
        />
        <InlineDiv>
          <TextField
            label='Price'
            placeholder='Price'
            type='number'
            name='price'
            small
            stacked
          />
          <SelectField
            label='€'
            placeholder='Payment info'
            name='paymentInfo'
            options={[{ key: 0, label: 'One time' }]}
            small
          />
        </InlineDiv>
      </Section>
      <Line addMargin />
      <Control>
        {activeId && <DeleteButton onClick={removeItem} />}
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}

const StyledPublishDiv = styled.div`
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
