import SignalFull from '../../assets/img/full.png';
import SignalLower from '../../assets/img/lower.png';
import SignalLowest from '../../assets/img/lowest.png';
import SignalMedium from '../../assets/img/medium.png';
import SignalNoSignal from '../../assets/img/noSignal.png';

const SignalIcon = {
  HIGH: SignalFull,
  MEDIUM: SignalMedium,
  LOW: SignalLower,
  LOWEST: SignalLowest,
  NO: SignalNoSignal,
};

export default SignalIcon;
