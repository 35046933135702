import type WeblinkMetaData from 'src/models/KeySharing/Weblink/WeblinkMetaData';
import type WeblinkMetaDataResponse from 'src/models/KeySharing/Weblink/WeblinkMetaDataResponse';
import toProduct from '../Parking/Product/toProduct';

export default function toWeblinkMetaData(
  data: WeblinkMetaDataResponse
): WeblinkMetaData {
  const { id, sender_id, product_id, created_at, updated_at, product } = data;

  return {
    id,
    senderId: sender_id,
    productId: product_id,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    product: toProduct(product),
  };
}
