import { useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { StatusOptions } from 'src/constants/Parking/ParkingDetectionImplementationStatus';
import AppRole from 'src/constants/Shared/AppRole';
import useUserHasRole from 'src/hooks/Shared/useUserHasRole';
import ButtonSize from '../../constants/Shared/ButtonSize';
import TimeZone from '../../constants/Shared/TimeZone';
import type LotFormValues from '../../models/Parking/Lot/LotFormValues';
import Button from '../Shared/Button';
import CheckBoxField from '../Shared/CheckBoxField';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import Map from '../Shared/Map';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Section from '../Shared/Section';
import SelectField from '../Shared/SelectField';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';

interface LotFormProps {
  closeParentModal: () => void;
  hasDetection?: boolean;
}

export default function LotForm(props: LotFormProps) {
  const { closeParentModal, hasDetection } = props;
  const userHasRole = useUserHasRole();

  const { values, isSubmitting, setValues, dirty } =
    useFormikContext<LotFormValues>();
  const { latitude, longitude, isDetect } = values;
  const lat = latitude ? parseFloat(latitude) : undefined;
  const lng = longitude ? parseFloat(longitude) : undefined;

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const onMapClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (!event) return;
      setValues((oldValues) => ({
        ...oldValues,
        latitude: event.latLng?.lat().toString() || '',
        longitude: event.latLng?.lng().toString() || '',
      }));
    },
    [setValues]
  );

  return (
    <>
      <Section>
        <Title bold>Lot</Title>
        <TextField
          autofocus
          label='Name'
          placeholder='Name'
          type='text'
          name='name'
          stacked
        />
        <TextField
          label='Address'
          placeholder='Address'
          type='text'
          name='address'
          stacked
        />
        <Title bold>Coordinates</Title>
        <SelectField
          label='Time Zone'
          placeholder='Time Zone'
          name='timeZone'
          options={TimeZone}
          stacked
        />
        <TextField
          label='Latitude'
          placeholder='Latitude'
          type='number'
          name='latitude'
          stacked
        />
        <TextField
          label='Longitude'
          placeholder='Longitude'
          type='number'
          name='longitude'
          stacked
        />
        <Map
          onMapClick={onMapClick}
          centerLatitude={lat}
          centerLongitude={lng}
          removeBottomMargin
        />
        {userIsParklioInternal && (
          <>
            <CheckBoxField label='Is detect lot' name='isDetect' />
            {hasDetection && isDetect && (
              <SelectField
                label='Status'
                placeholder='Status'
                name='detectionImplementationStatus'
                options={StatusOptions}
                stacked
              />
            )}
          </>
        )}
      </Section>
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
