import addZeroBeforeNumber from './addZeroBeforeNumber';

export default function formatDates(date: string, addSeconds?: boolean) {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const seconds = newDate.getSeconds();

  if (addSeconds) {
    return `${addZeroBeforeNumber(day)}-${addZeroBeforeNumber(
      month
    )}-${year} / ${addZeroBeforeNumber(hours)}:${addZeroBeforeNumber(
      minutes
    )}:${addZeroBeforeNumber(seconds)}`;
  }

  return `${addZeroBeforeNumber(day)}-${addZeroBeforeNumber(
    month
  )}-${year} / ${addZeroBeforeNumber(hours)}:${addZeroBeforeNumber(minutes)}`;
}
