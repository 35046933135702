import type ParkingDetectionIntegrations from 'src/constants/Parking/ParkingDetectionIntegrations';
import type LotCreateRequest from 'src/models/Parking/Lot/LotCreateRequest';
import type LotFormValues from 'src/models/Parking/Lot/LotFormValues';

export default function toLotCreateRequest(
  data: LotFormValues,
  targetAccountId?: number,
  detectionIntegration?: ParkingDetectionIntegrations
): LotCreateRequest {
  const { name, address, description, timeZone, latitude, longitude } = data;

  return {
    name,
    address,
    description,
    time_zone: timeZone?.key || null,
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
    target_account_id: targetAccountId,
    parking_detection_integration: detectionIntegration,
  };
}
