import type BindLicenseRequest from 'src/models/Parking/BindLicenseRequest';
import type BindLicenseValues from 'src/models/Parking/BindLicenseValues';

export default function toBindLicenseRequest(
  data: BindLicenseValues
): BindLicenseRequest {
  const { productIds, licenseIds } = data;

  return {
    product_ids: productIds,
    license_ids: licenseIds,
  };
}
