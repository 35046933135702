import type AccountInfo from 'src/models/Settings/AccountInfo';
import type AccountInfoResponse from 'src/models/Settings/AccountInfoResponse';
import toAccountRole from '../Shared/toAccountRole';
import toCountry from '../Shared/toCountry';
import toCurrency from '../Shared/toCurrency';

export default function toAccountInfo(data: AccountInfoResponse): AccountInfo {
  const {
    id,
    name,
    account_role,
    auto_extend,
    contact_name,
    contact_email,
    contact_phone,
    billing_title,
    vat,
    address,
    city,
    zip_code,
    invoice_country_id,
    invoice_currency_id,
    invoice_country,
    invoice_currency,
    country,
  } = data;

  return {
    id,
    name,
    accountRole: account_role ? toAccountRole(account_role) : null,
    autoExtend: auto_extend ? new Date(auto_extend) : null,
    contactName: contact_name,
    contactEmail: contact_email,
    contactPhone: contact_phone,
    billingTitle: billing_title,
    vat,
    address,
    city,
    zipCode: zip_code,
    invoiceCountryId: invoice_country_id,
    invoiceCurrencyId: invoice_currency_id,
    invoiceCurrency: invoice_currency ? toCurrency(invoice_currency) : null,
    invoiceCountry: invoice_country ? toCountry(invoice_country) : null,
    country: country ? toCountry(country) : null,
  };
}
