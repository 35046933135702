import styled, { css } from 'styled-components';

const DetailsColumn = styled.div<{ removePadding?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  :first-child {
    padding: 0px 10px 0px 30px;
  }

  :last-child {
    padding: 0px 30px 0px 10px;

    ${(props) => {
      const { removePadding } = props;

      if (removePadding) {
        return css`
          padding: 0;
        `;
      }
      return css``;
    }};
  }
`;

export default DetailsColumn;
