import React, { useCallback } from 'react';
import GatewayStatus from 'src/constants/Parking/GatewayStatus';
import Color from 'src/constants/Shared/Color';
import useGatewayStatusLabel from 'src/hooks/Parking/useGatewayStatusLabel';
import styled, { css } from 'styled-components';
import { Name, Status } from '../CardStyle';

interface CardProps {
  id: number;
  name: string;
  status: GatewayStatus;
  onNameClick: (id: number) => void;
  dashboardView?: boolean;
}

export default function GatewayCard(props: CardProps) {
  const { id, name, status, onNameClick, dashboardView } = props;
  const offline = status === GatewayStatus.OFFLINE;
  const statusLabel = useGatewayStatusLabel(status);

  const onClick = useCallback(() => {
    onNameClick(id);
  }, [onNameClick, id]);

  return (
    <StyledWrapper dashboardView={dashboardView}>
      <StyledIcon className='pa-gateway' />
      <Name onClick={onClick}>{name}</Name>
      <Status offline={offline}>{statusLabel}</Status>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ dashboardView?: boolean }>`
  height: 150px;
  box-shadow: 0px 0px 30px ${Color.SHADOW_DARK_TRANSPARENT};
  border-radius: 5px;
  background-color: ${Color.BACKGROUND_LIGHTER};
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin: 0px 0px 20px 20px;

  ${(props) => {
    const { dashboardView } = props;

    if (dashboardView) {
      return css`
        @media (min-width: 1101px) {
          width: calc((100% - 20px) / 2);

          :nth-child(2n -1) {
            margin: 0px 0px 20px 0px;
          }
        }
        @media (max-width: 1100px) {
          width: calc((100% - 40px) / 3);
          :nth-child(3n -2) {
            margin: 0px 0px 20px 0px;
          }
        }
      `;
    }

    return css`
      width: calc((100% - 40px) / 3);

      :nth-child(3n -2) {
        margin: 0px 0px 20px 0px;
      }
    `;
  }}
`;

const StyledIcon = styled.i`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  margin: 5px;
  color: ${Color.TEXT_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;
