import styled from 'styled-components';
import Color from '../../constants/Shared/Color';

const DetailsLabel = styled.div`
  text-align: left;
  padding-right: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${Color.TEXT_DARKER};
  margin: 20px 0 10px 0;
`;

export default DetailsLabel;
