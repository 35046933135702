import type ParkingPlaceQuery from 'src/models/Parking/ParkingPlace/ParkingPlaceQuery';
import type ParkingPlaceQueryParams from 'src/models/Parking/ParkingPlace/ParkingPlaceQueryParams';

export default function toParkingPlaceQuery(
  data: ParkingPlaceQueryParams
): ParkingPlaceQuery {
  const { lotId, page, size, uuid } = data;

  return {
    lot_id: lotId,
    page,
    size,
    uuid,
  };
}
