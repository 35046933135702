import type ParkingPlaceChangeData from 'src/models/Parking/ParkingPlace/ParkingPlaceChangeData';
import type ParkingPlaceChangePayloadData from 'src/models/Parking/ParkingPlace/ParkingPlaceChangePayloadData';

export default function toParkingPlaceChangeData(
  data: ParkingPlaceChangePayloadData
): ParkingPlaceChangeData {
  const { id, lot_id, zone_id, name, occupancy_status } = data;

  return {
    id,
    lotId: lot_id,
    zoneId: zone_id,
    name,
    occupancyStatus: occupancy_status,
  };
}
