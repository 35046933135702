import type AccountUser from 'src/models/Home/AccountUser';
import type AccountUserResponse from 'src/models/Home/AccountUserResponse';
import toAccount from './toAccount';

export default function toAccountUser(data: AccountUserResponse): AccountUser {
  const { id, email, account } = data;

  return {
    id,
    email,
    account: toAccount(account),
  };
}
