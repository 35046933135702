enum ReportsColors {
  LIGHTEST_BLUE = '#C0E2F1',
  LIGHTER_BLUE = '#9BD3EB',
  MEDIUM_BLUE = '#69BDE2',
  BLUE = '#0591CE',
  LIGHT_GREY = '#9B9B9D',
  MEDIUM_GREY = '#797A7C',
  DARK_GREY = '#58595B',
}

export default ReportsColors;
