import type CameraUpdateFormValues from 'src/models/Parking/Anpr/LprCamera/CameraUpdateFormValues';
import type CameraUpdateParams from 'src/models/Parking/Anpr/LprCamera/CameraUpdateParams';

export default function toCameraUpdateParams(
  data: CameraUpdateFormValues
): CameraUpdateParams {
  const { name } = data;

  return {
    name,
  };
}
