import { useCallback } from 'react';
import toLicensePricing from 'src/mappers/License/toLicensePricing';
import toLicensePricingCreateRequest from 'src/mappers/License/toLicensePricingCreateRequest';
import toLicensePricingQuery from 'src/mappers/License/toLicensePricingQuery';
import toLicensePricingUpdateParams from 'src/mappers/License/toLicensePricingUpdateParams';
import toMeta from 'src/mappers/Shared/toMeta';
import type LicenseFormValues from 'src/models/License/LicenseFormValues';
import type LicensePricing from 'src/models/License/LicensePricing';
import type LicensePricingCreateRequest from 'src/models/License/LicensePricingCreateRequest';
import type LicensePricingCreateResponse from 'src/models/License/LicensePricingCreateResponse';
import type LicensePricingQuery from 'src/models/License/LicensePricingQuery';
import type LicensePricingQueryParams from 'src/models/License/LicensePricingQueryParams';
import type LicensePricingResponse from 'src/models/License/LicensePricingResponse';
import type LicensePricingUpdateParams from 'src/models/License/LicensePricingUpdateParams';
import type LicenseRemoveResponse from 'src/models/License/LicenseRemoveResponse';
import type LicenseUpdateValues from 'src/models/License/LicenseUpdateValues';
import type GetAllDetails from 'src/models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const licensePricingBaseURL = '/license-pricings';

interface LicensePricingService {
  addLicensePricing: (
    values: LicenseFormValues,
    id: number
  ) => Promise<LicensePricingCreateResponse>;
  listAllLicensePricings: (
    queryParams: LicensePricingQueryParams
  ) => Promise<GetAllDetails<LicensePricing>>;
  updateLicensePricingById: (
    id: number,
    values: LicenseUpdateValues
  ) => Promise<LicensePricing>;
  getLicensePricingById: (id: number) => Promise<LicensePricing>;
  removeLicensePricing: (id: number) => Promise<LicenseRemoveResponse>;
}

export default function useLicensePricingService(): LicensePricingService {
  const { post, get, patch, remove } = useApiService();

  const addLicensePricing = useCallback(
    async (values: LicenseFormValues, id: number) => {
      const requestValues = toLicensePricingCreateRequest(values, id, 2);
      const response = await post<
        LicensePricingCreateRequest,
        LicensePricingCreateResponse
      >(`${licensePricingBaseURL}`, requestValues);

      return response;
    },
    [post]
  );

  const listAllLicensePricings = useCallback(
    async (queryParams: LicensePricingQueryParams) => {
      const query = toLicensePricingQuery(queryParams);
      const response = await get<LicensePricingResponse[], LicensePricingQuery>(
        `${licensePricingBaseURL}`,
        query
      );
      const data = response.data.map(toLicensePricing);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const getLicensePricingById = useCallback(
    async (id: number) => {
      const response = await get<LicensePricingResponse>(
        `${licensePricingBaseURL}/${id}`
      );

      return toLicensePricing(response.data);
    },
    [get]
  );

  const updateLicensePricingById = useCallback(
    async (id: number, values: LicenseUpdateValues) => {
      const params = toLicensePricingUpdateParams(values);
      const response = await patch<
        LicensePricingUpdateParams,
        LicensePricingResponse
      >(`${licensePricingBaseURL}/${id}`, params);

      return toLicensePricing(response.data);
    },
    [patch]
  );

  const removeLicensePricing = useCallback(
    async (id: number) => {
      const response = await remove<LicenseRemoveResponse>(
        `${licensePricingBaseURL}/${id}`
      );

      return response.data;
    },
    [remove]
  );

  return {
    addLicensePricing,
    listAllLicensePricings,
    getLicensePricingById,
    updateLicensePricingById,
    removeLicensePricing,
  };
}
