import type TariffQuery from 'src/models/Parking/Tariff/TariffQuery';
import type TariffQueryParams from 'src/models/Parking/Tariff/TariffQueryParams';

export default function toTariffQueryParams(
  data?: TariffQuery
): TariffQueryParams {
  return {
    zone_id: data?.zoneId,
    name: data?.name,
  };
}
