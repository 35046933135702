import {
  ParkingDetectionImplementationStatus,
  StatusValues,
} from 'src/constants/Parking/ParkingDetectionImplementationStatus';
import TimeZone from 'src/constants/Shared/TimeZone';
import type Lot from 'src/models/Parking/Lot/Lot';
import type LotFormValues from 'src/models/Parking/Lot/LotFormValues';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';

export default function toLotFormValues(data: Lot): LotFormValues {
  const {
    name,
    address,
    description,
    timeZone,
    latitude,
    longitude,
    detectionImplementationStatus,
  } = data;

  return {
    name,
    address,
    description: description || '',
    timeZone:
      timeZone !== null
        ? {
            key: timeZone,
            label: TimeZone.find((x) => x.key === timeZone)?.label || '',
          }
        : null,
    latitude: latitude ? latitude.toString() : '',
    longitude: longitude ? longitude.toString() : '',
    isDetect:
      detectionImplementationStatus !==
      ParkingDetectionImplementationStatus.NOT_IMPLEMENTED,
    detectionImplementationStatus: {
      key: StatusValues[detectionImplementationStatus],
      label: capitalizeFirstLetter(detectionImplementationStatus),
    },
  };
}
