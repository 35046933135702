import React, { useMemo } from 'react';
import BatteryIcon from 'src/constants/Parking/BatteryIcon';
import AllProductStatuses from '../../../constants/Parking/AllProductStatuses';
import AllProductTypes from '../../../constants/Parking/AllProductTypes';
import OperationIcon from '../../../constants/Parking/OperationIcon';
import ProductIcon from '../../../constants/Parking/ProductIcon';
import type ProductOperations from '../../../constants/Parking/ProductOperations';
import ProductStatus from '../../../constants/Parking/ProductStatus';
import ButtonSize from '../../../constants/Shared/ButtonSize';
import type Product from '../../../models/Parking/Product';
import {
  getDeviceStatuses,
  getErrors,
  getOpenCloseOperationLabel,
} from '../../../utils/getDeviceInfo';
import Button from '../../Shared/Button';
import CheckBox from '../../Shared/CheckBox';
import IconRow from '../../Shared/IconRow';
import Table from '../../Shared/Table';
import { StyledIcon } from '../BatteryPowerIcon';
import ProductStatuses from '../ProductStatuses';
import ProductTableIcon from '../ProductTableIcon';
import SignalStatus from '../SignalStatus';

interface ProductTableBollardRowProps {
  productData: Product;
  deviceInProgress: boolean;
  selectedForSharing: boolean;
  onClick: (
    productId: number,
    operation: ProductOperations | undefined
  ) => Promise<void>;
  updateSharableProducts: () => void;
  openProductDetailsModal: () => void;
  getDeviceOperation: (
    state: string
  ) => ProductOperations.OPEN | ProductOperations.CLOSE | undefined;
  isAnyGatewayOnline: boolean;
  lotHasGateway: boolean;
}

export default function ProductTableBollardRow(
  props: ProductTableBollardRowProps
) {
  const {
    productData,
    deviceInProgress,
    selectedForSharing,
    onClick,
    updateSharableProducts,
    getDeviceOperation,
    openProductDetailsModal,
    isAnyGatewayOnline,
    lotHasGateway,
  } = props;
  const { id, name, bollard, productError, status, rssi } = productData;
  const statuses = isAnyGatewayOnline
    ? getDeviceStatuses(productData)
    : AllProductStatuses.OFFLINE;
  const operation:
    | ProductOperations.OPEN
    | ProductOperations.CLOSE
    | undefined = getDeviceOperation(bollard?.state || '');
  const label =
    status === ProductStatus.OFFLINE || !isAnyGatewayOnline
      ? AllProductStatuses.NO_OPERATION
      : getOpenCloseOperationLabel(bollard?.state || '');
  const errors = getErrors(productError);
  const rtccError = useMemo(() => {
    if (errors.length === 0) return false;

    if (errors.length === 1 && productError?.rtccInvalid) return false;
    return true;
  }, [productError, errors]);
  const disabled = useMemo(
    () =>
      deviceInProgress ||
      statuses !== AllProductStatuses.ONLINE ||
      rtccError ||
      !isAnyGatewayOnline,
    [deviceInProgress, statuses, rtccError, isAnyGatewayOnline]
  );

  return (
    <Table.Row>
      <Table.Cell>
        <CheckBox
          checked={selectedForSharing}
          readonly
          onClick={updateSharableProducts}
          title='Select'
        />
      </Table.Cell>
      <Table.Cell primary>
        <div onClick={openProductDetailsModal}>
          <ProductTableIcon icon={ProductIcon.BOLLARD} />
          {name}
        </div>
      </Table.Cell>
      <Table.Cell show='smallDevices'>
        <Button
          primary
          size={ButtonSize.SMALL}
          type='button'
          onClick={() => onClick(id, operation)}
          isLoading={deviceInProgress}
          disabled={disabled}
        >
          {label === AllProductStatuses.OPEN && (
            <IconRow className={OperationIcon.DOWN} />
          )}
          {label === AllProductStatuses.CLOSE && (
            <IconRow className={OperationIcon.UP} />
          )}
          {label}
        </Button>
      </Table.Cell>
      {lotHasGateway && (
        <>
          <Table.Cell center>
            {<StyledIcon className={BatteryIcon.POWER} />}
          </Table.Cell>
          <Table.Cell
            error={errors.length > 1}
            invalid={
              statuses === AllProductStatuses.OFFLINE || errors.length === 1
            }
          >
            <ProductStatuses
              statuses={statuses}
              errors={errors}
              productType={AllProductTypes.BOLLARD}
            />
          </Table.Cell>
          <Table.Cell center>
            <SignalStatus
              signalStatus={rssi}
              isAnyGatewayOnline={isAnyGatewayOnline}
            />
          </Table.Cell>
          <Table.Cell show={'largeDevices'}>
            <Button
              primary
              size={ButtonSize.SMALL}
              type='button'
              onClick={() => onClick(id, operation)}
              isLoading={deviceInProgress}
              disabled={disabled}
            >
              {label === AllProductStatuses.OPEN && (
                <IconRow className={OperationIcon.DOWN} />
              )}
              {label === AllProductStatuses.CLOSE && (
                <IconRow className={OperationIcon.UP} />
              )}
              {label}
            </Button>
          </Table.Cell>
        </>
      )}
    </Table.Row>
  );
}
