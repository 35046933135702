import { useCallback } from 'react';
import toAccountUser from 'src/mappers/Home/toAccountUser';
import toAccountUserQuery from 'src/mappers/Home/toAccountUserQuery';
import type AccountUser from 'src/models/Home/AccountUser';
import type AccountUserQuery from 'src/models/Home/AccountUserQuery';
import type AccountUserQueryParams from 'src/models/Home/AccountUserQueryParams';
import type AccountUserResponse from 'src/models/Home/AccountUserResponse';
import ContentType from '../../constants/Shared/ContentType';
import toMeta from '../../mappers/Shared/toMeta';
import type GetAllDetails from '../../models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const accountUserBaseURL = '/account-users';

interface AccountUserService {
  findAllAccountUsersParklioInternal: (
    params: AccountUserQueryParams
  ) => Promise<GetAllDetails<AccountUser>>;
}

export default function useAccountUserService(): AccountUserService {
  const { get } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: true,
  });

  const findAllAccountUsersParklioInternal = useCallback(
    async (queryParams: AccountUserQueryParams) => {
      const query = toAccountUserQuery(queryParams);
      const response = await get<AccountUserResponse[], AccountUserQuery>(
        `${accountUserBaseURL}`,
        query
      );
      const data = response.data.map(toAccountUser);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  return {
    findAllAccountUsersParklioInternal,
  };
}
