export default function toAccountUserRole(role: number): string {
  switch (role) {
    case 1:
      return 'Super Admin';
    case 2:
      return 'Admin';
    case 3:
      return 'Operator';
    default:
      return 'Unknown';
  }
}
