export default function toProductTypeName(productTypeId: number): string {
  switch (productTypeId) {
    case 0:
      return 'Gateway';
    case 1:
      return 'Legacy Barrier';
    case 2:
      return 'Barrier';
    case 3:
      return 'Gate';
    case 4:
      return 'Brain';
    case 5:
      return 'Chain';
    case 6:
      return 'Bollard';
    default:
      return 'Unknown';
  }
}
