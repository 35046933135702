import type Option from '../../models/Shared/Option';

const TimeZone: Option[] = [
  {
    key: -12,
    label: '(GMT -12:00) Eniwetok, Kwajalein',
  },
  {
    key: -11,
    label: '(GMT -11:00) Midway Island, Samoa',
  },
  {
    key: -10,
    label: '(GMT -10:00) Hawaii',
  },
  {
    key: -9,
    label: '(GMT -9:00) Alaska',
  },
  {
    key: -8,
    label: '(GMT -8:00) Pacific Time (US & Canada)',
  },
  {
    key: -7,
    label: '(GMT -7:00) Mountain Time (US & Canada)',
  },
  {
    key: -6,
    label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
  },
  {
    key: -5,
    label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
  },
  {
    key: -4,
    label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
  },
  {
    key: -3.5,
    label: '(GMT -3:30) Newfoundland',
  },
  {
    key: -3,
    label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
  },
  {
    key: -2,
    label: '(GMT -2:00) Mid-Atlantic',
  },
  {
    key: -1,
    label: '(GMT -1:00) Azores, Cape Verde Islands',
  },
  {
    key: 0,
    label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
  },
  {
    key: 1,
    label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    key: 2,
    label: '(GMT +2:00) Kaliningrad, South Africa',
  },
  {
    key: 3,
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
  },
  {
    key: 3.5,
    label: '(GMT +3:30) Tehran',
  },
  {
    key: 4,
    label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
  },
  {
    key: 4.5,
    label: '(GMT +4:30) Kabul',
  },
  {
    key: 5,
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
  },
  {
    key: 5.5,
    label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
  },
  {
    key: 5.75,
    label: '(GMT +5:45) Kathmandu',
  },
  {
    key: 6,
    label: '(GMT +6:00) Almaty, Dhaka, Colombo',
  },
  {
    key: 7,
    label: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
  },
  {
    key: 8,
    label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
  },
  {
    key: 9,
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
  },
  {
    key: 9.5,
    label: '(GMT +9:30) Adelaide, Darwin',
  },
  {
    key: 10,
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
  },
  {
    key: 11,
    label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
  },
  {
    key: 12,
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
  },
];

export default TimeZone;
