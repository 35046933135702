import { useCallback } from 'react';
import KeyLogsStatuses from 'src/constants/KeyManagement/KeyLogsStatuses';
import toKeyLog from 'src/mappers/KeySharing/toKeyLog';
import toKeyLogResendParams from 'src/mappers/KeySharing/toKeyLogResendParams';
import toKeyLogUpdateByIdParams from 'src/mappers/KeySharing/toKeyLogUpdateByIdParams';
import toKeyLogUpdateParams from 'src/mappers/KeySharing/toKeyLogUpdateParams';
import type KeyLogResendParams from 'src/models/KeySharing/KeyLog/KeyLogResendParams';
import type KeyLogResendResponse from 'src/models/KeySharing/KeyLog/KeyLogResendResponse';
import type KeyLogResendValues from 'src/models/KeySharing/KeyLog/KeyLogResendValues';
import type KeyLogResponse from 'src/models/KeySharing/KeyLog/KeyLogResponse';
import type KeyLogUpdateByIdParams from 'src/models/KeySharing/KeyLog/KeyLogUpdateByIdParams';
import type KeyLogUpdateByIdResponse from 'src/models/KeySharing/KeyLog/KeyLogUpdateByIdResponse';
import type KeyLogUpdateByIdValues from 'src/models/KeySharing/KeyLog/KeyLogUpdateByIdValues';
import type KeyLogUpdateParams from 'src/models/KeySharing/KeyLog/KeyLogUpdateParams';
import type KeyLogUpdateResponse from 'src/models/KeySharing/KeyLog/KeyLogUpdateResponse';
import type KeyLogUpdateValues from 'src/models/KeySharing/KeyLog/KeyLogUpdateValues';
import toKeyManagementQuery from '../../mappers/KeySharing/toKeyManagementQuery';
import toMeta from '../../mappers/Shared/toMeta';
import type KeyLog from '../../models/KeySharing/KeyLog/KeyLog';
import type KeyManagementQuery from '../../models/KeySharing/KeyManagementQuery';
import type KeyManagementQueryParams from '../../models/KeySharing/KeyManagementQueryParams';
import type GetAllDetails from '../../models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const keyLogsBaseURL = '/key-logs';
interface KeyLogService {
  listAllKeyLogs: (
    query: KeyManagementQueryParams
  ) => Promise<GetAllDetails<KeyLog>>;

  updateKeyLogs: (
    values: KeyLogUpdateValues
  ) => Promise<KeyLogUpdateResponse[]>;

  updateKeyLogsById: (
    id: number,
    values: KeyLogUpdateByIdValues
  ) => Promise<KeyLogUpdateByIdResponse>;

  blockKeyLogs: (ids: number[]) => Promise<KeyLogUpdateResponse[]>;

  blockKeyLogById: (id: number) => Promise<KeyLogUpdateByIdResponse>;

  resendKeyLogs: (
    values: KeyLogResendValues
  ) => Promise<KeyLogResendResponse[]>;
}

export default function useKeyLogService(): KeyLogService {
  const { get, patch, post } = useApiService();

  const listAllKeyLogs = useCallback(
    async (queryParams: KeyManagementQueryParams) => {
      const query = toKeyManagementQuery(queryParams);
      const response = await get<KeyLogResponse[], KeyManagementQuery>(
        `${keyLogsBaseURL}`,
        query
      );

      const data = response.data.map(toKeyLog);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const updateKeyLogs = useCallback(
    async (values: KeyLogUpdateValues) => {
      const params = toKeyLogUpdateParams(values);
      const response = await patch<KeyLogUpdateParams, KeyLogUpdateResponse[]>(
        `${keyLogsBaseURL}`,
        params
      );

      return response.data;
    },
    [patch]
  );

  const updateKeyLogsById = useCallback(
    async (id: number, values: KeyLogUpdateByIdValues) => {
      const params = toKeyLogUpdateByIdParams(values);
      const response = await patch<
        KeyLogUpdateByIdParams,
        KeyLogUpdateByIdResponse
      >(`${keyLogsBaseURL}/${id}`, params);

      return response.data;
    },
    [patch]
  );

  const blockKeyLogs = useCallback(
    (ids: number[]) => updateKeyLogs({ ids, status: KeyLogsStatuses.BLOCKED }),
    [updateKeyLogs]
  );

  const blockKeyLogById = useCallback(
    (id: number) => updateKeyLogsById(id, { status: KeyLogsStatuses.BLOCKED }),
    [updateKeyLogsById]
  );

  const resendKeyLogs = useCallback(
    async (values: KeyLogResendValues) => {
      const params = toKeyLogResendParams(values);
      const response = await post<KeyLogResendParams, KeyLogResendResponse[]>(
        `${keyLogsBaseURL}/resend`,
        params
      );

      return response.data;
    },
    [post]
  );

  return {
    listAllKeyLogs,
    updateKeyLogs,
    updateKeyLogsById,
    blockKeyLogs,
    blockKeyLogById,
    resendKeyLogs,
  };
}
