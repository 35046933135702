import type { FormikHelpers } from 'formik';
import React, { useCallback, useState, useEffect } from 'react';
import { useGlobalConfirmationModal } from 'src/components/Shared/Modals/GlobalConfirmationModal';
import type Brain from 'src/models/Parking/Brain/Brain';
import type BrainFormValues from 'src/models/Parking/Brain/BrainFormValues';
import SharedProductList from 'src/screens/Parking/SharedProductList';
import useBrainService from 'src/services/Parking/useBrainService';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import { isNotString } from '../../../utils/checks';
import ExitButton from '../../Shared/Buttons/ExitButton';
import Form from '../../Shared/Form';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import Tabs from '../../Shared/Tabs';
import BrainUpdateForm from '../BrainUpdateForm';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
});

const defaultFormValues: BrainFormValues = {
  name: '',
};

interface BrainDetailsModalProps {
  id: number | undefined;
  productId: number | undefined;
  closeParentModal: () => void;
  onProductNameChange: (name: string, id: number) => void;
  isAnyGatewayOnline: boolean;
  hideSystemControl?: boolean;
}

export default function BrainDetailsModal(props: BrainDetailsModalProps) {
  const {
    id,
    productId,
    closeParentModal,
    onProductNameChange,
    isAnyGatewayOnline,
  } = props;
  const isMounted = useIsMounted();
  const { findOneBrain, removeBrain, updateBrain } = useBrainService();
  // const { changeStateOfProduct } = useProductService();
  const [details, setDetails] = useState<Brain | undefined>();
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  // const [isInProgress, setIsInProgress] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  // const socket = useSocket(SocketPath.SOCKETIO);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal: closeParentModal,
    message: messageSuccess,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const loadBrainDetails = async () => {
      if (!id) {
        return;
      }
      try {
        if (isMounted()) {
          setAreDetailsLoading(true);
        }
        const data = await findOneBrain(id);
        if (isMounted()) {
          setDetails(data);
          setInitialValues({
            name: data.product.name,
          });
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    loadBrainDetails();
  }, [id, isMounted, findOneBrain]);

  const onBrainRemove = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await removeBrain(id);

      if (isMounted()) {
        setMessageSuccess('Brain deleted successfully!');
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    removeBrain,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onBrainRemove,
    message: 'Are you sure you want to remove this brain?',
  });

  const onBrainFormSubmit = useCallback(
    async (
      values: BrainFormValues,
      { setErrors, resetForm }: FormikHelpers<BrainFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }
        await updateBrain(id, values);
        if (isMounted()) {
          resetForm({});
          setMessageSuccess('Brain updated successfully!');
          openGlobalSuccessModal();
          onProductNameChange(values.name, productId || 0);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      id,
      updateBrain,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      onProductNameChange,
      productId,
      setMessage,
    ]
  );

  // const onPermanentOpenChange = useCallback(async () => {
  //   try {
  //     if (!productId) {
  //       return;
  //     }

  //     if (isMounted()) {
  //       setIsInProgress(true);
  //     }

  //     const response = await changeStateOfProduct(productId, {
  //       operation:
  //         details?.permanentPosition === PermanentPositions.STAY_OPEN
  //           ? ProductOperations.CLEAR_STAY_CLOSE
  //           : ProductOperations.SET_STAY_OPEN,
  //     });

  //     let operationListener:
  //       | Socket
  //       | undefined = undefined;

  //     const operationTimeout = setTimeout(() => {
  //       if (operationListener) {
  //         operationListener = socket?.off(SocketEvents.LOT_CHANGE, handleLotChange);
  //       }
  //       setIsInProgress(false);

  //       setMessage({
  //         code: "Unknown",
  //         message: "Operation timeout",
  //       });
  //       openGlobalFailureModal();
  //     }, SocketTimer.PRODUCT_OPERATION_TIMEOUT);

  //     if (!socket) {
  //       throw Error();
  //     }

  //     const handleLotChange = (payload: string) => {
  //       try {
  //         const eventPayload: LotChangeEventPayload = JSON.parse(payload);
  //         const event = toLotChangeEvent(eventPayload);
  //         const { eventId } = event;

  //         if (response.eventId !== eventId) return;

  //         clearTimeout(operationTimeout);
  //         operationListener = socket?.off(
  //           SocketEvents.LOT_CHANGE,
  //           handleLotChange
  //         );

  //         setDetails((oldData) => {
  //           if (!oldData || !event.data.brain) {
  //             return oldData;
  //           }
  //           return { ...oldData, permanentPosition: event.data.brain.permanentPosition }
  //         });

  //         if (isMounted()) {
  //           setIsInProgress(false);
  //         }
  //       } catch {
  //         setMessage({ code: "Unknown", message: "JSON parse error" });
  //         openGlobalFailureModal();
  //       }
  //     };

  //     operationListener = socket?.on(SocketEvents.LOT_CHANGE, handleLotChange);

  //   } catch (error) {
  //     if (isMounted()) {
  //       setIsInProgress(false);
  //     }
  //     throw error;
  //   }
  // }, [
  //   changeStateOfProduct,
  //   productId,
  //   isMounted,
  //   openGlobalFailureModal,
  //   setMessage,
  //   socket,
  //   details
  // ]);

  return (
    <React.Fragment>
      {/* {isInProgress && <InProgress />} */}
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='brain'
            onSubmit={onBrainFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isLoading={areDetailsLoading}
          >
            <BrainUpdateForm
              details={details}
              openRemoveModal={openGlobalConfirmationModal}
              closeParentModal={closeParentModal}
              isAnyGatewayOnline={isAnyGatewayOnline}
              isLoading={areDetailsLoading}
            />
          </Form>
        </Tabs.Panel>
        {/* {!hideSystemControl ? <Tabs.Panel name="systemControl" label="System Control">
            <BrainSystemControlForm
              state={details?.permanentPosition}
              onChange={onPermanentOpenChange}
            />
        </Tabs.Panel> : <></>} */}
        <Tabs.Panel name='sharedAccess' label='Shared access'>
          <SharedProductList modalView productId={productId || 0} />
        </Tabs.Panel>
      </Tabs>
    </React.Fragment>
  );
}
