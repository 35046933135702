import { useCallback } from 'react';
import toCountry from '../../mappers/Shared/toCountry';
import type Country from '../../models/Shared/Country';
import type CountryResponse from '../../models/Shared/CountryResponse';
import useApiService from './useApiService';

interface CountryService {
  findAllCountries: () => Promise<Country[]>;
}

const countryBaseURL = '/countries';

export default function useCountryService(): CountryService {
  const { get } = useApiService();

  const findAllCountries = useCallback(async () => {
    const response = await get<CountryResponse[]>(`${countryBaseURL}`);
    const data = response.data.map(toCountry);

    return data;
  }, [get]);

  return { findAllCountries };
}
