import type Profile from 'src/models/Settings/Profile';
import type AccountInfoFormValues from '../../models/Settings/AccountInfoFormValues';

export default function toAccountInfoFormValues(
  data: Profile
): AccountInfoFormValues | undefined {
  const { account } = data;

  if (!account) {
    return undefined;
  }

  const { name, accountRole, contactEmail, contactName, contactPhone } =
    account;

  return {
    name: name || '',
    accountRole: accountRole
      ? { key: accountRole.id, label: accountRole.name }
      : null,
    contactEmail: contactEmail || '',
    contactName: contactName || '',
    contactPhone: contactPhone || '',
  };
}
