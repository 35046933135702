import AllProductStatuses from '../../constants/Parking/AllProductStatuses';
import ProductStatus from '../../constants/Parking/ProductStatus';
import type ConcreteProduct from '../../models/Parking/ConcreteProduct';

export default function useWeblinkStatusLabel(
  product: ConcreteProduct | undefined,
  isAnyGatewayOnline: boolean,
  isCarOnTop: boolean | undefined
) {
  const statuses = [];

  if (!product) return undefined;

  const { status, productError } = product;

  if (status === ProductStatus.OFFLINE || !isAnyGatewayOnline) {
    statuses.push(AllProductStatuses.OFFLINE);
    return statuses;
  }

  if (productError === null) {
    if (isCarOnTop) {
      statuses.push(AllProductStatuses.CAR_ON_TOP);
      return statuses;
    }
    statuses.push(AllProductStatuses.ONLINE);
    return statuses;
  }

  const {
    jammed,
    sensorDirty,
    pinBroken,
    rtccInvalid,
    batteryEmpty,
    masterKeyNotSet,
  } = productError;

  if (isCarOnTop) statuses.push(AllProductStatuses.CAR_ON_TOP);

  if (jammed) statuses.push(AllProductStatuses.JAMMED);

  if (sensorDirty) statuses.push(AllProductStatuses.SENSOR_DIRTY);

  if (pinBroken) statuses.push(AllProductStatuses.PIN_BROKEN);

  if (batteryEmpty) statuses.push(AllProductStatuses.BATTERY_EMPTY);

  if (rtccInvalid) statuses.push(AllProductStatuses.RTCC_INVALID);

  if (masterKeyNotSet) statuses.push(AllProductStatuses.MASTER_KEY_NOT_SET);

  if (statuses.length === 0) {
    statuses.push(AllProductStatuses.UNKNOWN);
  }

  return statuses;
}
