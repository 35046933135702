import type LicensePurchaseQuery from 'src/models/License/LicensePurchaseQuery';
import type LicensePurchaseQueryParams from 'src/models/License/LicensePurchaseQueryParams';

export default function toLicensePurchaseQuery(
  data: LicensePurchaseQueryParams
): LicensePurchaseQuery {
  const { page, size, accountId } = data;

  return { page, size, account_id: accountId };
}
