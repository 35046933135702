import { createContext } from 'react';

interface TabsContextData {
  activeTab: string;
}

const initialTabsContextData: TabsContextData = {
  activeTab: '',
};

const TabsContext = createContext(initialTabsContextData);

export default TabsContext;
