import type TariffActivePeriod from 'src/models/Parking/Tariff/TariffActivePeriod';
import type TariffActivePeriodResponse from 'src/models/Parking/Tariff/TariffActivePeriodResponse';

export default function toTariffActivePeriod(
  data: TariffActivePeriodResponse
): TariffActivePeriod {
  const {
    id,
    start_date,
    end_date,
    start_time,
    end_time,
    tariff_id,
    deleted_at,
    created_at,
    updated_at,
  } = data;

  return {
    id,
    startDate: start_date,
    endDate: end_date,
    startTime: start_time,
    endTime: end_time,
    tariffId: tariff_id,
    deletedAt: deleted_at ? new Date(deleted_at) : null,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
  };
}
