enum ProductOperations {
  OPEN = 'open',
  CLOSE = 'close',
  SET_STAY_OPEN = 'stay_open',
  SET_STAY_CLOSE = 'stay_close',
  CLEAR_STAY_OPEN = 'clear_stay_open',
  CLEAR_STAY_CLOSE = 'clear_stay_close',
}

export default ProductOperations;
