import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ButtonsContainer from 'src/components/Shared/ButtonsContainer';
import Content from 'src/components/Shared/Content';
import SelectFieldWithoutFormik from 'src/components/Shared/SelectField/SelectFieldWithoutFormik';
import StyledBlockIcon from 'src/components/Shared/StyledBlockIcon';
import StyledNoData from 'src/components/Shared/StyledNoData';
import Title from 'src/components/Shared/Title';
import WeblinksStatusValues from 'src/constants/KeyManagement/WeblinksStatusValues';
import ActionIcon from 'src/constants/Shared/ActionIcon';
import PaginationSize from 'src/constants/Shared/DataSize';
import InitialMetaData from 'src/constants/Shared/InitialMetaData';
import type SharedListFilterValues from 'src/models/KeySharing/SharedListFilterValues';
import type Weblink from 'src/models/KeySharing/Weblink/Weblink';
import formatOperationTokenDates from 'src/utils/formatOperationTokenDates';
import { getWeblinkStatus } from 'src/utils/getKeyStatus';
import * as yup from 'yup';
import WeblinksStatuses from '../../../constants/KeyManagement/WeblinksStatuses';
import ButtonSize from '../../../constants/Shared/ButtonSize';
import PaginationItemDisplay from '../../../constants/Shared/PaginationItemDisplay';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import type Dropdown from '../../../models/KeySharing/Dropdown';
import type Meta from '../../../models/Shared/Meta';
import type Option from '../../../models/Shared/Option';
import useWeblinksService from '../../../services/KeyManagement/useWeblinksService';
import Button from '../../Shared/Button';
import Card from '../../Shared/Card';
import CheckBox from '../../Shared/CheckBox';
import Control from '../../Shared/Control';
import DropdownFilter from '../../Shared/DropdownFilter';
import Form from '../../Shared/Form';
import Main from '../../Shared/Main';
import { useGlobalConfirmationModal } from '../../Shared/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import Pagination from '../../Shared/Pagination';
import SearchFilter from '../../Shared/SearchFilter';
import StyledCell from '../../Shared/StyledCell';
import Table from '../../Shared/Table';
import ProductTableIcon from '../ProductTableIcon';
import InfoMessages from './InfoMessages';

const initialValues: SharedListFilterValues = {
  receiverIdentifier: '',
  dateFrom: null,
  dateTo: null,
};

const optiondata: Option[] = [
  { key: WeblinksStatusValues.ALL, label: 'All' },
  { key: WeblinksStatusValues.ACTIVE, label: 'Active' },
  { key: WeblinksStatusValues.BLOCKED, label: 'Blocked' },
  { key: WeblinksStatusValues.EXPIRED, label: 'Expired' },
  { key: WeblinksStatusValues.DEPLETED, label: 'Depleted' },
  { key: WeblinksStatusValues.INVALID, label: 'Invalid' },
];

const initialDropdownValues: Dropdown = {
  filter: {
    key: WeblinksStatusValues.ALL,
    label: 'All',
  },
};

const validationSchema = yup.object().shape({
  receiverIdentifier: yup
    .string()
    .min(3, 'Email should be at least 3 characters long'),
  dateFrom: yup.date().nullable().notRequired(),
  dateTo: yup
    .date()
    .nullable()
    .notRequired()
    .when('dateFrom', {
      is: (dateFrom: Date | null) => dateFrom !== null,
      then: yup
        .date()
        .nullable()
        .notRequired()
        .min(yup.ref('dateFrom'), "End Date can't be before Start Date"),
    }),
});

interface WeblinksTableProps {
  modalView?: boolean;
  productId?: number;
}

export default function WeblinksTable(props: WeblinksTableProps) {
  const { modalView, productId } = props;
  const { listAllWeblinks, blockWeblinks, blockWeblinkById, resendWeblinks } =
    useWeblinksService();
  const isMounted = useIsMounted();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState<{
    [key: number]: boolean;
  }>({});
  const [showConfirmationButtons, setShowConfirmationButtons] = useState<{
    [key: number]: boolean;
  }>({});
  const [filter, setFilter] = useState<Option | undefined | null>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState<Weblink[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);
  const { params } = useRouteMatch<{ lotId: string }>();
  const { lotId } = params;

  const [filterValues, setFilterValues] = useState<SharedListFilterValues>({
    receiverIdentifier: '',
    dateFrom: undefined,
    dateTo: undefined,
  });

  const [selectedWeblinks, setSelectedWeblinks] = useState<number[]>([]);
  const [totalSelectableWeblinks, setTotalSelectableWeblinks] = useState<
    Weblink[]
  >([]);

  const options: Option[] = optiondata.map(({ key, label }) => ({
    key,
    label,
  }));

  const getWeblinksData = useCallback(
    async (page: number) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { receiverIdentifier, dateFrom, dateTo } = filterValues;

        const { data, meta } = await listAllWeblinks({
          page,
          size: PaginationSize.STANDARD,
          lotId: lotId ? parseInt(lotId) : undefined,
          productId: productId,
          receiverIdentifier,
          dateFrom: dateFrom?.toISOString(),
          dateTo: dateTo?.toISOString(),
          status: getWeblinkStatus(filter?.key),
        });
        if (isMounted()) {
          setData(data);
          setSelectedWeblinks([]);
          setIsDataLoading(false);

          const allSelcetableWeblinks = data.filter(
            (weblink: Weblink) =>
              (weblink.operationToken.status === WeblinksStatuses.ACTIVE &&
                new Date(weblink.operationToken.endTime) >= new Date() &&
                weblink.operationToken.usedCount < 1) ||
              weblink.operationToken.status === WeblinksStatuses.INVALID
          );
          setTotalSelectableWeblinks(allSelcetableWeblinks);

          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [listAllWeblinks, isMounted, filterValues, lotId, filter, productId]
  );

  useEffect(() => {
    getWeblinksData(1);
  }, [getWeblinksData]);

  const onSubmit = useCallback(
    async (values: SharedListFilterValues) => {
      const { receiverIdentifier, dateFrom, dateTo } = values;

      if (isMounted()) {
        setFilterValues((oldVal) => {
          const {
            dateFrom: oldDateFrom,
            receiverIdentifier: oldreceiverIdentifier,
            dateTo: oldDateTo,
          } = oldVal;
          if (
            oldDateFrom === dateFrom &&
            oldreceiverIdentifier === receiverIdentifier &&
            oldDateTo === dateTo
          ) {
            return oldVal;
          }
          return {
            receiverIdentifier,
            dateFrom,
            dateTo,
          };
        });
      }
    },
    [isMounted]
  );

  const onResetAllFilters = useCallback(() => {
    if (isMounted()) {
      setFilterValues((oldVal) => {
        const {
          dateFrom: oldDateFrom,
          receiverIdentifier: oldreceiverIdentifier,
          dateTo: oldDateTo,
        } = oldVal;
        if (!oldDateFrom && oldreceiverIdentifier === '' && !oldDateTo) {
          return oldVal;
        }
        return {
          receiverIdentifier: '',
          dateFrom: undefined,
          dateTo: undefined,
        };
      });
    }
  }, [isMounted]);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: successMessage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onBlockSubmit = useCallback(async () => {
    try {
      if (selectedWeblinks.length === 0) return;

      const response = await blockWeblinks(selectedWeblinks);

      const successfullyBlocked = response
        .filter((weblink) => weblink.success)
        .map((weblink) => weblink.id);

      if (isMounted()) {
        setData((oldData) =>
          oldData.map((oldSentWeblink) => {
            if (successfullyBlocked.includes(oldSentWeblink.id)) {
              return {
                ...oldSentWeblink,
                operationToken: {
                  ...oldSentWeblink.operationToken,
                  status: WeblinksStatuses.BLOCKED,
                },
              };
            }
            return oldSentWeblink;
          })
        );
        successfullyBlocked.length === selectedWeblinks.length
          ? setSuccessMessage('All weblinks are blocked successfully!')
          : setSuccessMessage(
              'Sorry for the inconvenience! Something went wrong, please try again.'
            );
        setTotalSelectableWeblinks((oldSelectableWeblinks) =>
          oldSelectableWeblinks.filter(
            (oldWeblink) => !selectedWeblinks.includes(oldWeblink.id)
          )
        );
        setSelectedWeblinks([]);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    isMounted,
    selectedWeblinks,
    blockWeblinks,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
  ]);

  const onResendSubmit = useCallback(async () => {
    try {
      if (selectedWeblinks.length === 0) return;

      const response = await resendWeblinks({ ids: selectedWeblinks });

      const resendFailed = response.findIndex((key) => !key.success);

      if (isMounted()) {
        if (resendFailed === -1) {
          setData((oldData) =>
            oldData.map((oldSentKey) => {
              if (selectedWeblinks.includes(oldSentKey.id)) {
                return {
                  ...oldSentKey,
                  operationToken: {
                    ...oldSentKey.operationToken,
                    status: WeblinksStatuses.ACTIVE,
                  },
                };
              }
              return oldSentKey;
            })
          );
          setSuccessMessage('All weblinks are resend successfully!');
        } else {
          getWeblinksData(1);
          setSuccessMessage(
            'Sorry for the inconvenience! Something went wrong, please try again.'
          );
        }

        setSelectedWeblinks([]);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    isMounted,
    selectedWeblinks,
    resendWeblinks,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    getWeblinksData,
  ]);

  const updateSelectableWeblinks = useCallback((id: number) => {
    setSelectedWeblinks((selectedWeblinks) => {
      if (selectedWeblinks.includes(id)) {
        return selectedWeblinks.filter(
          (selectedWeblink) => selectedWeblink !== id
        );
      }
      return [...selectedWeblinks, id];
    });
  }, []);

  const onSelectAllSelectableWeblinks = useCallback(() => {
    if (selectedWeblinks.length === totalSelectableWeblinks.length) {
      setSelectedWeblinks([]);
      return;
    }

    const activeWeblinks = data.filter(
      (activeWeblink) =>
        (activeWeblink.operationToken.status === WeblinksStatuses.ACTIVE &&
          activeWeblink.operationToken.usedCount < 1 &&
          new Date(activeWeblink.operationToken.endTime) >= new Date()) ||
        activeWeblink.operationToken.status === WeblinksStatuses.INVALID
    );
    if (activeWeblinks.length === 0) return;

    const activeWeblinkIds = activeWeblinks.map(
      (activeWeblink) => activeWeblink.id
    );
    setSelectedWeblinks(activeWeblinkIds);
  }, [data, selectedWeblinks, totalSelectableWeblinks]);

  const { openGlobalConfirmationModal: openBlockKeyModal } =
    useGlobalConfirmationModal({
      action: onBlockSubmit,
      message:
        'Are you sure you want to block all selected Shared weblinks? This action is permanent and can not be undone.',
    });

  const { openGlobalConfirmationModal: openResendKeyModal } =
    useGlobalConfirmationModal({
      action: onResendSubmit,
      message: 'Are you sure you want to resend all selected Shared weblinks?',
    });

  const onDeleteIconClick = useCallback(
    (id: number) => {
      if (showDeleteIcon[id]) {
        setShowDeleteIcon({ [id]: false });
        setShowConfirmationButtons({ [id]: true });
      }
    },
    [showDeleteIcon]
  );

  const onWeblinkBlock = useCallback(
    async (id: number) => {
      try {
        await blockWeblinkById(id);

        if (isMounted()) {
          setData((oldData) => {
            const index = oldData.findIndex((data) => data.id === id);
            if (index !== -1) {
              oldData[index].operationToken.status = WeblinksStatuses.BLOCKED;
            }
            return oldData;
          });
          setTotalSelectableWeblinks((oldSelectableWeblinks) =>
            oldSelectableWeblinks.filter((oldWeblink) => oldWeblink.id !== id)
          );
          setShowConfirmationButtons({ [id]: false });
          setSuccessMessage('Weblink is blocked successfully!');
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      blockWeblinkById,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  const onDropdownChange = useCallback((value: Option | undefined) => {
    setFilter(value);
  }, []);

  return (
    <Main modalView={modalView}>
      <Content widthSize='27%' modalView={modalView}>
        {!modalView && <Title>Weblinks</Title>}
        <Form
          name='Filter'
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <SearchFilter
            searchInput
            modalView={modalView}
            onResetAllFilters={onResetAllFilters}
          />
        </Form>
      </Content>
      <Content widthSize={modalView ? '100%' : '70%'} addTopMargin={!modalView}>
        <Card sticky modalView={modalView}>
          <Control left>
            <CheckBox
              checked={
                totalSelectableWeblinks.length > 0 &&
                totalSelectableWeblinks.length === selectedWeblinks.length
              }
              onClick={onSelectAllSelectableWeblinks}
              disabled={totalSelectableWeblinks.length === 0}
              readonly
              title='Select All'
            />
            <DropdownFilter>
              <SelectFieldWithoutFormik
                label=''
                placeholder='All'
                name='filter'
                initialValue={initialDropdownValues.filter}
                options={options}
                hideInput
                onChange={onDropdownChange}
              />
            </DropdownFilter>
            {selectedWeblinks.length > 0 ? (
              <>
                <Button
                  size={ButtonSize.MIDDLE}
                  style={{ marginRight: '20px' }}
                  onClick={openBlockKeyModal}
                >
                  Block
                </Button>
                <Button size={ButtonSize.MIDDLE} onClick={openResendKeyModal}>
                  Resend
                </Button>
              </>
            ) : null}
          </Control>
        </Card>
        <Card modalView={modalView}>
          <Table isLoading={isDataLoading}>
            <Table.Head>
              <Table.Row>
                <Table.Header />
                <Table.Header>EMAIL</Table.Header>
                {!modalView && <Table.Header>PRODUCT</Table.Header>}
                <Table.Header>SHARED WEBLINKS</Table.Header>
                <Table.Header />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {data.length > 0 ? (
                data.map((weblinkData) => {
                  const { id, receiverIdentifier, product, operationToken } =
                    weblinkData;
                  const { startTime, endTime, status, usedCount } =
                    operationToken;
                  const { name, productTypeId } = product;

                  const selected = selectedWeblinks.includes(id);

                  const selectId = () => {
                    updateSelectableWeblinks(id);
                  };

                  const isExpired =
                    new Date(endTime) < new Date() &&
                    status === WeblinksStatuses.ACTIVE;

                  const isDepleted =
                    status === WeblinksStatuses.ACTIVE && usedCount > 0;

                  const blockableWeblink =
                    (status === WeblinksStatuses.ACTIVE &&
                      new Date(endTime) >= new Date() &&
                      usedCount < 1) ||
                    status === WeblinksStatuses.INVALID;

                  const onHover = () => {
                    if (
                      blockableWeblink &&
                      showConfirmationButtons[id] !== true
                    ) {
                      setShowDeleteIcon({ [id]: true });
                    }
                  };
                  const onLeave = () => {
                    setShowDeleteIcon({ [id]: false });
                  };

                  const onIconClick = () => {
                    onDeleteIconClick(id);
                  };

                  const onBlockClick = () => {
                    onWeblinkBlock(id);
                  };

                  const onCancelClick = () => {
                    setShowConfirmationButtons({ [id]: false });
                  };

                  return (
                    <Table.Row
                      key={id}
                      disabled={
                        (status !== WeblinksStatuses.ACTIVE &&
                          status !== WeblinksStatuses.INVALID) ||
                        isExpired ||
                        isDepleted
                      }
                      onMouseEnter={onHover}
                      onMouseLeave={onLeave}
                    >
                      <Table.Cell>
                        <CheckBox
                          checked={selected}
                          onClick={selectId}
                          disabled={
                            (status !== WeblinksStatuses.ACTIVE &&
                              status !== WeblinksStatuses.INVALID) ||
                            isExpired ||
                            isDepleted
                          }
                          readonly
                          title='Select'
                        />
                      </Table.Cell>
                      <Table.Cell title={receiverIdentifier} short>
                        {receiverIdentifier}
                      </Table.Cell>
                      {!modalView && (
                        <Table.Cell>
                          <ProductTableIcon iconType={productTypeId} />
                          {name}
                        </Table.Cell>
                      )}
                      <Table.Cell minWidth>
                        <StyledCell>
                          Start time:
                          <br />
                          End time:
                        </StyledCell>
                        <div>
                          {formatOperationTokenDates(startTime)}
                          <br />
                          {formatOperationTokenDates(endTime)}
                        </div>
                      </Table.Cell>
                      <Table.Cell
                        invalid={status === WeblinksStatuses.INVALID}
                        disabled={status === WeblinksStatuses.ACTIVE}
                      >
                        <InfoMessages
                          status={status}
                          type='Key'
                          isExpired={isExpired}
                          isDepleted={isDepleted}
                        />
                      </Table.Cell>
                      <Table.Cell right>
                        {showDeleteIcon[id] && (
                          <StyledBlockIcon
                            className={ActionIcon.DELETE}
                            onClick={onIconClick}
                          />
                        )}
                        {showConfirmationButtons[id] && (
                          <ButtonsContainer table>
                            <Button
                              size={ButtonSize.SMALL}
                              style={{ marginRight: '5px' }}
                              onClick={onBlockClick}
                              primary
                            >
                              Block
                            </Button>
                            <Button
                              size={ButtonSize.SMALL}
                              onClick={onCancelClick}
                            >
                              Cancel
                            </Button>
                          </ButtonsContainer>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell merged={5}>
                    <StyledNoData>No shared weblinks.</StyledNoData>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card>
        {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
          <Pagination meta={meta} getData={getWeblinksData} />
        )}
      </Content>
    </Main>
  );
}
