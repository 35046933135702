import type Option from '../../models/Shared/Option';
import DaysOfWeek from '../Shared/DaysOfWeek';

const DayOptions = (days: string) => {
  const optionDays: Option[] = [];
  for (let i = 0; i < days.length; i++) {
    if (parseInt(days[i]) & 1) {
      optionDays.push({
        key: i,
        label: DaysOfWeek[i],
      });
    }
  }

  return optionDays;
};

export default DayOptions;
