import type LotEntryStatisticsParams from 'src/models/Statistics/LotEntry/LotEntryStatisticsParams';
import type LotEntryStatisticsQueryParams from 'src/models/Statistics/LotEntry/LotEntryStatisticsQueryParams';
import getTimeRange from 'src/utils/getTimeRange';

export default function toLotEntryStatisticsQueryParams(
  data: LotEntryStatisticsParams
): LotEntryStatisticsQueryParams {
  const { timeRange } = data;

  return {
    timerange: getTimeRange(timeRange),
  };
}
