import type AnprRemoteControlLog from 'src/models/Logs/AnprRemoteControlLog';
import type AnprRemoteControlLogResponse from 'src/models/Logs/AnprRemoteControlLogResponse';
import toLprCamera from '../Parking/Anpr/toLprCamera';
import toAccountUser from '../Settings/toAccountUser';

export default function toAnprRemoteControlLog(
  data: AnprRemoteControlLogResponse
): AnprRemoteControlLog {
  const {
    id,
    lpr_camera_id,
    account_id,
    account_user_id,
    operation,
    created_at,
    updated_at,
    lpr_camera,
    account_user,
  } = data;

  return {
    id,
    lprCameraId: lpr_camera_id,
    accountId: account_id,
    accountUserId: account_user_id,
    operation,
    createdAt: created_at === null ? null : new Date(created_at),
    updatedAt: updated_at === null ? null : new Date(updated_at),
    lprCamera: toLprCamera(lpr_camera),
    accountUser: toAccountUser(account_user),
  };
}
