import type LotWhitelistLogQuery from 'src/models/Logs/LotWhitelistLogQuery';
import type LotWhitelistLogQueryValues from 'src/models/Logs/LotWhitelistLogQueryValues';

export default function toLotWhitelistLogQuery(
  data: LotWhitelistLogQueryValues
): LotWhitelistLogQuery {
  const {
    page,
    size,
    lotId,
    startTime,
    endTime,
    accountUserId,
    accountUserName,
    licensePlate,
  } = data;

  return {
    lot_id: lotId,
    start_time: startTime?.toISOString(),
    end_time: endTime?.toISOString(),
    account_user_id: accountUserId,
    account_user_name: accountUserName,
    license_plate: licensePlate,
    page,
    size,
  };
}
