import type LicenseInfoUpdateParams from 'src/models/License/LicenseInfoUpdateParams';
import type Counter from 'src/models/Shared/Counter';

export default function toLicenseInfoPurchaseUpdate(
  data: Counter
): LicenseInfoUpdateParams {
  const { purchaseId, counter } = data;

  return {
    id: purchaseId,
    target_count: counter,
  };
}
