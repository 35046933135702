import { useCallback } from 'react';
import toLprImageStorageSubscriptionHistory from 'src/mappers/Parking/Anpr/toLprImageStorageSubscriptionHistory';
import toLprImageStorageSubscriptionHistoryQuery from 'src/mappers/Parking/Anpr/toLprImageStorageSubscriptionHistoryQuery';
import toMeta from 'src/mappers/Shared/toMeta';
import type LprImageStorageSubscriptionHistory from 'src/models/Parking/Anpr/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistory';
import type LprImageStorageSubscriptionHistoryQuery from 'src/models/Parking/Anpr/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistoryQuery';
import type LprImageStorageSubscriptionHistoryQueryParams from 'src/models/Parking/Anpr/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistoryQueryParams';
import type LprImageStorageSubscriptionHistoryResponse from 'src/models/Parking/Anpr/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistoryResponse';
import type GetAllDetails from 'src/models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const lprImageStorageSubscriptionHistoriesURL =
  '/lpr-storage-subscription-histories';

interface LprImageStorageSubscriptionHistoriesService {
  listAllLprImageStorageSubscriptionHistories: (
    values: LprImageStorageSubscriptionHistoryQueryParams
  ) => Promise<GetAllDetails<LprImageStorageSubscriptionHistory>>;
  getOneLprImageStorageSubscriptionHistory: (
    id: number
  ) => Promise<LprImageStorageSubscriptionHistory>;
}

export default function useLprImageStorageSubscriptionHistoriesService(): LprImageStorageSubscriptionHistoriesService {
  const { get } = useApiService();

  const listAllLprImageStorageSubscriptionHistories = useCallback(
    async (values: LprImageStorageSubscriptionHistoryQueryParams) => {
      const requestBody = toLprImageStorageSubscriptionHistoryQuery(values);

      const response = await get<
        LprImageStorageSubscriptionHistoryResponse[],
        LprImageStorageSubscriptionHistoryQuery
      >(`${lprImageStorageSubscriptionHistoriesURL}`, requestBody);

      const data = response.data.map(toLprImageStorageSubscriptionHistory);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const getOneLprImageStorageSubscriptionHistory = useCallback(
    async (id: number) => {
      const response = await get<LprImageStorageSubscriptionHistoryResponse>(
        `${lprImageStorageSubscriptionHistoriesURL}/${id}`
      );

      return toLprImageStorageSubscriptionHistory(response.data);
    },
    [get]
  );

  return {
    listAllLprImageStorageSubscriptionHistories,
    getOneLprImageStorageSubscriptionHistory,
  };
}
