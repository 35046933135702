import React, { useEffect, useMemo, useState } from 'react';
import AppRole from '../../constants/Shared/AppRole';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';
import type Option from '../../models/Shared/Option';
import useAccountRoleService from '../../services/Shared/useAccountRoleService';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import Section from '../Shared/Section';
import SelectField from '../Shared/SelectField/index';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';

export default function AccountInfoForm() {
  const [options, setOptions] = useState<Option[]>([]);
  const { findAllAccountRoles } = useAccountRoleService();
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  useEffect(() => {
    const getData = async () => {
      const data = await findAllAccountRoles();

      if (isMounted()) {
        const options = data.map(({ id, name }) => ({
          key: id,
          label: name,
        }));

        setOptions(options);
      }
    };

    getData();
  }, [findAllAccountRoles, isMounted]);

  return (
    <>
      <Title>Account</Title>
      <Card>
        <Section>
          <TextField
            label='Account'
            placeholder='Account'
            type='text'
            short
            name='name'
            readonly={!userIsParklioInternal}
          />
          <SelectField
            label='Account Type'
            placeholder='Account Type'
            name='accountRole'
            options={options}
            short
            readonly={!userIsParklioInternal}
          />
        </Section>
      </Card>
      <Title>Contact Person</Title>
      <Card>
        <Section>
          <TextField
            label='Contact Name'
            placeholder='Contact Name'
            type='text'
            short
            name='contactName'
          />
          <TextField
            label='Contact Email'
            placeholder='Contact Email'
            type='text'
            short
            name='contactEmail'
          />
          <TextField
            label='Contact Phone'
            placeholder='Contact Phone'
            type='text'
            short
            name='contactPhone'
          />
        </Section>
        <Line />
        <Control>
          <Button size={ButtonSize.LARGE} type='submit' primary>
            Save
          </Button>
        </Control>
      </Card>
    </>
  );
}
