enum TariffUnits {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
}

const UnitLabels: Record<number, TariffUnits> = {
  1: TariffUnits.MINUTE,
  2: TariffUnits.HOUR,
  3: TariffUnits.DAY,
};

const UnitValues: Record<TariffUnits, number> = {
  minute: 1,
  hour: 2,
  day: 3,
};

export { TariffUnits, UnitValues, UnitLabels };
