import type OperationToken from '../../models/KeySharing/OperationToken';
import type OperationTokenResponse from '../../models/KeySharing/OperationTokenResponse';

export default function toOperationToken(
  data: OperationTokenResponse
): OperationToken {
  const { id, start_time, end_time, status, deleted_at, used_count } = data;

  return {
    id,
    startTime: start_time,
    endTime: end_time,
    status,
    deletedAt: deleted_at ? new Date(deleted_at) : null,
    usedCount: used_count,
  };
}
