import React, { useCallback, useEffect, useState } from 'react';
import AccountUserRole from 'src/constants/Shared/AccountUserRole';
import type Manager from 'src/models/Parking/Manager/Manager';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type AccountUser from '../../models/Settings/AccountUser';
import useAccountUserService from '../../services/Settings/useAccountUserService';
import Button from '../Shared/Button';
import ExitButton from '../Shared/Buttons/ExitButton';
import Description from '../Shared/Description';
import Line from '../Shared/Line';
import Section from '../Shared/Section';
import Table from '../Shared/Table';
import Title from '../Shared/Title';

interface ManagerModalProps {
  managers: Manager[];
  addManager: (manager: AccountUser) => void;
  deleteManager: (managerId: number) => void;
  closeParentModal: () => void;
}

export default function ManagerModal(props: ManagerModalProps) {
  const { closeParentModal, managers, addManager, deleteManager } = props;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<AccountUser[]>([]);
  const { findAllAccountUsers } = useAccountUserService();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data } = await findAllAccountUsers();

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }

        throw error;
      }
    };

    getData();
  }, [findAllAccountUsers, isMounted]);

  const addNewManager = useCallback(
    (manager: AccountUser) => {
      if (!manager.id) return;

      addManager(manager);
    },
    [addManager]
  );

  const removeManager = useCallback(
    (managerId: number) => {
      if (!managerId) return;

      deleteManager(managerId);
    },
    [deleteManager]
  );

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Section>
        <Title bold>Parking Managers</Title>
        <Description>
          Only Added managers will be able to access this Parking lot.
        </Description>
        <Table margin isLoading={isDataLoading}>
          <Table.Colgroup>
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '35%' }} />
            <col span={1} style={{ width: '20%' }} />
            <col span={1} style={{ width: '25%' }} />
          </Table.Colgroup>
          <Table.Head>
            <Table.Row>
              <Table.Header>USER</Table.Header>
              <Table.Header>EMAIL</Table.Header>
              <Table.Header>ROLE</Table.Header>
              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map((user) => {
              const { name, role, id, email } = user;

              const isSelected = managers.some(
                (item) => item.accountUser.id === id
              );

              const disabled =
                role.key === AccountUserRole.SUPER_ADMIN && isSelected;

              const addManager = () => {
                addNewManager(user);
              };

              const deleteManager = () => {
                removeManager(id);
              };

              return (
                <Table.Row key={id} disabled={disabled}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell>{role.label}</Table.Cell>
                  <Table.Cell>
                    {isSelected ? (
                      <Button
                        size={ButtonSize.SMALL}
                        type='button'
                        onClick={deleteManager}
                        disabled={disabled}
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        size={ButtonSize.SMALL}
                        type='button'
                        onClick={addManager}
                        primary
                      >
                        Add
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Section>
      <Line />
    </>
  );
}
