import styled, { css } from 'styled-components';
import { ReactComponent } from '../../assets/svg/ParklioSign.svg';

interface ParklioSignProps {
  addmargin?: string;
  cursorpointer?: string;
}

const ParklioSign = styled(ReactComponent)<ParklioSignProps>`
  margin: 60px 0px;

  ${(props) => {
    const { addmargin } = props;

    if (addmargin === 'true') {
      return css`
        margin: 100px 0 -90px 0;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { cursorpointer } = props;

    if (cursorpointer === 'true') {
      return css`
        cursor: pointer;
      `;
    }
    return css``;
  }}

    @media (max-width: 500px) {
    margin: 60px 0px 0px 0px;
  }
`;

export default ParklioSign;
