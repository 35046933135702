import { useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import DayOptions from 'src/constants/Parking/DayOptions';
import { WHOLE_WEEK } from 'src/constants/Parking/TariffFormikValues';
import { TariffUnits, UnitValues } from 'src/constants/Parking/TariffUnits';
import ActionIcon from 'src/constants/Shared/ActionIcon';
import Color from 'src/constants/Shared/Color';
import type TariffFormValues from 'src/models/Parking/Tariff/TariffFormValues';
import type Option from 'src/models/Shared/Option';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import formatDate from 'src/utils/formatDate';
import styled from 'styled-components';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import DateField from '../Shared/DateField';
import InlineDiv from '../Shared/InlineDiv';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Section from '../Shared/Section';
import SelectField from '../Shared/SelectField';
import Spinner from '../Shared/Spinner';
import TextField from '../Shared/TextField';
import TimeField from '../Shared/TimeField';
import Title from '../Shared/Title';
import ToggleField from '../Shared/ToggleField';
import ToggleSwitchForFormik from '../Shared/ToggleSwitchForFormik';
import InfoMessage from './InfoMessage';

interface TariffFormProps {
  closeParentModal: () => void;
  invoiceCurrency: string | undefined;
  accountId: number | undefined;
  isLoading: boolean;
  tariffCurrency?: string | undefined;
  zoneView?: boolean;
}

export default function TariffForm(props: TariffFormProps) {
  const {
    closeParentModal,
    invoiceCurrency,
    accountId,
    isLoading,
    zoneView,
    tariffCurrency,
  } = props;
  const [gracePeriod] = useField({ name: 'gracePeriod' });
  const [datePeriod] = useField({ name: 'datePeriod' });
  const [activeDatePeriods] = useField({ name: 'activeDatePeriods' });
  const [dateFrom] = useField({ name: 'dateFrom' });
  const [dateTo] = useField({ name: 'dateTo' });
  const [timePeriod] = useField({ name: 'timePeriod' });
  const [activeTimePeriods] = useField({ name: 'activeTimePeriods' });
  const [timeFrom] = useField({ name: 'timeFrom' });
  const [timeTo] = useField({ name: 'timeTo' });
  const [overstay] = useField({ name: 'overstay' });
  const { isSubmitting, setFieldValue, dirty } =
    useFormikContext<TariffFormValues>();

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const unitOptions: Option[] = [
    {
      key: UnitValues[TariffUnits.MINUTE],
      label: capitalizeFirstLetter(TariffUnits.MINUTE),
    },
    {
      key: UnitValues[TariffUnits.HOUR],
      label: capitalizeFirstLetter(TariffUnits.HOUR),
    },
    {
      key: UnitValues[TariffUnits.DAY],
      label: capitalizeFirstLetter(TariffUnits.DAY),
    },
  ];

  const addPeriod = useCallback(() => {
    setFieldValue('activeDatePeriods', [
      ...activeDatePeriods.value,
      { from: dateFrom.value, to: dateTo.value },
    ]);
  }, [dateFrom, dateTo, setFieldValue, activeDatePeriods]);

  const removePeriod = useCallback(
    (index: number) => {
      activeDatePeriods.value.splice(index, 1);
      setFieldValue('activeDatePeriods', activeDatePeriods.value);
    },
    [setFieldValue, activeDatePeriods]
  );

  const addTime = useCallback(() => {
    setFieldValue('activeTimePeriods', [
      ...activeTimePeriods.value,
      { from: timeFrom.value, to: timeTo.value },
    ]);
  }, [timeFrom, timeTo, setFieldValue, activeTimePeriods]);

  const removeTime = useCallback(
    (index: number) => {
      activeTimePeriods.value.splice(index, 1);
      setFieldValue('activeTimePeriods', activeTimePeriods.value);
    },
    [setFieldValue, activeTimePeriods]
  );

  const getCurrency = useCallback(
    (label: string) => {
      if (tariffCurrency) {
        return `${tariffCurrency} / ${label}`;
      }
      if (invoiceCurrency) {
        return `${invoiceCurrency} / ${label}`;
      }
      return (
        <>
          <Link to={`/settings/account/${accountId}/invoice`}>
            Set currency{' '}
          </Link>{' '}
          / {label}
        </>
      );
    },
    [invoiceCurrency, accountId, tariffCurrency]
  );

  return (
    <>
      {isLoading ? (
        <Spinner primary />
      ) : (
        <>
          <Section>
            {zoneView ? (
              <Title tabModalView>New Tariff</Title>
            ) : (
              <>
                <Title>Tariff</Title>
                <Line addMargin />
              </>
            )}
            <TextField
              label='Name*'
              autofocus
              placeholder='Enter Name'
              type='text'
              name='name'
              short
              mediumLabel
            />
            <InlineDiv tariffView>
              <TextField
                label='Set price'
                placeholder=''
                type='number'
                name='price'
                mediumLabel
                tariffView
              />
              <SelectField
                label={getCurrency('per')}
                placeholder='Unit'
                name='pricingInterval'
                options={unitOptions}
                tariffView
              />
            </InlineDiv>
            <InlineDiv tariffView>
              <TextField
                label='Free time interval'
                placeholder=''
                type='number'
                name='freeTimeInterval'
                mediumLabel
                tariffView
              />
              <InfoMessage
                message='After the Free time interval, billing will begin.'
                label='min'
              />
            </InlineDiv>
            <InlineDiv tariffView>
              <TextField
                label='Grace period'
                placeholder=''
                type='number'
                name='gracePeriod'
                mediumLabel
                tariffView
              />
              <InfoMessage
                message={`Overstay by up to ${gracePeriod.value} minutes without penalty.`}
                label='min'
              />
            </InlineDiv>
            <ToggleField
              name='daysOfWeek'
              label='Days of week'
              stacked
              multiple
              options={DayOptions(WHOLE_WEEK)}
              tariffView
            />
            <ToggleSwitchForFormik
              name='datePeriod'
              label='Period'
              condition
              customWidth
            />
            {datePeriod.value ? (
              <>
                <StyledDiv>
                  <DateDiv>
                    <DateField
                      name='dateFrom'
                      label='From'
                      placeholder='Date'
                      tariffView
                      disabled={!datePeriod.value}
                      dateTimeView
                    />
                    <DateField
                      name='dateTo'
                      label='To'
                      placeholder='Date'
                      tariffView
                      center
                      disabled={!datePeriod.value}
                      dateTimeView
                    />
                  </DateDiv>
                  <Button
                    primary
                    size={ButtonSize.SMALL}
                    small
                    disabled={!datePeriod.value}
                    type='button'
                    onClick={addPeriod}
                  >
                    Add Period
                  </Button>
                </StyledDiv>
                <DateList>
                  {activeDatePeriods.value.map(
                    (d: { from: Date; to: Date }, index: number) => (
                      <StyledDateDiv key={index}>
                        <p>
                          FROM:
                          <StyledSpan>
                            {formatDate(d.from.toString())}
                          </StyledSpan>
                          TO:
                          <StyledSpan>{formatDate(d.to.toString())}</StyledSpan>
                          <StyledIcon
                            className={ActionIcon.DELETE}
                            onClick={() => removePeriod(index)}
                          />
                        </p>
                      </StyledDateDiv>
                    )
                  )}
                </DateList>
              </>
            ) : (
              <>
                <NoPeriodDiv>No period set.</NoPeriodDiv>
                <Line />
              </>
            )}
            <ToggleSwitchForFormik
              name='timePeriod'
              label='Time of a day'
              condition
              customWidth
            />
            {timePeriod.value ? (
              <>
                <StyledDiv>
                  <DateDiv>
                    <TimeField
                      name='timeFrom'
                      label='From'
                      placeholder='Time'
                      tariffView
                      disabled={!timePeriod.value}
                    />
                    <TimeField
                      name='timeTo'
                      label='To'
                      placeholder='Time'
                      initialEndValue
                      tariffView
                      center
                      disabled={!timePeriod.value}
                    />
                  </DateDiv>
                  <Button
                    primary
                    size={ButtonSize.SMALL}
                    small
                    disabled={!timePeriod.value}
                    type='button'
                    onClick={addTime}
                  >
                    Add Time
                  </Button>
                </StyledDiv>
                <DateList>
                  {activeTimePeriods.value.map(
                    (t: { from: Option; to: Option }, index: number) => (
                      <StyledDateDiv key={index}>
                        <p>
                          FROM:
                          <StyledSpan>{t.from.label}</StyledSpan>
                          TO:
                          <StyledSpan>{t.to.label}</StyledSpan>
                          <StyledIcon
                            className={ActionIcon.DELETE}
                            onClick={() => removeTime(index)}
                          />
                        </p>
                      </StyledDateDiv>
                    )
                  )}
                </DateList>
              </>
            ) : (
              <>
                <NoPeriodDiv>No time set.</NoPeriodDiv>
                <Line />
              </>
            )}
            <InlineDiv tariffView>
              <ToggleSwitchForFormik
                name='overstay'
                label='Overstay rules'
                condition
                customWidth
              />
              <InfoMessage message='If activated only Overstay fee will be applied in the case of overstay.' />
            </InlineDiv>
            {overstay.value && (
              <>
                <InlineDiv tariffView>
                  <TextField
                    label='Maximum time of stay'
                    placeholder=''
                    type='number'
                    name='maxTimeOfStay'
                    mediumLabel
                    tariffView
                  />
                  <SelectField
                    label=''
                    placeholder='Unit'
                    name='maxTimeOfStayUnit'
                    options={unitOptions}
                    tariffView
                    hideLabel
                  />
                </InlineDiv>
                <StyledWrapper>
                  <InlineDiv customView>
                    <TextField
                      label='Overstay fee'
                      placeholder=''
                      type='number'
                      name='overstayFee'
                      mediumLabel
                      tariffView
                    />
                    <TextField
                      label={getCurrency('every')}
                      placeholder=''
                      type='number'
                      name='overstayFeeInterval'
                      tariffView
                    />
                  </InlineDiv>
                  <SelectField
                    label=''
                    placeholder='Unit'
                    name='overstayFeeUnit'
                    options={unitOptions}
                    tariffView
                    hideLabel
                    customMargin
                  />
                </StyledWrapper>
              </>
            )}
          </Section>
          <Line addMargin />
          <Control>
            <Button
              onClick={dirty ? openGlobalCancelModal : closeParentModal}
              size={ButtonSize.LARGE}
              type='button'
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              isLoading={isSubmitting}
              size={ButtonSize.LARGE}
              type='submit'
              primary
              disabled={isSubmitting}
            >
              {zoneView ? 'Create new tariff' : 'Save changes'}
            </Button>
          </Control>
        </>
      )}
    </>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 150px;

  @media (max-width: 999px) {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const DateList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 150px;

  @media (max-width: 999px) {
    margin-left: 0px;
  }
`;

const StyledDateDiv = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-size: 15px;
  color: ${Color.TEXT_DARKER};

  & > p {
    margin: 5px 0;
    display: flex;
  }

  @media (max-width: 999px) {
    margin-left: 0px;
  }
`;

const StyledSpan = styled.span`
  margin: 0 30px 0 10px;
`;

const StyledIcon = styled.i`
  font-size: 18px;
  align-items: center;
  display: inline-flex;
  margin-left: 10px;
  cursor: pointer;
  color: ${Color.TEXT_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;

const DateDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const NoPeriodDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 20px 150px;
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${Color.TEXT_DARKER};

  @media (max-width: 999px) {
    margin-left: 0px;
  }
`;

const Link = styled(NavLink)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  margin-right: 5px;
  color: ${Color.TEXT_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;
