import React from 'react';
import InputIcon from '../../constants/Auth/InputIcon';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from './Button';
import Input from './Input';
import Link from './Link';

export default function LoginForm() {
  return (
    <>
      <Input
        name='email'
        type='text'
        icon={InputIcon.USER}
        placeholder='Enter Email'
        label='Email'
        autoComplete='username'
      />
      <Input
        name='password'
        type='password'
        icon={InputIcon.LOCK}
        placeholder='Enter Password'
        label='Password'
        autoComplete='current-password'
      />
      <Button size={ButtonSize.FULL} type='submit' primary>
        Login
      </Button>
      <Link to='/reset-password'>Forgot your password?</Link>
    </>
  );
}
