import type DetectCameraViewSchemaParkingPlaces from 'src/models/Parking/DetectCamera/DetectCameraViewSchemaParkingPlaces';
import type DetectCameraViewSchemaParkingPlacesResponse from 'src/models/Parking/DetectCamera/DetectCameraViewSchemaParkingPlacesResponse';

export default function toDetectCameraViewSchemaParkingPlaces(
  data: DetectCameraViewSchemaParkingPlacesResponse
): DetectCameraViewSchemaParkingPlaces {
  const { id, cx, cy, r } = data;

  return {
    id: parseInt(id),
    cx,
    cy,
    r,
  };
}
