import type TariffFormValues from 'src/models/Parking/Tariff/TariffFormValues';
import * as yup from 'yup';
import DayOptions from './DayOptions';

const WHOLE_WEEK = '1111111';

const defaultTariffFormValues: TariffFormValues = {
  name: '',
  price: undefined,
  pricingInterval: null,
  freeTimeInterval: 10,
  gracePeriod: 10,
  daysOfWeek: DayOptions(WHOLE_WEEK),
  datePeriod: false,
  activeDatePeriods: [],
  timePeriod: false,
  activeTimePeriods: [],
  overstay: false,
  maxTimeOfStay: undefined,
  maxTimeOfStayUnit: null,
  overstayFee: undefined,
  overstayFeeInterval: undefined,
  overstayFeeUnit: null,
};

const tariffValidationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  price: yup
    .number()
    .min(0, 'Price must be higher than 0')
    .required('Price is a required field'),
  pricingInterval: yup
    .object()
    .required('Price unit is a required field')
    .nullable(),
  freeTimeInterval: yup
    .number()
    .min(0, 'Free time interval must be higher than 0')
    .required('Free time interval is a required field'),
  gracePeriod: yup
    .number()
    .min(0, 'Grace period must be higher than 0')
    .required('Grace period is a required field'),
  daysOfWeek: yup.array().required('At least one day needs to be checked'),
  maxTimeOfStay: yup
    .number()
    .min(0, 'Maximum time of stay must be higher than 0')
    .notRequired()
    .nullable(),
  maxTimeOfStayUnit: yup
    .object()
    .nullable()
    .when('maxTimeOfStay', {
      is: (maxTimeOfStay: number | undefined) =>
        maxTimeOfStay && maxTimeOfStay >= 0,
      then: yup
        .object()
        .required(
          'Maximum time of stay unit is a required field when maximum time of stay is set'
        ),
    }),
  overstayFee: yup
    .number()
    .notRequired()
    .nullable()
    .when('overstay', {
      is: true,
      then: yup
        .number()
        .min(0, 'Overstay fee must be higher than 0')
        .required('Overstay fee is a required field when overstay is enabled'),
    }),
  overstayFeeInterval: yup
    .number()
    .notRequired()
    .nullable()
    .when('overstay', {
      is: true,
      then: yup
        .number()
        .min(0, 'Overstay fee interval must be higher than 0')
        .required(
          'Overstay fee interval is a required field when overstay is enabled'
        ),
    }),
  overstayFeeUnit: yup
    .object()
    .nullable()
    .when('overstay', {
      is: true,
      then: yup
        .object()
        .required(
          'Overstay fee unit is a required field when overstay is enabled'
        ),
    }),
});

export { defaultTariffFormValues, tariffValidationSchema, WHOLE_WEEK };
