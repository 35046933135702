import type InvitationKeyUpdateParams from 'src/models/KeySharing/InvitationKey/InvitationKeyUpdateParams';
import type InvitationKeyUpdateValues from 'src/models/KeySharing/InvitationKey/InvitationKeyUpdateValues';

export default function toInvitationKeyUpdateParams(
  data: InvitationKeyUpdateValues
): InvitationKeyUpdateParams {
  const { ids, status } = data;

  return { ids, status };
}
