import type RecurringOperationKeyCreateRequest from 'src/models/KeySharing/RecurringOperationKeyCreateRequest';
import type KeySharingFormValues from '../../models/KeySharing/KeySharingFormValues';

export default function toRecurringOperationKeyCreateRequest(
  productId: number[],
  data: KeySharingFormValues
): RecurringOperationKeyCreateRequest {
  const {
    startTime,
    endTime,
    receiverEmails,
    isUnlimited,
    recurringEndTime,
    recurringUnit,
  } = data;

  const responseBody: RecurringOperationKeyCreateRequest = {
    products: generateProductArray(productId),
    receiver_emails: generateEmailArray(receiverEmails),
    start_time: startTime ? startTime.toISOString() : '',
    recurring_start_time: startTime ? startTime.toISOString() : '',
    recurring_end_time: recurringEndTime ? recurringEndTime.toISOString() : '',
    recurring_unit: recurringUnit ? recurringUnit.label : '',
  };

  if (isUnlimited) {
    responseBody.is_unlimited = 1;
  } else {
    responseBody.end_time = endTime ? endTime.toISOString() : '';
  }

  return responseBody;
}

function generateEmailArray(mail: string) {
  const email = mail.split(/[ ,;:]+/);
  return email;
}

function generateProductArray(productArray: number[]) {
  const products = productArray.map((id) => ({
    name: undefined,
    product_id: id,
  }));

  return products;
}
