import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import AppRole from '../../constants/Shared/AppRole';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';
import type Option from '../../models/Shared/Option';
import useAccountUserRoleService from '../../services/Shared/useAccountUserRoleService';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Section from '../Shared/Section';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';
import ToggleField from '../Shared/ToggleField';

interface AccountUserFormProps {
  closeParentModal: () => void;
}

export default function AccountUserForm(props: AccountUserFormProps) {
  const { closeParentModal } = props;
  const [options, setOptions] = useState<Option[]>([]);
  const { isSubmitting, dirty } = useFormikContext();
  const { findAllAccountUserRoles } = useAccountUserRoleService();
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const userCanReadUserRole = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  useEffect(() => {
    const getData = async () => {
      const data = await findAllAccountUserRoles();

      if (isMounted()) {
        const options = data.map(({ id, name }) => ({
          key: id,
          label: name,
        }));

        setOptions(options);
      }
    };

    getData();
  }, [findAllAccountUserRoles, isMounted]);

  return (
    <>
      <Section>
        <Title bold>Parking manager</Title>
        {userCanReadUserRole && (
          <ToggleField label='Role' name='role' options={options} stacked />
        )}
        <TextField
          autofocus
          label='Email'
          placeholder='Email'
          type='text'
          name='email'
          stacked
        />
        <TextField
          label='Name'
          placeholder='Name'
          type='text'
          name='name'
          stacked
        />
        <TextField
          label='Phone'
          placeholder='Phone'
          type='text'
          name='phone'
          stacked
        />
      </Section>
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
