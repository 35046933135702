import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalModalProvider } from './components/Shared/GlobalModal';
import AuthContext from './contexts/Auth/AuthContext';
import Routes from './routes';
import useSessionService from './services/Shared/useSessionService';

export default function App() {
  const { getSessionData } = useSessionService();
  const [initialIsLoggedInState, initialSessionData] = useMemo(() => {
    const sessionData = getSessionData();

    if (!sessionData) {
      return [false, undefined];
    }

    return [true, sessionData];
  }, [getSessionData]);
  const [isLoggedIn, setIsLoggedIn] = useState(initialIsLoggedInState);
  const [sessionData, setSessionData] = useState(initialSessionData);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, sessionData, setSessionData }}
    >
      <Router>
        <GlobalModalProvider>
          <Routes />
        </GlobalModalProvider>
      </Router>
    </AuthContext.Provider>
  );
}
