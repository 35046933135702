import * as yup from 'yup';

const lotOccupancyValidationScheme = yup.object({
  occupiedCount: yup
    .number()
    .required('Occupied counter is a required field')
    .moreThan(-1, 'Occupied counter must be positive number'),
  spotCount: yup
    .number()
    .required('Total counter is a required field')
    .moreThan(-1, 'Total counter must be positive number'),
});
export default lotOccupancyValidationScheme;
