import { useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AccountRole from 'src/constants/Shared/AccountRole';
import accountRequiresLicense from 'src/utils/accountRequiresLicense';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import useWizardContext from '../../hooks/Shared/useWizardContext';
import type Option from '../../models/Shared/Option';
import useAccountRoleService from '../../services/Shared/useAccountRoleService';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Section from '../Shared/Section';
import SelectField from '../Shared/SelectField/index';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';

interface InfoStepProps {
  closeParentModal: () => void;
}

export default function InfoStep(props: InfoStepProps) {
  const { closeParentModal } = props;
  const { dirty } = useFormikContext();
  const { next } = useWizardContext();
  const [options, setOptions] = useState<Option[]>([]);
  const { filterAccountRoles } = useAccountRoleService();
  const isMounted = useIsMounted();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });
  const [field] = useField({ name: 'accountRole' });
  const { value } = field;

  useEffect(() => {
    const getData = async () => {
      const data = await filterAccountRoles(AccountRole.PARKLIO_INTERNAL);
      const options = data.map(({ id, name }) => ({
        key: id,
        label: name,
      }));
      if (isMounted()) {
        setOptions(options);
      }
    };

    getData();
  }, [filterAccountRoles, isMounted]);

  const skipStep = useCallback(() => {
    next(true);
  }, [next]);

  const isLicenseRequired = useMemo(
    () => accountRequiresLicense(value),
    [value]
  );

  return (
    <>
      <Section>
        <Title bold>Create Account</Title>
        <TextField
          label='Account'
          placeholder='Account'
          type='text'
          stacked
          name='name'
          autofocus
        />
        <SelectField
          label='Account Type'
          placeholder='Account Type'
          name='accountRole'
          options={options}
          stacked
        />
        <Title bold>Contact Person</Title>
        <TextField
          label='Contact Name'
          placeholder='Contact Name'
          type='text'
          stacked
          name='contactName'
        />
        <TextField
          label='Contact Email'
          placeholder='Contact Email'
          type='text'
          stacked
          name='contactEmail'
        />
        <TextField
          label='Contact Phone'
          placeholder='Contact Phone'
          type='text'
          stacked
          name='contactPhone'
        />
      </Section>
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
        >
          Cancel
        </Button>
        <Button
          size={ButtonSize.LARGE}
          type='button'
          primary
          onClick={isLicenseRequired ? next : skipStep}
        >
          Next step
        </Button>
      </Control>
    </>
  );
}
