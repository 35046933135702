import type LicensePurchaseCreateRequest from 'src/models/License/LicensePurchaseCreateRequest';
import type LicensePurchaseFormValues from 'src/models/License/LicensePurchaseFormValues';
import toLicenseInfoRequest from './toLicenseInfoRequest';

export default function toLicensePurchaseCreateRequest(
  data: LicensePurchaseFormValues,
  accountId: number
): LicensePurchaseCreateRequest {
  const { licenseInfo } = data;

  return {
    license_info: licenseInfo.map((license) => toLicenseInfoRequest(license)),
    account_id: accountId,
  };
}
