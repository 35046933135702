import React, { useCallback, useMemo } from 'react';
import ActionIcon from '../../constants/Shared/ActionIcon';
import AppRole from '../../constants/Shared/AppRole';
import ButtonSize from '../../constants/Shared/ButtonSize';
import InvitationStates from '../../constants/Shared/InvitationStates';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';
import type AccountUserInvitation from '../../models/Home/AccountUserInvitation';
import AddUser from '../../screens/Home/AddUser';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import { useGlobalModal } from '../Shared/GlobalModal';
import GlobalModal from '../Shared/Modals/GlobalModal';
import StyledNoData from '../Shared/StyledNoData';
import Table from '../Shared/Table';
import Title from '../Shared/Title';

interface AccountUserInvitationTableProps {
  openRemoveModal: (id: number) => void;
  onResendInvitation: (id: number, email: string) => void;
  data: AccountUserInvitation[];
  isLoading: boolean;
  resendingInvitation: { [key: number]: boolean };
}

const ACTIVE_INVITATION_TIME_MILISECONDS = 48 * 60 * 60 * 1000;

export default function AccountUserInvitationTable(
  props: AccountUserInvitationTableProps
) {
  const {
    data,
    openRemoveModal,
    isLoading,
    resendingInvitation,
    onResendInvitation,
  } = props;

  const userHasRole = useUserHasRole();

  const removeItem = useCallback(
    (data?: AccountUserInvitation) => {
      if (!data) {
        return;
      }

      const { id } = data;

      openRemoveModal(id);
    },
    [openRemoveModal]
  );

  const onClick = useCallback(
    (id: number, email: string) => {
      onResendInvitation(id, email);
    },
    [onResendInvitation]
  );

  const [openAddUserModal, closeAddUserModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <AddUser closeParentModal={closeAddUserModal} refreshPage />
    </GlobalModal>
  ));

  const userCanAddParkingManager = useMemo(() => {
    const roles = AppRole.PMS_SUPER_ADMIN | AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  return (
    <>
      <Title>Pending Managers</Title>
      {userCanAddParkingManager && (
        <Button
          size={ButtonSize.MIDDLE}
          type='button'
          primary
          onClick={openAddUserModal}
          addTopMargin
        >
          + Add Parking Manager
        </Button>
      )}
      <Card>
        <Table isLoading={isLoading}>
          <Table.Body>
            {data.length > 0 ? (
              data.map((accountUserInvitationFindAllDetails) => {
                const { email, id, updatedAt, name } =
                  accountUserInvitationFindAllDetails;

                const isExpired =
                  new Date().valueOf() - updatedAt.valueOf() >
                  ACTIVE_INVITATION_TIME_MILISECONDS;

                return (
                  <Table.Row key={id}>
                    <Table.Cell title={name} short bold>
                      {email}
                    </Table.Cell>
                    {isExpired ? (
                      <Table.Cell
                        center
                        action={removeItem}
                        primary
                        data={accountUserInvitationFindAllDetails}
                      >
                        <Table.Icon icon={ActionIcon.DELETE} />
                      </Table.Cell>
                    ) : (
                      <Table.Cell>{}</Table.Cell>
                    )}
                    <Table.Cell disabled={!isExpired}>
                      {isExpired ? (
                        <Button
                          size={ButtonSize.SMALL}
                          type='button'
                          onClick={() => onClick(id, email)}
                          disabled={resendingInvitation[id]}
                        >
                          {resendingInvitation[id]
                            ? InvitationStates.SENDING
                            : InvitationStates.RESEND}
                        </Button>
                      ) : (
                        InvitationStates.PENDING
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell>
                  <StyledNoData>No pending Parking Managers</StyledNoData>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Card>
    </>
  );
}
