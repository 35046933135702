import type Brain from 'src/models/Parking/Brain/Brain';
import type BrainResponse from 'src/models/Parking/Brain/BrainResponse';
import toConcreteProduct from '../Product/toConcreteProduct';

export default function toBrain(data: BrainResponse): Brain {
  const { id, product, state, permanent_position } = data;
  return {
    id,
    product: toConcreteProduct(product),
    state,
    permanentPosition: permanent_position,
  };
}
