import type DetectGatewayChange from 'src/models/Parking/DetectGateway/DetectGatewayChange';
import type DetectGatewayChangePayload from 'src/models/Parking/DetectGateway/DetectGatewayChangePayload';
import toDetectGatewayChangeData from './toDetectGatewayChangeData';

export default function toDetectGatewayChange(
  data: DetectGatewayChangePayload
): DetectGatewayChange {
  const { gateways } = data;

  return {
    gateways: gateways.map(toDetectGatewayChangeData),
  };
}
