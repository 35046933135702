import type Tariff from 'src/models/Parking/Tariff/Tariff';
import type TariffResponse from 'src/models/Parking/Tariff/TariffResponse';
import toPeriods from './toPeriods';
import toTariffActivePeriod from './toTariffActivePeriod';

export default function toTariff(data: TariffResponse): Tariff {
  const {
    id,
    name,
    price,
    account_id,
    currency_id,
    zone_ids,
    pricing_interval,
    free_time_interval,
    grace_period,
    days_of_week,
    max_time_of_stay,
    max_time_of_stay_unit,
    overstay_fee,
    overstay_fee_interval,
    overstay_fee_unit,
    deleted_at,
    created_at,
    updated_at,
    active_periods,
  } = data;

  return {
    id,
    name,
    price,
    accountId: account_id,
    currencyId: currency_id,
    zoneIds: zone_ids,
    pricingInterval: pricing_interval,
    freeTimeInterval: free_time_interval,
    gracePeriod: grace_period,
    daysOfWeek: days_of_week,
    maxTimeOfStay: max_time_of_stay,
    maxTimeOfStayUnit: max_time_of_stay_unit,
    overstayFee: overstay_fee,
    overstayFeeInterval: overstay_fee_interval,
    overstayFeeUnit: overstay_fee_unit,
    deletedAt: deleted_at ? new Date(deleted_at) : null,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    activePeriods: toPeriods(
      active_periods.map((period) => toTariffActivePeriod(period))
    ),
  };
}
