import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ParkingDetectionImplementationStatus } from 'src/constants/Parking/ParkingDetectionImplementationStatus';
import CheckedIcons from 'src/constants/Shared/CheckedIcons';
import type Lot from 'src/models/Parking/Lot/Lot';
import ActionIcon from '../../constants/Shared/ActionIcon';
import Card from '../Shared/Card';
import Spinner from '../Shared/Spinner';
import StyledNoData from '../Shared/StyledNoData';
import Table from '../Shared/Table';

interface DetectLotTableProps {
  openEditModal: (data: Lot) => void;
  openRemoveModal: (data: Lot) => void;
  data: Lot[];
  isLoading?: boolean;
}

export default function DetectLotTable(props: DetectLotTableProps) {
  const { openEditModal, openRemoveModal, data, isLoading } = props;

  const { pathname } = useLocation();
  const { push } = useHistory();

  const editItem = useCallback(
    (data?: Lot) => {
      if (!data) {
        return;
      }

      openEditModal(data);
    },
    [openEditModal]
  );

  const removeItem = useCallback(
    (data?: Lot) => {
      if (!data) {
        return;
      }

      openRemoveModal(data);
    },
    [openRemoveModal]
  );

  const openLotInfo = useCallback(
    (data?: Lot) => {
      if (
        !data ||
        data?.detectionImplementationStatus ===
          ParkingDetectionImplementationStatus.NOT_IMPLEMENTED
      ) {
        return;
      }

      const { id } = data;

      push(`${pathname}/${id}`);
    },
    [push, pathname]
  );

  return (
    <Card>
      {isLoading ? (
        <Spinner primary />
      ) : data.length > 0 ? (
        <Table isLoading={isLoading}>
          <Table.Head>
            <Table.Row>
              <Table.Header>#ID</Table.Header>
              <Table.Header>NAME</Table.Header>
              <Table.Header center>HAS DETECTION</Table.Header>
              <Table.Header colSpan={2} />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map((lot) => {
              const { id, name, detectionImplementationStatus } = lot;

              return (
                <Table.Row key={id}>
                  <Table.Cell>{id}</Table.Cell>
                  <Table.Cell
                    action={openLotInfo}
                    primary={
                      detectionImplementationStatus !==
                      ParkingDetectionImplementationStatus.NOT_IMPLEMENTED
                    }
                    data={lot}
                  >
                    {name}
                  </Table.Cell>
                  <Table.Cell>
                    <Table.Icon
                      icon={
                        detectionImplementationStatus !==
                        ParkingDetectionImplementationStatus.NOT_IMPLEMENTED
                          ? CheckedIcons.YES
                          : CheckedIcons.NO
                      }
                    />
                  </Table.Cell>
                  <Table.Cell center action={removeItem} primary data={lot}>
                    <Table.Icon icon={ActionIcon.DELETE} />
                  </Table.Cell>
                  <Table.Cell center action={editItem} primary data={lot}>
                    <Table.Icon icon={ActionIcon.EDIT} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <StyledNoData detectView>
          <i className='pa-android-warning' />
          Parklio Detect Lot not added
        </StyledNoData>
      )}
    </Card>
  );
}
