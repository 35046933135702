import React from 'react';
import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';

interface ToastProps {
  children: React.ReactNode;
  onClick: () => void;
  aboveTopBtn?: boolean;
}

function Toast(props: ToastProps) {
  const { children, onClick, aboveTopBtn } = props;

  return (
    <StyledWrapper aboveTopBtn={aboveTopBtn}>
      {children}
      <StyledIcon className='pa-iconNo' onClick={onClick} />
    </StyledWrapper>
  );
}

export default Toast;

const StyledWrapper = styled.div<{ aboveTopBtn?: boolean }>`
  width: auto;
  font-size: 13px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  background-color: ${Color.TEXT_DARKER};
  color: ${Color.TEXT_LIGHTEST};
  padding: 10px;
  margin: 10px;
  position: fixed;
  right: 10px;
  display: flex;
  flex-direction: row;
  z-index: 10000;

  ${(props) => {
    const { aboveTopBtn } = props;

    if (aboveTopBtn) {
      return css`
        bottom: 75px;
      `;
    }

    return css`
      bottom: 10px;
    `;
  }}
`;

const StyledIcon = styled.i`
  top: 0;
  right: 0;
  margin-left: 20px;
  cursor: pointer;
`;
