import { useField } from 'formik';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';
import CheckBox from './CheckBox';

interface CheckBoxFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
  description?: string;
  reverse?: boolean;
}

export default function CheckBoxField(props: CheckBoxFieldProps) {
  const { label, name, disabled, description, reverse } = props;
  const [field, , helpers] = useField({ name, type: 'checkbox' });
  const { checked, onChange } = field;
  const { setValue } = helpers;

  const toggleCheckbox = useCallback(() => {
    if (disabled) return;
    setValue(!checked);
  }, [setValue, checked, disabled]);

  return (
    <StyledWrapper description={description} reverse={reverse}>
      <CheckBox
        onChange={onChange}
        name={name}
        checked={checked}
        disabled={disabled}
      />
      <StyledDiv>
        <StyledLabel onClick={toggleCheckbox} reverse={reverse}>
          {label}
        </StyledLabel>
        {description && <StyledDescription>{description}</StyledDescription>}
      </StyledDiv>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ description?: string; reverse?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;

  :last-child {
    margin-bottom: 40px;
  }

  ${(props) => {
    const { description } = props;

    if (description) {
      return css`
        align-items: flex-start;
      `;
    }

    return css`
      align-items: center;
    `;
  }}

  ${(props) => {
    const { reverse } = props;

    if (reverse) {
      return css`
        flex-direction: row-reverse;
        margin-right: -50px;

        :last-child {
          margin-bottom: 0px;
        }
      `;
    }
    return css``;
  }}
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledLabel = styled.label<{ reverse?: boolean }>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${Color.TEXT_DARKER};

  ${(props) => {
    const { reverse } = props;

    if (reverse) {
      return css`
        margin-right: 10px;
      `;
    }
    return css``;
  }}
`;

const StyledDescription = styled.label`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: ${Color.TEXT_DARKER};
`;
