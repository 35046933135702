import type KeyStatisticsParams from 'src/models/Statistics/Key/KeysStatisticsParams';
import type KeyStatisticsQueryParams from 'src/models/Statistics/Key/KeysStatisticsQueryParams';
import getTimeRange from 'src/utils/getTimeRange';

export default function toKeyStatisticsQueryParams(
  data: KeyStatisticsParams
): KeyStatisticsQueryParams {
  const { timeRange } = data;

  return {
    timerange: getTimeRange(timeRange),
  };
}
