import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Color from '../../../constants/Shared/Color';

interface LinkProps {
  to: string;
  children: React.ReactNode;
  exact?: boolean;
  style?: React.CSSProperties;
  addpadding?: string;
  className?: string;
  addIcon?: boolean;
}

export default function Link(props: LinkProps) {
  const { to, children, exact, style, addpadding, className, addIcon } = props;

  return (
    <StyledLink
      to={to}
      activeClassName='active'
      style={style}
      exact={exact}
      addpadding={addpadding}
      className={className}
    >
      {children}
      {addIcon && <StyledIcon className='pa-android-alert' />}
    </StyledLink>
  );
}

const StyledLink = styled(NavLink)<LinkProps>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px 20px;
  text-decoration: none;
  color: ${Color.TEXT_DARKER};
  height: 40px;
  position: relative;
  margin: 10px 5px;
  justify-content: center;

  &.active {
    border-radius: 5px;
    background-color: ${Color.BACKGROUND_LIGTH};
    color: ${Color.TEXT_BRAND};
    :hover {
      color: ${Color.PRIMARY_HOVER};
    }
  }

  ${(props) => {
    const { addpadding } = props;

    if (addpadding === 'true') {
      return css`
        padding-right: 30px;
      `;
    }

    return css``;
  }}
`;

const StyledIcon = styled.i`
  margin: 0px 5px;
  font-size: 18px;
  color: #f9d11c;
`;
