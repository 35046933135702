import React, { useCallback } from 'react';
import AddIcon from 'src/components/Shared/AddIcon';
import type DetectGateway from 'src/models/Parking/DetectGateway/DetectGateway';
import Spinner from '../../Shared/Spinner';
import { CardWrapper } from '../Cards/CardStyle';
import DetectGatewayCardList from '../Cards/DetectGatewayCardList';

interface DetectGatewayCardsProps {
  data: DetectGateway[];
  openAddNewModal: () => void;
  openEditModal: (id: number) => void;
  isLoading?: boolean;
}

export default function DetectGatewayCards(props: DetectGatewayCardsProps) {
  const { data, openEditModal, isLoading, openAddNewModal } = props;

  const editItem = useCallback(
    (id?: number) => {
      if (!id) {
        return;
      }

      openEditModal(id);
    },
    [openEditModal]
  );

  return (
    <>
      {isLoading ? (
        <Spinner primary />
      ) : (
        <DetectGatewayCardList>
          {data.map((place) => {
            const { id, name, status, updatedAt } = place;

            return (
              <DetectGatewayCardList.Card
                key={id}
                id={id}
                name={name}
                status={status}
                updatedAt={updatedAt}
                onNameClick={editItem}
              />
            );
          })}
          <CardWrapper onClick={openAddNewModal}>
            <AddIcon className='pa-plus-round' />
          </CardWrapper>
        </DetectGatewayCardList>
      )}
    </>
  );
}
