enum ButtonSize {
  FULL = 'EXPAND_LARGE',
  LARGE = 'STANDARD_LARGE',
  MIDDLE = 'STANDARD_MIDDLE',
  SMALL = 'STANDARD_SMALL',
  MEDIUM = 'STANDARD_MEDIUM',
  ANPR = 'ANPR',
  DETECT = 'DETECT',
}

export default ButtonSize;
