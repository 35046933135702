import type LotWhitelistStatisticsParams from 'src/models/Statistics/LotWhitelist/LotWhitelistStatisticsParams';
import type LotWhitelistStatisticsQueryParams from 'src/models/Statistics/LotWhitelist/LotWhitelistStatisticsQueryParams';
import getTimeRange from 'src/utils/getTimeRange';

export default function toLotWhitelistStatisticsQueryParams(
  data: LotWhitelistStatisticsParams
): LotWhitelistStatisticsQueryParams {
  const { timeRange } = data;

  return {
    timerange: getTimeRange(timeRange),
  };
}
