import type AccountUser from '../../models/Settings/AccountUser';
import type AccountUserResponse from '../../models/Settings/AccountUserResponse';
import toAccountUserRole from '../Home/toAccountUserRole';

export default function toAccountUser(data: AccountUserResponse): AccountUser {
  const { id, name, email, phone, account_user_role_id } = data;

  return {
    id,
    name,
    email,
    phone,
    role: {
      key: account_user_role_id,
      label: toAccountUserRole(account_user_role_id),
    },
  };
}
