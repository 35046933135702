import React, { useCallback } from 'react';
import type GatewayStatus from '../../constants/Parking/GatewayStatus';
import type Gateway from '../../models/Parking/Gateway/Gateway';
import LoadingWrapper from '../Shared/LoadingWrapper';
import Spinner from '../Shared/Spinner';
import StyledNoData from '../Shared/StyledNoData';
import Title from '../Shared/Title';
import GatewayCardList from './Cards/GatewayCardList';

interface GatewayCardProps {
  data: Gateway[];
  statuses: { [key: string]: GatewayStatus };
  openGatewayModal: (id: number) => void;
  isLoading: boolean;
  dashboardView?: boolean;
}

export default function GatewayCards(props: GatewayCardProps) {
  const { data, statuses, openGatewayModal, isLoading, dashboardView } = props;

  const onNameClick = useCallback(
    (id: number) => {
      openGatewayModal(id);
    },
    [openGatewayModal]
  );

  return (
    <>
      <Title>Gateway Connections</Title>
      {isLoading ? (
        <LoadingWrapper>
          <Spinner primary />
        </LoadingWrapper>
      ) : (
        <>
          {data.length > 0 ? (
            <GatewayCardList>
              {data.map((gateway) => {
                const { id, name, uuid } = gateway;
                const status = statuses[uuid];

                return (
                  <GatewayCardList.Card
                    key={id}
                    id={id}
                    name={name}
                    status={status}
                    onNameClick={onNameClick}
                    dashboardView={dashboardView}
                  />
                );
              })}
            </GatewayCardList>
          ) : (
            <StyledNoData>No Gateway on the Parking Lot!</StyledNoData>
          )}
        </>
      )}
    </>
  );
}
