import type License from 'src/models/License/License';
import type LicenseFormValues from 'src/models/License/LicenseFormValues';
import type LicensePricing from 'src/models/License/LicensePricing';

export default function toLicenseFormValues(
  license: License,
  pricing: LicensePricing
): LicenseFormValues {
  const { barriersCount, lprCamerasCount, brainModulesCount, licenseTypeId } =
    license;
  const { name, price, status } = pricing;

  return {
    name,
    price,
    status,
    barriersCount,
    lprCamerasCount,
    brainModulesCount,
    licenseTypeId,
    paymentInfo: { key: 0, label: 'One time' },
  };
}
