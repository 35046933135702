import type LicenseCreateRequest from 'src/models/License/LicenseCreateRequest';
import type LicenseFormValues from 'src/models/License/LicenseFormValues';

export default function toLicenseCreateRequest(
  data: LicenseFormValues
): LicenseCreateRequest {
  const { barriersCount, lprCamerasCount, brainModulesCount, licenseTypeId } =
    data;

  return {
    barriers_count: barriersCount,
    lpr_cameras_count: lprCamerasCount,
    brain_modules_count: brainModulesCount,
    license_type_id: licenseTypeId,
  };
}
