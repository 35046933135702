import React, { useEffect } from 'react';

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(
        new google.maps.Marker({
          animation: google.maps.Animation.DROP,
        })
      );
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default Marker;
