import { useCallback } from 'react';
import toAnprRemoteControlLog from 'src/mappers/Logs/toAnprRemoteControlLog';
import toAnprRemoteControlLogQuery from 'src/mappers/Logs/toAnprRemoteControlLogQuery';
import toLotWhitelistLog from 'src/mappers/Logs/toLotWhitelistLog';
import toLotWhitelistLogQuery from 'src/mappers/Logs/toLotWhitelistLogQuery';
import toMeta from 'src/mappers/Shared/toMeta';
import type AnprRemoteControlLog from 'src/models/Logs/AnprRemoteControlLog';
import type AnprRemoteControlLogQuery from 'src/models/Logs/AnprRemoteControlLogQuery';
import type AnprRemoteControlLogQueryValues from 'src/models/Logs/AnprRemoteControlLogQueryValues';
import type AnprRemoteControlLogResponse from 'src/models/Logs/AnprRemoteControlLogResponse';
import type LotWhitelistLog from 'src/models/Logs/LotWhitelistLog';
import type LotWhitelistLogQuery from 'src/models/Logs/LotWhitelistLogQuery';
import type LotWhitelistLogQueryValues from 'src/models/Logs/LotWhitelistLogQueryValues';
import type LotWhitelistLogResponse from 'src/models/Logs/LotWhitelistLogResponse';
import type GetAllDetails from 'src/models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const logsURL = '/logs';
export interface LogsService {
  getAllAnprRemoteControlLogs: (
    values: AnprRemoteControlLogQueryValues
  ) => Promise<GetAllDetails<AnprRemoteControlLog>>;
  getAllLotWhitelistLogs: (
    values: LotWhitelistLogQueryValues
  ) => Promise<GetAllDetails<LotWhitelistLog>>;
}

export default function useLogsSerivce(): LogsService {
  const { get } = useApiService();

  const getAllAnprRemoteControlLogs = useCallback(
    async (values: AnprRemoteControlLogQueryValues) => {
      const query = toAnprRemoteControlLogQuery(values);
      const response = await get<
        AnprRemoteControlLogResponse[],
        AnprRemoteControlLogQuery
      >(`${logsURL}/lpr-remote-controls`, query);

      const data = response.data.map(toAnprRemoteControlLog);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const getAllLotWhitelistLogs = useCallback(
    async (values: LotWhitelistLogQueryValues) => {
      const query = toLotWhitelistLogQuery(values);
      const response = await get<
        LotWhitelistLogResponse[],
        LotWhitelistLogQuery
      >(`${logsURL}/lot-whitelists`, query);

      const data = response.data.map(toLotWhitelistLog);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  return {
    getAllAnprRemoteControlLogs,
    getAllLotWhitelistLogs,
  };
}
