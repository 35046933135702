import styled, { css } from 'styled-components';

interface MainProps {
  left?: boolean;
  removePadding?: boolean;
  modalView?: boolean;
  dashboardView?: boolean;
  small?: boolean;
  reportsView?: boolean;
}

const Main = styled.div<MainProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 999px) {
    flex-direction: column;
  }

  ${(props) => {
    const { left, modalView, dashboardView, small } = props;

    if (left) {
      return css`
        align-self: flex-start;
        padding: 0 5%;
        max-width: 2200px;
      `;
    }

    if (modalView) {
      return css`
        padding: 0;
        flex-direction: column;
      `;
    }

    if (dashboardView) {
      return css`
        padding: 0;
        max-width: 2000px;
        @media (max-width: 1100px) {
          flex-direction: column;
        }
      `;
    }

    if (small) {
      return css`
        max-width: 1400px;
        padding: 0px 20px;
      `;
    }

    return css`
      max-width: 2000px;
    `;
  }}

  ${(props) => {
    const { removePadding } = props;

    if (removePadding) {
      return css`
        padding: 0;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { reportsView } = props;

    if (reportsView) {
      return css`
        @media (max-width: 520px) {
          margin: 0;

          :last-child {
            margin-bottom: 20px;
          }
        }
      `;
    }

    return css`
      @media (max-width: 520px) {
        margin: 20px 0;
      }
    `;
  }}
`;

export default Main;
