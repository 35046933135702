import React from 'react';
import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';
import StyledLink from '../Shared/Link';

interface DetailsDataProps {
  label: string;
  value: string | number;
  offline?: boolean;
  linkValue?: string | boolean;
  occupancyColor?: Color;
  formStyle?: boolean;
}

export default function DetailsData(props: DetailsDataProps) {
  const { label, value, linkValue, occupancyColor, offline, formStyle } = props;

  return (
    <StyledWrapper>
      <StyledLabel formStyle={formStyle}>{label}</StyledLabel>
      {linkValue ? (
        <StyledLink href={`${linkValue}`} target='_blank'>
          <StyledValue>{value}</StyledValue>
        </StyledLink>
      ) : (
        <StyledValue occupancyColor={occupancyColor} offline={offline}>
          {value}
        </StyledValue>
      )}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
`;

const StyledLabel = styled.div<{ formStyle?: boolean }>`
  text-align: left;
  padding-right: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0px;

  ${(props) => {
    const { formStyle } = props;

    if (formStyle) {
      return css`
        font-size: 14px;
        line-height: 19px;
        color: ${Color.TEXT_DARKER};
      `;
    }

    return css`
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
    `;
  }}
`;

const StyledValue = styled.div<{ occupancyColor?: Color; offline?: boolean }>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 0px;

  ${(props) => {
    const { occupancyColor, offline } = props;

    if (occupancyColor) {
      return css`
        color: ${occupancyColor};
      `;
    }

    if (offline) {
      return css`
        color: ${Color.ERROR};
      `;
    }

    return css`
      color: ${Color.TEXT_DARKER};
    `;
  }}
`;
