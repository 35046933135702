import type RemoteControlStatistics from 'src/models/Statistics/RemoteControl/RemoteControlStatistics';
import type RemoteControlStatisticsResponse from 'src/models/Statistics/RemoteControl/RemoteControlStatisticsResponse';
import toRemoteControlStatisticsProduct from './toRemoteControlStatisticsProduct';

export default function toRemoteControlStatistics(
  data: RemoteControlStatisticsResponse
): RemoteControlStatistics {
  const { product_id, operation, product, total } = data;

  return {
    productId: product_id,
    operation,
    product: toRemoteControlStatisticsProduct(product),
    total,
  };
}
