enum Color {
  PRIMARY_BRAND = '#0591CE',
  PRIMARY_HOVER = '#06AAF1',
  BACKGROUND_DARKEST = '#000000',
  BACKGROUND_DARKEST_TRANSPARENT = 'rgba(0,0,0,0.5)',
  BACKGROUND_DARKER = '#6D6D6D',
  BACKGROUND_DARK = '#F3F6F9',
  BACKGROUND = '#F2F3F7',
  BACKGROUND_LIGTH = '#E3E3E3',
  BACKGROUND_LIGHTER = '#FAFAFA',
  BACKGROUND_LIGHTER_TRANSPARENT = 'rgba(250, 250, 250, 0.3)',
  BACKGROUND_LIGHTER_LESS_TRANSPARENT = 'rgba(250, 250, 250, 0.7)',
  BACKGROUND_LIGHTEST = '#FFFFFF',
  BACKGROUND_HOVER = '#FDFDFD',
  BORDER_LIGHT = '#E3E3E3',
  BORDER_BRAND = '#0591CE',
  TEXT_DARKER = '#58595B',
  TEXT_DARK = '#B9BAD4',
  TEXT_LIGHT = '#B9B9B9',
  TEXT_LIGHT_RGBA = '185,185,185',
  TEXT_LIGHTER = '#FAFAFA',
  TEXT_LIGHTEST = '#FFFFFF',
  TEXT_BRAND = '#0591CE',
  ERROR = '#FD6A6A',
  SHADOW_DARKEST_TRANSPARENT = 'rgba(0, 0, 0, 0.5)',
  SHADOW_DARK_TRANSPARENT = 'rgba(0, 0, 0, 0.1)',
  PARKING_PLACE_FREE = '#7ED321',
  PARKING_PLACE_OCCUPIED = '#C00',
  PARKING_PLACE_UNKNOWN = '#CCC',
  DETECTION_BACKGROUND_FREE = '#17BF95',
  DETECTION_BACKGROUND_OCCUPIED = '#CB5C6A',
  DETECTION_BACKGROUND_UNKNOWN = '#95A7B9',
  PRIMARY_TRANSPARENT = 'rgba(5,145,206, 0.6)',
}

export default Color;
