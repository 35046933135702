import {
  ArcElement,
  Chart,
  DoughnutController,
  Legend,
  Tooltip,
} from 'chart.js';
import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  StyledFree,
  StyledLabel,
  StyledLabelTotal,
  StyledOccupied,
  StyledTotal,
  StyledWrapper,
} from 'src/components/Rotaries/StyledSummary';
import Card from 'src/components/Shared/Card';
import Line from 'src/components/Shared/Line';
import ParkingDetectionStatus from 'src/constants/Parking/ParkingDetectionStatus';
import Color from 'src/constants/Shared/Color';
import type Lot from 'src/models/Parking/Lot/Lot';
import formatDates from 'src/utils/formatDates';
import styled, { css } from 'styled-components';

Chart.register(DoughnutController, ArcElement, Legend, Tooltip);

interface ParkingSummaryProps {
  lot: Lot | undefined;
}

export default function ParkingSummary(props: ParkingSummaryProps) {
  const { lot } = props;

  const disabled = useMemo(() => {
    if (!lot) return undefined;
    return lot?.detectionStatus !== ParkingDetectionStatus.ONLINE;
  }, [lot]);

  const free = useMemo(() => {
    if (!lot) return undefined;
    const { spotCount, occupiedCount } = lot;
    return spotCount - occupiedCount;
  }, [lot]);

  const summaryInfo = {
    labels: ['Occupied', 'Free'],
    datasets: [
      {
        data: [lot?.occupiedCount, free],
        backgroundColor: [
          Color.PARKING_PLACE_OCCUPIED,
          Color.PARKING_PLACE_FREE,
        ],
      },
    ],
  };

  const summaryOptions = {
    responsive: true,
    centerPercentage: !lot
      ? undefined
      : `${Math.round((lot.occupiedCount / lot.spotCount) * 100)}%`,
    centerText: 'Occupancy',
    cutout: 70,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const summaryPlugin = useMemo(
    () => ({
      id: 'parkingSummary',
      afterDraw(chart: any) {
        const { width } = chart;
        const { height } = chart;
        const { ctx } = chart;
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em Montserrat`;
        ctx.textBaseline = 'alphabetic';
        const text = chart.options.centerPercentage;
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.font = '12px Montserrat';
        ctx.textBaseline = 'middle';
        const text2 = chart.options.centerText;
        const text2X = Math.round((width - ctx.measureText(text2).width) / 2);
        const text2Y = height / 2 + 15;
        ctx.fillText(text2, text2X, text2Y);
        ctx.save();
      },
    }),
    []
  );

  return (
    <Card>
      {disabled && (
        <StyledUpdateInfo>
          {lot
            ? `Last updated: ${formatDates(lot.updatedAt, true)}`
            : undefined}
        </StyledUpdateInfo>
      )}
      <StyledInfoContainer disabled={disabled}>
        <StyledDoughnutContainer>
          <Doughnut
            data={summaryInfo}
            options={summaryOptions}
            plugins={[summaryPlugin]}
          />
        </StyledDoughnutContainer>
        <StyledWrapper>
          <StyledInfo>
            <StyledLabel>Free</StyledLabel> <StyledFree>{free}</StyledFree>
          </StyledInfo>
          <StyledInfo>
            <StyledLabel>Occupied</StyledLabel>{' '}
            <StyledOccupied>{lot?.occupiedCount}</StyledOccupied>
          </StyledInfo>
          <Line customWidth='60%' />
          <StyledInfo>
            <StyledLabelTotal>Total</StyledLabelTotal>{' '}
            <StyledTotal>{lot?.spotCount}</StyledTotal>
          </StyledInfo>
        </StyledWrapper>
      </StyledInfoContainer>
    </Card>
  );
}

const StyledInfoContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;

  @media (max-width: 850px) {
    flex-direction: column;
  }

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        opacity: 0.3;
      `;
    }
    return css``;
  }}
`;

const StyledDoughnutContainer = styled.div`
  max-width: 450px;
  width: 100%;
  margin: 20px auto;
`;

const StyledInfo = styled.div`
  display: inline-flex;
  justify-content: space-between;
  margin: 0 5%;
  width: 50%;
`;

const StyledUpdateInfo = styled.div`
  display: flex;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${Color.TEXT_DARKER};
  padding: 20px 0 10px 0;
`;
