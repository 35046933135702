import { useCallback } from 'react';
import toKeyInvitationStatistics from 'src/mappers/Statistics/toKeyInvitationStatistics';
import toKeyStatisticsQueryParams from 'src/mappers/Statistics/toKeyStatisticsQueryParams';
import toLotEntryStatistics from 'src/mappers/Statistics/toLotEntryStatistics';
import toLotEntryStatisticsQueryParams from 'src/mappers/Statistics/toLotEntryStatisticsQueryParams';
import toLotWhitelistStatistics from 'src/mappers/Statistics/toLotWhitelistStatistics';
import toLotWhitelistStatisticsQueryParams from 'src/mappers/Statistics/toLotWhitelistStatisticsQueryParams';
import toOperationKeyStatistics from 'src/mappers/Statistics/toOperationKeyStatistics';
import toRemoteControlStatistics from 'src/mappers/Statistics/toRemoteControlStatistics';
import toRemoteControlStatisticsQueryParams from 'src/mappers/Statistics/toRemoteControlStatisticsQueryParams';
import toWeblinkStatistics from 'src/mappers/Statistics/toWeblinkStatistics';
import type KeyStatisticsParams from 'src/models/Statistics/Key/KeysStatisticsParams';
import type KeyStatisticsQueryParams from 'src/models/Statistics/Key/KeysStatisticsQueryParams';
import type KeyInvitationStatistics from 'src/models/Statistics/KeyInvitation/KeyInvitationStatistics';
import type KeyInvitationStatisticsResponse from 'src/models/Statistics/KeyInvitation/KeyInvitationStatisticsResponse';
import type LotEntryStatistics from 'src/models/Statistics/LotEntry/LotEntryStatistics';
import type LotEntryStatisticsParams from 'src/models/Statistics/LotEntry/LotEntryStatisticsParams';
import type LotEntryStatisticsQueryParams from 'src/models/Statistics/LotEntry/LotEntryStatisticsQueryParams';
import type LotEntryStatisticsResponse from 'src/models/Statistics/LotEntry/LotEntryStatisticsResponse';
import type LotWhitelistStatistics from 'src/models/Statistics/LotWhitelist/LotWhitelistStatistics';
import type LotWhitelistStatisticsParams from 'src/models/Statistics/LotWhitelist/LotWhitelistStatisticsParams';
import type LotWhitelistStatisticsQueryParams from 'src/models/Statistics/LotWhitelist/LotWhitelistStatisticsQueryParams';
import type LotWhitelistStatisticsResponse from 'src/models/Statistics/LotWhitelist/LotWhitelistStatisticsResponse';
import type OperationKeyStatistics from 'src/models/Statistics/OperationKey/OperationKeyStatistics';
import type OperationKeyStatisticsResponse from 'src/models/Statistics/OperationKey/OperationKeyStatisticsResponse';
import type RemoteControlStatistics from 'src/models/Statistics/RemoteControl/RemoteControlStatistics';
import type RemoteControlStatisticsParams from 'src/models/Statistics/RemoteControl/RemoteControlStatisticsParams';
import type RemoteControlStatisticsQueryParams from 'src/models/Statistics/RemoteControl/RemoteControlStatisticsQueryParams';
import type RemoteControlStatisticsResponse from 'src/models/Statistics/RemoteControl/RemoteControlStatisticsResponse';
import type WeblinkStatistics from 'src/models/Statistics/Weblink/WeblinkStatistics';
import type WeblinkStatisticsResponse from 'src/models/Statistics/Weblink/WeblinkStatisticsResponse';
import useApiService from '../Shared/useApiService';

const statisticsReportsURL = '/statistics';

export interface StatisticsService {
  remoteControlStatistics: (
    values: RemoteControlStatisticsParams,
    productId?: number
  ) => Promise<RemoteControlStatistics[]>;
  operationKeysStatistics: (
    values: KeyStatisticsParams
  ) => Promise<OperationKeyStatistics>;
  keyInvitationsStatistics: (
    values: KeyStatisticsParams
  ) => Promise<KeyInvitationStatistics>;
  weblinksStatistics: (
    values: KeyStatisticsParams
  ) => Promise<WeblinkStatistics>;
  lotWhitelistStatistics: (
    values: LotWhitelistStatisticsParams
  ) => Promise<LotWhitelistStatistics>;
  lotEntriesStatistics: (
    values: LotEntryStatisticsParams
  ) => Promise<LotEntryStatistics[]>;
}

export default function useStatisticsService(): StatisticsService {
  const { get } = useApiService();

  const remoteControlStatistics = useCallback(
    async (values: RemoteControlStatisticsParams, productId?: number) => {
      const query = toRemoteControlStatisticsQueryParams(values, productId);
      const response = await get<
        RemoteControlStatisticsResponse[],
        RemoteControlStatisticsQueryParams
      >(`${statisticsReportsURL}/remote-control`, query);

      const data = response.data.map(toRemoteControlStatistics);

      return data;
    },
    [get]
  );

  const operationKeysStatistics = useCallback(
    async (values: KeyStatisticsParams) => {
      const query = toKeyStatisticsQueryParams(values);
      const response = await get<
        OperationKeyStatisticsResponse,
        KeyStatisticsQueryParams
      >(`${statisticsReportsURL}/operation-keys`, query);

      const data = toOperationKeyStatistics(response.data);

      return data;
    },
    [get]
  );

  const keyInvitationsStatistics = useCallback(
    async (values: KeyStatisticsParams) => {
      const query = toKeyStatisticsQueryParams(values);
      const response = await get<
        KeyInvitationStatisticsResponse,
        KeyStatisticsQueryParams
      >(`${statisticsReportsURL}/key-invitations`, query);

      const data = toKeyInvitationStatistics(response.data);

      return data;
    },
    [get]
  );

  const weblinksStatistics = useCallback(
    async (values: KeyStatisticsParams) => {
      const query = toKeyStatisticsQueryParams(values);
      const response = await get<
        WeblinkStatisticsResponse,
        KeyStatisticsQueryParams
      >(`${statisticsReportsURL}/weblinks`, query);

      const data = toWeblinkStatistics(response.data);

      return data;
    },
    [get]
  );

  const lotWhitelistStatistics = useCallback(
    async (values: LotWhitelistStatisticsParams) => {
      const query = toLotWhitelistStatisticsQueryParams(values);
      const response = await get<
        LotWhitelistStatisticsResponse,
        LotWhitelistStatisticsQueryParams
      >(`${statisticsReportsURL}/lot-whitelists`, query);

      const data = toLotWhitelistStatistics(response.data);

      return data;
    },
    [get]
  );

  const lotEntriesStatistics = useCallback(
    async (values: LotEntryStatisticsParams) => {
      const query = toLotEntryStatisticsQueryParams(values);
      const response = await get<
        LotEntryStatisticsResponse[],
        LotEntryStatisticsQueryParams
      >(`${statisticsReportsURL}/lot-entries`, query);

      const data = response.data.map(toLotEntryStatistics);

      return data;
    },
    [get]
  );

  return {
    remoteControlStatistics,
    operationKeysStatistics,
    keyInvitationsStatistics,
    weblinksStatistics,
    lotWhitelistStatistics,
    lotEntriesStatistics,
  };
}
