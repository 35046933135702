import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import NoImgPic from 'src/assets/svg/noImgPic.svg';
import Color from 'src/constants/Shared/Color';
import LotEntryDirections from 'src/constants/Shared/LotEntryDirections';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import type LotEntry from 'src/models/Parking/Anpr/LotEntries/LotEntry';
import type LicensePlateFormValues from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateFormValues';
import { StyledEntranceDiv } from 'src/screens/Parking/AnprList';
import useLotEntriesService from 'src/services/Lpr/useLotEntriesService';
import formatDates from 'src/utils/formatDates';
import getTimeFromMiliseconds from 'src/utils/getTimeFromMiliseconds';
import styled, { css } from 'styled-components';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Spinner from '../Shared/Spinner';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';
import DetailsRow from './DetailsRow';

interface EditLicensePlateFormProps {
  id: number | undefined;
  closeParentModal: () => void;
}

const UKNOWN = 'Unknown';

export default function EditLicensePlateForm(props: EditLicensePlateFormProps) {
  const { id, closeParentModal } = props;
  const { dirty } = useFormikContext<LicensePlateFormValues>();
  const { getLotEntryById } = useLotEntriesService();
  const isMounted = useIsMounted();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<LotEntry>();

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  useEffect(() => {
    const getOneLotEntry = async () => {
      try {
        if (!id) {
          return;
        }

        if (isMounted()) {
          setIsDataLoading(true);
        }

        const data = await getLotEntryById(id);

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };
    getOneLotEntry();
  }, [getLotEntryById, isMounted, id]);

  const shouldRenderDetails = !isDataLoading && data;

  const getEntranceInfo:
    | {
        color: Color;
        in: string;
        out: string | undefined;
        duration: number | null;
      }
    | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }
    const { scanTime, direction, lprCamera, relatedLotEntry, duration } = data;
    const { name } = lprCamera;

    if (direction === LotEntryDirections.OUT) {
      if (relatedLotEntry) {
        const { lprCamera, scanTime: relatedScanTime } = relatedLotEntry;
        return {
          color: Color.TEXT_DARKER,
          in: `${lprCamera?.name} - ${formatDates(relatedScanTime)}`,
          out: `${name} - ${formatDates(scanTime)}`,
          duration,
        };
      }
      return {
        color: Color.ERROR,
        in: UKNOWN,
        out: `${name} - ${formatDates(scanTime)}`,
        duration,
      };
    }

    if (relatedLotEntry) {
      const { lprCamera, scanTime: relatedScanTime } = relatedLotEntry;
      return {
        color: Color.PRIMARY_BRAND,
        in: `${name} - ${formatDates(scanTime)}`,
        out: `${lprCamera?.name} - ${formatDates(relatedScanTime)}`,
        duration,
      };
    }

    return {
      color: Color.PRIMARY_BRAND,
      in: `${name} - ${formatDates(scanTime)}`,
      out: undefined,
      duration: (new Date().getTime() - new Date(scanTime).getTime()) / 1000, // duration is in the seconds
    };
  }, [data]);

  const getDurationTime = useMemo(() => {
    if (!getEntranceInfo || !getEntranceInfo.duration) {
      return UKNOWN;
    }

    // duration is in the seconds
    const { days, hours, minutes } = getTimeFromMiliseconds(
      getEntranceInfo.duration * 1000
    );

    return `${days}d ${hours}h ${minutes}m`;
  }, [getEntranceInfo]);

  return (
    <>
      <Title>License plate details</Title>
      {isDataLoading && <Spinner primary />}
      {shouldRenderDetails && data && (
        <>
          <Card cameraModalView>
            <StyledEntranceDiv backgroundColor={getEntranceInfo?.color} />
            <StyledTimeDiv>
              {LotEntryDirections.IN.toUpperCase()}:{' '}
              <StyledSpan error={getEntranceInfo?.color === Color.ERROR}>
                {getEntranceInfo?.in}
              </StyledSpan>
            </StyledTimeDiv>
            <StyledTimeDiv hide={getEntranceInfo?.out === undefined}>
              {LotEntryDirections.OUT.toUpperCase()}: {getEntranceInfo?.out}
            </StyledTimeDiv>
            <StyledDuration>{getDurationTime}</StyledDuration>
          </Card>
          <DetailsRow wider changeFlexDirection>
            <StyledDiv>
              <TextField
                label='License Plate'
                placeholder={data?.licensePlate}
                type='text'
                name='licensePlate'
                stacked
                customMargin
              />
              <StyledImg
                src={data.licensePlateImageUrl || NoImgPic}
                alt={data.licensePlate}
              />
            </StyledDiv>
            <StyledLargeImg
              src={data.fullImageUrl || NoImgPic}
              alt={data.licensePlate}
            />
          </DetailsRow>
        </>
      )}
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
        >
          Cancel
        </Button>
        <Button size={ButtonSize.LARGE} type='submit' primary>
          Change
        </Button>
      </Control>
    </>
  );
}

const StyledDiv = styled.div`
  display: block;
  width: 30%;

  @media (max-width: 600px) {
    width: 60%;
  }
`;

const StyledImg = styled.img`
  width: 60%;
  margin-right: 20px;
  margin-top: 15px;
`;

const StyledLargeImg = styled.img`
  width: 70%;
  padding: 15px 0 30px 20px;

  @media (max-width: 600px) {
    width: 100%;
    padding: 15px 0 30px 0px;
  }
`;

const StyledTimeDiv = styled.div<{ hide?: boolean }>`
  margin: 20px 0 20px 10px;
  color: ${Color.TEXT_DARKER};
  font-family: Open Sans;
  font-size: 14px;
  width: 40%;
  align-self: center;

  ${(props) => {
    const { hide } = props;

    if (hide) {
      return css`
        visibility: hidden;
      `;
    }
    return css``;
  }}
`;

const StyledDuration = styled.div`
  margin: 20px 0 20px 10px;
  color: ${Color.TEXT_DARKER};
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 650;
  width: 18%;
  align-self: center;
`;

const StyledSpan = styled.span<{ error: boolean }>`
  ${(props) => {
    const { error } = props;

    if (error) {
      return css`
        color: ${Color.ERROR};
      `;
    }
    return css``;
  }}
`;
