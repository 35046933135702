import styled, { css } from 'styled-components';

const InlineDiv = styled.div<{ tariffView?: boolean; customView?: boolean }>`
  display: inline-flex;
  align-items: flex-end;

  ${(props) => {
    const { tariffView, customView } = props;

    if (tariffView) {
      return css``;
    }

    if (customView) {
      return css`
        @media (max-width: 550px) {
          flex-direction: column;
        }

        @media (max-width: 500px) {
          flex-direction: row;
        }
      `;
    }

    return css`
      @media (max-width: 600px) {
        flex-direction: column;
      }
    `;
  }}
`;

export default InlineDiv;
