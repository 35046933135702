import type Chain from '../../../models/Parking/Chain/Chain';
import type ChainResponse from '../../../models/Parking/Chain/ChainResponse';
import toConcreteProduct from '../Product/toConcreteProduct';

export default function toChain(data: ChainResponse): Chain {
  const { id, product, state, permanent_position } = data;

  return {
    id,
    product: toConcreteProduct(product),
    state,
    permanentPosition: permanent_position,
  };
}
