import React, { useCallback } from 'react';
import AddIcon from 'src/components/Shared/AddIcon';
import type DetectCamera from 'src/models/Parking/DetectCamera/DetectCamera';
import Spinner from '../../Shared/Spinner';
import { CardWrapper } from '../Cards/CardStyle';
import DetectCameraCardList from '../Cards/DetectCameraCardList';

interface DetectCameraCardsProps {
  data: DetectCamera[];
  openAddNewModal: () => void;
  openEditModal: (id: number) => void;
  isLoading?: boolean;
}

export default function DetectCameraCards(props: DetectCameraCardsProps) {
  const { data, openEditModal, isLoading, openAddNewModal } = props;

  const editItem = useCallback(
    (id?: number) => {
      if (!id) {
        return;
      }

      openEditModal(id);
    },
    [openEditModal]
  );

  return (
    <>
      {isLoading ? (
        <Spinner primary />
      ) : (
        <DetectCameraCardList>
          {data.map((place) => {
            const { id, name, updatedAt } = place;

            return (
              <DetectCameraCardList.Card
                key={id}
                id={id}
                name={name}
                updatedAt={updatedAt}
                onNameClick={editItem}
              />
            );
          })}
          <CardWrapper onClick={openAddNewModal}>
            <AddIcon className='pa-plus-round' />
          </CardWrapper>
        </DetectCameraCardList>
      )}
    </>
  );
}
