import { useCallback } from 'react';
import toBrain from 'src/mappers/Parking/Brain/toBrain';
import toBrainQuery from 'src/mappers/Parking/Brain/toBrainQuery';
import toBrainUpdateParams from 'src/mappers/Parking/Brain/toBrainUpdateParams';
import toMeta from 'src/mappers/Shared/toMeta';
import type Brain from 'src/models/Parking/Brain/Brain';
import type BrainFormValues from 'src/models/Parking/Brain/BrainFormValues';
import type BrainQuery from 'src/models/Parking/Brain/BrainQuery';
import type BrainQueryParams from 'src/models/Parking/Brain/BrainQueryParams';
import type BrainRemoveResponse from 'src/models/Parking/Brain/BrainRemoveResponse';
import type BrainResponse from 'src/models/Parking/Brain/BrainResponse';
import type BrainUpdateParams from 'src/models/Parking/Brain/BrainUpdateParams';
import type BrainUpdateResponse from 'src/models/Parking/Brain/BrainUpdateResponse';
import type GetAllDetails from 'src/models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const brainBaseUrl = '/brains';

interface BrainService {
  findAllBrains: (
    queryParams: BrainQueryParams
  ) => Promise<GetAllDetails<Brain>>;
  findOneBrain: (id: number) => Promise<Brain>;
  updateBrain: (
    id: number,
    values: BrainFormValues
  ) => Promise<BrainUpdateResponse>;
  removeBrain: (id: number) => Promise<BrainRemoveResponse>;
}
export default function useBrainService(): BrainService {
  const { get, patch, remove } = useApiService();

  const findAllBrains = useCallback(
    async (queryParams: BrainQueryParams) => {
      const query = toBrainQuery(queryParams);
      const response = await get<BrainResponse[], BrainQuery>(
        `${brainBaseUrl}`,
        query
      );

      const data = response.data.map(toBrain);
      const meta = toMeta(response.meta);
      return { data, meta };
    },
    [get]
  );

  const findOneBrain = useCallback(
    async (id: number) => {
      const response = await get<BrainResponse>(`${brainBaseUrl}/${id}`);
      const data = toBrain(response.data);
      return data;
    },
    [get]
  );

  const updateBrain = useCallback(
    async (id: number, values: BrainFormValues) => {
      const params = toBrainUpdateParams(values);
      const response = await patch<BrainUpdateParams, BrainUpdateResponse>(
        `${brainBaseUrl}/${id}`,
        params
      );
      return response;
    },
    [patch]
  );

  const removeBrain = useCallback(
    async (id: number) => {
      const response = await remove<BrainRemoveResponse>(
        `${brainBaseUrl}/${id}`
      );
      return response;
    },
    [remove]
  );
  return { findAllBrains, findOneBrain, updateBrain, removeBrain };
}
