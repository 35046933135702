enum AllProductTypes {
  GATEWAY = 'Gateway',
  LEGACY_BARRIER = 'Legacy barrier',
  BARRIER = 'Barrier',
  GATE = 'Gate',
  BRAIN = 'Brain',
  CHAIN = 'Chain',
  BOLLARD = 'Bollard',
  LPR = 'LPR',
}

export default AllProductTypes;
