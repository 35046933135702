import React, { useEffect, useState } from 'react';
import RotaryTable from 'src/components/Rotaries/RotaryTable';
import Content from 'src/components/Shared/Content';
import Main from 'src/components/Shared/Main';
import StyledHeader from 'src/components/Shared/StyledHeader';
import Title from 'src/components/Shared/Title';
import type Rotary from 'src/models/Rotaries/Rotary';
import useRotaryService from 'src/services/Rotaries/useRotaryService';
import useIsMounted from '../../hooks/Shared/useIsMounted';

export default function RotaryList() {
  const [data, setData] = useState<Rotary[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { findAllRotaries } = useRotaryService();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data } = await findAllRotaries();

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }

        throw error;
      }
    };

    getData();
  }, [findAllRotaries, isMounted]);

  return (
    <Main left>
      <Content>
        <StyledHeader>
          <Title addTopMargin>Rotaries</Title>
        </StyledHeader>
        <RotaryTable isLoading={isDataLoading} data={data} />
      </Content>
    </Main>
  );
}
