import type { FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import type DetectGateway from 'src/models/Parking/DetectGateway/DetectGateway';
import type DetectGatewayFormValues from 'src/models/Parking/DetectGateway/DetectGatewayFormValues';
import useDetectGatewayService from 'src/services/Parking/useDetectGatewayService';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import { isNotString } from '../../../utils/checks';
import ExitButton from '../../Shared/Buttons/ExitButton';
import Form from '../../Shared/Form';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import DetectGatewayAddForm from '../Detection/DetectGatewayAddForm';

interface AddNewDetectGatewayModalProps {
  closeParentModal: () => void;
  onAddNewDetectGateway: (data: DetectGateway) => void;
  lotId: number | undefined;
}

const initialValues: DetectGatewayFormValues = {
  name: '',
};

const validationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
});

export default function AddNewDetectGatewayModal(
  props: AddNewDetectGatewayModalProps
) {
  const { closeParentModal, onAddNewDetectGateway, lotId } = props;
  const { createDetectGateway } = useDetectGatewayService();
  const isMounted = useIsMounted();

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: 'Successfuly added new Detect Gateway!',
  });

  const onSubmit = useCallback(
    async (
      values: DetectGatewayFormValues,
      { setErrors }: FormikHelpers<DetectGatewayFormValues>
    ) => {
      try {
        if (!lotId) return;

        const response = await createDetectGateway(values, lotId);

        if (isMounted()) {
          openGlobalSuccessModal();
          onAddNewDetectGateway(response);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      lotId,
      onAddNewDetectGateway,
      openGlobalFailureModal,
      setMessage,
      openGlobalSuccessModal,
      isMounted,
      createDetectGateway,
    ]
  );

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Form
        name='addDetectGateway'
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <DetectGatewayAddForm closeParentModal={closeParentModal} />
      </Form>
    </>
  );
}
