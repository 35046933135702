import React from 'react';
import styled from 'styled-components';

interface RadioButtonProps {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  name?: string;
  readonly?: boolean;
  disabled?: boolean;
  value: string | number;
  defaultChecked?: boolean;
}

export default function RadioButton(props: RadioButtonProps) {
  const {
    onChange,
    onClick,
    checked,
    name,
    readonly,
    disabled,
    value,
    defaultChecked,
  } = props;

  return (
    <StyledContainer>
      <StyledInput
        type='radio'
        onChange={onChange}
        onClick={onClick}
        checked={checked}
        name={name}
        readOnly={readonly}
        disabled={disabled}
        value={value}
        defaultChecked={defaultChecked}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
`;

const StyledInput = styled.input`
  position: relative;
  cursor: pointer;
  height: 20px;
  width: 20px;
`;
