import { useFormikContext } from 'formik';
import React from 'react';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import ExitButton from '../Shared/Buttons/ExitButton';
import Control from '../Shared/Control';
import Description from '../Shared/Description';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Section from '../Shared/Section';
import StyledModalIcon from '../Shared/StyledModalIcon';
import TextField from '../Shared/TextField';

interface DeleteModalProps {
  closeParentModal: () => void;
  type: string;
  name: string | undefined;
}

export default function DeleteModal(props: DeleteModalProps) {
  const { closeParentModal, type, name } = props;
  const { dirty } = useFormikContext();

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });
  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Section>
        <StyledModalIcon className='pa-delete' size='80px' />
        <Description deleteView>
          If you are sure you want to delete the <b>{name}</b>, enter {type}{' '}
          name
        </Description>
        <TextField
          label=''
          placeholder={`Enter ${capitalizeFirstLetter(type)} name`}
          type='text'
          name='name'
          short
          deleteView
        />
        <Description deleteView>and proceed...</Description>
      </Section>
      <Line addMargin />
      <Control>
        <Button size={ButtonSize.LARGE} type='submit' primary>
          Yes, Delete {capitalizeFirstLetter(type)}
        </Button>
        <Button
          size={ButtonSize.LARGE}
          type='button'
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
        >
          No, Cancel
        </Button>
      </Control>
    </>
  );
}
