import { useCallback } from 'react';
import toLicenseFormValues from 'src/mappers/License/toLicenseFormValues';
import toLicensePricingUpdatedValues from 'src/mappers/License/toLicensePricingUpdatedValues';
import toLicensePricingUpdateParams from 'src/mappers/License/toLicensePricingUpdateParams';
import toLicenseUpdateParams from 'src/mappers/License/toLicenseUpdateParams';
import type ContactSupportFormValues from 'src/models/License/ContactSupportFormValues';
import type LicenseFormValues from 'src/models/License/LicenseFormValues';
import type LicensePricing from 'src/models/License/LicensePricing';
import type LicensePricingQueryParams from 'src/models/License/LicensePricingQueryParams';
import type LicensePurchaseCreateFormValues from 'src/models/License/LicensePurchaseCreateFormValues';
import type LicensePurchaseFormValues from 'src/models/License/LicensePurchaseFormValues';
import type LicensePurchaseQueryParams from 'src/models/License/LicensePurchaseQueryParams';
import type LicenseUpdateValues from 'src/models/License/LicenseUpdateValues';
import type Counter from 'src/models/Shared/Counter';
import useLicensePricingService from './useLicensePricingService';
import useLicensePurchaseService from './useLicensePurchaseService';
import useLicenseService from './useLicenseService';

export default function useLicenseManagementService() {
  const { addLicense, updateLicenseById, getLicenseById } = useLicenseService();
  const {
    addLicensePricing,
    updateLicensePricingById,
    getLicensePricingById,
    listAllLicensePricings,
    removeLicensePricing,
  } = useLicensePricingService();
  const {
    addLicensePurchase,
    updateLicensePurchase,
    listAllLicensePurchasesByAccountId,
    listAllLicensePurchases,
    contactSupport,
  } = useLicensePurchaseService();

  const addLicenseManagement = useCallback(
    async (values: LicenseFormValues) => {
      const response = await addLicense(values);
      const { id } = response;
      await addLicensePricing(values, id);
    },
    [addLicense, addLicensePricing]
  );

  const addLicensePurchaseManagement = useCallback(
    async (data: LicensePurchaseCreateFormValues, accountId: number) => {
      const requestValues = data.licenseInfo.filter(
        (License) => License.counter !== 0
      );
      return await addLicensePurchase(
        { licenseInfo: requestValues },
        accountId
      );
    },
    [addLicensePurchase]
  );

  const getLicenseManagementById = useCallback(
    async (licensePricingId: number, licenseId: number) => {
      const licenseResponse = await getLicenseById(licenseId);
      const licensePricingResponse = await getLicensePricingById(
        licensePricingId
      );

      const resposne = toLicenseFormValues(
        licenseResponse,
        licensePricingResponse
      );

      return resposne;
    },
    [getLicenseById, getLicensePricingById]
  );

  const updateLicenseManagement = useCallback(
    async (
      formValues: LicenseFormValues,
      values: LicenseUpdateValues,
      licensePricingId: number,
      licenseId: number
    ) => {
      const licenseValues = toLicenseUpdateParams(values);
      const licensePricingValues = toLicensePricingUpdateParams(values);

      const newLicenseValues =
        Object.values(licenseValues).filter((value) => value !== undefined)
          .length > 0;

      const newLicensePricingValues =
        Object.values(licensePricingValues).filter(
          (value) => value !== undefined
        ).length > 0;

      if (newLicenseValues) {
        await updateLicenseById(licenseId, values);
      }

      if (newLicensePricingValues) {
        await updateLicensePricingById(licensePricingId, values);
      }

      const updatedValues = toLicensePricingUpdatedValues(
        formValues,
        licenseId,
        licensePricingId
      );

      return updatedValues;
    },
    [updateLicenseById, updateLicensePricingById]
  );
  const purchaseLicenseManagement = useCallback(
    async (values: LicensePurchaseFormValues, accountId: number) => {
      const addNewLicense = values.licenseInfo.filter(
        (value) => value.purchaseId === 0
      );

      const updateLicense = values.licenseInfo.filter(
        (value) => value.purchaseId !== 0
      );

      if (updateLicense.length > 0) {
        await updateLicensePurchase({ licenseInfo: updateLicense }, accountId);
      }

      if (addNewLicense.length > 0) {
        await addLicensePurchase({ licenseInfo: addNewLicense }, accountId);
      }
    },
    [updateLicensePurchase, addLicensePurchase]
  );

  const listAllLicensePricingsManagement = useCallback(
    async (values: LicensePricingQueryParams) => {
      const { data, meta } = await listAllLicensePricings(values);
      const counterArray: Counter[] = [];
      data.forEach((license: LicensePricing) =>
        counterArray.push({
          pricingId: license.id,
          purchaseId: 0,
          counter: 0,
        })
      );
      return { data, meta, counterArray };
    },
    [listAllLicensePricings]
  );

  const listAllLicensePurchasesByAccountIdManagement = useCallback(
    async (
      pricingParams: LicensePricingQueryParams,
      purchaseParams: LicensePurchaseQueryParams
    ) => {
      const { data: pricingData } = await listAllLicensePricings(pricingParams);

      const { data: purchaseData } = await listAllLicensePurchasesByAccountId(
        purchaseParams
      );
      const counterArray: Counter[] = [];
      pricingData.forEach((license) => {
        const findPurchase = purchaseData.find(
          (d) => d.licensePricing.id === license.id
        );
        if (!findPurchase && !license.status) return undefined;
        return counterArray.push({
          pricingId: license.id,
          purchaseId: findPurchase ? findPurchase.id : 0,
          counter: findPurchase ? findPurchase.purchasedCount : 0,
        });
      });
      return { pricingData, purchaseData, counterArray };
    },
    [listAllLicensePricings, listAllLicensePurchasesByAccountId]
  );

  const listAllLicensePurchasesManagement = useCallback(
    async (values: LicensePurchaseQueryParams) =>
      await listAllLicensePurchases(values),
    [listAllLicensePurchases]
  );

  const contactSupportManagement = useCallback(
    async (values: ContactSupportFormValues) => await contactSupport(values),
    [contactSupport]
  );

  const removeLicensePricingManagement = useCallback(
    async (id: number) => await removeLicensePricing(id),
    [removeLicensePricing]
  );

  return {
    addLicenseManagement,
    getLicenseManagementById,
    updateLicenseManagement,
    purchaseLicenseManagement,
    listAllLicensePricingsManagement,
    addLicensePurchaseManagement,
    listAllLicensePurchasesByAccountIdManagement,
    listAllLicensePurchasesManagement,
    contactSupportManagement,
    removeLicensePricingManagement,
  };
}
