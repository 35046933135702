import { PlanLabels } from 'src/constants/Parking/LprImageStorageSubscriptionPlans';
import type LprImageStorageSubscriptionStatuses from 'src/constants/Parking/LprImageStorageSubscriptionStatuses';
import type LprImageStorageSubscriptionCreateFormValues from 'src/models/Parking/Anpr/ImageStorageSubscription/LprImageStorageSubscriptionCreateFormValues';
import type LprImageStorageSubscriptionCreateRequest from 'src/models/Parking/Anpr/ImageStorageSubscription/LprImageStorageSubscriptionCreateRequest';

export default function toLprImageStorageSubscriptionCreateRequest(
  data: LprImageStorageSubscriptionCreateFormValues,
  lprCameraId: number,
  status: LprImageStorageSubscriptionStatuses
): LprImageStorageSubscriptionCreateRequest {
  const { subscriptionPlan } = data;

  return {
    subscription_plan: PlanLabels[subscriptionPlan?.key || 0],
    lpr_camera_id: lprCameraId,
    status,
  };
}
