import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import LprCameraStatus from 'src/constants/Parking/LprCameraStatus';
import useCameraStatusLabel from 'src/hooks/Parking/useCameraStatusLabel';
import type CameraUpdateFormValues from 'src/models/Parking/Anpr/LprCamera/CameraUpdateFormValues';
import type LprCamera from 'src/models/Parking/Anpr/LprCamera/LprCamera';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import DeleteButton from '../Shared/Buttons/DeleteButton';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Spinner from '../Shared/Spinner';
import TextField from '../Shared/TextField';
import DetailsColumn from './DetailsColumn';
import DetailsData from './DetailsData';
import DetailsRow from './DetailsRow';
import DetailsStatus from './DetailsStatus';

interface CameraUpdateFormProps {
  details?: LprCamera;
  openRemoveModal: (id: number) => void;
  isLoading?: boolean;
  closeParentModal: () => void;
}

export default function CameraUpdateForm(props: CameraUpdateFormProps) {
  const { details, isLoading, openRemoveModal, closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext<CameraUpdateFormValues>();

  const onRemove = useCallback(() => {
    if (!details?.id) {
      return;
    }

    openRemoveModal(details.id);
  }, [openRemoveModal, details]);

  const shouldRenderDetails = !isLoading && details;

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });
  const statusLabel = useCameraStatusLabel(details?.status);

  const offline = details?.status === LprCameraStatus.OFFLINE;

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='ANPR Name'
              placeholder='Name'
              type='text'
              name='name'
              stacked
              short
            />
            <DetailsStatus offline={offline} addVerticalMargin>
              {statusLabel}
            </DetailsStatus>
            <Line />
            <DetailsData label='DEVICE ID' value={details.deviceId} />
            <Line />
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        <DeleteButton onClick={onRemove} />
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
