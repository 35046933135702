import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import Button from 'src/components/Shared/Button';
import DeleteButton from 'src/components/Shared/Buttons/DeleteButton';
import Control from 'src/components/Shared/Control';
import Line from 'src/components/Shared/Line';
import { useGlobalCancelModal } from 'src/components/Shared/Modals/GlobalCancelModal';
import Spinner from 'src/components/Shared/Spinner';
import TextField from 'src/components/Shared/TextField';
import GatewayStatus from 'src/constants/Parking/GatewayStatus';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import type DetectGateway from 'src/models/Parking/DetectGateway/DetectGateway';
import type DetectGatewayFormValues from 'src/models/Parking/DetectGateway/DetectGatewayFormValues';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import formatDates from 'src/utils/formatDates';
import DetailsColumn from '../DetailsColumn';
import DetailsData from '../DetailsData';
import DetailsRow from '../DetailsRow';

interface DetectGatewayUpdateFormProps {
  closeParentModal: () => void;
  details: DetectGateway | undefined;
  openRemoveModal: (id: number) => void;
  isLoading?: boolean;
}
export default function DetectGatewayUpdateForm(
  props: DetectGatewayUpdateFormProps
) {
  const { details, isLoading, closeParentModal, openRemoveModal } = props;
  const { isSubmitting, dirty } = useFormikContext<DetectGatewayFormValues>();

  const shouldRenderDetails = !isLoading && details;

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const onRemove = useCallback(() => {
    if (!details?.id) {
      return;
    }

    openRemoveModal(details.id);
  }, [openRemoveModal, details]);

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && details && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='NAME'
              placeholder='Name'
              type='text'
              name='name'
              short
              stacked
            />
            <TextField
              label='UUID'
              placeholder='UUID'
              type='text'
              name='uuid'
              stacked
            />
            <DetailsData
              label='Status'
              value={capitalizeFirstLetter(details.status)}
              offline={details.status === GatewayStatus.OFFLINE}
              formStyle
            />
            <DetailsData
              label='Last updated'
              value={formatDates(details.updatedAt, true)}
              formStyle
            />
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        <DeleteButton onClick={onRemove} />
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
