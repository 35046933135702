import type LotEntryStatistics from 'src/models/Statistics/LotEntry/LotEntryStatistics';
import type LotEntryStatisticsResponse from 'src/models/Statistics/LotEntry/LotEntryStatisticsResponse';
import toLotEntryDirection from './toLotEntryDirection';

export default function toLotEntryStatistics(
  data: LotEntryStatisticsResponse
): LotEntryStatistics {
  const { scan_date, scan_day, directions } = data;

  return {
    scanDate: scan_date,
    scanDay: scan_day,
    directions: directions.map(toLotEntryDirection),
  };
}
