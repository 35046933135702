import React from 'react';
import useTabsContext from '../../../hooks/Shared/useTabsContext';

interface PanelProps {
  name: string;
  label: string;
  children: React.ReactNode;
}

export default function Panel(props: PanelProps) {
  const { children, name } = props;
  const { activeTab } = useTabsContext();

  if (name !== activeTab) {
    return null;
  }

  return <>{children}</>;
}
