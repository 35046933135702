import React from 'react';
import { List } from '../CardStyle';
import GatewayCard from './GatewayCard';

interface GatewayCardListProps {
  children: React.ReactNode;
}

export default function GatewayCardList(props: GatewayCardListProps) {
  const { children } = props;

  return <List>{children}</List>;
}

GatewayCardList.Card = GatewayCard;
