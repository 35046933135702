import { useFormikContext } from 'formik';
import { useCallback, useContext } from 'react';
import WizardContext from '../../contexts/Shared/WizardContext';
import { isNotEmptyObject } from '../../utils/checks';

export default function useWizardContext() {
  const {
    next: unvalidatedNext,
    back,
    breadcrumbsData,
    activeIndex,
  } = useContext(WizardContext);

  const { validateForm, setTouched } = useFormikContext();

  const next = useCallback(
    async (skip?: boolean) => {
      const validated = await validateForm();

      if (isNotEmptyObject(validated)) {
        const fields = Object.keys(validated).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        );

        setTouched(fields);

        return;
      }

      if (skip === true) {
        unvalidatedNext(skip);
        return;
      }

      unvalidatedNext();
    },
    [unvalidatedNext, validateForm, setTouched]
  );

  return {
    breadcrumbsData,
    activeIndex,
    next,
    back,
  };
}
