import LicenseStatus from 'src/constants/License/LicenseStatus';
import type LicensePricing from 'src/models/License/LicensePricing';
import type LicensePricingResponse from 'src/models/License/LicensePricingResponse';
import toLicense from './toLicense';

export default function toLicensePricing(
  data: LicensePricingResponse
): LicensePricing {
  const { id, name, status, price, currency_id, license } = data;

  return {
    id,
    name,
    status: status === LicenseStatus.AVAILABLE,
    price,
    currencyId: currency_id,
    license: license ? toLicense(license) : undefined,
  };
}
