enum LprImageStorageSubscriptionPlans {
  ONE_MONTH = '1 month',
  THREE_MONTH = '3 month',
  SIX_MONTH = '6 month',
  ONE_YEAR = '1 year',
}

const PlanLabels: Record<number, LprImageStorageSubscriptionPlans> = {
  1: LprImageStorageSubscriptionPlans.ONE_MONTH,
  3: LprImageStorageSubscriptionPlans.THREE_MONTH,
  6: LprImageStorageSubscriptionPlans.SIX_MONTH,
  12: LprImageStorageSubscriptionPlans.ONE_YEAR,
};

const PlanValues: Record<LprImageStorageSubscriptionPlans, number> = {
  '1 month': 1,
  '3 month': 3,
  '6 month': 6,
  '1 year': 12,
};

export { LprImageStorageSubscriptionPlans, PlanLabels, PlanValues };
