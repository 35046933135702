import styled from 'styled-components';
import Color from '../../constants/Shared/Color';

const StyledLink = styled.a`
  text-decoration: none;
  color: ${Color.PRIMARY_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;

export default StyledLink;
