enum SocketEvents {
  ALL_GATEWAYS_STATUS = 'all_gateways_status',
  GATEWAY_STATUS = 'gateway_status',
  LOT_CHANGE = 'lot_change',
  PRODUCT_ERROR = 'product_error',
  LPR_EVENT = 'lpr_event',
  LPR_CAMERA_STATUS = 'lpr_camera_status',
  LOT_DETECTION_CHANGE = 'lot_detection_change',
  PARKING_PLACE_CHANGE = 'parking_place_change',
  PARKING_GATEWAY_STATE_CHANGE = 'parking_gateway_state_change',
}

export default SocketEvents;
