import React from 'react';
import styled from 'styled-components';
import Card from '../Card';
import Link from './Link';

interface SideNavigationProps {
  children: React.ReactNode;
}

export default function SideNavigation(props: SideNavigationProps) {
  const { children } = props;

  return (
    <StyledWrapper>
      <Card>
        <StyledContainer>{children}</StyledContainer>
      </Card>
    </StyledWrapper>
  );
}

SideNavigation.Link = Link;

const StyledWrapper = styled.div`
  flex: 1;
  margin-right: 30px;
`;

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
