import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SendInquiryModal from 'src/components/Home/SendInquiryModal';
import LicenseInfoTable from 'src/components/Settings/LicenseInfoTable';
import PmsLicenseTable from 'src/components/Settings/PmsLicenseTable';
import Form from 'src/components/Shared/Form';
import { useGlobalConfirmationModal } from 'src/components/Shared/Modals/GlobalConfirmationModal';
import GlobalModal from 'src/components/Shared/Modals/GlobalModal';
import { useGlobalSuccessModal } from 'src/components/Shared/Modals/GlobalSuccessModal';
import PaginationSize from 'src/constants/Shared/DataSize';
import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';
import type ContactSupportFormValues from 'src/models/License/ContactSupportFormValues';
import type LicensePricing from 'src/models/License/LicensePricing';
import type LicensePurchase from 'src/models/License/LicensePurchase';
import type LicensePurchaseFormValues from 'src/models/License/LicensePurchaseFormValues';
import useLicenseManagementService from 'src/services/License/useLicenseManagementService';
import * as yup from 'yup';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import AppRole from '../../constants/Shared/AppRole';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';

const defaultValues: LicensePurchaseFormValues = {
  licenseInfo: [
    {
      pricingId: 0,
      purchaseId: 0,
      counter: 0,
    },
  ],
};

const sendInquiryInitialValues: ContactSupportFormValues = {
  lprCameras: {
    pricingId: 0,
    purchaseId: 0,
    counter: 0,
  },
  brainModules: {
    pricingId: 0,
    purchaseId: 0,
    counter: 0,
  },
  barriers: {
    pricingId: 0,
    purchaseId: 0,
    counter: 0,
  },
  message: '',
};

const sendInquiryCalidationSchema = yup.object({
  lprCameras: yup.object({ counter: yup.number().min(0) }),
  brainModules: yup.object({ counter: yup.number().min(0) }),
  barriers: yup.object({ counter: yup.number().min(0) }),
});

interface AccountLicenseListProps {
  accountId: number;
}

export default function AccountLicenseList(props: AccountLicenseListProps) {
  const { accountId } = props;
  const [data, setData] = useState<LicensePricing[]>([]);
  const [purchasedData, setPurchasedData] = useState<LicensePurchase[]>([]);
  const [initialValues, setInitialValues] =
    useState<LicensePurchaseFormValues>(defaultValues);
  const [values, setValues] = useState<LicensePurchaseFormValues>();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [inquiryResponseInfo, setInquiryResponseInfo] = useState<
    { success: boolean; message: string } | undefined
  >(undefined);

  const {
    purchaseLicenseManagement,
    listAllLicensePurchasesByAccountIdManagement,
    contactSupportManagement,
  } = useLicenseManagementService();
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PARKLIO_INTERNAL_OPERATOR;

    return userHasRole(roles);
  }, [userHasRole]);

  const pmsUser = useMemo(() => {
    const roles =
      AppRole.PMS_SUPER_ADMIN | AppRole.PMS_ADMIN | AppRole.PMS_OPERATOR;

    return userHasRole(roles);
  }, [userHasRole]);

  const validationSchema = yup.object({});

  const [openSendInquiryModal, closeSendInquiryModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <Form
        name='contactSupport'
        initialValues={sendInquiryInitialValues}
        validationSchema={sendInquiryCalidationSchema}
        onSubmit={onSubmitSendInquiry}
      >
        <SendInquiryModal
          closeParentModal={closeInquiryModal}
          onTryAgainClick={onTryAgainClick}
          inquiryResponseInfo={inquiryResponseInfo}
        />
      </Form>
    </GlobalModal>
  ));

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal: closeSendInquiryModal,
    message: messageSuccess,
  });

  const onSubmitSendInquiry = useCallback(
    async (values: ContactSupportFormValues) => {
      try {
        await contactSupportManagement(values);

        if (isMounted()) {
          setInquiryResponseInfo({
            success: true,
            message:
              'Your inquiry was sent successfully. Our customer service will contact you as soon as possible. You can also contact us over the phone:',
          });
        }
      } catch (error) {
        if (isMounted()) {
          setInquiryResponseInfo({
            success: false,
            message:
              'Sorry, there is some error... Please try again or contact us over the phone:',
          });
        }
      }
    },
    [isMounted, contactSupportManagement]
  );

  const onTryAgainClick = useCallback(() => {
    setInquiryResponseInfo(undefined);
  }, []);

  const closeInquiryModal = useCallback(() => {
    closeSendInquiryModal();
    setInquiryResponseInfo(undefined);
  }, [closeSendInquiryModal]);

  const getData = useCallback(
    async (page: number) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        if (userIsParklioInternal) {
          const { pricingData, purchaseData, counterArray } =
            await listAllLicensePurchasesByAccountIdManagement(
              {
                page,
                size: PaginationSize.STANDARD,
              },
              {
                accountId,
              }
            );
          if (isMounted()) {
            setData(pricingData);
            setPurchasedData(purchaseData);
            setInitialValues({
              licenseInfo: counterArray,
            });
            setIsDataLoading(false);
          }

          return;
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [
      listAllLicensePurchasesByAccountIdManagement,
      userIsParklioInternal,
      accountId,
      isMounted,
    ]
  );

  useEffect(() => {
    getData(1);
  }, [getData]);

  const onSubmit = useCallback(
    async (values: LicensePurchaseFormValues) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const valuesToUpdate = values.licenseInfo.filter((currentValue) =>
          initialValues.licenseInfo.find(
            (value) =>
              value.pricingId === currentValue.pricingId &&
              value.counter !== currentValue.counter
          )
        );

        await purchaseLicenseManagement(
          { licenseInfo: valuesToUpdate },
          accountId
        );

        if (isMounted()) {
          setInitialValues(values);
          setIsDataLoading(false);
          setMessageSuccess('Licenses were successfully updated!');
          openGlobalSuccessModal();
          getData(1);
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsDataLoading(false);
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      accountId,
      isMounted,
      purchaseLicenseManagement,
      openGlobalFailureModal,
      openGlobalSuccessModal,
      setMessage,
      getData,
      initialValues,
    ]
  );

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onSubmit,
    message: 'Are you sure you want to make changes?',
    values,
  });

  const openUpdateModal = useCallback(
    async (values: LicensePurchaseFormValues) => {
      setValues(values);
      openGlobalConfirmationModal();
    },
    [openGlobalConfirmationModal]
  );

  return (
    <>
      {userIsParklioInternal && (
        <Form
          name='licenseInfoForm'
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={openUpdateModal}
          isLoading={isDataLoading}
        >
          <LicenseInfoTable
            data={data}
            isLoading={isDataLoading}
            purchasedData={purchasedData}
            initialValues={initialValues}
          />
        </Form>
      )}
      {pmsUser && (
        <PmsLicenseTable openSendInquiryModal={openSendInquiryModal} />
      )}
    </>
  );
}
