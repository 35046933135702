import { useFormikContext } from 'formik';
import React from 'react';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import CounterField from '../Shared/CounterField';
import Description from '../Shared/Description';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import ParklioPhoneNumber from '../Shared/ParklioPhoneNumber';
import Section from '../Shared/Section';
import Subtitle from '../Shared/Subtitle';
import Table from '../Shared/Table';
import TextArea from '../Shared/TextArea';
import Title from '../Shared/Title';

interface SendInquiryFormProps {
  closeParentModal: () => void;
  noLicense?: boolean;
}

export default function SendInquiryForm(props: SendInquiryFormProps) {
  const { closeParentModal, noLicense } = props;
  const { isSubmitting, dirty } = useFormikContext();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      <Section>
        <Title bold>Licenses</Title>
        {noLicense && (
          <Subtitle>
            {' '}
            Sorry, no licenses have been added to your account.
          </Subtitle>
        )}
        <Description>
          Please state how many products you need to add to your Parking
          Management account and our Customer Service will contact you as soon
          as possible.
          <p>
            You can also contact us over the phone:{' '}
            <ParklioPhoneNumber callingCode='+385' phoneNumber='977247276' />.
          </p>
        </Description>
        <Table margin customWidth>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <CounterField name='lprCameras' label='LPR cameras' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField
                  name='brainModules'
                  label='Gates / Chains / Bollards'
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <CounterField name='barriers' label='Barriers' />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <TextArea
          name='message'
          label='Message'
          placeholder='For any additional information, send us a message.'
        />
      </Section>
      <Line />
      <Control>
        {!noLicense && (
          <Button
            onClick={dirty ? openGlobalCancelModal : closeParentModal}
            size={ButtonSize.LARGE}
            type='button'
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        )}
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Send
        </Button>
      </Control>
    </>
  );
}
