import type KeyLogResponse from 'src/models/KeySharing/KeyLog/KeyLogResponse';
import type KeyLog from '../../models/KeySharing/KeyLog/KeyLog';
import toKeyProduct from './toKeyProduct';
import toOperationToken from './toOperationToken';

export default function toKeyLog(data: KeyLogResponse): KeyLog {
  const {
    id,
    sender_id,
    receiver_identifier,
    product_id,
    created_at,
    updated_at,
    operation_token,
    product,
  } = data;

  return {
    id,
    senderId: sender_id,
    receiverIdentifier: receiver_identifier,
    productId: product_id,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    operationToken: toOperationToken(operation_token),
    product: toKeyProduct(product),
  };
}
