import type WeblinkCreateRequest from '../../models/KeySharing/Weblink/WeblinkCreateRequest';
import type WeblinkFormValues from '../../models/KeySharing/Weblink/WeblinkFormValues';

export default function toWeblinkCreateRequest(
  productId: number[],
  data: WeblinkFormValues
): WeblinkCreateRequest {
  const { startTime, endTime, receiverEmail } = data;

  return {
    products: productId,
    receiver_email: receiverEmail,
    start_time: startTime ? startTime.toISOString() : '',
    end_time: endTime ? endTime.toISOString() : '',
  };
}
