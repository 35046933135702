import React, { useMemo } from 'react';
import LockIcons from 'src/constants/Shared/LockIcons';
import type Product from 'src/models/Parking/Product';
import styled from 'styled-components';
import AllProductStatuses from '../../constants/Parking/AllProductStatuses';
import OperationIcon from '../../constants/Parking/OperationIcon';
import ProductOperations from '../../constants/Parking/ProductOperations';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import IconRow from '../Shared/IconRow';

interface WeblinksScreenButtonsProps {
  product: Product;
  buttonLabel: AllProductStatuses;
  deviceInProgress: boolean;
  disabled: boolean;
  onClick: (
    disabledStateOperation?: ProductOperations.OPEN | ProductOperations.CLOSE
  ) => Promise<void>;
}

export default function WeblinkScreenButtons(
  props: WeblinksScreenButtonsProps
) {
  const { product, buttonLabel, deviceInProgress, disabled, onClick } = props;
  const { gate, brain } = product;

  const getOperationicon = useMemo(() => {
    if (
      buttonLabel === AllProductStatuses.OPEN ||
      buttonLabel === AllProductStatuses.LOWER
    ) {
      if (gate) return OperationIcon.UP;
      else if (brain) return LockIcons.LOCK_OPEN;
      else return OperationIcon.DOWN;
    } else if (
      buttonLabel === AllProductStatuses.CLOSE ||
      buttonLabel === AllProductStatuses.EXTEND
    ) {
      if (gate) return OperationIcon.DOWN;
      else if (brain) return LockIcons.LOCK_CLOSED;
      else return OperationIcon.UP;
    } else {
      return undefined;
    }
  }, [buttonLabel, gate, brain]);

  return (
    <>
      {buttonLabel === AllProductStatuses.DISABLED ? (
        <StyledWrapper>
          <Button
            primary
            size={ButtonSize.MIDDLE}
            type='button'
            onClick={() => onClick(ProductOperations.OPEN)}
            isLoading={deviceInProgress}
            disabled={disabled}
          >
            <IconRow className={LockIcons.LOCK_OPEN} />
            {AllProductStatuses.OPEN}
          </Button>
          <Button
            addLeftMargin={30}
            primary
            size={ButtonSize.MIDDLE}
            type='button'
            onClick={() => onClick(ProductOperations.CLOSE)}
            isLoading={deviceInProgress}
            disabled={disabled}
          >
            <IconRow className={LockIcons.LOCK_CLOSED} />
            {AllProductStatuses.CLOSE}
          </Button>
        </StyledWrapper>
      ) : (
        <StyledWrapper>
          <Button
            primary
            size={ButtonSize.MIDDLE}
            type='button'
            onClick={() => onClick()}
            isLoading={deviceInProgress}
            disabled={disabled}
          >
            <IconRow className={getOperationicon} />
            {buttonLabel}
          </Button>
        </StyledWrapper>
      )}
    </>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  @media (max-width: 500px) {
    width: 75%;
    flex-direction: column;

    button {
      margin-bottom: 0;
      margin-left: 0;

      :last-child {
        margin-top: 5px;
      }
    }
  }
`;
