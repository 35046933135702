import styled, { css } from 'styled-components';

const ButtonsContainer = styled.div<{ table?: boolean }>`
  display: flex;

  @media (max-width: 500px) {
    display: block;

    ${(props) => {
      const { table } = props;

      if (table) {
        return css`
          justify-content: flex-end;
          margin: 15px 0;
        `;
      }
      return css``;
    }};
  }
`;

export default ButtonsContainer;
