import type ContactSupportFormValues from 'src/models/License/ContactSupportFormValues';
import type ContactSupportRequest from 'src/models/License/ContactSupportRequest';

export default function toContactSupportRequest(
  data: ContactSupportFormValues
): ContactSupportRequest {
  const { barriers, lprCameras, brainModules, message } = data;

  return {
    products: {
      barriers: barriers.counter,
      brain_modules: brainModules.counter,
      lpr_cameras: lprCameras.counter,
    },
    message,
  };
}
