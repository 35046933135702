import type Profile from 'src/models/Settings/Profile';
import type ProfileResponse from 'src/models/Settings/ProfileResponse';
import toAccountUserRole from '../Shared/toAccountUserRole';
import toAccountInfo from './toAccountInfo';

export default function toProfile(data: ProfileResponse): Profile {
  const { id, name, email, phone, account, account_user_role } = data;

  return {
    id,
    name,
    email,
    phone,
    role: { key: account_user_role.id, label: account_user_role.name },
    account: toAccountInfo(account),
    accountUserRole: toAccountUserRole(account_user_role),
  };
}
