import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Color from '../../../constants/Shared/Color';

interface LinkProps {
  to: string;
  last?: boolean;
  children: React.ReactNode;
}

export default function Link(props: LinkProps) {
  const { to, last, children } = props;

  return (
    <>
      <StyledLink to={to}>{children}</StyledLink>
      {!last && <StyledSlash>/</StyledSlash>}
    </>
  );
}

const linkStyle = css`
  font-family: Open Sans;
  font-weight: normal;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${Color.TEXT_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;

const StyledLink = styled(NavLink)`
  ${linkStyle}
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledSlash = styled.p`
  ${linkStyle}
  margin: 0px 5px;
`;
