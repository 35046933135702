import type LoginFormValues from '../../models/Auth/LoginFormValues';
import type LoginRequest from '../../models/Auth/LoginRequest';

export default function toLoginRequest(data: LoginFormValues): LoginRequest {
  const { email, password } = data;

  return {
    email: email.replace(/\s/g, ''),
    password,
  };
}
