import type ChainFormValues from 'src/models/Parking/Chain/ChainFormValues';
import type ChainUpdateParams from 'src/models/Parking/Chain/ChainUpdateParams';

export default function toChainUpdateParams(
  data: ChainFormValues
): ChainUpdateParams {
  return {
    name: data.name,
  };
}
