import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Color from '../../../constants/Shared/Color';
import useTabsContext from '../../../hooks/Shared/useTabsContext';

interface TabProps {
  name: string;
  onSelect: (name: string) => void;
  children: React.ReactNode;
}

export default function Tab(props: TabProps) {
  const { name, children, onSelect } = props;
  const { activeTab } = useTabsContext();
  const active = useMemo(() => name === activeTab, [activeTab, name]);

  const onClick = useCallback(() => {
    onSelect(name);
  }, [onSelect, name]);

  return (
    <StyledTab active={active} onClick={onClick}>
      {children}
    </StyledTab>
  );
}

const StyledTab = styled.p<{ active: boolean }>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px 20px;
  text-decoration: none;
  color: ${Color.TEXT_DARKER};
  height: 40px;

  ${(props) => {
    const { active } = props;

    if (!active) {
      return css``;
    }

    return css`
      border-radius: 5px;
      background-color: ${Color.BACKGROUND_LIGTH};
      color: ${Color.TEXT_BRAND};
      :hover {
        color: ${Color.PRIMARY_HOVER};
      }
    `;
  }}
`;
