import { useCallback } from 'react';
import toRecurringOperationKeyCreateRequest from 'src/mappers/KeySharing/toRecurringOperationKeyCreateRequest';
import type RecurringOperationKeyCreateRequest from 'src/models/KeySharing/RecurringOperationKeyCreateRequest';
import type RecurringOperationKeyCreateResponse from 'src/models/KeySharing/RecurringOperationKeyCreateResponse';
import toOperationKeyCreateRequest from '../../mappers/KeySharing/toOperationKeyCreateRequest';
import type KeySharingFormValues from '../../models/KeySharing/KeySharingFormValues';
import type OperationKeyCreateRequest from '../../models/KeySharing/OperationKeyCreateRequest';
import type OperationKeyCreateResponse from '../../models/KeySharing/OperationKeyCreateResponse';
import useApiService from '../Shared/useApiService';

const operationKeysBaseURL = '/operation-keys';
interface OperationKeysService {
  createOperationKey: (
    productId: number[],
    values: KeySharingFormValues
  ) => Promise<OperationKeyCreateResponse>;

  createRecurringOperationKey: (
    productId: number[],
    values: KeySharingFormValues
  ) => Promise<RecurringOperationKeyCreateResponse>;
}

export default function useOperationKeysService(): OperationKeysService {
  const { post } = useApiService();

  const createOperationKey = useCallback(
    async (productId: number[], values: KeySharingFormValues) => {
      const requestBody = toOperationKeyCreateRequest(productId, values);

      const response = await post<
        OperationKeyCreateRequest,
        OperationKeyCreateResponse
      >(`${operationKeysBaseURL}`, requestBody);

      return response;
    },
    [post]
  );

  const createRecurringOperationKey = useCallback(
    async (productId: number[], values: KeySharingFormValues) => {
      const requestBody = toRecurringOperationKeyCreateRequest(
        productId,
        values
      );

      const response = await post<
        RecurringOperationKeyCreateRequest,
        RecurringOperationKeyCreateResponse
      >(`${operationKeysBaseURL}/recurring`, requestBody);

      return response;
    },
    [post]
  );

  return {
    createOperationKey,
    createRecurringOperationKey,
  };
}
