import Color from 'src/constants/Shared/Color';
import styled, { css } from 'styled-components';

const AddParkingPlaceIcon = styled.i<{
  active?: boolean;
  backgroundColor?: string;
}>`
  margin: 0px 3px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px;
  :first-child {
    margin: 0px 3px 0px 10px;
  }
  :hover {
    color: ${Color.PRIMARY_BRAND};
  }
  ${(props) => {
    const { active, backgroundColor } = props;
    if (active) {
      return css`
        background-color: ${Color.SHADOW_DARK_TRANSPARENT};
      `;
    }
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return css``;
  }}
`;

export default AddParkingPlaceIcon;
