import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import Button from 'src/components/Shared/Button';
import Control from 'src/components/Shared/Control';
import Line from 'src/components/Shared/Line';
import { useGlobalCancelModal } from 'src/components/Shared/Modals/GlobalCancelModal';
import SelectField from 'src/components/Shared/SelectField';
import TextField from 'src/components/Shared/TextField';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import type DetectCameraFormValues from 'src/models/Parking/DetectCamera/DetectCameraFormValues';
import type DetectGateway from 'src/models/Parking/DetectGateway/DetectGateway';
import type Option from 'src/models/Shared/Option';
import DetailsColumn from '../DetailsColumn';
import DetailsRow from '../DetailsRow';

interface DetectCameraAddFormProps {
  closeParentModal: () => void;
  gateways: DetectGateway[];
}
export default function DetectCameraAddForm(props: DetectCameraAddFormProps) {
  const { closeParentModal, gateways } = props;
  const [searchGateway, setSearchGateway] = useState('');
  const { isSubmitting, dirty } = useFormikContext<DetectCameraFormValues>();

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const gatewayOptions: Option[] = useMemo(
    () =>
      gateways
        .filter(({ name }) =>
          name.toLowerCase().includes(searchGateway.toLowerCase())
        )
        .map(({ id, name }) => ({
          key: id,
          label: name,
        })),
    [gateways, searchGateway]
  );

  return (
    <>
      <DetailsRow>
        <DetailsColumn>
          <TextField
            label='NAME'
            placeholder='Name'
            type='text'
            name='name'
            short
            stacked
          />
          <SelectField
            label='GATEWAY'
            placeholder='Select Gateway'
            name='gateway'
            options={gatewayOptions}
            short
            stacked
            onSearch={setSearchGateway}
            search={searchGateway}
          />
        </DetailsColumn>
      </DetailsRow>
      <Line addMargin />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
