import type RelatedLotEntry from 'src/models/Parking/Anpr/LotEntries/RelatedLotEntry';
import type RelatedLotEntryResponse from 'src/models/Parking/Anpr/LotEntries/RelatedLotEntryResponse';
import toLprCamera from './toLprCamera';

export default function toRelatedLotEntry(
  data: RelatedLotEntryResponse
): RelatedLotEntry {
  const {
    id,
    scan_time,
    lpr_camera_id,
    lot_id,
    related_lot_entry_id,
    duration,
    is_allowed,
    direction,
    license_plate,
    license_plate_image_url,
    full_image_url,
    created_at,
    updated_at,
    lpr_camera,
  } = data;

  return {
    id,
    scanTime: scan_time,
    lprCameraId: lpr_camera_id,
    lotId: lot_id,
    isAllowed: !!is_allowed,
    relatedLotEntryId: related_lot_entry_id,
    duration,
    direction,
    licensePlate: license_plate,
    licensePlateImageUrl: license_plate_image_url,
    fullImageUrl: full_image_url,
    createdAt: created_at,
    updatedAt: updated_at,
    lprCamera: lpr_camera ? toLprCamera(lpr_camera) : undefined,
  };
}
