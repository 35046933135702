import SelectAllFilterValue from '../Shared/SelectAllFilterValue';

enum InvitationKeysStatusValues {
  ALL = SelectAllFilterValue,
  ACTIVATED = 0,
  BLOCKED = 1,
  EXPIRED = 2,
  INVALID = 3,
  PENDING = 4,
  DELETED = 5,
}

export default InvitationKeysStatusValues;
