import type DetectCameraQuery from 'src/models/Parking/DetectCamera/DetectCameraQuery';
import type DetectCameraQueryParams from 'src/models/Parking/DetectCamera/DetectCameraQueryParams';

export default function toDetectCameraQuery(
  data: DetectCameraQueryParams
): DetectCameraQuery {
  const { lotId, parkingDetectionGatewayId } = data;

  return {
    lot_id: lotId,
    parking_detection_gateway_id: parkingDetectionGatewayId,
  };
}
