import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import DeleteModal from 'src/components/Parking/DeleteModal';
import Content from 'src/components/Shared/Content';
import Main from 'src/components/Shared/Main';
import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/components/Shared/Modals/GlobalSuccessModal';
import TariffForm from 'src/components/Tariffs/TariffForm';
import TariffTable from 'src/components/Tariffs/TariffTable';
import {
  defaultTariffFormValues,
  tariffValidationSchema,
} from 'src/constants/Parking/TariffFormikValues';
import toTariffFormValues from 'src/mappers/Parking/Tariff/toTariffFormValues';
import type Tariff from 'src/models/Parking/Tariff/Tariff';
import type TariffFormValues from 'src/models/Parking/Tariff/TariffFormValues';
import useTariffService from 'src/services/Parking/useTariffService';
import useAccountUserService from 'src/services/Settings/useAccountUserService';
import useCurrencyService from 'src/services/Shared/useCurrencyService';
import { isNotString } from 'src/utils/checks';
import * as yup from 'yup';
import Form from '../../components/Shared/Form';
import GlobalModal from '../../components/Shared/Modals/GlobalModal';
import { useGlobalModal } from '../../hooks/Shared/useGlobalModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';

const removeTariffValidationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
});

export default function TariffList() {
  const [tariffData, setTariffData] = useState<Tariff[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currencies, setCurrencies] = useState<Record<number, string>>({});
  const [invoiceCurrency, setInvoiceCurrency] = useState<
    { id: number | undefined; symbol: string | undefined } | undefined
  >(undefined);
  const [tariffCurrency, setTariffCurrency] = useState<
    { id: number | undefined; symbol: string | undefined } | undefined
  >(undefined);
  const [accountId, setAccountId] = useState<number | undefined>(undefined);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [initialValues, setInitialValues] = useState<TariffFormValues>(
    defaultTariffFormValues
  );
  const [activeTariff, setActiveTariff] = useState<
    { id: number; name: string } | undefined
  >();
  const {
    findAllTariffs,
    createTariff,
    findOneTariff,
    updateTariff,
    removeTariff,
  } = useTariffService();
  const { findAllCurrencies } = useCurrencyService();
  const { findProfileAccountUser } = useAccountUserService();
  const isMounted = useIsMounted();

  const [openTariffModal, closeTariffModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <Form
        name='tariff'
        initialValues={initialValues}
        validationSchema={tariffValidationSchema}
        onSubmit={onSubmit}
      >
        <TariffForm
          closeParentModal={closeEditTariffModal}
          invoiceCurrency={invoiceCurrency?.symbol}
          tariffCurrency={tariffCurrency?.symbol}
          accountId={accountId}
          isLoading={isLoading}
        />
      </Form>
    </GlobalModal>
  ));

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal: closeTariffModal,
    message: messageSuccess,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const findCurrencySymbol = useCallback(
    (id: number) => currencies[id],
    [currencies]
  );

  const openEditTariffModal = useCallback(
    (data: Tariff) => {
      setActiveTariff({ id: data.id, name: data.name });
      setTariffCurrency({
        id: data.currencyId,
        symbol: findCurrencySymbol(data.currencyId),
      });
      openTariffModal();
    },
    [openTariffModal, findCurrencySymbol]
  );

  const closeEditTariffModal = useCallback(() => {
    setActiveTariff(undefined);
    setInitialValues(defaultTariffFormValues);
    setTariffCurrency(undefined);
    closeTariffModal();
  }, [closeTariffModal]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data } = await findAllTariffs();

        if (isMounted()) {
          setTariffData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }

        throw error;
      }
    };

    getData();
  }, [isMounted, findAllTariffs]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }
        const data = await findAllCurrencies();

        if (isMounted()) {
          const reducedData = data.reduce(
            (acc, item) => ({
              ...acc,
              [item.id]: item.symbol,
            }),
            {}
          );
          setCurrencies(reducedData);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }

        throw error;
      }
    };

    getData();
  }, [isMounted, findAllCurrencies]);

  useEffect(() => {
    const getData = async () => {
      const data = await findProfileAccountUser();

      if (isMounted()) {
        setInvoiceCurrency({
          id: data.account?.invoiceCurrency?.id,
          symbol: data.account?.invoiceCurrency?.symbol,
        });
        setAccountId(data.account?.id);
      }
    };

    getData();
  }, [isMounted, findProfileAccountUser]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!activeTariff) {
          return;
        }

        if (isMounted()) {
          setIsLoading(true);
        }

        const data = await findOneTariff(activeTariff.id);

        const formValues = toTariffFormValues(data);

        if (isMounted()) {
          setIsLoading(false);
          setInitialValues(formValues);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [
    isMounted,
    findProfileAccountUser,
    activeTariff,
    findOneTariff,
    currencies,
  ]);

  const getChangedValues = useCallback(
    (values: TariffFormValues) => {
      const getDays = () => {
        if (values.daysOfWeek.length !== initialValues.daysOfWeek.length) {
          return values.daysOfWeek;
        }

        let isChanged = false;

        for (let i = 0; i < values.daysOfWeek.length; i++) {
          const index = initialValues.daysOfWeek.findIndex(
            (day) => day.key === values.daysOfWeek[i].key
          );
          if (index === -1) {
            isChanged = true;
            break;
          }
        }

        if (isChanged) {
          return values.daysOfWeek;
        }

        return undefined;
      };

      return {
        name: values.name !== initialValues.name ? values.name : undefined,
        price: values.price !== initialValues.price ? values.price : undefined,
        pricingInterval:
          values.pricingInterval?.key !== initialValues.pricingInterval?.key
            ? values.pricingInterval
            : undefined,
        freeTimeInterval:
          values.freeTimeInterval !== initialValues.freeTimeInterval
            ? values.freeTimeInterval
            : undefined,
        gracePeriod:
          values.gracePeriod !== initialValues.gracePeriod
            ? values.gracePeriod
            : undefined,
        daysOfWeek: getDays(),
        activeDatePeriods: values.datePeriod
          ? values.activeDatePeriods
          : values.datePeriod !== initialValues.datePeriod
          ? []
          : undefined,
        activeTimePeriods: values.timePeriod
          ? values.activeTimePeriods
          : values.timePeriod !== initialValues.timePeriod
          ? []
          : undefined,
        maxTimeOfStay: values.overstay
          ? values.maxTimeOfStay !== initialValues.maxTimeOfStay
            ? values.maxTimeOfStay
            : undefined
          : values.overstay !== initialValues.overstay
          ? null
          : undefined,
        maxTimeOfStayUnit: values.overstay
          ? values.maxTimeOfStayUnit?.key !==
            initialValues.maxTimeOfStayUnit?.key
            ? values.maxTimeOfStayUnit
            : undefined
          : values.overstay !== initialValues.overstay
          ? null
          : undefined,
        overstayFee: values.overstay
          ? values.overstayFee !== initialValues.overstayFee
            ? values.overstayFee
            : undefined
          : values.overstay !== initialValues.overstay
          ? null
          : undefined,
        overstayFeeInterval: values.overstay
          ? values.overstayFeeInterval !== initialValues.overstayFeeInterval
            ? values.overstayFeeInterval
            : undefined
          : values.overstay !== initialValues.overstay
          ? null
          : undefined,
        overstayFeeUnit: values.overstay
          ? values.overstayFeeUnit?.key !== initialValues.overstayFeeUnit?.key
            ? values.overstayFeeUnit
            : undefined
          : values.overstay !== initialValues.overstay
          ? null
          : undefined,
      };
    },
    [initialValues]
  );

  const getCreateValues = useCallback(
    (values: TariffFormValues) => ({
      ...values,
      activeDatePeriods: values.datePeriod ? values.activeDatePeriods : [],
      activeTimePeriods: values.timePeriod ? values.activeTimePeriods : [],
      maxTimeOfStay: values.overstay ? values.maxTimeOfStay : undefined,
      maxTimeOfStayUnit: values.overstay ? values.maxTimeOfStayUnit : undefined,
      overstayFee: values.overstay ? values.overstayFee : undefined,
      overstayFeeInterval: values.overstay
        ? values.overstayFeeInterval
        : undefined,
      overstayFeeUnit: values.overstay ? values.overstayFeeUnit : undefined,
    }),
    []
  );

  const onTariffChange = useCallback((tariff: Tariff) => {
    setTariffData((oldData) => {
      const index = oldData.findIndex((data) => data.id === tariff.id);

      if (index === -1) return oldData;

      oldData[index] = tariff;
      return [...oldData];
    });
  }, []);

  const onSubmit = useCallback(
    async (
      values: TariffFormValues,
      { setErrors }: FormikHelpers<TariffFormValues>
    ) => {
      try {
        if (!invoiceCurrency?.id) {
          if (isMounted()) {
            setMessage({ code: 'currency', message: 'Currency must be set.' });
            openGlobalFailureModal();
          }
          return;
        }

        if (!activeTariff) {
          const createValues = getCreateValues(values);
          const data = await createTariff(invoiceCurrency.id, createValues);

          if (isMounted()) {
            setTariffData((oldData) => [...oldData, data]);
            setMessageSuccess('Successfully added new tariff!');
            openGlobalSuccessModal();
          }
        } else {
          const valuesToUpdate = getChangedValues(values);
          const response = await updateTariff(activeTariff.id, valuesToUpdate);

          onTariffChange(response);

          if (isMounted()) {
            setMessageSuccess('Successfuly updated tariff!');
            openGlobalSuccessModal();
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      openGlobalFailureModal,
      setMessage,
      invoiceCurrency,
      createTariff,
      openGlobalSuccessModal,
      getChangedValues,
      activeTariff,
      updateTariff,
      onTariffChange,
      getCreateValues,
    ]
  );

  const [openDeleteTariffModal, closeDeleteTariffModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <Form
        name='DeleteTariff'
        initialValues={{ name: '' }}
        validationSchema={removeTariffValidationSchema}
        onSubmit={onRemove}
      >
        <DeleteModal
          closeParentModal={closeDeleteTariffModal}
          type='Tariff'
          name={activeTariff?.name}
        />
      </Form>
    </GlobalModal>
  ));

  const { openGlobalSuccessModal: deleteTariffSuccessModal } =
    useGlobalSuccessModal({
      message: messageSuccess,
      closeParentModal: closeDeleteTariffModal,
    });

  const onRemove = useCallback(
    async (values: { name: string }) => {
      try {
        if (!activeTariff) {
          return;
        }
        const { name } = values;
        if (activeTariff.name !== name && isMounted()) {
          setMessage({
            code: 'Undefined',
            message: "Name doesn't match. Please try again.",
          });
          openGlobalFailureModal();
        } else {
          await removeTariff(activeTariff.id);

          if (isMounted()) {
            setTariffData((oldData) =>
              oldData.filter((data) => data.id !== activeTariff.id)
            );
            setActiveTariff(undefined);
            setInitialValues(defaultTariffFormValues);
            setMessageSuccess('Tariff deleted successfully!');
            deleteTariffSuccessModal();
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      removeTariff,
      isMounted,
      activeTariff,
      openGlobalFailureModal,
      setMessage,
      deleteTariffSuccessModal,
    ]
  );

  const openRemoveModal = useCallback(
    (data: Tariff) => {
      setActiveTariff({ id: data.id, name: data.name });
      openDeleteTariffModal();
    },
    [openDeleteTariffModal]
  );

  return (
    <Main left>
      <Content>
        <TariffTable
          data={tariffData}
          isLoading={isDataLoading}
          openCreateModal={openTariffModal}
          openEditModal={openEditTariffModal}
          openRemoveModal={openRemoveModal}
          findCurrencySymbol={findCurrencySymbol}
        />
      </Content>
    </Main>
  );
}
