import type ParkingPlaceUpdateParams from 'src/models/Parking/ParkingPlace/ParkingPlaceUpdateParams';
import type ParkingPlaceUpdateValues from 'src/models/Parking/ParkingPlace/ParkingPlaceUpdateValues';

export default function toParkingPlaceUpdateParams(
  data: ParkingPlaceUpdateValues
): ParkingPlaceUpdateParams {
  const { zoneId, name, externalId, uuid } = data;

  return {
    zone_id: zoneId,
    name,
    external_id: externalId,
    uuid,
  };
}
