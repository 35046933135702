import DayOptions from 'src/constants/Parking/DayOptions';
import { UnitValues } from 'src/constants/Parking/TariffUnits';
import type DatePeriod from 'src/models/Parking/Tariff/DatePeriod';
import type Tariff from 'src/models/Parking/Tariff/Tariff';
import type TariffFormValues from 'src/models/Parking/Tariff/TariffFormValues';
import type TimePeriod from 'src/models/Parking/Tariff/TimePeriod';
import type Option from 'src/models/Shared/Option';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import generateTimeLabel from 'src/utils/generateTimeLabel';

export default function toTariffFormValues(data: Tariff): TariffFormValues {
  const {
    name,
    price,
    pricingInterval,
    freeTimeInterval,
    gracePeriod,
    daysOfWeek,
    maxTimeOfStay,
    maxTimeOfStayUnit,
    overstayFee,
    overstayFeeInterval,
    overstayFeeUnit,
    activePeriods,
  } = data;

  return {
    name,
    price,
    pricingInterval: pricingInterval
      ? {
          key: UnitValues[pricingInterval],
          label: capitalizeFirstLetter(pricingInterval),
        }
      : null,
    freeTimeInterval,
    gracePeriod,
    daysOfWeek: DayOptions(daysOfWeek),
    maxTimeOfStay,
    maxTimeOfStayUnit: maxTimeOfStayUnit
      ? {
          key: UnitValues[maxTimeOfStayUnit],
          label: capitalizeFirstLetter(maxTimeOfStayUnit),
        }
      : null,
    overstay: !!overstayFee,
    overstayFee,
    overstayFeeInterval,
    overstayFeeUnit: overstayFeeUnit
      ? {
          key: UnitValues[overstayFeeUnit],
          label: capitalizeFirstLetter(overstayFeeUnit),
        }
      : null,
    datePeriod: isDatePeriod(activePeriods.dates).datePeriod,
    activeDatePeriods: isDatePeriod(activePeriods.dates).activeDatePeriods,
    timePeriod: isTimePeriod(activePeriods.times).timePeriod,
    activeTimePeriods: isTimePeriod(activePeriods.times).activeTimePeriods,
  };
}

const isDatePeriod = (activeDatePeriods: DatePeriod[]) => {
  const currentYear = new Date().getFullYear();
  const activePeriods = activeDatePeriods
    .filter((v) => v.startDate && v.endDate)
    .map((v) => ({
      from: new Date(`${currentYear}-${v.startDate}`),
      to: new Date(`${currentYear}-${v.endDate}`),
    }));

  const unique: { from: Date; to: Date }[] = [];
  activePeriods.forEach((item) => {
    const index = unique.findIndex(
      (x) =>
        x.from.getTime() === item.from.getTime() &&
        x.to.getTime() === item.to.getTime()
    );
    if (index === -1) {
      unique.push({ from: item.from, to: item.to });
    }
  });

  return {
    datePeriod: activePeriods.length > 0,
    activeDatePeriods: unique,
  };
};

const isTimePeriod = (activeTimePeriods: TimePeriod[]) => {
  const activePeriods = activeTimePeriods
    .filter((v) => v.startTime && v.endTime)
    .map((v) => {
      const startTime = v.startTime.split(':');
      const endTime = v.endTime.split(':');
      const startMinutes = generateMinutes(startTime);
      const endMinutes = generateMinutes(endTime, true);
      return {
        from: {
          key: startMinutes,
          label: generateTimeLabel(startMinutes),
        },
        to: {
          key: endMinutes,
          label: generateTimeLabel(endMinutes),
        },
      };
    });

  const unique: { from: Option; to: Option }[] = [];
  activePeriods.forEach((item) => {
    const index = unique.findIndex(
      (x) => x.from.key === item.from.key && x.to.key === item.to.key
    );
    if (index === -1) {
      unique.push({ from: item.from, to: item.to });
    }
  });

  return {
    timePeriod: activePeriods.length > 0,
    activeTimePeriods: unique,
  };
};

const generateMinutes = (value: string[], endTime?: boolean) => {
  const midnight = 1440;
  if (endTime) {
    if (parseInt(value[0]) === 0 && parseInt(value[1]) === 0) {
      return midnight;
    }
  }
  return parseInt(value[0]) * 60 + parseInt(value[1]);
};
