import type LicenseUpdateParams from 'src/models/License/LicenseUpdateParams';
import type LicenseUpdateValues from 'src/models/License/LicenseUpdateValues';

export default function toLicenseUpdateParams(
  data: LicenseUpdateValues
): LicenseUpdateParams {
  const { barriersCount, lprCamerasCount, brainModulesCount } = data;

  return {
    barriers_count: barriersCount,
    lpr_cameras_count: lprCamerasCount,
    brain_modules_count: brainModulesCount,
  };
}
