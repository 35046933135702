import LicenseStatus from 'src/constants/License/LicenseStatus';
import type LicensePricingUpdateParams from 'src/models/License/LicensePricingUpdateParams';
import type LicenseUpdateValues from 'src/models/License/LicenseUpdateValues';

export default function toLicensePricingUpdateParams(
  data: LicenseUpdateValues
): LicensePricingUpdateParams {
  const { name, status, price } = data;

  return {
    name,
    price,
    status: status ? LicenseStatus.AVAILABLE : LicenseStatus.UNAVAILABLE,
  };
}
