import qs from 'qs';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PasswordCreationErrorMessages from 'src/constants/Shared/PasswordCreationErrorMessages';
import * as yup from 'yup';
import Container from '../../components/Auth/Container';
import Form from '../../components/Auth/Form';
import NewPasswordForm from '../../components/Auth/NewPasswordForm';
import NewPasswordText from '../../components/Auth/NewPasswordText';
import ParklioSign from '../../components/Auth/ParklioSign';
import Title from '../../components/Auth/Title';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type NewPasswordFormValues from '../../models/Settings/NewPasswordFormValues';
import useAccountUserService from '../../services/Settings/useAccountUserService';

const initialValues: NewPasswordFormValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required(PasswordCreationErrorMessages.REQUIRED)
    .min(8, PasswordCreationErrorMessages.MIN_LEN)
    .matches(/^(?=.*[a-z])/, PasswordCreationErrorMessages.LOWERCASE)
    .matches(/^(?=.*[A-Z])/, PasswordCreationErrorMessages.UPPERCASE)
    .matches(/^(?=.*[0-9])/, PasswordCreationErrorMessages.NUMBER)
    .matches(
      /[!?@#$%^&*()\-/_"'=+{};:,<.>]/,
      PasswordCreationErrorMessages.SPECIAL_CHAR
    ),
  passwordConfirmation: yup
    .string()
    .required('Confirm New Password is a required field')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
});

export default function NewPassword() {
  const [isSent, setIsSent] = useState(false);
  const { newPassword } = useAccountUserService();
  const isMounted = useIsMounted();
  const location = useLocation();
  const { search } = location;

  const { token, signature } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as { [key: string]: string };

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (values: NewPasswordFormValues) => {
      try {
        await newPassword(token, signature, values);

        if (isMounted()) {
          setIsSent(true);
        }
      } catch (error: any) {
        if (isMounted()) {
          openGlobalFailureModal();
          setMessage(error);
          setIsSent(false);
        }
      }
    },
    [
      isMounted,
      openGlobalFailureModal,
      newPassword,
      setMessage,
      signature,
      token,
    ]
  );

  return (
    <Container>
      <ParklioSign
        onClick={() => window.location.assign('/login')}
        cursorpointer='true'
      />
      <Title>New password</Title>
      {!isSent && (
        <Form
          name='newPassword'
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <NewPasswordForm />
        </Form>
      )}
      {isSent && <NewPasswordText />}
    </Container>
  );
}
