import type ProductChangeSettingsRequest from 'src/models/Parking/ProductChangeSettingsRequest';
import type ProductChangeSettingsValues from 'src/models/Parking/ProductChangeSettingsValues';

export default function toProductChangeSettingsRequest(
  data: ProductChangeSettingsValues
): ProductChangeSettingsRequest {
  const { isAutoclose } = data;

  return { is_autoclose: isAutoclose };
}
