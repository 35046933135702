import { useCallback } from 'react';
import toAccountUserRole from '../../mappers/Shared/toAccountUserRole';
import type AccountUserRole from '../../models/Shared/AccountUserRole';
import type AccountUserRoleResponse from '../../models/Shared/AccountUserRoleResponse';
import useApiService from './useApiService';

interface AccountUserRoleService {
  findAllAccountUserRoles: () => Promise<AccountUserRole[]>;
}

const accountUserRoleBaseUrl = '/account-user-roles';

export default function useAccountUserRoleService(): AccountUserRoleService {
  const { get } = useApiService();

  const findAllAccountUserRoles = useCallback(async () => {
    const response = await get<AccountUserRoleResponse[]>(
      `${accountUserRoleBaseUrl}`
    );
    const data = response.data.map(toAccountUserRole);

    return data;
  }, [get]);

  return { findAllAccountUserRoles };
}
