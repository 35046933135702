import styled, { css } from 'styled-components';

interface ContentProps {
  widthSize?: string;
  addTopMargin?: boolean;
  modalView?: boolean;
  dashboardView?: boolean;
  anprTopMargin?: boolean;
  order?: number;
  licenseView?: boolean;
  addPadding?: boolean;
}

const Content = styled.div<ContentProps>`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;

  @media (max-width: 999px) {
    width: 100%;
  }

  ${(props) => {
    const { addTopMargin, anprTopMargin, licenseView, addPadding } = props;

    if (addTopMargin) {
      return css`
        margin-top: 60px;
      `;
    }

    if (licenseView) {
      return css`
        margin-top: 60px;
        @media (max-width: 999px) {
          margin-top: 30px;
        }
      `;
    }

    if (anprTopMargin) {
      return css`
        margin-top: 30px;
      `;
    }

    if (addPadding) {
      return css`
        padding: 0 10px;
      `;
    }

    return css``;
  }};

  ${(props) => {
    const { dashboardView } = props;

    if (dashboardView) {
      return css`
        @media (max-width: 1100px) {
          width: 100%;
        }
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { order } = props;

    if (order) {
      return css`
        @media (max-width: 999px) {
          order: ${order};
        }
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { widthSize } = props;

    if (widthSize) {
      return css`
        flex: none;
        width: ${widthSize};
      `;
    }
    return css``;
  }};

  ${(props) => {
    const { modalView } = props;

    if (modalView) {
      return css`
        width: 100%;
        @media (max-width: 520px) {
          margin-bottom: 20px;
        }
      `;
    }
    return css``;
  }};
`;

export default Content;
