import type ParkingPlaceCreateRequest from 'src/models/Parking/ParkingPlace/ParkingPlaceCreateRequest';
import type ParkingPlaceFormValues from 'src/models/Parking/ParkingPlace/ParkingPlaceFormValues';

export default function toParkingPlaceCreateRequest(
  data: ParkingPlaceFormValues
): ParkingPlaceCreateRequest {
  const { name, zone, externalId, uuid, placeCount, multiplePlaces } = data;

  return {
    zone_id: zone?.key || 0,
    name,
    external_id: externalId,
    uuid,
    place_count: multiplePlaces ? placeCount : undefined,
  };
}
