import { useField } from 'formik';
import React, { useCallback, useMemo } from 'react';
import Card from 'src/components/Shared/Card';
import type Option from 'src/models/Shared/Option';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from './Button';
import ButtonsContainer from './ButtonsContainer';
import DateTimeField from './DateTimeField';
import SelectField from './SelectField';
import TextField from './TextField';
import Title from './Title';

interface AnprFilterProps {
  options: Option[];
  onResetAllFilters: () => void;
}

export default function AnprFilter(props: AnprFilterProps) {
  const { options, onResetAllFilters } = props;
  const [field, , startTimeHelpers] = useField({ name: 'startTime' });
  const [, , endTimeHelpers] = useField({ name: 'endTime' });
  const { setValue: setStartTimeValue } = startTimeHelpers;
  const { setValue: setEndTimeValue } = endTimeHelpers;

  const today = useMemo(() => new Date(), []);

  const tomorrow = useMemo(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  }, []);

  const onReset = useCallback(() => {
    setStartTimeValue(null);
    setEndTimeValue(null);
    onResetAllFilters();
  }, [setStartTimeValue, setEndTimeValue, onResetAllFilters]);

  return (
    <Card addLastChildMargin>
      <Title addCustomMargin='15px 0 0 0'>Filter</Title>
      <TextField
        label='License Plate'
        placeholder='Enter license plate'
        type='text'
        stacked
        customMargin
        name='licensePlate'
      />
      <SelectField
        label='Camera'
        placeholder='Select camera'
        name='camera'
        options={options}
        stacked
        customMargin
      />
      <DateTimeField
        name='startTime'
        label='From'
        placeholderDate='Start Date'
        placeholderTime='Start Time'
        maxBookingDate={today}
        noInitialValue
        block
      />
      <DateTimeField
        name='endTime'
        label='To'
        placeholderDate='End Date'
        placeholderTime='End Time'
        minBookingDate={new Date(field.value)}
        maxBookingDate={tomorrow}
        endTimeOffset
        noInitialValue
        block
      />
      <ButtonsContainer>
        <Button
          size={ButtonSize.MEDIUM}
          type='reset'
          addVerticalMargin
          onClick={onReset}
        >
          Reset all
        </Button>
        <Button
          size={ButtonSize.MEDIUM}
          type='submit'
          addVerticalMargin
          primary
        >
          Apply filters
        </Button>
      </ButtonsContainer>
    </Card>
  );
}
