import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';

const StyledNoData = styled.div<{
  detectView?: boolean;
  imageStorageView?: boolean;
}>`
  text-align: center;
  width: 100%;
  margin-left: 10px;
  line-height: 20px;

  ${(props) => {
    const { detectView } = props;

    if (detectView) {
      return css`
        padding: 50px 0px;
        margin-left: 0px;
        font-size: 14px;

        & > i {
          margin-right: 5px;
        }
      `;
    }

    return css`
      padding: 20px;
      margin-left: 10px;
    `;
  }}

  ${(props) => {
    const { imageStorageView } = props;

    if (imageStorageView) {
      return css`
        color: ${Color.TEXT_DARKER};
        line-height: 20px;
        padding-top: 30px;
      `;
    }

    return css`
      color: ${Color.TEXT_LIGHT};
    `;
  }}
`;

export default StyledNoData;
