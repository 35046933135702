import { useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import AppRole from 'src/constants/Shared/AppRole';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import useUserHasRole from 'src/hooks/Shared/useUserHasRole';
import styled from 'styled-components';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import TextField from '../Shared/TextField';
import VerticalLine from '../Shared/VerticalLine';

interface DisplayCounterProps {
  setDisableInputField: (value: (prevVal: boolean) => boolean) => void;
  disableInputField: boolean;
}

const CUSTOM_HEIGHT = 36;
const CUSTOM_LEFT_MARGIN = 10;
const MIN_INPUT_VALUE = 0;

const DisplayCounter = (props: DisplayCounterProps) => {
  const { isValid } = useFormikContext();
  const { setDisableInputField, disableInputField } = props;
  const userHasRole = useUserHasRole();

  const userIsOperater = useMemo(() => {
    const roles = AppRole.PARKLIO_INTERNAL_OPERATOR | AppRole.PMS_OPERATOR;
    return userHasRole(roles);
  }, [userHasRole]);

  const onClick = useCallback(() => {
    isValid && setDisableInputField((prevState) => !prevState);
  }, [isValid, setDisableInputField]);

  return (
    <Control scrollOverflow justifyContent='flex-start'>
      <StyledDiv>
        <TextField
          minValue={MIN_INPUT_VALUE}
          autofocus
          primary
          vehicleCount
          label='Vehicle Count'
          name='occupiedCount'
          placeholder='Occupied'
          type='number'
          readonly={disableInputField}
          removeCursor
        />
        <VerticalLine />
        <TextField
          minValue={MIN_INPUT_VALUE}
          vehicleCount
          label=''
          name='spotCount'
          placeholder='Total'
          type='number'
          readonly={disableInputField}
        />
      </StyledDiv>
      <StyledDiv>
        {!userIsOperater && (
          <Button
            size={ButtonSize.MEDIUM}
            type='button'
            customHeight={CUSTOM_HEIGHT}
            primary
            onClick={onClick}
            hideButton={!disableInputField}
          >
            <ButtonWrapper>
              Edit <i className={'pa-edit'} />
            </ButtonWrapper>
          </Button>
        )}
        <Button
          size={ButtonSize.MEDIUM}
          type='reset'
          customHeight={CUSTOM_HEIGHT}
          onClick={onClick}
          hideButton={disableInputField}
          testId='displayCounterCloseButton'
        >
          Close
        </Button>
        <Button
          size={ButtonSize.MEDIUM}
          type='submit'
          customHeight={CUSTOM_HEIGHT}
          primary
          addLeftMargin={CUSTOM_LEFT_MARGIN}
          hideButton={disableInputField}
          testId='displayCounterSaveButton'
        >
          Save
        </Button>
      </StyledDiv>
    </Control>
  );
};
export default DisplayCounter;

const ButtonWrapper = styled.div`
  min-width: 75px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 520px) {
    margin: 15px;
  }
`;
