enum ActionIcon {
  EDIT = 'pa-edit',
  DELETE = 'pa-delete',
  REFRESH = 'pa-android-sync',
  ADD = 'pa-android-add',
  REMOVE = 'pa-android-remove',
  TIME = 'pa-time',
  EYE = 'pa-eye',
  DISABLED_EYE = 'pa-eye-disabled',
}

export default ActionIcon;
