import { UnitLabels } from 'src/constants/Parking/TariffUnits';
import type ActivePeriodResponse from 'src/models/Parking/Tariff/ActivePeriodResponse';
import type TariffUpdateParams from 'src/models/Parking/Tariff/TariffUpdateParams';
import type TariffUpdateValues from 'src/models/Parking/Tariff/TariffUpdateValues';
import formatDate from 'src/utils/formatDate';
import generateTimeLabel from 'src/utils/generateTimeLabel';
import getBinaryDates from 'src/utils/getBinaryDates';

export default function toTariffUpdateParams(
  data: TariffUpdateValues,
  zoneId?: number
): TariffUpdateParams {
  const {
    name,
    price,
    pricingInterval,
    freeTimeInterval,
    gracePeriod,
    daysOfWeek,
    activeDatePeriods,
    activeTimePeriods,
    maxTimeOfStay,
    maxTimeOfStayUnit,
    overstayFee,
    overstayFeeInterval,
    overstayFeeUnit,
  } = data;

  const responseBody: TariffUpdateParams = {
    zone_ids: zoneId ? [zoneId] : undefined,
    name,
    price,
    pricing_interval: pricingInterval
      ? UnitLabels[pricingInterval.key]
      : undefined,
    free_time_interval: freeTimeInterval,
    grace_period: gracePeriod,
    days_of_week: getBinaryDates(daysOfWeek),
    overstay_fee: overstayFee,
    overstay_fee_unit: overstayFeeUnit
      ? UnitLabels[overstayFeeUnit.key]
      : overstayFeeUnit === null
      ? null
      : undefined,
    overstay_fee_interval: overstayFeeInterval,
    max_time_of_stay: maxTimeOfStay,
    max_time_of_stay_unit: maxTimeOfStayUnit
      ? UnitLabels[maxTimeOfStayUnit.key]
      : maxTimeOfStayUnit === null
      ? null
      : undefined,
  };

  if (activeDatePeriods?.length === 0 && activeTimePeriods?.length === 0) {
    responseBody.active_periods = [];
    return responseBody;
  }

  if (activeTimePeriods?.length === 0) {
    responseBody.active_periods = activeDatePeriods?.map((v) => ({
      start_date: formatDate(v.from.toString(), true),
      end_date: formatDate(v.to.toString(), true),
      start_time: null,
      end_time: null,
    }));
    return responseBody;
  }

  if (activeDatePeriods?.length === 0) {
    responseBody.active_periods = activeTimePeriods?.map((v) => ({
      start_date: null,
      end_date: null,
      start_time: generateTimeLabel(v.from.key, true, true),
      end_time: generateTimeLabel(v.to.key, true, true),
    }));
    return responseBody;
  }

  const periods: ActivePeriodResponse[] = [];
  activeDatePeriods?.forEach((datePeriod) => {
    activeTimePeriods?.forEach((timePeriod) =>
      periods.push({
        start_date: formatDate(datePeriod.from.toString(), true),
        end_date: formatDate(datePeriod.to.toString(), true),
        start_time: generateTimeLabel(timePeriod.from.key, true, true),
        end_time: generateTimeLabel(timePeriod.to.key, true, true),
      })
    );
  });

  responseBody.active_periods = periods;
  return responseBody;
}
