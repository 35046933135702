import React from 'react';
import type * as yup from 'yup';
import type { ObjectShape } from 'yup/lib/object';

interface StepProps {
  children: React.ReactNode;
  name: string;
  label: string;
  validationSchema?: yup.ObjectSchema<ObjectShape>;
}

export default function Step(props: StepProps) {
  const { children } = props;

  return <>{children}</>;
}
