import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalConfirmationModal } from 'src/components/Shared/Modals/GlobalConfirmationModal';
import SocketEvents from 'src/constants/Shared/SocketEvents';
import SocketPath from 'src/constants/Shared/SocketPath';
import useSocket from 'src/hooks/Shared/useSocket';
import toDetectGatewayChange from 'src/mappers/Parking/DetectGateway/toDetectGatewayChange';
import toDetectGatewayFormValues from 'src/mappers/Parking/DetectGateway/toDetectGatewayFormValues';
import type DetectGateway from 'src/models/Parking/DetectGateway/DetectGateway';
import type DetectGatewayChange from 'src/models/Parking/DetectGateway/DetectGatewayChange';
import type DetectGatewayChangePayload from 'src/models/Parking/DetectGateway/DetectGatewayChangePayload';
import type DetectGatewayFormValues from 'src/models/Parking/DetectGateway/DetectGatewayFormValues';
import useDetectGatewayService from 'src/services/Parking/useDetectGatewayService';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import { isNotString } from '../../../utils/checks';
import ExitButton from '../../Shared/Buttons/ExitButton';
import Form from '../../Shared/Form';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import Tabs from '../../Shared/Tabs';
import DetectGatewayUpdateForm from '../Detection/DetectGatewayUpdateForm';

interface DetectGatewayDetailsModalProps {
  id: number | undefined;
  closeParentModal: () => void;
  onGatewayNameChange: (name: string, id: number) => void;
  onRemoveDetectGateway: (id: number) => void;
}

const defaultFormValues: DetectGatewayFormValues = {
  name: '',
  uuid: '',
};

const validationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
  uuid: yup.string().required('UUID is a required field'),
});

export default function ParkingPlaceDetailsModal(
  props: DetectGatewayDetailsModalProps
) {
  const { id, closeParentModal, onGatewayNameChange, onRemoveDetectGateway } =
    props;

  const [gatewayDetails, setGatewayDetails] = useState<
    DetectGateway | undefined
  >();
  const [initialValues, setInitialValues] = useState(defaultFormValues);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const { findOneDetectGateway, updateDetectGateway, removeDetectGateway } =
    useDetectGatewayService();
  const isMounted = useIsMounted();
  const socket = useSocket(SocketPath.SOCKETIO);

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
  });

  useEffect(() => {
    const handleDetectGatewayChange = (payload: string) => {
      try {
        const eventPayload: DetectGatewayChangePayload = JSON.parse(payload);
        const eventData: DetectGatewayChange =
          toDetectGatewayChange(eventPayload);

        const { gateways } = eventData;

        setGatewayDetails((oldValue) => {
          if (!oldValue) return undefined;
          const value = gateways.find((gateway) => gateway.id === oldValue.id);
          if (!value) return oldValue;

          return {
            ...oldValue,
            ...value,
          };
        });
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };
    socket?.on(
      SocketEvents.PARKING_GATEWAY_STATE_CHANGE,
      handleDetectGatewayChange
    );

    return () => {
      socket?.off(
        SocketEvents.PARKING_GATEWAY_STATE_CHANGE,
        handleDetectGatewayChange
      );
    };
  }, [socket, openGlobalFailureModal, setMessage]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!id) {
          return;
        }
        if (isMounted()) {
          setAreDetailsLoading(true);
        }

        const data = await findOneDetectGateway(id);
        const values = toDetectGatewayFormValues(data);

        if (isMounted()) {
          setInitialValues(values);
          setGatewayDetails(data);
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    getData();
  }, [id, isMounted, findOneDetectGateway]);

  const getChangedValues = useCallback(
    (values: DetectGatewayFormValues) => ({
      name: values.name !== initialValues.name ? values.name : undefined,
      uuid: values.uuid !== initialValues.uuid ? values.uuid : undefined,
    }),
    [initialValues]
  );

  const onSubmit = useCallback(
    async (
      values: DetectGatewayFormValues,
      { setErrors }: FormikHelpers<DetectGatewayFormValues>
    ) => {
      try {
        if (!id) {
          return;
        }

        const valuesToUpdate = getChangedValues(values);

        await updateDetectGateway(id, valuesToUpdate);

        if (isMounted()) {
          setMessageSuccess('Successfuly updated Detect Gateway!');
          openGlobalSuccessModal();
          onGatewayNameChange(values.name, id);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      id,
      onGatewayNameChange,
      openGlobalFailureModal,
      setMessage,
      openGlobalSuccessModal,
      isMounted,
      updateDetectGateway,
      getChangedValues,
    ]
  );

  const onParkingPlaceRemove = useCallback(async () => {
    try {
      if (!id) {
        return;
      }
      await removeDetectGateway(id);

      if (isMounted()) {
        setMessageSuccess('Detect Gateway deleted successfully!');
        onRemoveDetectGateway(id);
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    id,
    removeDetectGateway,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    onRemoveDetectGateway,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onParkingPlaceRemove,
    message: 'Are you sure you want to remove this Detect Gateway?',
  });

  return (
    <>
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='detectGateway'
            initialValues={initialValues}
            validationSchema={validationSchema}
            isLoading={areDetailsLoading}
            onSubmit={onSubmit}
          >
            <DetectGatewayUpdateForm
              details={gatewayDetails}
              isLoading={areDetailsLoading}
              closeParentModal={closeParentModal}
              openRemoveModal={openGlobalConfirmationModal}
            />
          </Form>
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
