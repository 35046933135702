import type EmailConfirmationQuery from '../../models/Settings/EmailConfirmationQuery';

export default function toEmailConfirmationQuery(
  token: string,
  signature: string
): EmailConfirmationQuery {
  return {
    token,
    signature,
  };
}
