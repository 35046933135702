import type AccountRole from '../../models/Shared/AccountRole';
import type AccountRoleResponse from '../../models/Shared/AccountRoleResponse';

export default function toAccountRole(data: AccountRoleResponse): AccountRole {
  const { id, name, deleted_at, created_at, updated_at } = data;

  return {
    id,
    name,
    deletedAt: new Date(deleted_at),
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
  };
}
