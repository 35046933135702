import type KeyInvitationStatistics from 'src/models/Statistics/KeyInvitation/KeyInvitationStatistics';
import type KeyInvitationStatisticsResponse from 'src/models/Statistics/KeyInvitation/KeyInvitationStatisticsResponse';

export default function toKeyInvitationStatistics(
  data: KeyInvitationStatisticsResponse
): KeyInvitationStatistics {
  const { pending, reactived, blocked, invalid, deleted, expired, activated } =
    data;

  return {
    pending,
    reactived,
    blocked,
    invalid,
    deleted,
    expired,
    activated,
  };
}
