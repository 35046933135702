import type AccountInvoiceFormValues from 'src/models/Settings/AccountInvoiceFormValues';
import type Profile from 'src/models/Settings/Profile';

export default function toAccountInvoiceFormValues(
  data: Profile
): AccountInvoiceFormValues | undefined {
  const { account } = data;

  if (!account) {
    return undefined;
  }

  const { invoiceCountry, invoiceCurrency } = account;

  return {
    invoiceCountry: invoiceCountry
      ? { key: invoiceCountry.id, label: invoiceCountry.name }
      : null,
    invoiceCurrency: invoiceCurrency
      ? { key: invoiceCurrency.id, label: invoiceCurrency.name }
      : null,
  };
}
