import type { FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import type KeySharingFormValues from '../../../models/KeySharing/KeySharingFormValues';
import useOperationKeysService from '../../../services/KeyManagement/useOperationKeysService';
import { isNotString } from '../../../utils/checks';
import Form from '../../Shared/Form';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import KeySharingForm from '../KeySharing/KeySharingForm';

interface ShareDigitalKeyModalProps {
  productId: number[];
  closeParentModal: () => void;
  setSharableProducts: (products: number[]) => void;
}

enum DropdownValues {
  NO_REPEAT = 1,
  WEEKLY = 2,
}

const initialValues: KeySharingFormValues = {
  receiverEmails: '',
  startTime: null,
  endTime: null,
  isUnlimited: false,
};

const validationSchema = yup.object().shape({
  receiverEmails: yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[ ,;:]+/) : [];
    })
    .of(yup.string().email(({ value }) => `${value} is not a valid email `))
    .required('Email is a required field'),
  isUnlimited: yup.boolean().notRequired(),
  startTime: yup.date().nullable().required('Start Time is a required field'),
  endTime: yup
    .date()
    .nullable()
    .when('isUnlimited', {
      is: false,
      then: yup
        .date()
        .required('End Time is a required field')
        .min(yup.ref('startTime'), "End Time can't be before Start Time"),
    }),
});

export default function ShareDigitalkeyModal(props: ShareDigitalKeyModalProps) {
  const { productId, closeParentModal, setSharableProducts } = props;
  const isMounted = useIsMounted();
  const { createOperationKey } = useOperationKeysService();

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: 'All digital keys have been successfully created!',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const options = [
    { key: DropdownValues.NO_REPEAT, label: 'Does not repeat' },
    { key: DropdownValues.WEEKLY, label: 'Every week' },
  ];

  const onSubmit = useCallback(
    async (
      values: KeySharingFormValues,
      { setErrors, resetForm }: FormikHelpers<KeySharingFormValues>
    ) => {
      try {
        if (!productId) {
          return;
        }

        await createOperationKey(productId, values);
        if (isMounted()) {
          resetForm({});
          openGlobalSuccessModal();
          setSharableProducts([]);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      productId,
      createOperationKey,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setSharableProducts,
      setMessage,
    ]
  );

  return (
    <Form
      name='ShareDigitalKey'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <KeySharingForm closeParentModal={closeParentModal} options={options} />
    </Form>
  );
}
