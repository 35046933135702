import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import type AccountUser from 'src/models/Settings/AccountUser';
import ManagerModal from '../../components/Parking/ManagerModal';
import ManagerTable from '../../components/Parking/ManagerTable';
import GlobalModal from '../../components/Shared/Modals/GlobalModal';
import { useGlobalModal } from '../../hooks/Shared/useGlobalModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type Manager from '../../models/Parking/Manager/Manager';
import useManagerService from '../../services/Parking/useManagerService';

export default function ManagerList() {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<Manager[]>([]);
  const isMounted = useIsMounted();
  const { findAllManagers, addManagers, removeManager } = useManagerService();
  const { params } = useRouteMatch<{ lotId?: string }>();
  const { lotId } = params;
  const id = useMemo(() => {
    if (!lotId) {
      return undefined;
    }

    return parseInt(lotId);
  }, [lotId]);

  const [openManageModal, closeManageModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <ManagerModal
        managers={data}
        addManager={addManager}
        deleteManager={deleteManager}
        closeParentModal={closeManageModal}
      />
    </GlobalModal>
  ));

  useEffect(() => {
    const getData = async () => {
      try {
        if (!id) {
          return;
        }

        if (isMounted()) {
          setIsDataLoading(true);
        }

        const data = await findAllManagers(id);

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }

        throw error;
      }
    };

    getData();
  }, [findAllManagers, isMounted, id]);

  const addManager = useCallback(
    async (manager: AccountUser) => {
      if (!id) {
        return;
      }

      const response = await addManagers(id, manager.id);

      if (isMounted()) {
        if (response[0].success) {
          setData((oldData) => [
            ...oldData,
            {
              id: response[0].id,
              accountUser: manager,
            },
          ]);
        }
      }
    },
    [addManagers, id, isMounted]
  );

  const deleteManager = useCallback(
    async (managerId: number) => {
      if (!id) {
        return;
      }

      await removeManager(id, managerId);

      if (isMounted()) {
        setData((oldData) =>
          oldData.filter((manager) => manager.accountUser.id !== managerId)
        );
      }
    },
    [removeManager, id, isMounted]
  );

  return (
    <ManagerTable
      data={data}
      manageUsers={openManageModal}
      isLoading={isDataLoading}
    />
  );
}
