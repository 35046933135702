import React from 'react';
import InputIcon from '../../constants/Auth/InputIcon';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from './Button';
import Input from './Input';

export default function ResetPasswordForm() {
  return (
    <>
      <Input
        name='email'
        type='text'
        icon={InputIcon.USER}
        placeholder='Enter Email'
        label='Email'
      />
      <Button size={ButtonSize.FULL} type='submit' primary>
        Reset your password
      </Button>
    </>
  );
}
