import type WeblinkUpdateParams from 'src/models/KeySharing/Weblink/WeblinkUpdateParams';
import type WeblinkUpdateValues from 'src/models/KeySharing/Weblink/WeblinkUpdateValues';

export default function toWeblinkUpdateParams(
  data: WeblinkUpdateValues
): WeblinkUpdateParams {
  const { ids, status } = data;

  return { ids, status };
}
