import React, { useCallback } from 'react';
import type Tariff from 'src/models/Parking/Tariff/Tariff';
import { changeDateFormat } from 'src/utils/formatDateTime';
import styled from 'styled-components';
import ActionIcon from '../../constants/Shared/ActionIcon';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Content from '../Shared/Content';
import Control from '../Shared/Control';
import StyledHeader from '../Shared/StyledHeader';
import Table from '../Shared/Table';
import Title from '../Shared/Title';
import ToggleSwitchForFormik from '../Shared/ToggleSwitchForFormik';

interface TariffTableProps {
  data: Tariff[];
  findCurrencySymbol: (id: number) => void;
  openCreateModal?: () => void;
  closeParentModal?: () => void;
  openEditModal?: (data: Tariff) => void;
  openRemoveModal?: (data: Tariff) => void;
  isLoading?: boolean;
  zoneView?: boolean;
  addTopMargin?: boolean;
  modalView?: boolean;
}

export default function TariffTable(props: TariffTableProps) {
  const {
    data,
    openCreateModal,
    openRemoveModal,
    isLoading,
    findCurrencySymbol,
    openEditModal,
    zoneView,
    addTopMargin,
    modalView,
    closeParentModal,
  } = props;

  const editTariff = useCallback(
    (data?: Tariff) => {
      if (!data || !openEditModal) {
        return;
      }

      openEditModal(data);
    },
    [openEditModal]
  );

  const removeTariff = useCallback(
    (data?: Tariff) => {
      if (!data || !openRemoveModal) {
        return;
      }

      openRemoveModal(data);
    },
    [openRemoveModal]
  );

  return (
    <>
      <StyledHeader>
        {modalView && <Title tabModalView>List of Tariffs</Title>}
        {!zoneView && (
          <>
            <Title>Tariffs</Title>
            <Button
              primary
              size={ButtonSize.LARGE}
              onClick={openCreateModal}
              tariffView
            >
              Add New Tariff
            </Button>
          </>
        )}
      </StyledHeader>
      <Content
        widthSize={zoneView ? '100%' : '70%'}
        addPadding={zoneView}
        anprTopMargin={addTopMargin}
      >
        <Card removeAllStyles={zoneView}>
          <Table isLoading={isLoading}>
            <Table.Head>
              <Table.Row>
                <Table.Header>TARIFF NAME</Table.Header>
                <Table.Header>DATE</Table.Header>
                <Table.Header>HOURS</Table.Header>
                <Table.Header>PRICE</Table.Header>
                {modalView && <Table.Header />}
                {!zoneView && <Table.Header colSpan={2} />}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {data.map((tariff, index) => {
                const {
                  id,
                  name,
                  activePeriods,
                  price,
                  currencyId,
                  pricingInterval,
                } = tariff;

                return (
                  <Table.Row key={id}>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell flexColumn>
                      {activePeriods.dates.map((date, index) => {
                        const { startDate, endDate } = date;
                        return (
                          <DateTimeDiv key={index}>
                            {changeDateFormat(startDate)}-
                            {changeDateFormat(endDate)}
                          </DateTimeDiv>
                        );
                      })}
                    </Table.Cell>
                    <Table.Cell flexColumn>
                      {activePeriods.times.map((time, index) => {
                        const { startTime, endTime } = time;
                        return (
                          <DateTimeDiv key={index}>
                            {startTime?.slice(0, -3)}-{endTime?.slice(0, -3)}
                          </DateTimeDiv>
                        );
                      })}
                    </Table.Cell>
                    <Table.Cell>
                      <>
                        {price} {findCurrencySymbol(currencyId)} / per{' '}
                        {pricingInterval}
                      </>
                    </Table.Cell>
                    {modalView && (
                      <Table.Cell>
                        <ToggleSwitchForFormik
                          name={`tariffIds.${index}.activated`}
                          label=''
                          condition
                          hideLabel
                        />
                      </Table.Cell>
                    )}
                    {!zoneView && (
                      <>
                        <Table.Cell
                          center
                          action={removeTariff}
                          primary
                          data={tariff}
                        >
                          <Table.Icon icon={ActionIcon.DELETE} />
                        </Table.Cell>
                        <Table.Cell
                          center
                          action={editTariff}
                          primary
                          data={tariff}
                        >
                          <Table.Icon icon={ActionIcon.EDIT} />
                        </Table.Cell>
                      </>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Card>
      </Content>
      {modalView && (
        <Control>
          <Button
            onClick={closeParentModal}
            size={ButtonSize.LARGE}
            type='button'
          >
            Cancel
          </Button>
          <Button size={ButtonSize.LARGE} type='submit' primary>
            Add to zone
          </Button>
        </Control>
      )}
    </>
  );
}

const DateTimeDiv = styled.div`
  margin-top: 10px;
`;
