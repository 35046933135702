import type ProductQuery from 'src/models/Parking/ProductQuery';
import type ProductQueryParams from 'src/models/Parking/ProductQueryParams';

export default function toProductQuery(data: ProductQueryParams): ProductQuery {
  const { lotId, zoneId, productTypeId, orderByDirection, page, size } = data;

  return {
    lot_id: lotId,
    zone_id: zoneId,
    order_by_direction: orderByDirection,
    product_type_id: productTypeId || undefined,
    page,
    size,
  };
}
