import { Formik, Form as UnstyledForm } from 'formik';
import type { FormikFormProps, FormikHelpers } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import styled from 'styled-components';
import LoadingContext from '../../../contexts/Shared/LoadingContext';
import WizardContext from '../../../contexts/Shared/WizardContext';
import { isNotArray, isNotNullOrUndefined } from '../../../utils/checks';
import invariant from '../../../utils/invariant';
import Breadcrumbs from './Breadcrumbs';
import Step from './Step';

interface WizardProps<Values> {
  name: string;
  initialValues: Values;
  onSubmit: (values: Values, helpers: FormikHelpers<Values>) => Promise<void>;
  children: JSX.Element[];
  isLoading?: boolean;
}

export default function Wizard<Values extends object>(
  props: WizardProps<Values>
) {
  const { name, onSubmit, initialValues, children, isLoading } = props;
  const isMounted = useIsMounted();

  invariant(!isNotArray(children), 'Wizzard has to have at least 2 steps');

  const [initial] = children;

  const nameIndexMap = useMemo(
    () =>
      children.reduce<{ [key: string]: number }>((acc, element, index) => {
        const {
          type,
          props: { name },
        } = element;

        invariant(
          !isNotNullOrUndefined(acc[name]),
          'WizzardStep name has to be unique'
        );

        invariant(
          type === Step,
          'Wizzard child has to be instance of Wizzard.Step'
        );

        return { ...acc, [name]: index };
      }, {}),
    [children]
  );

  const [current, setCurrent] = useState(initial);

  const next = useCallback(
    (skip?: boolean) => {
      const index = nameIndexMap[current.props.name];

      if (index >= children.length - 1) {
        return;
      }

      if (isMounted()) {
        if (skip === true) {
          setCurrent(children[index + 2]);
          return;
        }
        setCurrent(children[index + 1]);
      }
    },
    [current, children, nameIndexMap, isMounted]
  );

  const back = useCallback(
    (skip?: boolean) => {
      const index = nameIndexMap[current.props.name];

      if (index <= 0) {
        return;
      }

      if (isMounted()) {
        if (skip === true) {
          setCurrent(children[index - 2]);
          return;
        }
        setCurrent(children[index - 1]);
      }
    },
    [current, children, nameIndexMap, isMounted]
  );

  const breadcrumbsData = useMemo(
    () =>
      children.map((child) => {
        const {
          props: { name, label },
        } = child;

        return { name, label };
      }),
    [children]
  );

  const activeIndex = nameIndexMap[current.props.name];

  const onSubmitHandler = useCallback(
    async (values: Values, helpers: FormikHelpers<Values>) => {
      await onSubmit(values, helpers);
      next();
    },
    [onSubmit, next]
  );

  return (
    <WizardContext.Provider
      value={{ next, back, breadcrumbsData, activeIndex }}
    >
      <Formik
        enableReinitialize
        onSubmit={onSubmitHandler}
        initialValues={initialValues}
        validationSchema={current.props.validationSchema}
      >
        <>
          <Breadcrumbs />
          <LoadingContext.Provider value={{ isLoading }}>
            <StyledForm name={name}>{current}</StyledForm>
          </LoadingContext.Provider>
        </>
      </Formik>
    </WizardContext.Provider>
  );
}

const StyledForm = styled(UnstyledForm)<FormikFormProps>`
  width: 100%;
`;

Wizard.Step = Step;
