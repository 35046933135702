import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GatewayPort from 'src/constants/Parking/GatewayPort';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import useIsGatewayAvailableService from 'src/services/Shared/useIsGatewayAvailableService';
import GatewayStatus from '../../constants/Parking/GatewayStatus';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useGatewayStatusLabel from '../../hooks/Parking/useGatewayStatusLabel';
import type Gateway from '../../models/Parking/Gateway/Gateway';
import type GatewayFormValues from '../../models/Parking/Gateway/GatewayFormValues';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import { useGlobalCancelModal } from '../Shared/Modals/GlobalCancelModal';
import Spinner from '../Shared/Spinner';
import TextField from '../Shared/TextField';
import DetailsColumn from './DetailsColumn';
import DetailsData from './DetailsData';
import DetailsRemove from './DetailsRemove';
import DetailsRow from './DetailsRow';
import DetailsStatus from './DetailsStatus';

interface GatewayDetailsPorps {
  details?: Gateway;
  openRemoveModal: (id: number) => void;
  isLoading?: boolean;
  closeParentModal: () => void;
}
export default function GatewayUpdateForm(props: GatewayDetailsPorps) {
  const { details, isLoading, openRemoveModal, closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext<GatewayFormValues>();
  const statusLabel = useGatewayStatusLabel(details?.status);
  const [url, setUrl] = useState('');
  const [isReachable, setIsReachable] = useState<boolean | undefined>(
    undefined
  );
  const { get } = useIsGatewayAvailableService();
  const isMounted = useIsMounted();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const onRemove = useCallback(() => {
    if (!details?.id) {
      return;
    }

    openRemoveModal(details.id);
  }, [openRemoveModal, details]);

  const shouldRenderDetails = !isLoading && details;
  const offline = details?.status === GatewayStatus.OFFLINE;

  useEffect(() => {
    const isIpReachable = async () => {
      try {
        if (!details) {
          return;
        }

        const { ipAddress } = details;

        if (ipAddress === '') {
          return;
        }

        const address = `http://${ipAddress}:${GatewayPort.GATEWAY_APP_PORT}`;

        await get(address);

        if (isMounted()) {
          setUrl(address);
          setIsReachable(true);
        }
      } catch (error) {
        if (isMounted()) {
          setIsReachable(false);
        }
        throw error;
      }
    };
    isIpReachable();
  }, [details, get, isMounted]);

  const ipAddressValue = useMemo(() => {
    if (!details || isReachable === undefined) {
      return 'Unknown';
    }

    if (isReachable === true) {
      return details.ipAddress;
    }

    return 'Unreachable via local network';
  }, [details, isReachable]);

  return (
    <>
      {isLoading && <Spinner primary />}
      {shouldRenderDetails && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='Name'
              placeholder='Name'
              type='text'
              name='name'
              stacked
              short
            />
            <DetailsStatus offline={offline} addVerticalMargin>
              {statusLabel}
            </DetailsStatus>
            <Line />
            <DetailsData label='UUID' value={details.uuid} />
            {false && (
              <>
                <DetailsData
                  label='FIRMWARE'
                  value={details?.firmwareVersion || ''}
                />
                <DetailsData
                  label='IP ADDRESS'
                  value={ipAddressValue}
                  linkValue={isReachable ? url : false}
                />
              </>
            )}
            <Line />
            <DetailsRemove onRemove={onRemove} />
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
