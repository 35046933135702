import React, { useCallback, useMemo } from 'react';
import AppRole from 'src/constants/Shared/AppRole';
import ArrowIcons from 'src/constants/Shared/ArrowIcons';
import Color from 'src/constants/Shared/Color';
import useLicenseContext from 'src/hooks/Shared/useLicenseContext';
import useUserHasRole from 'src/hooks/Shared/useUserHasRole';
import type Tariff from 'src/models/Parking/Tariff/Tariff';
import type TariffId from 'src/models/Parking/Tariff/TariffId';
import type ZoneModel from 'src/models/Parking/Zone/Zone';
import type ZoneData from 'src/models/Parking/Zone/ZoneData';
import styled from 'styled-components';
import Card from '../Shared/Card';
import Line from '../Shared/Line';
import TextField from '../Shared/TextField';
import ToggleSwitchForFormik from '../Shared/ToggleSwitchForFormik';
import ZonesProducts from './ZoneProducts';
import ZonesTariffs from './ZoneTariffs';

interface ZoneProps {
  data: ZoneData;
  index: number;
  onClick: () => void;
  toggle: boolean;
  zoneId: number;
  elementId: string | undefined;
  openRemoveModal: (data: ZoneModel) => void;
  onCameraNameChange: (name: string, id: number, zoneId?: number) => void;
  onProductNameChange: (name: string, id: number, zoneId?: number) => void;
  onTariffChange: (tariffs: TariffId[] | Tariff, zoneId: number) => void;
  findCurrencySymbol: (id: number) => void;
  invoiceCurrency:
    | { id: number | undefined; symbol: string | undefined }
    | undefined;
  accountId: number | undefined;
}

export default function Zone(props: ZoneProps) {
  const {
    data,
    index,
    onClick,
    toggle,
    zoneId,
    elementId,
    openRemoveModal,
    onCameraNameChange,
    onProductNameChange,
    onTariffChange,
    findCurrencySymbol,
    invoiceCurrency,
    accountId,
  } = props;
  const { id, cameras } = data;
  const { purchasedLicenses } = useLicenseContext();
  const userHasRole = useUserHasRole();

  const userHasCamera = useMemo(() => {
    const numberOfLprCameras = purchasedLicenses.filter(
      (data) => data.lprCameras
    );
    return numberOfLprCameras.length > 0;
  }, [purchasedLicenses]);

  const userIsOperater = useMemo(() => {
    const roles = AppRole.PARKLIO_INTERNAL_OPERATOR | AppRole.PMS_OPERATOR;
    return userHasRole(roles);
  }, [userHasRole]);

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const removeItem = useCallback(
    (data?: ZoneModel) => {
      if (!data) {
        return;
      }

      openRemoveModal(data);
    },
    [openRemoveModal]
  );

  const InfoMessage = useMemo(() => {
    if (cameras.length > 0) {
      return 'If activated, everyone will be allowed to enter this zone.';
    }

    return 'This feature is enabled when ANPR camera is installed.';
  }, [cameras]);

  return (
    <StyledWrapper>
      <StyledDiv id={id === zoneId ? elementId : undefined}>
        <TextField
          label='Zone name*'
          placeholder='Zone name'
          type='text'
          name={`zoneInfo.${index}.name`}
          short
          zoneView
          disabled={userIsOperater}
        />
        <StyledIcon
          className={toggle ? ArrowIcons.UP : ArrowIcons.DOWN}
          onClick={onClick}
        />
      </StyledDiv>
      {toggle && (
        <Card zoneView>
          {userHasCamera && (
            <ToggleSwitchForFormik
              name={`zoneInfo.${index}.isPrivate`}
              label='Public entrance'
              condition={false}
              info={InfoMessage}
              disabled={cameras.length === 0 || userIsOperater}
              zoneView
            />
          )}
          <WrapperDiv>
            <ZonesProducts
              zone={data}
              onCameraNameChange={onCameraNameChange}
              onProductNameChange={onProductNameChange}
            />
            {userIsParklioInternal && (
              <ZonesTariffs
                data={data}
                findCurrencySymbol={findCurrencySymbol}
                invoiceCurrency={invoiceCurrency}
                accountId={accountId}
                onTariffChange={onTariffChange}
              />
            )}
          </WrapperDiv>
          <Line />
          <DeleteDiv onClick={() => removeItem(data)}>
            <StyledDeleteIcon className='pa-delete' />
            <StyledP>Delete Zone</StyledP>
          </DeleteDiv>
        </Card>
      )}
    </StyledWrapper>
  );
}

const StyledDiv = styled.div`
  background-color: ${Color.TEXT_DARKER};
  padding: 15px 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  scroll-margin-top: 80px;
  @media (max-width: 867px) {
    scroll-margin-top: 100px;
  }
  @media (max-width: 700px) {
    scroll-margin-top: 200px;
  }
`;

const DeleteDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 5px;
  cursor: pointer;
  color: ${Color.PRIMARY_BRAND};
  width: fit-content;
`;

const StyledIcon = styled.i`
  display: flex;
  width: 60px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  color: ${Color.BACKGROUND_LIGHTER};
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
`;

const StyledDeleteIcon = styled.i`
  display: flex;
  margin-right: 10px;
  font-size: 18px;
`;

const StyledP = styled.p`
  display: flex;
  margin-right: 10px;
  font-size: 14px;
`;

const StyledWrapper = styled.div`
  :last-child {
    margin-bottom: 40px;
  }
`;

const WrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
