import { useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import AppRole from '../../constants/Shared/AppRole';
import ButtonSize from '../../constants/Shared/ButtonSize';
import TimeZone from '../../constants/Shared/TimeZone';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';
import type LotFormValues from '../../models/Parking/Lot/LotFormValues';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Content from '../Shared/Content';
import Control from '../Shared/Control';
import Main from '../Shared/Main';
import Map from '../Shared/Map';
import Section from '../Shared/Section';
import SelectField from '../Shared/SelectField';
import TextArea from '../Shared/TextArea';
import TextField from '../Shared/TextField';
import Title from '../Shared/Title';

export default function LotInfoForm() {
  const { values, isSubmitting, setValues } = useFormikContext<LotFormValues>();
  const { latitude, longitude } = values;
  const lat = latitude ? parseFloat(latitude) : undefined;
  const lng = longitude ? parseFloat(longitude) : undefined;
  const userHasRole = useUserHasRole();

  const userCantEditLotInfo = useMemo(() => {
    const roles = AppRole.PARKLIO_INTERNAL_OPERATOR | AppRole.PMS_OPERATOR;

    return userHasRole(roles);
  }, [userHasRole]);

  const onMapClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (userCantEditLotInfo || !event) return;
      setValues((oldValues) => ({
        ...oldValues,
        latitude: event.latLng?.lat().toString() || '',
        longitude: event.latLng?.lng().toString() || '',
      }));
    },
    [setValues, userCantEditLotInfo]
  );

  return (
    <>
      <Main>
        <Content widthSize='49%'>
          <Title>Lot</Title>
          <Card>
            <Section>
              <TextField
                label='Name'
                placeholder='Name'
                type='text'
                name='name'
                short
                readonly={userCantEditLotInfo}
              />
              <TextField
                label='Address'
                placeholder='Address'
                type='text'
                name='address'
                short
                readonly={userCantEditLotInfo}
              />
              <SelectField
                label='Time Zone'
                placeholder='Time Zone'
                name='timeZone'
                options={TimeZone}
                short
                readonly={userCantEditLotInfo}
              />
              <TextArea
                name='description'
                label='Description'
                placeholder=''
                descriptionView
                readonly={userCantEditLotInfo}
              />
            </Section>
          </Card>
        </Content>
        <Content widthSize='49%'>
          <Title>Coordinates</Title>
          <Card>
            <Section>
              <StyledDiv>
                <TextField
                  label='Latitude'
                  placeholder='Latitude'
                  type='number'
                  name='latitude'
                  stacked
                  inline
                  readonly={userCantEditLotInfo}
                />
                <TextField
                  label='Longitude'
                  placeholder='Longitude'
                  type='number'
                  name='longitude'
                  stacked
                  inline
                  readonly={userCantEditLotInfo}
                />
              </StyledDiv>
              <Map
                onMapClick={onMapClick}
                centerLatitude={lat}
                centerLongitude={lng}
                removeTopMargin
              />
            </Section>
          </Card>
        </Content>
      </Main>
      <Control>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting || userCantEditLotInfo}
          addBottomMargin
        >
          Save all changes
        </Button>
      </Control>
    </>
  );
}

const StyledDiv = styled.div`
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
