import React from 'react';
import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';

interface CheckBoxProps {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  name?: string;
  readonly?: boolean;
  disabled?: boolean;
  title?: string;
  dropdown?: boolean;
}

export default function CheckBox(props: CheckBoxProps) {
  const {
    onChange,
    onClick,
    checked,
    name,
    readonly,
    disabled,
    title,
    dropdown,
  } = props;

  return (
    <StyledContainer title={title}>
      <StyledInput
        type='checkbox'
        onChange={onChange}
        onClick={onClick}
        checked={checked}
        name={name}
        readOnly={readonly}
        disabled={disabled}
      />
      <StyledBox dropdown={dropdown}>
        <StyledIcon className='pa-check' dropdown={dropdown} />
      </StyledBox>
    </StyledContainer>
  );
}

const StyledIcon = styled.i<{ dropdown?: boolean }>`
  font-weight: normal;
  display: none;
  color: ${Color.TEXT_LIGHTEST};

  ${(props) => {
    const { dropdown } = props;

    if (dropdown) {
      return css`
        font-size: 24px;
      `;
    }

    return css`
      font-size: 30px;
    `;
  }}
`;

const StyledBox = styled.div<{ dropdown?: boolean }>`
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  background-color: ${Color.BACKGROUND_LIGHTEST};

  ${(props) => {
    const { dropdown } = props;

    if (dropdown) {
      return css`
        width: 26px;
        height: 26px;
      `;
    }

    return css`
      width: 32px;
      height: 32px;
    `;
  }}
`;

const StyledContainer = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-right: 20px;

  > input:checked + ${StyledBox} {
    background-color: ${Color.PRIMARY_BRAND};
    border-color: ${Color.BORDER_BRAND};

    :hover {
      background-color: ${Color.PRIMARY_HOVER};
    }
  }

  > input:checked + ${StyledBox} ${StyledIcon} {
    display: block;
  }

  > input:disabled + ${StyledBox} {
    cursor: default;
    background-color: ${Color.BACKGROUND_LIGTH};
    border: 1px solid ${Color.BORDER_LIGHT};
    opacity: 0.5;
  }
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;
