import type ConcreteProduct from 'src/models/Parking/ConcreteProduct';
import type ConcreteProductResponse from 'src/models/Parking/ConcreteProductResponse';
import toConcreteProductError from './toConcreteProductError';

export default function toConcreteProduct(
  data: ConcreteProductResponse
): ConcreteProduct {
  const {
    id,
    device_id,
    name,
    zone_id,
    power_type,
    battery_status,
    status,
    firmware_version,
    updated_at,
    product_error,
    product_type_id,
  } = data;

  return {
    id,
    deviceId: device_id,
    name,
    zoneId: zone_id,
    powerType: power_type,
    batteryStatus: battery_status,
    status,
    firmwareVersion: firmware_version,
    updatedAt: updated_at,
    productError: product_error ? toConcreteProductError(product_error) : null,
    productTypeId: product_type_id,
  };
}
