import React from 'react';
import type License from 'src/models/License/License';
import type LicensePurchase from 'src/models/License/LicensePurchase';
import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';

interface GetPurchaseDescriptionProps {
  productValue: number;
  maxValue: number;
  purchase: number;
  comma: boolean;
  description: string;
}

interface GetDescriptionProps {
  productValue: number;
  description: string;
  comma: boolean;
}

export function GetLicenseDescription(license: License | undefined) {
  if (!license) return '';
  const { barriersCount, lprCamerasCount, brainModulesCount } = license;

  const products = [
    {
      product: barriersCount,
      desciption: 'Barriers',
    },
    {
      product: lprCamerasCount,
      desciption: 'Cameras',
    },
    {
      product: brainModulesCount,
      desciption: 'Gates / Chains / Bollards',
    },
  ];

  const filteredProducts = products.filter((product) => product.product > 0);

  return filteredProducts.map((productValue, index) => {
    const { product, desciption } = productValue;
    return (
      <React.Fragment key={index}>
        <GetDescription
          productValue={product}
          description={desciption}
          comma={index + 1 !== filteredProducts.length}
        />
      </React.Fragment>
    );
  });
}

export function GetLicensePurchaseDescription(
  licensePurchase: LicensePurchase | undefined
) {
  if (!licensePurchase) return '';
  const {
    purchasedCount,
    barriers,
    lprCameras,
    gates,
    brains,
    chains,
    bollards,
    licensePricing,
  } = licensePurchase;
  const { license } = licensePricing;
  if (!license) return undefined;
  const { barriersCount, lprCamerasCount, brainModulesCount } = license;

  const brainModules = gates + brains + chains + bollards;

  const products = [
    {
      productValue: barriers,
      maxValue: barriersCount,
      desciption: 'Barriers',
    },
    {
      productValue: lprCameras,
      maxValue: lprCamerasCount,
      desciption: 'Cameras',
    },
    {
      productValue: brainModules,
      maxValue: brainModulesCount,
      desciption: 'Gates / Chains / Bollards',
    },
  ];

  const filteredProducts = products.filter((product) => product.maxValue > 0);

  return filteredProducts.map((product, index) => {
    const { productValue, maxValue, desciption } = product;
    return (
      <React.Fragment key={index}>
        <GetPurchaseDescription
          productValue={productValue}
          maxValue={maxValue}
          purchase={purchasedCount}
          comma={index + 1 !== filteredProducts.length}
          description={desciption}
        />
      </React.Fragment>
    );
  });
}

export function GetPurchaseDescription(props: GetPurchaseDescriptionProps) {
  const { productValue, maxValue, purchase, comma, description } = props;
  return (
    <span>
      <StyledSpan maxValue={productValue === maxValue * purchase}>
        {productValue === null ? 0 : productValue}/{maxValue * purchase}{' '}
      </StyledSpan>
      {description}
      {comma && ', '}
    </span>
  );
}

export function GetDescription(props: GetDescriptionProps) {
  const { productValue, description, comma } = props;
  return (
    <>
      {`${productValue} ${description}`}
      {comma && ', '}
    </>
  );
}

const StyledSpan = styled.span<{ maxValue?: boolean }>`
  ${(props) => {
    const { maxValue } = props;

    if (maxValue) {
      return css`
        color: ${Color.ERROR};
      `;
    }
    return css``;
  }}
`;
