import type LprImageStorageSubscriptionUpdateFormValues from 'src/models/Parking/Anpr/ImageStorageSubscription/LprImageStorageSubscriptionUpdateFormValues';
import type LprImageStorageSubscriptionUpdateParams from 'src/models/Parking/Anpr/ImageStorageSubscription/LprImageStorageSubscriptionUpdateParams';

export default function toLprImageStorageSubscriptionUpdateParams(
  data: LprImageStorageSubscriptionUpdateFormValues
): LprImageStorageSubscriptionUpdateParams {
  const { status } = data;

  return {
    status,
  };
}
