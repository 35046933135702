import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import type LicensePricing from 'src/models/License/LicensePricing';
import useLicenseManagementService from 'src/services/License/useLicenseManagementService';
import * as yup from 'yup';
import LicenseForm from '../../components/Home/LicenseForm';
import Form from '../../components/Shared/Form';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../components/Shared/Modals/GlobalSuccessModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type LicenseFormValues from '../../models/License/LicenseFormValues';
import { isNotString } from '../../utils/checks';

interface LicenseModalProps {
  closeParentModal: () => void;
  activeLicensePricingId?: number | undefined;
  activeLicenseId?: number | undefined;
  onLicneseUpdate: (data: LicensePricing) => void;
  onRemove: (id: number) => void;
}

const defaultFormValues: LicenseFormValues = {
  name: '',
  status: false,
  licenseTypeId: 2,
  lprCamerasCount: 0,
  brainModulesCount: 0,
  barriersCount: 0,
  price: 0,
  paymentInfo: { key: 0, label: 'One time' },
};

const licenseModalValidationSchema = yup.object({
  name: yup.string().required('License Name is a required field'),
  lprCamerasCount: yup
    .number()
    .required('Number of LPR cameras is a required field')
    .min(0),
  brainModulesCount: yup
    .number()
    .required('Number of Brain modules is a required field')
    .min(0),
  barriersCount: yup
    .number()
    .required('Number of Barriers is a required field')
    .min(0),
  price: yup.number().required('Price is a required field').min(0),
  paymentInfo: yup
    .object()
    .nullable()
    .required('Payment info is a required field'),
});

export default function LicenseModal(props: LicenseModalProps) {
  const {
    closeParentModal,
    activeLicensePricingId,
    activeLicenseId,
    onLicneseUpdate,
    onRemove,
  } = props;
  const {
    addLicenseManagement,
    getLicenseManagementById,
    updateLicenseManagement,
  } = useLicenseManagementService();
  const isMounted = useIsMounted();
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [initialLicenseModalValues, setInitialLicenseModalValues] =
    useState<LicenseFormValues>(defaultFormValues);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
    refreshPage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const removeItem = useCallback(() => {
    if (!activeLicensePricingId) return;

    onRemove(activeLicensePricingId);
  }, [onRemove, activeLicensePricingId]);

  useEffect(() => {
    const loadLicenseDetails = async () => {
      if (!activeLicensePricingId || !activeLicenseId) {
        return;
      }
      try {
        if (isMounted()) {
          setAreDetailsLoading(true);
        }
        const data = await getLicenseManagementById(
          activeLicensePricingId,
          activeLicenseId
        );

        if (isMounted()) {
          setInitialLicenseModalValues(data);
          setAreDetailsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setAreDetailsLoading(false);
        }
        throw error;
      }
    };
    loadLicenseDetails();
  }, [
    activeLicensePricingId,
    activeLicenseId,
    isMounted,
    getLicenseManagementById,
  ]);

  const getChangedValues = useCallback(
    (values: LicenseFormValues) => ({
      name:
        values.name !== initialLicenseModalValues.name
          ? values.name
          : undefined,
      status:
        values.status !== initialLicenseModalValues.status
          ? values.status
          : undefined,
      lprCamerasCount:
        values.lprCamerasCount !== initialLicenseModalValues.lprCamerasCount
          ? values.lprCamerasCount
          : undefined,
      brainModulesCount:
        values.brainModulesCount !== initialLicenseModalValues.brainModulesCount
          ? values.brainModulesCount
          : undefined,
      barriersCount:
        values.barriersCount !== initialLicenseModalValues.barriersCount
          ? values.barriersCount
          : undefined,
      price:
        values.price !== initialLicenseModalValues.price
          ? values.price
          : undefined,
    }),
    [initialLicenseModalValues]
  );

  const onAddNewLicenseSubmit = useCallback(
    async (
      values: LicenseFormValues,
      { setErrors, resetForm }: FormikHelpers<LicenseFormValues>
    ) => {
      try {
        if (!activeLicensePricingId || !activeLicenseId) {
          await addLicenseManagement(values);

          if (isMounted()) {
            resetForm({});
            setMessageSuccess('Successfuly created new license!');
            setRefreshPage(true);
            openGlobalSuccessModal();
          }
        } else {
          const valuesToUpdate = getChangedValues(values);

          const response = await updateLicenseManagement(
            values,
            valuesToUpdate,
            activeLicensePricingId,
            activeLicenseId
          );

          onLicneseUpdate(response);

          if (isMounted()) {
            setMessageSuccess('Successfuly updated license!');
            setRefreshPage(false);
            openGlobalSuccessModal();
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
      addLicenseManagement,
      getChangedValues,
      updateLicenseManagement,
      onLicneseUpdate,
      activeLicenseId,
      activeLicensePricingId,
    ]
  );

  return (
    <Form
      name='addNewLicense'
      initialValues={initialLicenseModalValues}
      validationSchema={licenseModalValidationSchema}
      onSubmit={onAddNewLicenseSubmit}
      isLoading={areDetailsLoading}
    >
      <LicenseForm
        closeParentModal={closeParentModal}
        activeId={activeLicensePricingId}
        removeItem={removeItem}
      />
    </Form>
  );
}
