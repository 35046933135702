import type License from 'src/models/License/License';
import type LicenseFormValues from 'src/models/License/LicenseFormValues';

export default function toLicenseUpdatedValues(
  data: LicenseFormValues,
  id: number
): License {
  const { barriersCount, lprCamerasCount, brainModulesCount, licenseTypeId } =
    data;

  return {
    id,
    barriersCount,
    lprCamerasCount,
    brainModulesCount,
    licenseTypeId,
  };
}
