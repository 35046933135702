import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  InfoIcon,
  LabelWrapper,
  StyledErrorWrapper,
  StyledWrapper,
} from 'src/components/Shared/InfoMessage';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import KeyLogsStatuses from '../../../constants/KeyManagement/KeyLogsStatuses';
import WeblinksStatuses from '../../../constants/KeyManagement/WeblinksStatuses';
import Dropdown from '../../Shared/Dropdown';

interface InfoMessagesProps {
  status: string;
  type: string;
  isExpired?: boolean;
  isDepleted?: boolean;
}

export default function InfoMessages(props: InfoMessagesProps) {
  const { status, isExpired, isDepleted } = props;
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const open = useCallback(() => {
    if (status !== KeyLogsStatuses.INVALID) return;
    setIsDropdownOpen(true);
  }, [status]);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (!wrapperRef?.current?.contains(event.target as Node)) {
        close();
      }
    };

    document.addEventListener('click', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
    };
  }, [close]);

  return (
    <StyledWrapper>
      <LabelWrapper onMouseEnter={open} onMouseLeave={close} ref={wrapperRef}>
        {isExpired
          ? capitalizeFirstLetter(KeyLogsStatuses.EXPIRED)
          : isDepleted
          ? capitalizeFirstLetter(WeblinksStatuses.DEPLETED)
          : capitalizeFirstLetter(status)}
        {status === KeyLogsStatuses.INVALID && <InfoIcon className='pa-info' />}
      </LabelWrapper>
      {isDropdownOpen && (
        <Dropdown close={close}>
          <StyledErrorWrapper>
            The Maser key is changed. Select the Invalid keys and Resend Key to
            the Users.
          </StyledErrorWrapper>
        </Dropdown>
      )}
    </StyledWrapper>
  );
}
