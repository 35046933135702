import React from 'react';
import { NavLink } from 'react-router-dom';
import type { NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import Color from '../../../constants/Shared/Color';
import useSideMenuContext from '../../../hooks/Shared/useSideMenuContext';

interface LinkProps {
  children: React.ReactNode;
  to: string;
}

export default function Link(props: LinkProps) {
  const { children, to } = props;
  const { close } = useSideMenuContext();

  return (
    <StyledLink to={to} onClick={close}>
      {children}
    </StyledLink>
  );
}

const StyledLink = styled(NavLink)<NavLinkProps>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${Color.TEXT_DARKER};
  text-decoration: none;
  margin-bottom: 30px;

  :last-of-type {
    margin-bottom: 0px;
  }
`;
