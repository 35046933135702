import type License from 'src/models/License/License';
import type LicenseResponse from 'src/models/License/LicenseResponse';

export default function toLicense(data: LicenseResponse): License {
  const {
    id,
    barriers_count,
    lpr_cameras_count,
    brain_modules_count,
    license_type_id,
  } = data;

  return {
    id,
    barriersCount: barriers_count,
    lprCamerasCount: lpr_cameras_count,
    brainModulesCount: brain_modules_count,
    licenseTypeId: license_type_id,
  };
}
