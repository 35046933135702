import type ProductBarrier from 'src/models/Parking/ProductBarrier';
import type ProductBarrierResponse from 'src/models/Parking/ProductBarrierResponse';

export default function toProductBarrier(
  data: ProductBarrierResponse
): ProductBarrier {
  const { id, state, is_car_on_top } = data;

  return {
    id,
    state,
    isCarOnTop: !!is_car_on_top,
  };
}
