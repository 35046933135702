import React from 'react';
import Color from 'src/constants/Shared/Color';
import type RotaryParkingPlace from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlace';
import styled, { css } from 'styled-components';
import Card from '../Shared/Card';
import Table from '../Shared/Table';

interface RotaryParkingPlaceTableProps {
  data: RotaryParkingPlace[];
  isLoading?: boolean;
}

export default function RotaryParkingPlaceTable(
  props: RotaryParkingPlaceTableProps
) {
  const { data, isLoading } = props;

  return (
    <Card>
      <Table isLoading={isLoading}>
        <Table.Colgroup>
          <col span={1} style={{ width: '50%' }} />
          <col span={1} style={{ width: '40%' }} />
          <col span={1} style={{ width: '10%' }} />
        </Table.Colgroup>
        <Table.Head>
          <Table.Row>
            <Table.Header>NAME</Table.Header>
            <Table.Header>STATUS</Table.Header>
            <Table.Header />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map((rotaryPlace) => {
            const { name, id, isOccupied } = rotaryPlace;

            return (
              <Table.Row key={id}>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{isOccupied ? 'Occupied' : 'Free'}</Table.Cell>
                <Table.Cell>
                  <StyledIcon
                    changeColor={isOccupied}
                    className='pa-starFull'
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Card>
  );
}

const StyledIcon = styled.i<{ changeColor?: boolean }>`
  ${(props) => {
    const { changeColor } = props;

    if (changeColor) {
      return css`
        color: ${Color.PARKING_PLACE_OCCUPIED};
      `;
    }

    return css`
      color: ${Color.PARKING_PLACE_FREE};
    `;
  }}
`;
