import { useCallback } from 'react';
import WeblinksStatuses from 'src/constants/KeyManagement/WeblinksStatuses';
import toKeyManagementQuery from 'src/mappers/KeySharing/toKeyManagementQuery';
import toWeblink from 'src/mappers/KeySharing/toWeblink';
import toWeblinkMetaData from 'src/mappers/KeySharing/toWeblinkMetaData';
import toWeblinkMetaDataQuery from 'src/mappers/KeySharing/toWeblinkMetaDataQuery';
import toWeblinkResendParams from 'src/mappers/KeySharing/toWeblinkResendParams';
import toWeblinkUpdateByIdParams from 'src/mappers/KeySharing/toWeblinkUpdateByIdParams';
import toWeblinkUpdateParams from 'src/mappers/KeySharing/toWeblinkUpdateParams';
import type KeyManagementQuery from 'src/models/KeySharing/KeyManagementQuery';
import type KeyManagementQueryParams from 'src/models/KeySharing/KeyManagementQueryParams';
import type Weblink from 'src/models/KeySharing/Weblink/Weblink';
import type WeblinkCreateResponse from 'src/models/KeySharing/Weblink/WeblinkCreateResponse';
import type WeblinkFormValues from 'src/models/KeySharing/Weblink/WeblinkFormValues';
import type WeblinkMetaData from 'src/models/KeySharing/Weblink/WeblinkMetaData';
import type WeblinkMetaDataQuery from 'src/models/KeySharing/Weblink/WeblinkMetaDataQuery';
import type WeblinkMetaDataResponse from 'src/models/KeySharing/Weblink/WeblinkMetaDataResponse';
import type WeblinkMetaDataValues from 'src/models/KeySharing/Weblink/WeblinkMetaDataValues';
import type WeblinkOperationQuery from 'src/models/KeySharing/Weblink/WeblinkOperationQuery';
import type WeblinkOperationResponse from 'src/models/KeySharing/Weblink/WeblinkOperationResponse';
import type WeblinkResendParams from 'src/models/KeySharing/Weblink/WeblinkResendParams';
import type WeblinkResendResponse from 'src/models/KeySharing/Weblink/WeblinkResendResponse';
import type WeblinkResendValues from 'src/models/KeySharing/Weblink/WeblinkResendValues';
import type WeblinkResponse from 'src/models/KeySharing/Weblink/WeblinkResponse';
import type WeblinkUpdateByIdParams from 'src/models/KeySharing/Weblink/WeblinkUpdateByIdParams';
import type WeblinkUpdateByIdResponse from 'src/models/KeySharing/Weblink/WeblinkUpdateByIdResponse';
import type WeblinkUpdateByIdValues from 'src/models/KeySharing/Weblink/WeblinkUpdateByIdValues';
import type WeblinkUpdateParams from 'src/models/KeySharing/Weblink/WeblinkUpdateParams';
import type WeblinkUpdateResponse from 'src/models/KeySharing/Weblink/WeblinkUpdateResponse';
import type WeblinkUpdateValues from 'src/models/KeySharing/Weblink/WeblinkUpdateValues';
import toWeblinkCreateRequest from '../../mappers/KeySharing/toWeblinkCreateRequest';
import toMeta from '../../mappers/Shared/toMeta';
import type WeblinkCreateRequest from '../../models/KeySharing/Weblink/WeblinkCreateRequest';
import type GetAllDetails from '../../models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const weblinksBaseURL = '/weblinks';
interface WeblinksService {
  createWeblink: (
    productId: number[],
    values: WeblinkFormValues
  ) => Promise<WeblinkCreateResponse[]>;

  listAllWeblinks: (
    query: KeyManagementQueryParams
  ) => Promise<GetAllDetails<Weblink>>;

  updateWeblinks: (
    values: WeblinkUpdateValues
  ) => Promise<WeblinkUpdateResponse[]>;

  updateWeblinkById: (
    id: number,
    values: WeblinkUpdateByIdValues
  ) => Promise<WeblinkUpdateByIdResponse>;

  getWeblinksMetaData: (
    query: WeblinkMetaDataValues
  ) => Promise<WeblinkMetaData>;

  weblinksOperation: (
    id: number,
    query: WeblinkOperationQuery
  ) => Promise<WeblinkOperationResponse>;

  blockWeblinks: (weblinks: number[]) => Promise<WeblinkUpdateResponse[]>;

  blockWeblinkById: (id: number) => Promise<WeblinkUpdateByIdResponse>;

  resendWeblinks: (
    values: WeblinkResendValues
  ) => Promise<WeblinkResendResponse[]>;
}

export default function useWeblinksService(): WeblinksService {
  const { get, post, patch } = useApiService();

  const createWeblink = useCallback(
    async (productId: number[], values: WeblinkFormValues) => {
      const requestBody = toWeblinkCreateRequest(productId, values);

      const response = await post<
        WeblinkCreateRequest,
        WeblinkCreateResponse[]
      >(`${weblinksBaseURL}`, requestBody);

      return response.data;
    },
    [post]
  );

  const listAllWeblinks = useCallback(
    async (queryParams: KeyManagementQueryParams) => {
      const request = toKeyManagementQuery(queryParams);
      const response = await get<WeblinkResponse[], KeyManagementQuery>(
        `${weblinksBaseURL}`,
        request
      );

      const data = response.data.map(toWeblink);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const updateWeblinks = useCallback(
    async (values: WeblinkUpdateValues) => {
      const params = toWeblinkUpdateParams(values);
      const response = await patch<
        WeblinkUpdateParams,
        WeblinkUpdateResponse[]
      >(`${weblinksBaseURL}`, params);

      return response.data;
    },
    [patch]
  );

  const updateWeblinkById = useCallback(
    async (id: number, values: WeblinkUpdateByIdValues) => {
      const params = toWeblinkUpdateByIdParams(values);
      const response = await patch<
        WeblinkUpdateByIdParams,
        WeblinkUpdateByIdResponse
      >(`${weblinksBaseURL}/${id}`, params);

      return response.data;
    },
    [patch]
  );

  const blockWeblinkById = useCallback(
    (id: number) => updateWeblinkById(id, { status: WeblinksStatuses.BLOCKED }),
    [updateWeblinkById]
  );

  const blockWeblinks = useCallback(
    (ids: number[]) =>
      updateWeblinks({
        ids,
        status: WeblinksStatuses.BLOCKED,
      }),
    [updateWeblinks]
  );

  const getWeblinksMetaData = useCallback(
    async (values: WeblinkMetaDataValues) => {
      const query = toWeblinkMetaDataQuery(values);
      const response = await get<WeblinkMetaDataResponse, WeblinkMetaDataQuery>(
        `${weblinksBaseURL}/metadata`,
        query
      );

      const data = toWeblinkMetaData(response.data);

      return data;
    },
    [get]
  );

  const weblinksOperation = useCallback(
    async (id: number, query: WeblinkOperationQuery) => {
      const response = await post<
        WeblinkOperationQuery,
        WeblinkOperationResponse
      >(`${weblinksBaseURL}/${id}/operation`, query);

      return response.data;
    },
    [post]
  );

  const resendWeblinks = useCallback(
    async (values: WeblinkResendValues) => {
      const params = toWeblinkResendParams(values);
      const response = await post<WeblinkResendParams, WeblinkResendResponse[]>(
        `${weblinksBaseURL}/resend`,
        params
      );

      return response.data;
    },
    [post]
  );

  return {
    createWeblink,
    listAllWeblinks,
    updateWeblinks,
    updateWeblinkById,
    getWeblinksMetaData,
    weblinksOperation,
    blockWeblinks,
    blockWeblinkById,
    resendWeblinks,
  };
}
