import type Option from '../../models/Shared/Option';

const Months: Option[] = [
  {
    key: 0,
    label: 'January',
  },
  {
    key: 1,
    label: 'February',
  },
  {
    key: 2,
    label: 'March',
  },
  {
    key: 3,
    label: 'April',
  },
  {
    key: 4,
    label: 'May',
  },
  {
    key: 5,
    label: 'June',
  },
  {
    key: 6,
    label: 'July',
  },
  {
    key: 7,
    label: 'August',
  },
  {
    key: 8,
    label: 'September',
  },
  {
    key: 9,
    label: 'October',
  },
  {
    key: 10,
    label: 'November',
  },
  {
    key: 11,
    label: 'December',
  },
];

export default Months;
