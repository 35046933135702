import { START_DATE, useDatepicker } from '@datepicker-react/hooks';
import type { OnDatesChangeProps } from '@datepicker-react/hooks';
import { useField } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import formatDate from 'src/utils/formatDate';
import DatePickerContext from '../../../contexts/Shared/DatePickerContext';
import { areNotSameDates } from '../../../utils/checks';
import {
  StyledDateContainer,
  StyledDateFieldColumn,
  StyledPlaceholder,
  StyledReadonlyData,
  StyledText,
  StyledWrapper,
} from '../DateTimeStyle';
import Dropdown from '../Dropdown';
import Error from '../Error';
import Label from '../Label';
import Calendar from './Calendar';
import Icon from './Icon';

interface DateFieldProps {
  name: string;
  label: string;
  placeholder: string;
  stacked?: boolean;
  short?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  small?: boolean;
  modalView?: boolean;
  tariffView?: boolean;
  center?: boolean;
  dateTimeView?: boolean;
}

export default function DateField(props: DateFieldProps) {
  const {
    name,
    label,
    placeholder,
    stacked,
    short,
    readonly,
    disabled,
    minBookingDate,
    maxBookingDate,
    small,
    modalView,
    tariffView,
    center,
    dateTimeView,
  } = props;
  const [field, meta, helpers] = useField({ name });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { value } = field;
  const { error, touched } = meta;
  const { setValue, setTouched } = helpers;
  const shouldShowError = error && touched;
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);
  const inputRef = useRef<React.ElementRef<'div'>>(null);
  const [shouldSetTouched, setShouldSetTouched] = useState(false);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
    setShouldSetTouched(false);
  }, []);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (
        !wrapperRef?.current?.contains(event.target as Node) &&
        shouldSetTouched
      ) {
        close();
        setTouched(true);
      }
    };

    document.addEventListener('click', listener, { capture: true });
    document.addEventListener('focusin', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
      document.removeEventListener('focusin', listener, { capture: true });
    };
  }, [close, setTouched, shouldSetTouched]);

  const pickDate = useCallback(
    (data: OnDatesChangeProps) => {
      if (data.startDate === null) return;

      setIsDropdownOpen(false);

      if (!areNotSameDates(value, data.startDate)) {
        setValue(null);

        return;
      }

      if (!areNotSameDates(data.startDate, minBookingDate)) {
        data.startDate.setHours(23, 59);
      }

      setValue(data.startDate);
    },
    [value, setValue, minBookingDate]
  );

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
    goToDate,
  } = useDatepicker({
    startDate: value,
    minBookingDate,
    maxBookingDate,
    endDate: null,
    focusedInput: START_DATE,
    onDatesChange: pickDate,
    numberOfMonths: 1,
  });

  useEffect(() => {
    if (value === null) {
      goToDate(new Date());
    }
  }, [goToDate, value]);

  const open = useCallback(() => {
    setIsDropdownOpen(true);

    if (!shouldSetTouched) {
      setShouldSetTouched(true);
    }
  }, [setIsDropdownOpen, shouldSetTouched]);

  const focusInput = useCallback(() => {
    inputRef?.current?.focus();
  }, []);

  const readonlyValue = useMemo(() => {
    if (!value) {
      return 'N/A';
    }

    return value.toDateString();
  }, [value]);

  return (
    <DatePickerContext.Provider
      value={{
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToPreviousMonths,
        goToNextMonths,
        goToDate,
      }}
    >
      <StyledWrapper
        stacked={stacked}
        ref={wrapperRef}
        short={short}
        modalView={modalView}
        tariffView={tariffView}
      >
        {label !== '' && (
          <Label
            stacked={stacked}
            onClick={focusInput}
            smallLabel={tariffView}
            center={center}
          >
            {label}
          </Label>
        )}
        <StyledDateFieldColumn
          short={short}
          small={small}
          modalView={modalView}
        >
          {!readonly && (
            <StyledDateContainer
              tabIndex={0}
              onFocus={disabled ? undefined : open}
              ref={inputRef}
              disabled={disabled}
            >
              {!!value && (
                <StyledText>
                  {tariffView ? formatDate(value) : value.toDateString()}
                </StyledText>
              )}
              {!value && <StyledPlaceholder>{placeholder}</StyledPlaceholder>}
              <Icon />
            </StyledDateContainer>
          )}
          {readonly && <StyledReadonlyData>{readonlyValue}</StyledReadonlyData>}
          {shouldShowError && <Error>*{error}</Error>}
          {isDropdownOpen && (
            <Dropdown close={close} tariffView={tariffView}>
              <Calendar dateTimeView={dateTimeView} />
            </Dropdown>
          )}
        </StyledDateFieldColumn>
      </StyledWrapper>
    </DatePickerContext.Provider>
  );
}
