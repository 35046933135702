import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InvoiceSetupForm from 'src/components/Settings/InvoiceSetupForm';
import SessionStorageKey from 'src/constants/Shared/SessionStorageKey';
import toAccountInvoiceByIdFormValues from 'src/mappers/Settings/toAccountInvoiceByIdFormValues';
import toAccountInvoiceFormValues from 'src/mappers/Settings/toAccountInvoiceFormValues';
import type AccountInvoiceFormValues from 'src/models/Settings/AccountInvoiceFormValues';
import useAccountService from 'src/services/Home/useAccountService';
import useAccountUserService from 'src/services/Settings/useAccountUserService';
import * as yup from 'yup';
import Form from '../../components/Shared/Form';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../components/Shared/Modals/GlobalSuccessModal';
import AppRole from '../../constants/Shared/AppRole';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import useUserHasRole from '../../hooks/Shared/useUserHasRole';
import { isNotString } from '../../utils/checks';

const defaultValues: AccountInvoiceFormValues = {
  invoiceCountry: null,
  invoiceCurrency: null,
};

const validationSchema = yup.object({
  invoiceCountry: yup
    .object()
    .nullable()
    .required('Country is a required field'),
  invoiceCurrency: yup
    .object()
    .nullable()
    .required('Currency is a required field'),
});

interface AccountInvoiceEditProps {
  accountId: number;
}

export default function AccountInvoiceEdit(props: AccountInvoiceEditProps) {
  const { accountId } = props;
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();
  const { findProfileAccountUser } = useAccountUserService();
  const { findAccountById, updateAccount } = useAccountService();

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: 'Successfuly updated account invoice!',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const userIsParklioInternalSuperAdmin = useMemo(() => {
    const roles = AppRole.PARKLIO_INTERNAL_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsLoading(true);
        }

        let formValues: AccountInvoiceFormValues | undefined;

        if (
          userIsParklioInternalSuperAdmin &&
          accountId !==
            parseInt(sessionStorage.getItem(SessionStorageKey.ID) || '')
        ) {
          const dataInternal = await findAccountById(accountId);
          formValues = toAccountInvoiceByIdFormValues(dataInternal);
        } else {
          const data = await findProfileAccountUser();
          formValues = toAccountInvoiceFormValues(data);
        }

        if (isMounted()) {
          setIsLoading(false);
          setInitialValues((values) => formValues || values);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [
    userIsParklioInternalSuperAdmin,
    isMounted,
    setIsLoading,
    accountId,
    findAccountById,
    findProfileAccountUser,
  ]);

  const getChangedValues = useCallback(
    (values: AccountInvoiceFormValues) => ({
      invoiceCountry:
        values.invoiceCountry?.key !== initialValues.invoiceCountry?.key
          ? values.invoiceCountry
          : undefined,
      invoiceCurrency:
        values.invoiceCurrency?.key !== initialValues.invoiceCurrency?.key
          ? values.invoiceCurrency
          : undefined,
    }),
    [initialValues]
  );

  const onSubmit = useCallback(
    async (
      values: AccountInvoiceFormValues,
      { setErrors }: FormikHelpers<AccountInvoiceFormValues>
    ) => {
      try {
        const valuesToUpdate = getChangedValues(values);

        if (isMounted()) {
          setIsLoading(true);
        }

        await updateAccount(valuesToUpdate, accountId);

        if (isMounted()) {
          setInitialValues(values);
          setIsLoading(false);
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsLoading(false);
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      updateAccount,
      getChangedValues,
      isMounted,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      accountId,
      setMessage,
    ]
  );

  return (
    <Form
      name='invoiceSetup'
      initialValues={initialValues}
      validationSchema={validationSchema}
      isLoading={isLoading}
      onSubmit={onSubmit}
    >
      <InvoiceSetupForm accountId={accountId} />
    </Form>
  );
}
