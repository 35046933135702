import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useSideMenuContext from '../../hooks/Shared/useSideMenuContext';
import useAuthService from '../../services/Auth/useAuthService';
import Button from '../Shared/Button';

export default function UserMenuActions() {
  const { logOut } = useAuthService();
  const history = useHistory();
  const { close } = useSideMenuContext();

  const onClick = useCallback(() => {
    logOut();
    close();
    history.push('/');
  }, [logOut, history, close]);

  return (
    <StyledWrapper>
      <Button size={ButtonSize.MIDDLE} onClick={onClick} type='button' primary>
        Sign Out
      </Button>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
