import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(NavLink)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  text-decoration: none;
`;

export default StyledLink;
