export default function generateTimeLabel(
  value: number,
  addSeconds?: boolean,
  convert24to0?: boolean
) {
  const dayMinutes = 24 * 60;
  if (convert24to0) {
    if (value === dayMinutes) {
      value = 0;
    }
  }
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  if (addSeconds) {
    return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}:00`;
  }
  return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
}
