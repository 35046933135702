import type LicensePricingQuery from 'src/models/License/LicensePricingQuery';
import type LicensePricingQueryParams from 'src/models/License/LicensePricingQueryParams';

export default function toLicensePricingQuery(
  data: LicensePricingQueryParams
): LicensePricingQuery {
  const { page, size, status } = data;

  return { page, size, status };
}
