import React from 'react';
import styled from 'styled-components';
import Container from '../../components/Auth/Container';
import ParklioSign from '../../components/Auth/ParklioSign';
import Color from '../../constants/Shared/Color';

export default function ConnectScreen() {
  return (
    <Container removeText>
      <ParklioSign addmargin='true' />
      <StyledDiv>
        <StyledText>You want to join our Parklio management system?</StyledText>
        <StyledLink
          href='https://parklio.com/en/parking-solutions/parking-management-system'
          target='_blank'
        >
          Find out more!
        </StyledLink>
      </StyledDiv>
    </Container>
  );
}

const StyledText = styled.p`
  font-family: 'Montserrat';
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  color: ${Color.TEXT_DARKER};
`;

const StyledDiv = styled.div`
  width: 80%;
  max-width: 500px;
  padding: 35px 30px;
  background-color: ${Color.BACKGROUND_LIGHTEST};
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 10px;
  margin: auto;
  display: block;
  min-height: 250px;
  text-align: center;
`;

const StyledLink = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-size: 25px;
  line-height: 160%;
  text-align: center;
  color: ${Color.PRIMARY_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;
