import type BoundLicense from 'src/models/Parking/BoundLicense';
import type BoundLicenseResponse from 'src/models/Parking/BoundLicenseResponse';

export default function toBoundLicense(
  data: BoundLicenseResponse
): BoundLicense {
  const { id, license_plate } = data;

  return {
    id,
    licensePlate: license_plate,
  };
}
