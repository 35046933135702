import { useCallback } from 'react';
import useAuthContext from '../Auth/useAuthContext';

export default function useUserHasRole() {
  const { sessionData } = useAuthContext();
  return useCallback(
    (roles: number) => {
      if (!sessionData) {
        return false;
      }

      const { role } = sessionData;
      return !!(role & roles);
    },
    [sessionData]
  );
}
