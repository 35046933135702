import type ZoneCreateRequest from 'src/models/Parking/Zone/ZoneCreateRequest';
import type ZoneFormValues from 'src/models/Parking/Zone/ZoneFormValues';

export default function toZoneCreateRequest(
  lotId: number,
  data: ZoneFormValues
): ZoneCreateRequest {
  const { name, description, isPrivate, hasPriority } = data;

  return {
    name,
    lot_id: lotId,
    description,
    is_private: isPrivate,
    has_priority: hasPriority,
  };
}
