import type { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import type WeblinkFormValues from 'src/models/KeySharing/Weblink/WeblinkFormValues';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import useWeblinksService from '../../../services/KeyManagement/useWeblinksService';
import { isNotString } from '../../../utils/checks';
import Form from '../../Shared/Form';
import { useGlobalFailureModal } from '../../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../Shared/Modals/GlobalSuccessModal';
import WeblinkForm from '../KeySharing/WeblinkForm';

interface ShareWeblinkModalProps {
  productId: number[];
  closeParentModal: () => void;
  setSharableProducts: (products: number[]) => void;
  offlineDevices: string[];
}

const initialValues: WeblinkFormValues = {
  receiverEmail: '',
  startTime: null,
  endTime: null,
};

const validationSchema = yup.object().shape({
  receiverEmail: yup
    .string()
    .email('Enter a valid email address')
    .required('Email is a required field'),
  startTime: yup.date().nullable().required('Start Time is a required field'),
  endTime: yup
    .date()
    .nullable()
    .required('End Time is a required field')
    .min(yup.ref('startTime'), "End Time can't be before Start Time"),
});

export default function ShareWeblinkModal(props: ShareWeblinkModalProps) {
  const { productId, closeParentModal, setSharableProducts, offlineDevices } =
    props;
  const isMounted = useIsMounted();
  const [successMessage, setSuccessMessage] = useState('');
  const { createWeblink } = useWeblinksService();

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: successMessage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (
      values: WeblinkFormValues,
      { setErrors, resetForm }: FormikHelpers<WeblinkFormValues>
    ) => {
      try {
        if (!productId) {
          return;
        }
        const response = await createWeblink(productId, values);

        const weblinkFailed = response.findIndex((weblink) => !weblink.success);

        if (isMounted()) {
          resetForm({});
          if (weblinkFailed === -1) {
            setSuccessMessage('All weblinks have been successfully created!');
          } else {
            setSuccessMessage(
              'Sorry for the inconvenience! Something went wrong, please try again.'
            );
          }
          openGlobalSuccessModal();
          setSharableProducts([]);
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      productId,
      createWeblink,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setSharableProducts,
      setMessage,
    ]
  );

  return (
    <Form
      name='ShareWeblink'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <WeblinkForm
        closeParentModal={closeParentModal}
        offlineDevices={offlineDevices}
      />
    </Form>
  );
}
