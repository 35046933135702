import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import LotForm from 'src/components/Parking/LotForm';
import {
  ParkingDetectionImplementationStatus,
  StatusValues,
} from 'src/constants/Parking/ParkingDetectionImplementationStatus';
import TimeZone from 'src/constants/Shared/TimeZone';
import toLotFormValues from 'src/mappers/Parking/Lot/toLotFormValues';
import type Lot from 'src/models/Parking/Lot/Lot';
import type LotFormValues from 'src/models/Parking/Lot/LotFormValues';
import useLotService from 'src/services/Parking/useLotService';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import * as yup from 'yup';
import Form from '../../components/Shared/Form';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../components/Shared/Modals/GlobalSuccessModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import { isNotString } from '../../utils/checks';

interface LotModalProps {
  closeParentModal: () => void;
  activeLot?: Lot;
  accountId: number;
  onUpdate: (data: Lot) => void;
}

const timeZoneKey = new Date().getTimezoneOffset() / -60;

const defaultValues: LotFormValues = {
  name: '',
  address: '',
  description: '',
  timeZone: TimeZone.find((zone) => zone.key === timeZoneKey) || null,
  latitude: '',
  longitude: '',
  isDetect: false,
  detectionImplementationStatus: {
    key: StatusValues[ParkingDetectionImplementationStatus.NOT_IMPLEMENTED],
    label: capitalizeFirstLetter(
      ParkingDetectionImplementationStatus.NOT_IMPLEMENTED
    ),
  },
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is a required field')
    .min(3, 'Name should be at least 3 characters long'),
  address: yup
    .string()
    .required('Address is a required field')
    .min(3, 'Address should be at least 3 characters long'),
  timeZone: yup.object().nullable().required('Time Zone is a required field'),
  latitude: yup.string().required('Latitude is a required field'),
  longitude: yup.string().required('Longitude is a required field'),
  isDetect: yup.boolean().notRequired(),
  detectionImplementationStatus: yup
    .object()
    .nullable()
    .when('isDetect', {
      is: true,
      then: yup.object().required('Status is a required field'),
    }),
});

export default function LotModal(props: LotModalProps) {
  const { closeParentModal, activeLot, accountId, onUpdate } = props;
  const { createLot, updateLot } = useLotService();

  const isMounted = useIsMounted();
  const [refreshPage, setRefreshPage] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [initialValues, setInitialValues] =
    useState<LotFormValues>(defaultValues);

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
    refreshPage,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    if (!activeLot) return;

    const values = toLotFormValues(activeLot);
    setInitialValues(values);
  }, [activeLot]);

  const getChangedValues = useCallback(
    (values: LotFormValues) => ({
      name: values.name !== initialValues.name ? values.name : undefined,
      address:
        values.address !== initialValues.address ? values.address : undefined,
      description:
        values.description !== initialValues.description
          ? values.description
          : undefined,
      timeZone:
        values.timeZone !== initialValues.timeZone
          ? values.timeZone
          : undefined,
      longitude:
        values.longitude !== initialValues.longitude
          ? values.longitude
          : undefined,
      latitude:
        values.latitude !== initialValues.latitude
          ? values.latitude
          : undefined,
      isDetect:
        values.isDetect !== initialValues.isDetect
          ? values.isDetect
          : undefined,
      detectionImplementationStatus: values.isDetect
        ? values.detectionImplementationStatus !==
          initialValues.detectionImplementationStatus
          ? values.detectionImplementationStatus
          : undefined
        : values.isDetect !== initialValues.isDetect
        ? {
            key: StatusValues[
              ParkingDetectionImplementationStatus.NOT_IMPLEMENTED
            ],
            label: ParkingDetectionImplementationStatus.NOT_IMPLEMENTED,
          }
        : undefined,
    }),
    [initialValues]
  );

  const onCreateUpdateLot = useCallback(
    async (
      values: LotFormValues,
      { setErrors, resetForm }: FormikHelpers<LotFormValues>
    ) => {
      try {
        if (!activeLot) {
          await createLot(values, accountId);

          if (isMounted()) {
            resetForm({});
            setMessageSuccess('Successfuly created a new lot!');
            setRefreshPage(true);
            openGlobalSuccessModal();
          }
        } else {
          const valuesToUpdate = getChangedValues(values);

          const response = await updateLot(activeLot.id, valuesToUpdate);

          onUpdate(response);

          if (isMounted()) {
            setMessageSuccess('Successfuly updated lot!');
            setRefreshPage(false);
            openGlobalSuccessModal();
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      accountId,
      isMounted,
      createLot,
      updateLot,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
      activeLot,
      getChangedValues,
      onUpdate,
    ]
  );

  return (
    <Form
      name='lot'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onCreateUpdateLot}
    >
      <LotForm
        closeParentModal={closeParentModal}
        hasDetection={initialValues.isDetect}
      />
    </Form>
  );
}
