import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import Input from 'src/components/Shared/Input';
import Label from 'src/components/Shared/Label';
import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/components/Shared/Modals/GlobalSuccessModal';
import TariffForm from 'src/components/Tariffs/TariffForm';
import TariffTable from 'src/components/Tariffs/TariffTable';
import {
  defaultTariffFormValues,
  tariffValidationSchema,
} from 'src/constants/Parking/TariffFormikValues';
import SearchTimer from 'src/constants/Shared/SearchTimer';
import type Tariff from 'src/models/Parking/Tariff/Tariff';
import type TariffFormValues from 'src/models/Parking/Tariff/TariffFormValues';
import type TariffId from 'src/models/Parking/Tariff/TariffId';
import useTariffService from 'src/services/Parking/useTariffService';
import useZoneService from 'src/services/Parking/useZoneService';
import { isNotString } from 'src/utils/checks';
import debounce from 'src/utils/debounce';
import styled from 'styled-components';
import * as yup from 'yup';
import useIsMounted from '../../../hooks/Shared/useIsMounted';
import Form from '../../Shared/Form';
import Tabs from '../../Shared/Tabs';

interface TariffsModalProps {
  closeParentModal: () => void;
  zoneId: number;
  findCurrencySymbol: (id: number) => void;
  invoiceCurrency:
    | { id: number | undefined; symbol: string | undefined }
    | undefined;
  accountId: number | undefined;
  onTariffChange: (tariffs: TariffId[] | Tariff, zoneId: number) => void;
}

interface TariffToggle {
  tariffIds: TariffId[];
}

const defaultValues: TariffToggle = {
  tariffIds: [],
};

export default function TariffsModal(props: TariffsModalProps) {
  const {
    closeParentModal,
    zoneId,
    findCurrencySymbol,
    invoiceCurrency,
    accountId,
    onTariffChange,
  } = props;
  const isMounted = useIsMounted();
  const { findAllTariffs, createTariff } = useTariffService();
  const { updateZone } = useZoneService();
  const [tariffData, setTariffData] = useState<Tariff[]>([]);
  const [initialValues, setinitialValues] =
    useState<TariffToggle>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const searchRef = React.useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState('');

  const onSearch = useCallback(async () => {
    if (searchRef.current === null) {
      return;
    }
    if (inputValue !== searchRef.current.value) {
      return;
    }

    setSearchValue(inputValue);
    setIsLoading(false);
  }, [inputValue]);

  useEffect(() => {
    setIsLoading(true);
    const search = debounce(onSearch, SearchTimer.INPUT_SEARCH);
    search();
  }, [onSearch]);

  const onChangeInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsLoading(true);
        }

        let name = '';

        if (searchValue !== '') {
          name = searchValue;
        }

        const { data } = await findAllTariffs({ name });

        if (isMounted()) {
          setTariffData(data);
          const ids = data.map((tariff) => ({
            id: tariff.id,
            activated: tariff.zoneIds.includes(zoneId),
            tariff,
          }));
          setinitialValues({ tariffIds: ids });
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };
    getData();
  }, [isMounted, findAllTariffs, zoneId, searchValue]);

  const onSubmit = useCallback(
    async (
      values: TariffFormValues,
      { setErrors }: FormikHelpers<TariffFormValues>
    ) => {
      try {
        if (!invoiceCurrency?.id) {
          setMessage({ code: 'currency', message: 'Currency must be set.' });
          openGlobalFailureModal();
          return;
        }
        const data = await createTariff(invoiceCurrency.id, values, zoneId);

        if (isMounted()) {
          onTariffChange(data, zoneId);
          setMessageSuccess('Successfully added new tariff!');
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      openGlobalFailureModal,
      setMessage,
      invoiceCurrency,
      createTariff,
      openGlobalSuccessModal,
      zoneId,
      onTariffChange,
    ]
  );

  const onZoneUpdate = useCallback(
    async (
      values: TariffToggle,
      { setErrors }: FormikHelpers<TariffToggle>
    ) => {
      try {
        await updateZone(zoneId, { tariffIds: values.tariffIds });

        if (isMounted()) {
          const activeTariffs = values.tariffIds.filter(
            (tariff) => tariff.activated
          );
          onTariffChange(activeTariffs, zoneId);
          setMessageSuccess('Zone updated successfully!');
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      openGlobalFailureModal,
      setMessage,
      updateZone,
      openGlobalSuccessModal,
      zoneId,
      onTariffChange,
    ]
  );

  return (
    <Tabs flexEnd>
      <Tabs.Panel name='tariffs' label='Select from the list'>
        <StyledDiv>
          <Label tariffModal>Select Tariff(s) for Parking Lot</Label>
          <Input
            placeholder='Filter name / part name'
            type='text'
            ref={searchRef}
            value={inputValue}
            onChange={onChangeInputSearch}
          />
        </StyledDiv>
        <Form
          name='tariff'
          initialValues={initialValues}
          validationSchema={yup.object()}
          onSubmit={onZoneUpdate}
        >
          <TariffTable
            data={tariffData}
            findCurrencySymbol={findCurrencySymbol}
            zoneView
            addTopMargin
            modalView
            closeParentModal={closeParentModal}
            isLoading={isLoading}
          />
        </Form>
      </Tabs.Panel>
      <Tabs.Panel name='newTariff' label='Add new'>
        <Form
          name='tariff'
          initialValues={defaultTariffFormValues}
          validationSchema={tariffValidationSchema}
          onSubmit={onSubmit}
        >
          <TariffForm
            closeParentModal={closeParentModal}
            invoiceCurrency={invoiceCurrency?.symbol}
            accountId={accountId}
            isLoading={isLoading}
            zoneView
          />
        </Form>
      </Tabs.Panel>
    </Tabs>
  );
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
