import type ProductChangeState from 'src/models/Parking/ProductChangeState';
import type ProductChangeStateResponse from 'src/models/Parking/ProductChangeStateResponse';

export default function toProductChangeState(
  data: ProductChangeStateResponse
): ProductChangeState {
  const { success, event_id, message } = data;

  return { success, eventId: event_id, message };
}
