import addZeroBeforeNumber from './addZeroBeforeNumber';

const formatDate = (date: Date | null) => {
  if (date === null) {
    return '';
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${addZeroBeforeNumber(day)}-${addZeroBeforeNumber(month)}-${year}`;
};

const changeDateFormat = (date: string | null) => {
  if (!date) {
    return '';
  }
  const dates = date.split('-');
  return `${dates[1]}.${dates[0]}.`;
};

const formatTime = (date: Date | null) => {
  if (date === null) {
    return '';
  }
  return `${addZeroBeforeNumber(date.getHours())}:${addZeroBeforeNumber(
    date.getMinutes()
  )}:${addZeroBeforeNumber(date.getSeconds())}`;
};

export { formatDate, formatTime, changeDateFormat };
