import type Profile from 'src/models/Settings/Profile';
import type UserProfileFormValues from '../../models/Settings/UserProfileFormValues';

export default function toUserProfileFormValues(
  data: Profile
): UserProfileFormValues {
  const { email, phone, name, role } = data;

  return {
    role,
    email,
    name,
    phone: phone || '',
  };
}
