import type GateFormValues from 'src/models/Parking/Gate/GateFormValues';
import type GateUpdateParams from 'src/models/Parking/Gate/GateUpdateParams';

export default function toGateUpdateParams(
  data: GateFormValues
): GateUpdateParams {
  return {
    name: data.name,
  };
}
