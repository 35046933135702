import AccountRole from '../constants/Shared/AccountRole';
import AccountUserRole from '../constants/Shared/AccountUserRole';
import AppRole from '../constants/Shared/AppRole';

export default function generateAppRole(
  accountRole: AccountRole,
  accountUserRole: AccountUserRole
): AppRole {
  const appRole = appRoleMap[`${accountRole}@${accountUserRole}`];

  if (!appRole) {
    return AppRole.NONE;
  }

  return appRole;
}

const appRoleMap = {
  [`${AccountRole.PARKLIO_INTERNAL}@${AccountUserRole.SUPER_ADMIN}`]:
    AppRole.PARKLIO_INTERNAL_SUPER_ADMIN,
  [`${AccountRole.PARKLIO_INTERNAL}@${AccountUserRole.ADMIN}`]:
    AppRole.PARKLIO_INTERNAL_ADMIN,
  [`${AccountRole.PARKLIO_INTERNAL}@${AccountUserRole.OPERATOR}`]:
    AppRole.PARKLIO_INTERNAL_OPERATOR,
  [`${AccountRole.PMS}@${AccountUserRole.SUPER_ADMIN}`]:
    AppRole.PMS_SUPER_ADMIN,
  [`${AccountRole.PMS}@${AccountUserRole.ADMIN}`]: AppRole.PMS_ADMIN,
  [`${AccountRole.PMS}@${AccountUserRole.OPERATOR}`]: AppRole.PMS_OPERATOR,
};
