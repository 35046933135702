import type RotaryUpdateParams from 'src/models/Rotaries/RotaryUpdateParams';
import type RotaryUpdateValues from 'src/models/Rotaries/RotaryUpdateValues';

export default function toRotaryUpdateParams(
  data: RotaryUpdateValues,
  lotId?: number
): RotaryUpdateParams {
  const { name, address } = data;

  return {
    name,
    address,
    lot_id: lotId,
  };
}
