import type { FormikHelpers } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import NoImgPic from 'src/assets/svg/noImgPic.svg';
import AllowLicensePlate from 'src/components/Parking/AllowLicensePlate';
import CameraTable from 'src/components/Parking/CameraTable';
import DisplayCounter from 'src/components/Parking/DisplayCounter';
import AnprFilter from 'src/components/Shared/AnprFilter';
import { useGlobalConfirmationModal } from 'src/components/Shared/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from 'src/components/Shared/Modals/GlobalFailureModal';
import Spinner from 'src/components/Shared/Spinner';
import StyledNoData from 'src/components/Shared/StyledNoData';
import Title from 'src/components/Shared/Title';
import Toast from 'src/components/Shared/Toast';
import CameraFiltersDropdownValues from 'src/constants/Parking/CameraValues';
import ActionIcon from 'src/constants/Shared/ActionIcon';
import PaginationSize from 'src/constants/Shared/DataSize';
import HTMLIds from 'src/constants/Shared/HTMLIds';
import InitialMetaData from 'src/constants/Shared/InitialMetaData';
import SocketEvents from 'src/constants/Shared/SocketEvents';
import SocketPath from 'src/constants/Shared/SocketPath';
import useSocket from 'src/hooks/Shared/useSocket';
import toLprCameraStatusEvent from 'src/mappers/Parking/Anpr/toLprCameraStatusEvent';
import toLprEvent from 'src/mappers/Parking/Anpr/toLprEvent';
import toLotDetectionChangeEvent from 'src/mappers/Parking/Lot/toLotDetectionChangeEvent';
import type LotEntry from 'src/models/Parking/Anpr/LotEntries/LotEntry';
import type { LotEntryFilterValues } from 'src/models/Parking/Anpr/LotEntries/LotEntryQueryValues';
import type LicensePlateFormValues from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateFormValues';
import type LprCamera from 'src/models/Parking/Anpr/LprCamera/LprCamera';
import type LprCameraStatusEventPayload from 'src/models/Parking/Anpr/LprCameraStatusEventPayload';
import type LprEventPayload from 'src/models/Parking/Anpr/LprEventPayload';
import type LotOccupancyFormValues from 'src/models/Parking/Lot/LotOccupancyFormValues';
import type LotDetectionChangeEvent from 'src/models/Parking/Lot/SocketEvents/LotDetectionChangeEvent/LotDetectionChangeEvent';
import type LotDetectionChangeEventPayload from 'src/models/Parking/Lot/SocketEvents/LotDetectionChangeEvent/LotDetectionChangeEventPayload';
import type Meta from 'src/models/Shared/Meta';
import useLotEntriesService from 'src/services/Lpr/useLotEntriesService';
import useLprCamerasService from 'src/services/Lpr/useLprCamerasService';
import { isNotString } from 'src/utils/checks';
import formatDates from 'src/utils/formatDates';
import { getCameraPosition } from 'src/utils/getCameraPosition';
import getLotEntryColor from 'src/utils/getLotEntryColor';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import topBtn from '../../assets/img/topBtn.png';
import EditLicensePlateForm from '../../components/Parking/EditLicensePlateForm';
import Button from '../../components/Shared/Button';
import Card from '../../components/Shared/Card';
import Content from '../../components/Shared/Content';
import Form from '../../components/Shared/Form';
import Main from '../../components/Shared/Main';
import GlobalModal from '../../components/Shared/Modals/GlobalModal';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Color from '../../constants/Shared/Color';
import lotOccupancyValidationScheme from '../../constants/Validation/LotOccupancyValidationScheme';
import { useGlobalModal } from '../../hooks/Shared/useGlobalModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type Option from '../../models/Shared/Option';
import useLotService from '../../services/Parking/useLotService';

interface Props {
  lotIdProps?: number;
  occupiedCount: number;
  spotCount: number;
  updateLotOccupancyCount: (occupiedCount: number, spotCount?: number) => void;
}

const showTopBtn = 1000;

const filterCamerasInitialValues: LotEntryFilterValues = {
  licensePlate: '',
  startTime: null,
  endTime: null,
  camera: null,
};

const editLicensePlateValidationSchema = yup.object().shape({
  licensePlate: yup.string().required('License Plate is a required field'),
});

const filtereValidationSchema = yup.object().shape({
  licensePlate: yup.string().min(3).notRequired(),
});

const optiondata: Option[] = [
  { key: CameraFiltersDropdownValues.ALL, label: 'All Cameras' },
  { key: CameraFiltersDropdownValues.IN, label: 'In Cameras' },
  { key: CameraFiltersDropdownValues.OUT, label: 'Out Cameras' },
];

const editLicensePlateInitialValues: LicensePlateFormValues = {
  licensePlate: '',
};

const TOAST_TIMER_MILISECONDS = 10 * 1000;
const LOT_ENTRY_DELAY = 1 * 1000;

export default function AnprList(props: Props) {
  const { lotIdProps, occupiedCount, spotCount, updateLotOccupancyCount } =
    props;
  const [activeLicensePlateId, setActiveLicensePlateId] = useState<
    number | undefined
  >(undefined);
  const [values, setValues] = useState<LicensePlateFormValues>();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isCameraDataLoading, setIsCameraDataLoading] = useState(false);
  const [lprData, setLprData] = useState<LotEntry[]>([]);
  const [initialEditLicensePlatetValues, setInitialEditLicensePlatetValues] =
    useState(editLicensePlateInitialValues);
  const [cameraData, setCameraData] = useState<LprCamera[]>([]);
  const [page, setPage] = useState(1);
  const [showScroll, setShowScroll] = useState(false);
  const [isToastShowing, SetIsToastShowing] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [activeReboot, setActiveReboot] = useState<string | undefined>(
    undefined
  );

  const [disableInputField, setDisableInputField] = useState(true);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);
  const { push } = useHistory();
  const { updateLot } = useLotService();

  const { lastPage } = meta;

  const [filterValues, setFilterValues] = useState<LotEntryFilterValues>({
    licensePlate: '',
    startTime: undefined,
    endTime: undefined,
    camera: undefined,
  });

  const { listAllLotEntries, updateLotEntry } = useLotEntriesService();
  const { listAllLprCameras } = useLprCamerasService();
  const { params } = useRouteMatch<{ lotId?: string }>();
  const lotId = useMemo(() => {
    if (!params.lotId) {
      return lotIdProps;
    }
    return parseInt(params.lotId);
  }, [params, lotIdProps]);

  const isMounted = useIsMounted();
  const socket = useSocket(SocketPath.SOCKETIO);

  const toastTimeout = useRef<NodeJS.Timeout | undefined>();

  useEffect(
    () => () => {
      if (toastTimeout.current) {
        clearTimeout(toastTimeout.current);
      }
    },
    []
  );

  const showToast = useCallback((message: string, device?: string) => {
    SetIsToastShowing(true);
    setActiveReboot(device);
    setToastMessage(message);

    if (toastTimeout.current) {
      clearTimeout(toastTimeout.current);
    }
    toastTimeout.current = setTimeout(() => {
      SetIsToastShowing(false);
    }, TOAST_TIMER_MILISECONDS);
  }, []);

  const hideToast = useCallback(() => {
    SetIsToastShowing(false);
    if (!toastTimeout.current) {
      return;
    }
    clearTimeout(toastTimeout.current);
  }, []);

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const handleLprEvent = (payload: string) => {
      try {
        const eventPayload: LprEventPayload = JSON.parse(payload);
        const eventData = toLprEvent(eventPayload);

        const { lotId: payloadLotId, data } = eventData;

        if (payloadLotId !== lotId) {
          return;
        }

        if (Object.values(filterValues).filter((value) => value).length !== 0) {
          return;
        }

        // we get lot entry event before picture is uploaded to S3 bucket so we have to wait for picture to be ready for download
        // otherwise img request fails
        setTimeout(() => {
          setLprData((oldData) => {
            const slicedData =
              lastPage === page ? oldData : oldData.slice(0, -1);
            return [data, ...slicedData];
          });
        }, LOT_ENTRY_DELAY);
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };

    const handleLprCameraStatusEvent = (payload: string) => {
      try {
        const eventPayload: LprCameraStatusEventPayload = JSON.parse(payload);
        const eventData = toLprCameraStatusEvent(eventPayload);

        const { deviceId, status } = eventData;

        if (deviceId === activeReboot) {
          const findCameraName = cameraData.find(
            (camera) => camera.deviceId === deviceId
          );
          showToast(`Camera ${findCameraName?.name} is Online`);
        }

        setCameraData((data) =>
          data.map((camera) => {
            if (camera.deviceId !== deviceId) {
              return camera;
            }

            return {
              ...camera,
              status,
            };
          })
        );
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };

    const handleLotDetectionChange = (payload: string) => {
      try {
        const eventPayload: LotDetectionChangeEventPayload =
          JSON.parse(payload);
        const eventData: LotDetectionChangeEvent =
          toLotDetectionChangeEvent(eventPayload);

        const { lots } = eventData;

        const lot = lots.find((lot) => lot.id === lotId);

        if (!lot) return;

        const { occupiedCount } = lot;

        updateLotOccupancyCount(occupiedCount);
      } catch {
        setMessage({ code: 'Unknown', message: 'JSON parse error' });
        openGlobalFailureModal();
      }
    };

    socket?.on(SocketEvents.LPR_EVENT, handleLprEvent);
    socket?.on(SocketEvents.LPR_CAMERA_STATUS, handleLprCameraStatusEvent);
    socket?.on(SocketEvents.LOT_DETECTION_CHANGE, handleLotDetectionChange);
    return () => {
      socket?.off(SocketEvents.LPR_EVENT, handleLprEvent);
      socket?.off(SocketEvents.LPR_CAMERA_STATUS, handleLprCameraStatusEvent);
      socket?.off(SocketEvents.LOT_DETECTION_CHANGE, handleLotDetectionChange);
    };
  }, [
    socket,
    lotId,
    page,
    lastPage,
    filterValues,
    activeReboot,
    cameraData,
    showToast,
    openGlobalFailureModal,
    setMessage,
    updateLotOccupancyCount,
  ]);

  const getAllLotEntries = useCallback(async () => {
    try {
      if (!lotId) {
        return;
      }

      if (isMounted()) {
        setIsDataLoading(true);
      }

      const { licensePlate, startTime, endTime, camera } = filterValues;

      const { data, meta } = await listAllLotEntries({
        page,
        size: PaginationSize.LARGE,
        lotId,
        licensePlate,
        startTime,
        endTime,
        camera: getCameraPosition(camera?.key),
      });

      if (isMounted()) {
        if (page === 1) {
          setLprData(data);
        } else {
          setLprData((oldData) => [...oldData, ...data]);
        }
        setIsDataLoading(false);

        if (meta) {
          setMeta(meta);
        }
      }
    } catch (error) {
      if (isMounted()) {
        setIsDataLoading(false);
      }
      throw error;
    }
  }, [listAllLotEntries, isMounted, lotId, filterValues, page]);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < lastPage) {
        setPage((prev) => prev + 1);
      }
    })
  );

  const lastEntryElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isDataLoading) {
        return;
      }
      observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < lastPage) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isDataLoading, lastPage, page]
  );

  useEffect(() => {
    getAllLotEntries();
  }, [getAllLotEntries]);

  const [openEditLicensePlateModal, closeEditLicensePlate] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <Form
          name='EditLicensePlate'
          initialValues={initialEditLicensePlatetValues}
          validationSchema={editLicensePlateValidationSchema}
          onSubmit={openConfirmationModal}
        >
          <EditLicensePlateForm
            id={activeLicensePlateId}
            closeParentModal={closeEditLicensePlate}
          />
        </Form>
      </GlobalModal>
    )
  );

  const closeEditLicensePlateModal = useCallback(() => {
    setActiveLicensePlateId(undefined);
    closeEditLicensePlate();
  }, [closeEditLicensePlate]);

  const getChangedLicensePlateValues = useCallback(
    (values: LicensePlateFormValues) => ({
      licensePlate:
        values.licensePlate !== initialEditLicensePlatetValues.licensePlate
          ? values.licensePlate
          : undefined,
    }),
    [initialEditLicensePlatetValues]
  );

  const onEditLicensePlateSubmit = useCallback(
    async (values: LicensePlateFormValues) => {
      const formValues = getChangedLicensePlateValues(values);

      if (!formValues.licensePlate || !activeLicensePlateId) {
        return;
      }

      await updateLotEntry(activeLicensePlateId, formValues);

      if (isMounted()) {
        setLprData((oldData) => {
          const index = oldData.findIndex(
            (data) => data.id === activeLicensePlateId
          );

          if (index === -1) return oldData;

          oldData[index].licensePlate = formValues.licensePlate || '';
          return [...oldData];
        });
      }
    },
    [
      getChangedLicensePlateValues,
      activeLicensePlateId,
      isMounted,
      updateLotEntry,
    ]
  );

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onEditLicensePlateSubmit,
    message: 'Are you sure you want to change this license plate?',
    values,
    closeParentModal: closeEditLicensePlateModal,
  });

  const openConfirmationModal = useCallback(
    async (values: LicensePlateFormValues) => {
      if (isMounted()) {
        setValues(values);
        openGlobalConfirmationModal();
      }
    },
    [openGlobalConfirmationModal, isMounted]
  );

  const options: Option[] = optiondata.map(({ key, label }) => ({
    key,
    label,
  }));

  const onFilterSubmit = useCallback(
    async (values: LotEntryFilterValues) => {
      const { licensePlate, startTime, endTime, camera } = values;

      if (isMounted()) {
        setPage(1);
        setFilterValues((oldValues) => {
          const {
            licensePlate: oldLicensePlate,
            startTime: oldStartTime,
            endTime: oldEndTime,
            camera: oldCamera,
          } = oldValues;

          if (
            oldLicensePlate === licensePlate &&
            oldStartTime?.getTime() === startTime?.getTime() &&
            oldEndTime?.getTime() === endTime?.getTime() &&
            oldCamera === camera
          ) {
            return oldValues;
          }
          return {
            licensePlate,
            startTime,
            endTime,
            camera,
          };
        });
      }
    },
    [isMounted]
  );

  const getAllLprCameras = useCallback(
    async (page: number) => {
      try {
        if (!lotId) {
          return;
        }

        if (isMounted()) {
          setIsCameraDataLoading(true);
        }

        const { data } = await listAllLprCameras({
          page,
          size: PaginationSize.STANDARD,
          lotId,
        });

        if (isMounted()) {
          setCameraData(data);
          setIsCameraDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsCameraDataLoading(false);
        }
        throw error;
      }
    },
    [listAllLprCameras, isMounted, lotId]
  );

  useEffect(() => {
    getAllLprCameras(1);
  }, [getAllLprCameras]);

  const onCameraNameChange = useCallback((name: string, id: number) => {
    if (name === '' || id === 0) return;
    setCameraData((oldCamera) => {
      const index = oldCamera.findIndex((camera) => camera.id === id);

      if (index === -1) return oldCamera;

      oldCamera[index].name = name;
      return [...oldCamera];
    });

    setLprData((oldEntriesData) => {
      oldEntriesData.forEach((entry) => {
        if (entry.lprCamera.id === id) {
          return (entry.lprCamera.name = name);
        }
        return oldEntriesData;
      });
      return [...oldEntriesData];
    });
  }, []);

  const checkScrollTop = useCallback(() => {
    const tmp = document.getElementById(HTMLIds.CONTAINER);
    if (!tmp) {
      return;
    }
    if (!showScroll && tmp.scrollTop > showTopBtn) {
      setShowScroll(true);
    } else if (showScroll && tmp.scrollTop <= showTopBtn) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const scrollTop = useCallback(() => {
    const tmp = document.getElementById(HTMLIds.CONTAINER);
    if (!tmp) {
      return;
    }
    tmp.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const tmp = document.getElementById(HTMLIds.CONTAINER);
    if (!tmp) {
      return undefined;
    }
    tmp.addEventListener('scroll', checkScrollTop, false);
    return () => {
      tmp.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  const onResetAllFilters = useCallback(() => {
    if (isMounted()) {
      setFilterValues((oldValues) => {
        const {
          licensePlate: oldLicensePlate,
          startTime: oldStartTime,
          endTime: oldEndTime,
          camera: oldCamera,
        } = oldValues;

        if (
          oldLicensePlate === '' &&
          !oldStartTime &&
          !oldEndTime &&
          !oldCamera
        ) {
          return oldValues;
        }
        return {
          licensePlate: '',
          startTime: undefined,
          endTime: undefined,
          camera: undefined,
        };
      });

      setPage(1);
    }
  }, [isMounted]);
  const onSubmitLotOccupancyValues = useCallback(
    async (
      values: LotOccupancyFormValues,
      { setErrors }: FormikHelpers<LotOccupancyFormValues>
    ) => {
      try {
        if (!lotId) return;
        const { occupiedCount, spotCount } = values;
        const response = await updateLot(lotId, { occupiedCount, spotCount });
        if (isMounted()) {
          updateLotOccupancyCount(response.occupiedCount, response.spotCount);
          setDisableInputField(true);
        }
      } catch (error: any) {
        if (isMounted()) {
          setDisableInputField(false);
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      lotId,
      updateLot,
      openGlobalFailureModal,
      setMessage,
      updateLotOccupancyCount,
    ]
  );

  const goToAnprLogsScreen = useCallback(() => {
    if (!lotId) {
      return;
    }

    push(`/parking/lots/${lotId}/anpr/remote-control-logs`);
  }, [push, lotId]);

  return (
    <>
      <Title anpr>ANPR Control</Title>
      <Main>
        <Content widthSize='20%' order={2}>
          <Form
            name='Filter'
            initialValues={filterCamerasInitialValues}
            validationSchema={filtereValidationSchema}
            onSubmit={onFilterSubmit}
          >
            <AnprFilter
              options={options}
              onResetAllFilters={onResetAllFilters}
            />
          </Form>
          <StyledLegend>
            <LegendDiv>
              <ColorDiv backgroundColor={Color.PRIMARY_BRAND} />
              <span>Entrance</span>
            </LegendDiv>
            <LegendDiv>
              <ColorDiv backgroundColor={Color.TEXT_DARKER} />
              <span>Exit</span>
            </LegendDiv>
            <LegendDiv>
              <ColorDiv backgroundColor={Color.ERROR} />
              <span>Exit with unknown entrance</span>
            </LegendDiv>
          </StyledLegend>
        </Content>
        <Content widthSize='43%' anprTopMargin order={3}>
          {lprData.length > 0 ? (
            lprData.map((data, index) => {
              const {
                id,
                direction,
                lprCamera,
                scanTime,
                licensePlate,
                relatedLotEntryId,
              } = data;
              const { name } = lprCamera;
              const isLastElement = lprData.length === index + 1;

              const getColor = () =>
                getLotEntryColor(relatedLotEntryId, direction);

              const setActiveData = () => {
                setActiveLicensePlateId(id);
                setInitialEditLicensePlatetValues({
                  licensePlate: data.licensePlate,
                });
                openEditLicensePlateModal();
              };

              return (
                <Card
                  key={id}
                  anprView
                  lastRef={isLastElement ? lastEntryElementRef : undefined}
                >
                  <StyledEntranceDiv backgroundColor={getColor} />
                  <StyledImg
                    src={data.licensePlateImageUrl || NoImgPic}
                    alt={data.licensePlate}
                  />
                  <CameraInfoDiv>
                    {direction.toUpperCase()}: <b>{formatDates(scanTime)}</b>
                  </CameraInfoDiv>
                  <StyledName>
                    <p>{name}</p>
                  </StyledName>
                  <ButtonDiv>
                    <Button
                      anpr
                      size={ButtonSize.ANPR}
                      type='button'
                      onClick={setActiveData}
                    >
                      {licensePlate}
                    </Button>
                  </ButtonDiv>
                </Card>
              );
            })
          ) : (
            <StyledNoData>No scaned license plates.</StyledNoData>
          )}
          {isDataLoading && <Spinner primary anpr />}
        </Content>
        <Content widthSize='33%' anprTopMargin order={1}>
          <Card>
            <Form
              name='counter'
              initialValues={{ occupiedCount, spotCount }}
              validationSchema={lotOccupancyValidationScheme}
              onSubmit={onSubmitLotOccupancyValues}
            >
              <DisplayCounter
                setDisableInputField={setDisableInputField}
                disableInputField={disableInputField}
              />
            </Form>
          </Card>
          <AllowLicensePlate lotId={lotId} />
          <CameraTable
            cameraData={cameraData}
            isCameraDataLoading={isCameraDataLoading}
            onCameraNameChange={onCameraNameChange}
            showToast={showToast}
          />
          <StyledDivIcon onClick={goToAnprLogsScreen}>
            <StyledIcon className={ActionIcon.TIME} />
            <span>ANPR Remote Control Logs</span>
          </StyledDivIcon>
        </Content>
        {isToastShowing && (
          <Toast onClick={hideToast} aboveTopBtn={showScroll}>
            {toastMessage}
          </Toast>
        )}
        {showScroll && (
          <StyledImgDiv onClick={scrollTop}>
            <img src={topBtn} alt='Button top' />
          </StyledImgDiv>
        )}
      </Main>
    </>
  );
}

const ButtonDiv = styled.div`
  width: 30%;
  margin: 10px;
  display: flex;
  align-items: center;
`;

const StyledLegend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: -15px 0 0 5px;
`;

const LegendDiv = styled.div`
  display: flex;
  flex-direction: row;
  color: ${Color.TEXT_DARKER};
  font-size: 14px;
  margin: 5px 0;
`;

const ColorDiv = styled.div<{ backgroundColor: any }>`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  align-self: center;
  ${(props) => {
    const { backgroundColor } = props;

    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return css``;
  }};
`;

export const StyledEntranceDiv = styled.div<{ backgroundColor: any }>`
  width: 1%;
  border-radius: 10px 0 0 10px;
  ${(props) => {
    const { backgroundColor } = props;

    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return css``;
  }};
`;

const CameraInfoDiv = styled.div`
  margin: 10px;
  display: inline;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${Color.TEXT_DARKER};
  width: 29%;
  justify-content: center;
  align-self: center;
`;

const StyledName = styled.div`
  width: 20%;
  display: flex;
  color: ${Color.TEXT_DARKER};
  align-items: center;
  text-align: center;
  font-family: Open Sans;

  & > p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledDivIcon = styled.div`
  justify-content: flex-end;
  margin: 15px 0;
  display: flex;
  align-items: center;
  color: ${Color.PRIMARY_BRAND};
  font-size: 14px;
  cursor: pointer;
`;

export const StyledIcon = styled.i`
  font-size: 16px;
  margin-left: 5px;
  margin-right: 10px;
  color: ${Color.PRIMARY_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;

const StyledImgDiv = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
`;

const StyledImg = styled.img`
  width: 20%;
  margin: 5px 10px;
  border-radius: 10px;
`;
