import React from 'react';
import BoundLicensesTable from 'src/components/Parking/BoundLicensesTable';
import InvitationKeysTable from '../../components/Parking/KeySharing/InvitationKeysTable';
import KeyLogsTable from '../../components/Parking/KeySharing/KeyLogsTable';
import WeblinksTable from '../../components/Parking/KeySharing/WeblinksTable';
import Tabs from '../../components/Shared/Tabs';

interface SharedProductListProps {
  productId: number;
  modalView?: boolean;
  licensePlates?: boolean;
}

export default function SharedProductList(props: SharedProductListProps) {
  const { modalView, productId, licensePlates } = props;

  return (
    <Tabs noBackground={!modalView}>
      <Tabs.Panel name='sharedKeys' label='Shared keys'>
        <KeyLogsTable modalView={modalView} productId={productId} />
      </Tabs.Panel>
      <Tabs.Panel name='weblinks' label='Weblinks'>
        <WeblinksTable modalView={modalView} productId={productId} />
      </Tabs.Panel>
      <Tabs.Panel name='invitationKeys' label='Pending access'>
        <InvitationKeysTable modalView={modalView} productId={productId} />
      </Tabs.Panel>
      {licensePlates ? (
        <Tabs.Panel name='licensePlates' label='License plates'>
          <BoundLicensesTable modalView={modalView} productId={productId} />
        </Tabs.Panel>
      ) : (
        <></>
      )}
    </Tabs>
  );
}
