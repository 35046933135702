import type WeblinkStatistics from 'src/models/Statistics/Weblink/WeblinkStatistics';
import type WeblinkStatisticsResponse from 'src/models/Statistics/Weblink/WeblinkStatisticsResponse';

export default function toWeblinkStatistics(
  data: WeblinkStatisticsResponse
): WeblinkStatistics {
  const { active, reactived, depleted, blocked, invalid, deleted, expired } =
    data;

  return {
    active,
    reactived,
    depleted,
    blocked,
    invalid,
    deleted,
    expired,
  };
}
