enum AppRole {
  NONE = 0,
  PARKLIO_INTERNAL_SUPER_ADMIN = 1,
  PARKLIO_INTERNAL_ADMIN = 2,
  PMS_SUPER_ADMIN = 4,
  PMS_ADMIN = 8,
  PMS_OPERATOR = 16,
  PARKLIO_INTERNAL_OPERATOR = 32,
}

export default AppRole;
