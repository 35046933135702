import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import Container from '../../components/Auth/Container';
import Form from '../../components/Auth/Form';
import ParklioSign from '../../components/Auth/ParklioSign';
import ResetPasswordForm from '../../components/Auth/ResetPasswordForm';
import ResetPasswordText from '../../components/Auth/ResetPasswordText';
import Title from '../../components/Auth/Title';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type ResetPasswordFormValues from '../../models/Settings/ResetPasswordFormValues';
import useAccountUserService from '../../services/Settings/useAccountUserService';

const initialValues: ResetPasswordFormValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is a required field')
    .email('Enter a valid email address'),
});

export default function ResetPassword() {
  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState('');
  const { requestResetPassword } = useAccountUserService();
  const isMounted = useIsMounted();

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (values: ResetPasswordFormValues) => {
      try {
        await requestResetPassword(values);

        if (isMounted()) {
          setIsSent(true);
          setEmail(values.email);
        }
      } catch (error: any) {
        if (isMounted()) {
          openGlobalFailureModal();
          setMessage(error);
          setIsSent(false);
        }
      }
    },
    [isMounted, openGlobalFailureModal, requestResetPassword, setMessage]
  );

  return (
    <Container>
      <ParklioSign
        onClick={() => window.location.assign('/login')}
        cursorpointer='true'
      />
      <Title>Reset password</Title>
      {!isSent && (
        <Form
          name='resetPassword'
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <ResetPasswordForm />
        </Form>
      )}
      {isSent && <ResetPasswordText email={email} />}
    </Container>
  );
}
