import type LotWhitelistStatistics from 'src/models/Statistics/LotWhitelist/LotWhitelistStatistics';
import type LotWhitelistStatisticsResponse from 'src/models/Statistics/LotWhitelist/LotWhitelistStatisticsResponse';

export default function toLotWhitelistStatistics(
  data: LotWhitelistStatisticsResponse
): LotWhitelistStatistics {
  const { active, deleted, expired } = data;

  return {
    active,
    deleted,
    expired,
  };
}
