import React from 'react';
import styled from 'styled-components';
import Color from '../../constants/Shared/Color';

interface Props {
  email: string;
}

export default function ResetPasswordText(props: Props) {
  const { email } = props;

  return (
    <StyledWrapper>
      <StyledText>
        We sent you a link on: <br />
        <b>{email}</b>. <br />
        Click on the link to reset your password.
      </StyledText>
    </StyledWrapper>
  );
}

const StyledText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: ${Color.TEXT_DARKER};
  margin: 36px 0px;
`;

const StyledWrapper = styled.div`
  width: 386px;
  padding: 35px 30px;
  background-color: ${Color.BACKGROUND_LIGHTER};
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 10px;
  margin: 35px 0px;
`;
