import { useCallback } from 'react';
import toBindLicenseRequest from 'src/mappers/Parking/Product/toBindLicenseRequest';
import toBoundLicense from 'src/mappers/Parking/Product/toBoundLicense';
import toError from 'src/mappers/Parking/Product/toError';
import toProduct from 'src/mappers/Parking/Product/toProduct';
import toProductChangeSettings from 'src/mappers/Parking/Product/toProductChangeSettings';
import toProductChangeSettingsRequest from 'src/mappers/Parking/Product/toProductChangeSettingsRequest';
import toProductChangeState from 'src/mappers/Parking/Product/toProductChangeState';
import toProductChangeStateRequest from 'src/mappers/Parking/Product/toProductChangeStateRequest';
import toProductQuery from 'src/mappers/Parking/Product/toProductQuery';
import type BindLicenseRequest from 'src/models/Parking/BindLicenseRequest';
import type BindLicenseResponse from 'src/models/Parking/BindLicenseResponse';
import type BindLicenseValues from 'src/models/Parking/BindLicenseValues';
import type BoundLicense from 'src/models/Parking/BoundLicense';
import type BoundLicenseResponse from 'src/models/Parking/BoundLicenseResponse';
import type UnbindLicenseResponse from 'src/models/Parking/UnbindLicenseResponse';
import toMeta from '../../mappers/Shared/toMeta';
import type Error from '../../models/Parking/Error';
import type ErrorResponse from '../../models/Parking/ErrorResponse';
import type Product from '../../models/Parking/Product';
import type ProductChangeSettings from '../../models/Parking/ProductChangeSettings';
import type ProductChangeSettingsRequest from '../../models/Parking/ProductChangeSettingsRequest';
import type ProductChangeSettingsResponse from '../../models/Parking/ProductChangeSettingsResponse';
import type ProductChangeSettingsValues from '../../models/Parking/ProductChangeSettingsValues';
import type ProductChangeState from '../../models/Parking/ProductChangeState';
import type ProductChangeStateRequest from '../../models/Parking/ProductChangeStateRequest';
import type ProductChangeStateResponse from '../../models/Parking/ProductChangeStateResponse';
import type ProductChangeStateValues from '../../models/Parking/ProductChangeStateValues';
import type ProductQuery from '../../models/Parking/ProductQuery';
import type ProductQueryParams from '../../models/Parking/ProductQueryParams';
import type ProductResponse from '../../models/Parking/ProductResponse';
import type BaseQueryParams from '../../models/Shared/BaseQueryParams';
import type GetAllDetails from '../../models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const productsBaseURL = '/products';
interface ProductService {
  findAllProducts: (
    queryParams: ProductQueryParams
  ) => Promise<GetAllDetails<Product>>;
  findAllProductErrors: (query: BaseQueryParams) => Promise<Error[]>;
  changeStateOfProduct: (
    productId: number,
    values: ProductChangeStateValues
  ) => Promise<ProductChangeState>;
  changeSettingsOfProduct: (
    productId: number,
    values: ProductChangeSettingsValues
  ) => Promise<ProductChangeSettings>;
  listAllBoundLicenses: (
    productId: number,
    query: BaseQueryParams
  ) => Promise<GetAllDetails<BoundLicense>>;
  bindLicenses: (values: BindLicenseValues) => Promise<BindLicenseResponse>;
  unbindLicense: (
    productId: number,
    licenseId: number
  ) => Promise<UnbindLicenseResponse>;
}

export default function useProductService(): ProductService {
  const { get, post, remove } = useApiService();

  const findAllProducts = useCallback(
    async (queryParams: ProductQueryParams) => {
      const query = toProductQuery(queryParams);
      const response = await get<ProductResponse[], ProductQuery>(
        `${productsBaseURL}`,
        query
      );
      const data = response.data.map(toProduct);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findAllProductErrors = useCallback(
    async (query: BaseQueryParams) => {
      const response = await get<ErrorResponse[], BaseQueryParams>(
        `${productsBaseURL}/errors`,
        query
      );

      const data = response.data.map(toError);

      return data;
    },
    [get]
  );

  const changeStateOfProduct = useCallback(
    async (productId: number, values: ProductChangeStateValues) => {
      const requestBody = toProductChangeStateRequest(values);
      const response = await post<
        ProductChangeStateRequest,
        ProductChangeStateResponse
      >(`${productsBaseURL}/${productId}/state`, requestBody);

      const data = toProductChangeState(response.data);

      return data;
    },
    [post]
  );

  const changeSettingsOfProduct = useCallback(
    async (productId: number, values: ProductChangeSettingsValues) => {
      const requestBody = toProductChangeSettingsRequest(values);
      const response = await post<
        ProductChangeSettingsRequest,
        ProductChangeSettingsResponse
      >(`${productsBaseURL}/${productId}/settings`, requestBody);

      const data = toProductChangeSettings(response.data);

      return data;
    },
    [post]
  );

  const listAllBoundLicenses = useCallback(
    async (productId: number, query: BaseQueryParams) => {
      const response = await get<BoundLicenseResponse[], BaseQueryParams>(
        `${productsBaseURL}/${productId}/bound-licenses`,
        query
      );

      const data = response.data.map(toBoundLicense);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const bindLicenses = useCallback(
    async (values: BindLicenseValues) => {
      const requestBody = toBindLicenseRequest(values);
      const response = await post<BindLicenseRequest, BindLicenseResponse>(
        `${productsBaseURL}/bind-licenses`,
        requestBody
      );

      return response.data;
    },
    [post]
  );

  const unbindLicense = useCallback(
    async (productId: number, licenseId: number) => {
      const response = await remove<UnbindLicenseResponse>(
        `${productsBaseURL}/${productId}/bound-licenses/${licenseId}`
      );

      return response.data;
    },
    [remove]
  );

  return {
    findAllProducts,
    findAllProductErrors,
    changeStateOfProduct,
    changeSettingsOfProduct,
    listAllBoundLicenses,
    bindLicenses,
    unbindLicense,
  };
}
