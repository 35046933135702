import { useCallback } from 'react';
import ContentType from 'src/constants/Shared/ContentType';
import toContactSupportRequest from 'src/mappers/License/toContactSupportRequest';
import toLicensePurchase from 'src/mappers/License/toLicensePurchase';
import toLicensePurchaseCreateRequest from 'src/mappers/License/toLicensePurchaseCreateRequest';
import toLicensePurchaseQuery from 'src/mappers/License/toLicensePurchaseQuery';
import toLicensePurchaseUpdateParams from 'src/mappers/License/toLicensePurchaseUpdateParams';
import toMeta from 'src/mappers/Shared/toMeta';
import type ContactSupportFormValues from 'src/models/License/ContactSupportFormValues';
import type ContactSupportRequest from 'src/models/License/ContactSupportRequest';
import type ContactSupportResponse from 'src/models/License/ContactSupportResponse';
import type LicensePurchase from 'src/models/License/LicensePurchase';
import type LicensePurchaseCreateFormValues from 'src/models/License/LicensePurchaseCreateFormValues';
import type LicensePurchaseCreateRequest from 'src/models/License/LicensePurchaseCreateRequest';
import type LicensePurchaseCreateResponse from 'src/models/License/LicensePurchaseCreateResponse';
import type LicensePurchaseFormValues from 'src/models/License/LicensePurchaseFormValues';
import type LicensePurchaseQuery from 'src/models/License/LicensePurchaseQuery';
import type LicensePurchaseQueryParams from 'src/models/License/LicensePurchaseQueryParams';
import type LicensePurchaseResponse from 'src/models/License/LicensePurchaseResponse';
import type LicensePurchaseUpdateParams from 'src/models/License/LicensePurchaseUpdateParams';
import type LicensePurchaseUpdateResponse from 'src/models/License/LicensePurchaseUpdateResponse';
import type GetAllDetails from 'src/models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const licensePurchaseBaseURL = '/license-purchases';

interface LicensePurchaseService {
  addLicensePurchase: (
    data: LicensePurchaseCreateFormValues,
    accountId: number
  ) => Promise<LicensePurchaseCreateResponse>;
  contactSupport: (
    values: ContactSupportFormValues
  ) => Promise<ContactSupportResponse>;
  listAllLicensePurchasesByAccountId: (
    values: LicensePurchaseQueryParams
  ) => Promise<GetAllDetails<LicensePurchase>>;
  listAllLicensePurchases: (
    values: LicensePurchaseQueryParams
  ) => Promise<GetAllDetails<LicensePurchase>>;
  updateLicensePurchase: (
    data: LicensePurchaseFormValues,
    accountId: number
  ) => Promise<LicensePurchaseUpdateResponse>;
}

export default function useLicensePurchaseService(): LicensePurchaseService {
  const { post, get, patch } = useApiService();

  const { get: parklioInternalGetData } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: true,
  });

  const addLicensePurchase = useCallback(
    async (data: LicensePurchaseCreateFormValues, accountId: number) => {
      const addLicense = toLicensePurchaseCreateRequest(data, accountId);
      const response = await post<
        LicensePurchaseCreateRequest,
        LicensePurchaseCreateResponse
      >(`${licensePurchaseBaseURL}`, addLicense);

      return response.data;
    },
    [post]
  );

  const contactSupport = useCallback(
    async (values: ContactSupportFormValues) => {
      const request = toContactSupportRequest(values);

      const response = await post<
        ContactSupportRequest,
        ContactSupportResponse
      >(`${licensePurchaseBaseURL}/support`, request);

      return response.data;
    },
    [post]
  );

  const listAllLicensePurchases = useCallback(
    async (queryParams: LicensePurchaseQueryParams) => {
      const query = toLicensePurchaseQuery(queryParams);
      const response = await get<
        LicensePurchaseResponse[],
        LicensePurchaseQuery
      >(`${licensePurchaseBaseURL}`, query);
      const data = response.data.map(toLicensePurchase);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const listAllLicensePurchasesByAccountId = useCallback(
    async (queryParams: LicensePurchaseQueryParams) => {
      const query = toLicensePurchaseQuery(queryParams);
      const response = await parklioInternalGetData<
        LicensePurchaseResponse[],
        LicensePurchaseQuery
      >(`${licensePurchaseBaseURL}`, query);
      const data = response.data.map(toLicensePurchase);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [parklioInternalGetData]
  );

  const updateLicensePurchase = useCallback(
    async (data: LicensePurchaseFormValues, accountId: number) => {
      const updateParams = toLicensePurchaseUpdateParams(data, accountId);
      const response = await patch<
        LicensePurchaseUpdateParams,
        LicensePurchaseUpdateResponse
      >(`${licensePurchaseBaseURL}`, updateParams);

      return response.data;
    },
    [patch]
  );

  return {
    addLicensePurchase,
    contactSupport,
    listAllLicensePurchasesByAccountId,
    listAllLicensePurchases,
    updateLicensePurchase,
  };
}
