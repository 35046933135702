enum InvitationKeysStatuses {
  ACTIVATED = 'activated',
  PENDING = 'pending',
  BLOCKED = 'blocked',
  EXPIRED = 'expired',
  INVALID = 'invalid',
  DELETED = 'deleted',
}

export default InvitationKeysStatuses;
