import type TariffActivePeriod from 'src/models/Parking/Tariff/TariffActivePeriod';

export default function toPeriods(periods: TariffActivePeriod[]) {
  const dates: { startDate: string; endDate: string }[] = [];
  const times: { startTime: string; endTime: string }[] = [];
  periods.forEach((item) => {
    if (item.startDate !== null && item.endDate !== null) {
      const dateIndex = dates.findIndex(
        (x) => x.startDate === item.startDate && x.endDate === item.endDate
      );
      if (dateIndex <= -1) {
        dates.push({ startDate: item.startDate, endDate: item.endDate });
      }
    }
    if (item.startTime !== null && item.endTime !== null) {
      const timeIndex = times.findIndex(
        (x) => x.startTime === item.startTime && x.endTime === item.endTime
      );
      if (timeIndex <= -1) {
        times.push({ startTime: item.startTime, endTime: item.endTime });
      }
    }
  });

  return {
    dates,
    times,
  };
}
