import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';

const DetailsStatus = styled.div<{
  offline?: boolean;
  addVerticalMargin?: boolean;
  addMargin?: boolean;
}>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  :last-child {
    margin-bottom: 20px;
  }

  ${(props) => {
    const { offline } = props;

    if (offline) {
      return css`
        color: ${Color.ERROR};
      `;
    }

    return css`
      color: ${Color.TEXT_DARKER};
    `;
  }}

  ${(props) => {
    const { addVerticalMargin, addMargin } = props;

    if (addVerticalMargin) {
      return css`
        margin: 20px 0;
      `;
    }

    if (addMargin) {
      return css`
        margin: 20px;
        @media (max-width: 500px) {
          margin: 5px 0 20px 0;
        }
      `;
    }

    return css``;
  }}
`;

export default DetailsStatus;
