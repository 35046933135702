export default function cutOffAt(value: string, at: number) {
  if (value.length <= at) {
    return value;
  }

  const short = value.slice(0, at);

  if (short[short.length - 1] === ' ') {
    return `${short}...`;
  }

  return `${short} ...`;
}
