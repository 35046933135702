import TimeRanges from 'src/constants/Shared/TimeRanges';
import TimeRangeValues from 'src/constants/Shared/TimeRangeValues';

export default function getTimeRange(timeRange?: TimeRangeValues) {
  switch (timeRange) {
    case TimeRangeValues.DAY:
      return TimeRanges.DAY;
    case TimeRangeValues.WEEK:
      return TimeRanges.WEEK;
    case TimeRangeValues.TWO_WEEKS:
      return TimeRanges.TWO_WEEKS;
    case TimeRangeValues.MONTH:
      return TimeRanges.MONTH;
    default:
      return TimeRanges.DAY;
  }
}
