import React from 'react';
import InquiryResponse from './InquiryResponse';
import SendInquiryForm from './SendInquiryForm';

interface SendInquiryModalProps {
  closeParentModal: () => void;
  onTryAgainClick: () => void;
  inquiryResponseInfo: { success: boolean; message: string } | undefined;
  noLicense?: boolean;
}

export default function SendInquiryModal(props: SendInquiryModalProps) {
  const { closeParentModal, noLicense, onTryAgainClick, inquiryResponseInfo } =
    props;

  return (
    <>
      {inquiryResponseInfo ? (
        <InquiryResponse
          success={inquiryResponseInfo.success}
          message={inquiryResponseInfo.message}
          onTryAgainClick={onTryAgainClick}
          closeParentModal={closeParentModal}
          noLicense={noLicense}
        />
      ) : (
        <SendInquiryForm
          closeParentModal={closeParentModal}
          noLicense={noLicense}
        />
      )}
    </>
  );
}
