import type KeyLogUpdateByIdParams from 'src/models/KeySharing/KeyLog/KeyLogUpdateByIdParams';
import type KeyLogUpdateByIdValues from 'src/models/KeySharing/KeyLog/KeyLogUpdateByIdValues';

export default function toKeyLogUpdateByIdParams(
  data: KeyLogUpdateByIdValues
): KeyLogUpdateByIdParams {
  const { status } = data;

  return { status };
}
