import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import Color from '../../constants/Shared/Color';
import RadioButton from './RadioButton';

interface RadioButtonFieldProps {
  name: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  defaultChecked?: boolean;
}

export default function RadioButtonField(props: RadioButtonFieldProps) {
  const { label, name, disabled, value, defaultChecked } = props;
  const [field] = useField({ name });
  const { checked, onChange } = field;

  return (
    <StyledWrapper>
      <RadioButton
        onChange={onChange}
        name={name}
        disabled={disabled}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
      />
      <StyledLabel>{label}</StyledLabel>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;

  :last-child {
    margin-bottom: 40px;
  }
`;

const StyledLabel = styled.label`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.TEXT_DARKER};
`;
