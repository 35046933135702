import type LotEntryDirection from 'src/models/Statistics/LotEntry/LotEntryDirection';
import type LotEntryDirectionResponse from 'src/models/Statistics/LotEntry/LotEntryDirectionResponse';

export default function toLotEntryDirection(
  data: LotEntryDirectionResponse
): LotEntryDirection {
  const { direction, total } = data;

  return {
    direction,
    total,
  };
}
