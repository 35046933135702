import { useCallback } from 'react';
import toTariff from 'src/mappers/Parking/Tariff/toTariff';
import toTariffCreateRequest from 'src/mappers/Parking/Tariff/toTariffCreateRequest';
import toTariffQueryParams from 'src/mappers/Parking/Tariff/toTariffQueryParams';
import toTariffUpdateParams from 'src/mappers/Parking/Tariff/toTariffUpdateParams';
import toMeta from 'src/mappers/Shared/toMeta';
import type Tariff from 'src/models/Parking/Tariff/Tariff';
import type TariffCreateRequest from 'src/models/Parking/Tariff/TariffCreateRequest';
import type TariffFormValues from 'src/models/Parking/Tariff/TariffFormValues';
import type TariffQuery from 'src/models/Parking/Tariff/TariffQuery';
import type TariffQueryParams from 'src/models/Parking/Tariff/TariffQueryParams';
import type TariffRemoveResponse from 'src/models/Parking/Tariff/TariffRemoveResponse';
import type TariffResponse from 'src/models/Parking/Tariff/TariffResponse';
import type TariffUpdateParams from 'src/models/Parking/Tariff/TariffUpdateParams';
import type TariffUpdateValues from 'src/models/Parking/Tariff/TariffUpdateValues';
import type GetAllDetails from 'src/models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const tariffBaseURL = '/tariffs';

interface TariffService {
  findOneTariff: (id: number) => Promise<Tariff>;
  findAllTariffs: (values?: TariffQuery) => Promise<GetAllDetails<Tariff>>;
  createTariff: (
    currencyId: number,
    values: TariffFormValues,
    zoneId?: number
  ) => Promise<Tariff>;
  updateTariff: (
    id: number,
    values: TariffUpdateValues,
    zoneId?: number
  ) => Promise<Tariff>;
  removeTariff: (id: number) => Promise<TariffRemoveResponse>;
}

export default function useTariffService(): TariffService {
  const { get, post, patch, remove } = useApiService();

  const findOneTariff = useCallback(
    async (id: number) => {
      const response = await get<TariffResponse>(`${tariffBaseURL}/${id}`);

      const data = toTariff(response.data);

      return data;
    },
    [get]
  );

  const findAllTariffs = useCallback(
    async (values?: TariffQuery) => {
      const query = toTariffQueryParams(values);

      const response = await get<TariffResponse[], TariffQueryParams>(
        `${tariffBaseURL}`,
        query
      );

      const data = response.data.map(toTariff);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const createTariff = useCallback(
    async (currencyId: number, values: TariffFormValues, zoneId?: number) => {
      const requestBody = toTariffCreateRequest(currencyId, values, zoneId);
      const { data } = await post<TariffCreateRequest, TariffResponse>(
        `${tariffBaseURL}`,
        requestBody
      );

      return toTariff(data);
    },
    [post]
  );

  const updateTariff = useCallback(
    async (id: number, values: TariffUpdateValues, zoneId?: number) => {
      const params = toTariffUpdateParams(values, zoneId);
      const response = await patch<TariffUpdateParams, TariffResponse>(
        `${tariffBaseURL}/${id}`,
        params
      );

      return toTariff(response.data);
    },
    [patch]
  );

  const removeTariff = useCallback(
    async (id: number) => {
      const response = await remove<TariffRemoveResponse>(
        `${tariffBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  return {
    findAllTariffs,
    findOneTariff,
    createTariff,
    updateTariff,
    removeTariff,
  };
}
