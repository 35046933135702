import React, { useCallback, useState } from 'react';
import 'react-toggle/style.css';
import '../../assets/css/toggle.css';
import Toggle from 'react-toggle';
import Dropdown from './Dropdown';
import {
  InfoIcon,
  LabelWrapper,
  StyledDescription,
  StyledErrorWrapper,
  StyledWrapper,
} from './InfoMessage';
import Label from './Label';

interface ToggleSwitchProps {
  onChange: () => void;
  state: string | undefined | boolean;
  name?: string;
  condition: boolean | string;
  info?: string;
  disabled?: boolean;
  alignCenter?: boolean;
}

export default function ToggleSwitch(props: ToggleSwitchProps) {
  const { state, onChange, name, condition, info, disabled, alignCenter } =
    props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const open = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  return (
    <StyledWrapper alignCenter={alignCenter}>
      <LabelWrapper>
        {name && (
          <Label wider>
            {name}
            {info && (
              <InfoIcon
                className='pa-info'
                onMouseEnter={open}
                onMouseLeave={close}
              />
            )}
          </Label>
        )}
        <Toggle
          checked={state === condition}
          onChange={onChange}
          disabled={disabled}
        />
      </LabelWrapper>
      {info && isDropdownOpen && (
        <Dropdown close={close} small>
          <StyledErrorWrapper>
            <StyledDescription>{info}</StyledDescription>
          </StyledErrorWrapper>
        </Dropdown>
      )}
    </StyledWrapper>
  );
}
