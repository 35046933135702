import { useCallback } from 'react';
import AccountRole from '../../constants/Shared/AccountRole';
import useAuthContext from '../../hooks/Auth/useAuthContext';
import toLoginRequest from '../../mappers/Auth/toLoginRequest';
import toSessionData from '../../mappers/Auth/toSessionData';
import type LoginFormValues from '../../models/Auth/LoginFormValues';
import type LoginRequest from '../../models/Auth/LoginRequest';
import type LoginResponse from '../../models/Auth/LoginResponse';
import type SessionData from '../../models/Shared/SessionData';
import useApiService from '../Shared/useApiService';
import useSessionService from '../Shared/useSessionService';

interface AuthService {
  logIn: (values: LoginFormValues) => Promise<SessionData>;
  logOut: () => void;
}

export default function useAuthService(): AuthService {
  const { setIsLoggedIn, setSessionData: setSessionDataState } =
    useAuthContext();
  const { setSessionData, removeSessionData } = useSessionService();
  const { post } = useApiService({
    withAuth: false,
  });

  const logIn = useCallback(
    async (values: LoginFormValues): Promise<SessionData> => {
      const requestBody = toLoginRequest(values);

      const response = await post<LoginRequest, LoginResponse>(
        '/auth/account/login',
        requestBody
      );

      if (response.data.account_role_id === AccountRole.CONNECT) {
        response.data.token = '';
      }

      const sessionData = toSessionData(response.data);

      setIsLoggedIn(true);
      setSessionData(sessionData);
      setSessionDataState(sessionData);

      return sessionData;
    },
    [setIsLoggedIn, post, setSessionData, setSessionDataState]
  );

  const logOut = useCallback(() => {
    setIsLoggedIn(false);
    setSessionDataState(undefined);
    removeSessionData();
  }, [removeSessionData, setIsLoggedIn, setSessionDataState]);

  return { logIn, logOut };
}
