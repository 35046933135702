import { useField } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import 'react-toggle/style.css';
import '../../assets/css/toggle.css';
import Toggle from 'react-toggle';
import Dropdown from './Dropdown';
import {
  InfoIcon,
  LabelWrapper,
  StyledDescription,
  StyledErrorWrapper,
  StyledWrapper,
} from './InfoMessage';
import Label from './Label';

interface ToggleSwitchForFormikProps {
  name: string;
  label: string;
  condition: boolean | string;
  info?: string;
  disabled?: boolean;
  zoneView?: boolean;
  customWidth?: boolean;
  hideLabel?: boolean;
}

export default function ToggleSwitchForFormik(
  props: ToggleSwitchForFormikProps
) {
  const {
    name,
    condition,
    info,
    label,
    disabled,
    zoneView,
    customWidth,
    hideLabel,
  } = props;

  const [field, , helpers] = useField({ name });
  const { value } = field;
  const { setValue } = helpers;

  const toggleValue = useMemo(() => String(value), [value]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const open = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const onChange = useCallback(() => {
    setValue(!value);
  }, [setValue, value]);

  const isChecked = useMemo(() => !!value === condition, [value, condition]);

  return (
    <StyledWrapper zoneView={zoneView} customWidth={customWidth}>
      <LabelWrapper>
        <Label wider mediumLabel={customWidth} hideLabel={hideLabel}>
          {label}
          {info && (
            <InfoIcon
              className='pa-info'
              onMouseEnter={open}
              onMouseLeave={close}
            />
          )}
        </Label>
        <Toggle
          checked={isChecked}
          onChange={onChange}
          name={name}
          value={toggleValue}
          disabled={disabled}
        />
      </LabelWrapper>
      {info && isDropdownOpen && (
        <Dropdown close={close} small>
          <StyledErrorWrapper>
            <StyledDescription>{info}</StyledDescription>
          </StyledErrorWrapper>
        </Dropdown>
      )}
    </StyledWrapper>
  );
}
