import LicenseStatus from 'src/constants/License/LicenseStatus';
import type LicenseFormValues from 'src/models/License/LicenseFormValues';
import type LicensePricingCreateRequest from 'src/models/License/LicensePricingCreateRequest';

export default function toLicensePricingCreateRequest(
  data: LicenseFormValues,
  licenseId: number,
  currencyId: number
): LicensePricingCreateRequest {
  const { name, price, status } = data;

  return {
    name,
    price,
    status: status ? LicenseStatus.AVAILABLE : LicenseStatus.UNAVAILABLE,
    license_id: licenseId,
    currency_id: currencyId,
  };
}
