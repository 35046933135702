import type Barrier from '../../../models/Parking/Barrier/Barrier';
import type BarrierResponse from '../../../models/Parking/Barrier/BarrierResponse';
import toConcreteProduct from '../Product/toConcreteProduct';

export default function toBarrier(data: BarrierResponse): Barrier {
  const {
    id,
    state,
    autoclose_time,
    is_autoclose,
    is_car_on_top,
    product_error_id,
    product,
  } = data;

  return {
    id,
    state,
    autocloseTime: autoclose_time,
    isAutoclose: !!is_autoclose,
    isCarOnTop: !!is_car_on_top,
    productErrorId: product_error_id,
    product: toConcreteProduct(product),
  };
}
