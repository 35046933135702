import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ParklioSignInverse } from '../../../assets/svg/ParklioSignInverse.svg';
import Color from '../../../constants/Shared/Color';
import Actions from './Actions';
import Button from './Button';
import Link from './Link';
import Navigation from './Navigation';

interface PrimaryNavigationProps {
  children: React.ReactNode;
}

export default function PrimaryNavigation(props: PrimaryNavigationProps) {
  const { children } = props;

  return (
    <StyledWrapper>
      <StyledParklioSign onClick={() => window.location.assign('/home')} />
      {children}
    </StyledWrapper>
  );
}

PrimaryNavigation.Link = Link;
PrimaryNavigation.Navigation = Navigation;
PrimaryNavigation.Actions = Actions;
PrimaryNavigation.Button = Button;

const StyledParklioSign = styled(ParklioSignInverse)`
  width: 56px;
  cursor: pointer;
`;

const StyledWrapper = styled.header`
  width: 100%;
  height: 70px;
  background-color: ${Color.PRIMARY_BRAND};
  padding: 0px 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  @media (max-width: 500px) {
    padding: 0 20px;
  }

  @media (max-width: 400px) {
    padding: 0;
  }
`;
