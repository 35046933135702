import React, { useCallback } from 'react';
import ActionIcon from 'src/constants/Shared/ActionIcon';
import type AccountUser from 'src/models/Home/AccountUser';
import toAccountRole from '../../mappers/Home/toAccountRole';
import Card from '../Shared/Card';
import Table from '../Shared/Table';

interface AccountTableProps {
  data: AccountUser[];
  isLoading: boolean;
}

export default function AccountTable(props: AccountTableProps) {
  const { data, isLoading } = props;

  const itemInfo = useCallback((data?: AccountUser) => {
    if (!data) {
      return;
    }

    const { account } = data;
    const { id } = account;

    window.open(`/settings/account/${id}/info`);
  }, []);

  return (
    <Card>
      <Table isLoading={isLoading}>
        <Table.Head>
          <Table.Row>
            <Table.Header>ID #</Table.Header>
            <Table.Header>PARKING MANAGER EMAIL</Table.Header>
            <Table.Header>ACCOUNT</Table.Header>
            <Table.Header>ACCOUNT TYPE</Table.Header>
            <Table.Header />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map((accountUserFindAllDetails) => {
            const { id, account, email } = accountUserFindAllDetails;

            const { name, accountRole, id: accountId } = account;

            return (
              <Table.Row key={id}>
                <Table.Cell>{accountId}</Table.Cell>
                <Table.Cell>{email}</Table.Cell>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{toAccountRole(accountRole)}</Table.Cell>
                <Table.Cell
                  action={itemInfo}
                  primary
                  data={accountUserFindAllDetails}
                >
                  <Table.Icon icon={ActionIcon.EDIT} />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Card>
  );
}
