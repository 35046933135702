import React, { useCallback, useMemo, useState } from 'react';
import Form from 'src/components/Shared/Form';
import {
  LprImageStorageSubscriptionPlans,
  PlanValues,
} from 'src/constants/Parking/LprImageStorageSubscriptionPlans';
import LprImageStorageSubscriptionStatuses from 'src/constants/Parking/LprImageStorageSubscriptionStatuses';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import Color from 'src/constants/Shared/Color';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import type LprImageStorageSubscription from 'src/models/Parking/Anpr/ImageStorageSubscription/LprImageStorageSubscription';
import type LprImageStorageSubscriptionCreateFormValues from 'src/models/Parking/Anpr/ImageStorageSubscription/LprImageStorageSubscriptionCreateFormValues';
import type LprImageStorageSubscriptionHistory from 'src/models/Parking/Anpr/ImageStorageSubscriptionHistory/LprImageStorageSubscriptionHistory';
import type LprCamera from 'src/models/Parking/Anpr/LprCamera/LprCamera';
import type Option from 'src/models/Shared/Option';
import useLprImageStorageSubscriptionsService from 'src/services/Lpr/useLprImageStorageService';
import capitalizeFirstLetter from 'src/utils/capitalizeFirstLetter';
import formatDate from 'src/utils/formatDate';
import {
  hasExpiryDateChanged,
  isStatusInRegularGracePeriod,
  remainingTime,
} from 'src/utils/imageStorageFunctions';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import { useGlobalConfirmationModal } from '../Shared/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../Shared/Modals/GlobalFailureModal';
import SelectField from '../Shared/SelectField';
import StyledNoData from '../Shared/StyledNoData';
import Table from '../Shared/Table';
import Title from '../Shared/Title';
import ToggleSwitch from '../Shared/ToggleSwitch';

interface LprImageSubscriptionTableParklioInternalProps {
  data: LprCamera;
  histories: LprImageStorageSubscriptionHistory[];
  onChangeState: (
    id: number,
    subscription: LprImageStorageSubscription
  ) => void;
  getData: () => void;
}

const options: Option[] = [
  {
    key: PlanValues[LprImageStorageSubscriptionPlans.ONE_MONTH],
    label: capitalizeFirstLetter(LprImageStorageSubscriptionPlans.ONE_MONTH),
  },
  {
    key: PlanValues[LprImageStorageSubscriptionPlans.THREE_MONTH],
    label: capitalizeFirstLetter(LprImageStorageSubscriptionPlans.THREE_MONTH),
  },
  {
    key: PlanValues[LprImageStorageSubscriptionPlans.SIX_MONTH],
    label: capitalizeFirstLetter(LprImageStorageSubscriptionPlans.SIX_MONTH),
  },
  {
    key: PlanValues[LprImageStorageSubscriptionPlans.ONE_YEAR],
    label: capitalizeFirstLetter(LprImageStorageSubscriptionPlans.ONE_YEAR),
  },
];

const initialValues: LprImageStorageSubscriptionCreateFormValues = {
  subscriptionPlan: null,
};

const validationSchema = yup.object({
  subscriptionPlan: yup
    .object()
    .nullable()
    .required('Subscription plan is a required field'),
});

export default function LprImageSubscriptionParklioInternalTable(
  props: LprImageSubscriptionTableParklioInternalProps
) {
  const { data, histories, onChangeState, getData } = props;
  const { id, name, storageSubscriptionPlan, lot } = data;
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [createValues, setCreateValues] =
    useState<LprImageStorageSubscriptionCreateFormValues>();
  const {
    createLprImageStorageSubscription,
    updateLprImageStorageSubscription,
  } = useLprImageStorageSubscriptionsService();
  const isMounted = useIsMounted();

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onToggleChange = useCallback(async () => {
    try {
      if (!storageSubscriptionPlan) {
        return;
      }

      const { id: subscriptionId, status } = storageSubscriptionPlan;

      if (isMounted()) {
        setIsUpdating(true);
      }

      const newSubscriptionStatus =
        status === LprImageStorageSubscriptionStatuses.ON_HOLD
          ? LprImageStorageSubscriptionStatuses.ACTIVE
          : LprImageStorageSubscriptionStatuses.ON_HOLD;

      const response = await updateLprImageStorageSubscription(subscriptionId, {
        status: newSubscriptionStatus,
      });

      if (isMounted()) {
        onChangeState(id, response);
        setIsUpdating(false);
      }
    } catch (error: any) {
      if (isMounted()) {
        setIsUpdating(false);
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    updateLprImageStorageSubscription,
    isMounted,
    onChangeState,
    storageSubscriptionPlan,
    id,
    setMessage,
    openGlobalFailureModal,
  ]);

  const { openGlobalConfirmationModal: updateSubscriptionPlan } =
    useGlobalConfirmationModal({
      action: onToggleChange,
      message: 'Are you sure you want to update this subscription plan?',
    });

  const getPutOnHoldValue = useMemo(() => {
    if (!storageSubscriptionPlan) {
      return undefined;
    }

    const { status } = storageSubscriptionPlan;

    if (
      hasExpiryDateChanged(storageSubscriptionPlan) ||
      status === LprImageStorageSubscriptionStatuses.ACTIVE
    ) {
      return (
        <ToggleSwitch
          state={status}
          condition={LprImageStorageSubscriptionStatuses.ON_HOLD}
          onChange={updateSubscriptionPlan}
          disabled={isUpdating}
          alignCenter={hasExpiryDateChanged(storageSubscriptionPlan)}
        />
      );
    }

    if (isStatusInRegularGracePeriod(storageSubscriptionPlan)) {
      return `${remainingTime(storageSubscriptionPlan)} days remaining`;
    }

    return status.toUpperCase();
  }, [updateSubscriptionPlan, storageSubscriptionPlan, isUpdating]);

  const onSubmit = useCallback(
    async (values: LprImageStorageSubscriptionCreateFormValues) => {
      try {
        if (isMounted()) {
          setIsProcessing(true);
        }
        await createLprImageStorageSubscription(values, data.id);
        getData();

        if (isMounted()) {
          setIsProcessing(false);
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsProcessing(false);
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      createLprImageStorageSubscription,
      data,
      isMounted,
      setMessage,
      openGlobalFailureModal,
      getData,
    ]
  );

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onSubmit,
    message: 'Are you sure you want to add a new subscription plan?',
    values: createValues,
  });

  const openCreateConfirmationModal = useCallback(
    async (values: LprImageStorageSubscriptionCreateFormValues) => {
      if (isMounted()) {
        setCreateValues(values);
        openGlobalConfirmationModal();
      }
    },
    [openGlobalConfirmationModal, isMounted]
  );

  const statusLabel = useCallback(
    (history: LprImageStorageSubscriptionHistory) => {
      const { expiryDate } = history;
      const currentDate = new Date();

      if (new Date(expiryDate).getTime() >= currentDate.getTime()) {
        return undefined;
      }

      return 'EXPIRED';
    },
    []
  );

  return (
    <Card>
      <StyledWrapper>
        <Title addBottomMargin bold>
          {name} - {lot?.name}
        </Title>
        <Form
          name='ImageStorage'
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={openCreateConfirmationModal}
        >
          <StyledDiv>
            <SelectField
              label=''
              placeholder='Subscription plan'
              name='subscriptionPlan'
              options={options}
              short
              small
              hideLabel
              imageStorageView
            />
            <Button
              type='submit'
              primary
              size={ButtonSize.MIDDLE}
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              Add Subscription Plan
            </Button>
          </StyledDiv>
        </Form>
      </StyledWrapper>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header>SUBSCRIPTION PLAN</Table.Header>
            <Table.Header>ACTIVATED</Table.Header>
            <Table.Header>VALID TIL</Table.Header>
            <Table.Header
              colSpan={2}
              right={hasExpiryDateChanged(storageSubscriptionPlan)}
            >
              ON HOLD
            </Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {storageSubscriptionPlan &&
            storageSubscriptionPlan.status !==
              LprImageStorageSubscriptionStatuses.EXPIRED && (
              <Table.Row>
                <Table.Cell
                  disabled={isStatusInRegularGracePeriod(
                    storageSubscriptionPlan
                  )}
                >
                  Current
                </Table.Cell>
                <Table.Cell
                  disabled={isStatusInRegularGracePeriod(
                    storageSubscriptionPlan
                  )}
                >
                  {formatDate(
                    storageSubscriptionPlan.activationDate,
                    false,
                    true
                  )}
                </Table.Cell>
                <Table.Cell
                  disabled={isStatusInRegularGracePeriod(
                    storageSubscriptionPlan
                  )}
                >
                  {formatDate(storageSubscriptionPlan.expiryDate, false, true)}
                </Table.Cell>
                {hasExpiryDateChanged(storageSubscriptionPlan) && (
                  <Table.Cell invalid>
                    {remainingTime(storageSubscriptionPlan)} days remaining
                  </Table.Cell>
                )}
                <Table.Cell
                  merged={
                    isStatusInRegularGracePeriod(storageSubscriptionPlan)
                      ? 2
                      : 1
                  }
                  invalid={isStatusInRegularGracePeriod(
                    storageSubscriptionPlan
                  )}
                >
                  {getPutOnHoldValue}
                </Table.Cell>
              </Table.Row>
            )}
          {showHistory &&
            histories.map((history) => {
              const { id, targetSubscriptionPlan, activationDate, expiryDate } =
                history;
              return (
                <Table.Row key={id}>
                  <Table.Cell disabled>{targetSubscriptionPlan}</Table.Cell>
                  <Table.Cell disabled>
                    {formatDate(activationDate, false, true)}
                  </Table.Cell>
                  <Table.Cell disabled>
                    {formatDate(expiryDate, false, true)}
                  </Table.Cell>
                  <Table.Cell merged={2} disabled>
                    {statusLabel(history)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          {histories.length > 0 && (
            <Table.Row>
              <Table.Cell merged={4}>
                <ButtonDiv addBottomMargin={showHistory}>
                  <Button
                    size={ButtonSize.MIDDLE}
                    type='button'
                    onClick={() => setShowHistory((oldValue) => !oldValue)}
                    centerAlign
                  >
                    Subscription History{' '}
                    <StyledSpan>
                      <StyledIcon
                        className={
                          showHistory ? 'pa-chevron-up' : 'pa-chevron-down'
                        }
                      />
                    </StyledSpan>
                  </Button>
                </ButtonDiv>
              </Table.Cell>
            </Table.Row>
          )}
          {!storageSubscriptionPlan && histories.length <= 0 && (
            <Table.Row>
              <Table.Cell merged={5}>
                <StyledNoData>
                  There is no subscription plan for this camera.
                </StyledNoData>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Card>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 699px) {
    flex-direction: column;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 30px;

  @media (max-width: 699px) {
    justify-content: flex-start;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const ButtonDiv = styled.div<{ addBottomMargin: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;

  ${(props) => {
    const { addBottomMargin } = props;

    if (addBottomMargin) {
      return css`
        margin-bottom: 15px;
      `;
    }
    return css``;
  }}
`;

const StyledSpan = styled.span`
  display: flex;
`;

const StyledIcon = styled.i`
  margin-left: 5px;
  color: ${Color.TEXT_DARKER};
`;
