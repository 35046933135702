enum AllProductStatuses {
  JAMMED = 'Jammed',
  SENSOR_DIRTY = 'Dirty sensor',
  PIN_BROKEN = 'Broken pin',
  BATTERY_EMPTY = 'Battery empty',
  RTCC_INVALID = 'RTCC warning',
  MASTER_KEY_NOT_SET = 'Master key is not set',
  OFFLINE = 'Offline',
  ONLINE = 'Online',
  OPEN = 'Open',
  CLOSE = 'Close',
  EXTEND = 'Extend',
  LOWER = 'Lower',
  NO_OPERATION = 'No operation',
  CAR_ON_TOP = 'Car on top',
  UNKNOWN = 'Unknown',
  PERMANENT_OPEN = 'Permanent open',
  PERMANENT_CLOSE = 'Permanent close',
  DISABLED = 'Disabled',
}

export default AllProductStatuses;
