import { useEffect } from 'react';

interface ModalControl {
  useEscapeKey: (onEscapePress: () => void) => void;
}
export default function useModalControl(): ModalControl {
  const useEscapeKey = (onEscapePress: () => void) => {
    useEffect(() => {
      const close = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onEscapePress();
        }
      };
      window.addEventListener('keydown', close);
      return () => window.removeEventListener('keydown', close);
    }, [onEscapePress]);
  };

  return { useEscapeKey };
}
