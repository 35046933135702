import type DetectGatewayQuery from 'src/models/Parking/DetectGateway/DetectGatewayQuery';
import type DetectGatewayQueryParams from 'src/models/Parking/DetectGateway/DetectGatewayQueryParams';

export default function toDetectGatewayQuery(
  data: DetectGatewayQueryParams
): DetectGatewayQuery {
  const { lotId, status } = data;

  return {
    lot_id: lotId,
    status,
  };
}
