import type LprCameraStatusEvent from 'src/models/Parking/Anpr/LprCameraStatusEvent';
import type LprCameraStatusEventPayload from 'src/models/Parking/Anpr/LprCameraStatusEventPayload';

export default function toLprCameraStatusEvent(
  eventData: LprCameraStatusEventPayload
): LprCameraStatusEvent {
  const { device_id, status } = eventData;

  return {
    deviceId: device_id,
    status,
  };
}
