import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { GetLicenseDescription } from 'src/components/License/GetLicenseDescription';
import LicenseStatus from 'src/constants/License/LicenseStatus';
import PaginationSize from 'src/constants/Shared/DataSize';
import type LicensePricing from 'src/models/License/LicensePricing';
import useLicenseManagementService from 'src/services/License/useLicenseManagementService';
import ButtonSize from '../../constants/Shared/ButtonSize';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import useWizardContext from '../../hooks/Shared/useWizardContext';
import type Counter from '../../models/Shared/Counter';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import CounterField from '../Shared/CounterField';
import Error from '../Shared/Error';
import Line from '../Shared/Line';
import Section from '../Shared/Section';
import Table from '../Shared/Table';
import Title from '../Shared/Title';

interface LicenseStepProps {
  initialValues: Counter[];
}

export default function LicenseStep(props: LicenseStepProps) {
  const { initialValues } = props;
  const { back, next } = useWizardContext();
  const [data, setData] = useState<LicensePricing[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { listAllLicensePricingsManagement } = useLicenseManagementService();
  const [field, meta] = useField({ name: 'licenseInfo' });
  const { value } = field;
  const { error, touched } = meta;
  const shouldShowError = error && touched;
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsLoading(true);
        }

        const { data } = await listAllLicensePricingsManagement({
          page: 1,
          size: PaginationSize.STANDARD,
          status: LicenseStatus.AVAILABLE,
        });

        if (isMounted()) {
          setData(data);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [listAllLicensePricingsManagement, isMounted]);

  return (
    <>
      <Section>
        <Title bold>Licenses</Title>
        <Table margin customWidth isLoading={isLoading}>
          <Table.Body>
            {value.map((license: Counter, index: number) => {
              const pricing = data.find(
                (pricingData) => pricingData.id === license.pricingId
              );
              const initial = initialValues.find(
                (value) => value.pricingId === license.pricingId
              );
              return (
                <Table.Row key={index} data-testid='licenseStepRow'>
                  <Table.Cell>
                    <CounterField
                      name={`licenseInfo.${index}`}
                      label={pricing ? pricing.name : 'Label'}
                      initialValue={initial}
                    >
                      {GetLicenseDescription(pricing?.license)}
                    </CounterField>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {shouldShowError && <Error>*{error}</Error>}
      </Section>
      <Line />
      <Control>
        <Button onClick={back} size={ButtonSize.LARGE} type='button'>
          Back
        </Button>
        <Button size={ButtonSize.LARGE} type='button' primary onClick={next}>
          Next step
        </Button>
      </Control>
    </>
  );
}
