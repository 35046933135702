import type CallbackFunc from 'src/constants/Shared/FunctionType';

export function isNotNull(value: any) {
  return value != null;
}

export function isNotUndefined(value: any) {
  return value !== undefined;
}

export function isNotNullOrUndefined(value: any) {
  return isNotNull(value) && isNotUndefined(value);
}

export function isNotString(value: any) {
  return typeof value !== 'string';
}

export function isNotFunction(object: any): object is CallbackFunc {
  return typeof object === 'function';
}

export function isNotEmptyObject(object: any): object is Record<string, any> {
  return !(Object.keys(object).length === 0);
}

export function isNotArray<T>(object: T | T[]): object is T {
  return !Array.isArray(object);
}

export function isNotEmptyArray(array: []) {
  return array.length !== 0;
}

export function areNotSameDates(dateOne?: Date | null, dateTwo?: Date | null) {
  const first = dateOne ? new Date(dateOne?.toDateString()) : undefined;
  const second = dateTwo ? new Date(dateTwo?.toDateString()) : undefined;
  return first?.getTime() !== second?.getTime();
}
