import { useField, useFormikContext } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import generateTimeLabel from 'src/utils/generateTimeLabel';
import SearchSelectIcon from '../../constants/Shared/SearchSelectIcon';
import type Option from '../../models/Shared/Option';
import cutOffAt from '../../utils/cutOffAt';
import {
  StyledColumn,
  StyledDateTimeContainer,
  StyledInput,
  StyledTimeContainer,
  StyledWrapper,
} from './DateTimeStyle';
import Dropdown from './Dropdown';
import Error from './Error';
import Label from './Label';
import TimeIcon from './SelectField/Icon';
import Options from './SelectField/Options';

interface TimeFieldProps {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  minutesOffset?: number;
  noInitialValue?: boolean;
  initialEndValue?: boolean;
  tariffView?: boolean;
  center?: boolean;
}

export default function TimeField(props: TimeFieldProps) {
  const {
    name,
    label,
    disabled,
    placeholder,
    noInitialValue,
    initialEndValue,
    tariffView,
    center,
  } = props;
  const [field, meta] = useField({ name });
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);
  const inputRef = useRef<React.ElementRef<'input'>>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [shouldSetTouched, setShouldSetTouched] = useState(false);
  const minutesOffset = props.minutesOffset || 30;
  const { value } = field;
  const { error, touched } = meta;
  const shouldShowError = error && touched;

  const generateTime = useCallback(
    (minuteOffset: number) => {
      const dayMinutes = 24 * 60;
      const times = [];

      for (let offset = 0; offset <= dayMinutes; offset += minuteOffset) {
        times.push({
          key: offset,
          label: generateTimeLabel(offset),
        });
      }

      const result = initialEndValue ? times.slice(1) : times.slice(0, -1);

      return result;
    },
    [initialEndValue]
  );

  useEffect(() => {
    if (value || noInitialValue) {
      return;
    }

    if (initialEndValue) {
      const arr = generateTime(minutesOffset);
      setFieldValue(name, arr[arr.length - 1]);
      return;
    }

    setFieldValue(name, generateTime(minutesOffset)[0]);
  }, [
    generateTime,
    value,
    setFieldValue,
    noInitialValue,
    minutesOffset,
    initialEndValue,
    name,
  ]);

  const activePlaceholder = useMemo(() => {
    if (isDropdownOpen && value !== null) {
      return value.label;
    }
    return placeholder;
  }, [value, placeholder, isDropdownOpen]);

  const activeValue = useMemo(() => {
    if (value) {
      return value.label;
    }
    return '';
  }, [value]);

  const onSelectTime = useCallback(
    (option: Option) => {
      if (value && option.key === value.key) {
        return setFieldValue(name, null);
      }

      return setFieldValue(name, option);
    },
    [value, setFieldValue, name]
  );

  const open = useCallback(() => {
    setIsDropdownOpen(true);

    if (!shouldSetTouched) {
      setShouldSetTouched(true);
    }
  }, [shouldSetTouched]);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
    setShouldSetTouched(false);
  }, []);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (
        !wrapperRef?.current?.contains(event.target as Node) &&
        shouldSetTouched
      ) {
        close();
        setFieldTouched(name, true);
      }
    };

    document.addEventListener('click', listener, { capture: true });
    document.addEventListener('focusin', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
      document.removeEventListener('focusin', listener, {
        capture: true,
      });
    };
  }, [close, setFieldTouched, shouldSetTouched, value, name]);

  const focusInput = useCallback(() => {
    inputRef?.current?.focus();
  }, []);

  const options = useMemo(
    () => generateTime(minutesOffset),
    [generateTime, minutesOffset]
  );

  return (
    <React.Fragment>
      <StyledWrapper tariffView={tariffView} ref={wrapperRef}>
        <Label
          stacked
          onClick={focusInput}
          smallLabel={tariffView}
          center={center}
        >
          {label}
        </Label>
        <StyledDateTimeContainer>
          <StyledColumn tariffView={tariffView}>
            <StyledTimeContainer
              onFocus={disabled ? undefined : open}
              disabled={disabled}
            >
              <StyledInput
                ref={inputRef}
                type='text'
                readOnly
                value={cutOffAt(activeValue, 48)}
                placeholder={cutOffAt(activePlaceholder, 48)}
                disabled={disabled}
              />
              <TimeIcon icon={SearchSelectIcon.TIME} onClick={focusInput} />
            </StyledTimeContainer>
            {shouldShowError && <Error>*{error}</Error>}
            {isDropdownOpen && (
              <Dropdown close={close}>
                <Options
                  value={value}
                  onSelect={onSelectTime}
                  options={options}
                />
              </Dropdown>
            )}
          </StyledColumn>
        </StyledDateTimeContainer>
      </StyledWrapper>
    </React.Fragment>
  );
}
