import type Meta from '../../models/Shared/Meta';
import type MetaResponse from '../../models/Shared/MetaResponse';

export default function toMeta(data?: MetaResponse): Meta | undefined {
  if (!data) {
    return undefined;
  }

  const { per_page, current_page, total, last_page } = data;

  return {
    perPage: per_page,
    currentPage: current_page,
    total,
    lastPage: last_page,
  };
}
