import type WeblinkMetaDataQuery from 'src/models/KeySharing/Weblink/WeblinkMetaDataQuery';
import type WeblinkMetaDataValues from 'src/models/KeySharing/Weblink/WeblinkMetaDataValues';

export default function toWeblinkMetaDataQuery(
  data: WeblinkMetaDataValues
): WeblinkMetaDataQuery {
  const { token } = data;

  return {
    token,
  };
}
