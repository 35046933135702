import styled from 'styled-components';
import Color from '../../constants/Shared/Color';

const Subtitle = styled.h3`
  font-family: Montserrat;
  font-style: normal;
  font-size: 14px;
  color: ${Color.TEXT_DARKER};
  margin: 30px 0px 5px 0px;
`;

export default Subtitle;
