import React, { useCallback } from 'react';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import useAccountUserService from 'src/services/Settings/useAccountUserService';
import Button from '../Shared/Button';
import Control from '../Shared/Control';
import Line from '../Shared/Line';
import { useGlobalFailureModal } from '../Shared/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../Shared/Modals/GlobalSuccessModal';
import Text from '../Shared/Text';

interface ConfirmEmailModalProps {
  email: string;
  closeParentModal: () => void;
}

export default function ConfirmEmailModal(props: ConfirmEmailModalProps) {
  const { email, closeParentModal } = props;
  const { emailActivation } = useAccountUserService();
  const isMounted = useIsMounted();

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message:
      'Email with instructions on how to confirm your email has been sent. Please check your email.',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onEmailActivation = useCallback(async () => {
    try {
      await emailActivation(email);

      if (isMounted()) {
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    emailActivation,
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    email,
  ]);

  return (
    <>
      <Text>
        Your email is not confirmed. Do you want to resend confirmation email
        to: <b>{email}</b>?
      </Text>
      <Line />
      <Control>
        <Button
          onClick={closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
        >
          Cancel
        </Button>
        <Button
          size={ButtonSize.LARGE}
          type='button'
          primary
          onClick={onEmailActivation}
        >
          Send
        </Button>
      </Control>
    </>
  );
}
