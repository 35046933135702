import React from 'react';
import styled from 'styled-components';
import Color from '../../constants/Shared/Color';

interface DetailsRemoveProps {
  onRemove: () => void;
}

export default function DetailsRemove(props: DetailsRemoveProps) {
  const { onRemove } = props;

  return (
    <StyledWrapper onClick={onRemove}>
      <StyledIcon className='pa-delete' />
      <StyledText>Remove product</StyledText>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px 40px 0px;
  cursor: pointer;
`;

const StyledIcon = styled.i`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 0px 10px 0px 0px;
  color: ${Color.ERROR};
`;

const StyledText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 0px;
  color: ${Color.ERROR};
`;
