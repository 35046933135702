import { Wrapper } from '@googlemaps/react-wrapper';
import React, { useCallback, useEffect } from 'react';
import DefaultCordinates from 'src/constants/Shared/DefaultCordinates';
import MapZoom from 'src/constants/Shared/MapZoom';
import styled, { css } from 'styled-components';
import AppKeys from '../../../constants/Shared/AppKeys';
import Map from './Map';
import Marker from './Marker';

interface MapProps {
  children?: React.ReactNode;
  centerLatitude?: number;
  centerLongitude?: number;
  onMapClick: (event: google.maps.MapMouseEvent) => void;
  removeTopMargin?: boolean;
  removeBottomMargin?: boolean;
}

export default function GoogleMap(props: MapProps) {
  const {
    centerLatitude,
    centerLongitude,
    removeTopMargin,
    removeBottomMargin,
    onMapClick,
  } = props;

  const [click, setClick] = React.useState<google.maps.LatLngLiteral | null>(
    null
  );
  const [zoom, setZoom] = React.useState(MapZoom.ZOOM_IN);
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const shouldRenderMarker = centerLatitude && centerLongitude;

  useEffect(() => {
    if (!shouldRenderMarker) {
      setZoom(MapZoom.ZOOM_OUT);
      return;
    }
    setZoom(MapZoom.ZOOM_IN);
  }, [shouldRenderMarker]);

  useEffect(() => {
    setCenter({
      lat: centerLatitude || DefaultCordinates.LATITUDE,
      lng: centerLongitude || DefaultCordinates.LONGITUDE,
    });
    setClick({
      lat: centerLatitude || DefaultCordinates.LATITUDE,
      lng: centerLongitude || DefaultCordinates.LONGITUDE,
    });
  }, [centerLatitude, centerLongitude]);

  const onClick = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (!e.latLng) return;
      onMapClick(e);
      setClick({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    },
    [onMapClick]
  );

  const onIdle = useCallback((m: google.maps.Map) => {
    const zoom = m.getZoom();
    if (!zoom) return;
    setZoom(zoom);
  }, []);

  return (
    <StyledMapWrapper
      removeTopMargin={removeTopMargin}
      removeBottomMargin={removeBottomMargin}
    >
      <Wrapper apiKey={AppKeys.MAP_KEY}>
        <Map center={center} onClick={onClick} onIdle={onIdle} zoom={zoom}>
          {shouldRenderMarker && <Marker position={click} />}
        </Map>
      </Wrapper>
    </StyledMapWrapper>
  );
}

const StyledMapWrapper = styled.div<{
  removeTopMargin?: boolean;
  removeBottomMargin?: boolean;
}>`
  width: 100%;
  height: 300px;
  margin: 30px 0px;
  border-radius: 5px;
  overflow: hidden;

  ${(props) => {
    const { removeTopMargin, removeBottomMargin } = props;

    if (removeTopMargin) {
      return css`
        margin-top: 0;
      `;
    }

    if (removeBottomMargin) {
      return css`
        margin-bottom: 0;
      `;
    }

    return css``;
  }}
`;
