import { useCallback } from 'react';
import ContentType from 'src/constants/Shared/ContentType';
import toZone from 'src/mappers/Parking/Zone/toZone';
import toZoneCreateRequest from 'src/mappers/Parking/Zone/toZoneCreateRequest';
import toZoneQueryParams from 'src/mappers/Parking/Zone/toZoneQueryParams';
import toZoneUpdateParams from 'src/mappers/Parking/Zone/toZoneUpdateParams';
import toMeta from 'src/mappers/Shared/toMeta';
import type Zone from 'src/models/Parking/Zone/Zone';
import type ZoneQuery from 'src/models/Parking/Zone/ZoneAllQuery';
import type ZoneCreateRequest from 'src/models/Parking/Zone/ZoneCreateRequest';
import type ZoneFormValues from 'src/models/Parking/Zone/ZoneFormValues';
import type ZoneQueryParams from 'src/models/Parking/Zone/ZoneQueryParams';
import type ZoneRemoveResponse from 'src/models/Parking/Zone/ZoneRemoveResponse';
import type ZoneResponse from 'src/models/Parking/Zone/ZoneResponse';
import type ZoneUpdateParams from 'src/models/Parking/Zone/ZoneUpdateParams';
import type ZoneUpdateResponse from 'src/models/Parking/Zone/ZoneUpdateResponse';
import type ZoneUpdateValues from 'src/models/Parking/Zone/ZoneUpdateValues';
import type GetAllDetails from 'src/models/Shared/GetAllDetails';
import useApiService from '../Shared/useApiService';

const zoneBaseURL = '/zones';

interface ZoneService {
  findOneZone: (id: number) => Promise<Zone>;
  findAllZones: (values: ZoneQuery) => Promise<GetAllDetails<Zone>>;
  findAllZonesParklioInternal: (
    values: ZoneQuery
  ) => Promise<GetAllDetails<Zone>>;
  createZone: (values: ZoneFormValues, lotId: number) => Promise<Zone>;
  updateZone: (
    id: number,
    values: ZoneUpdateValues
  ) => Promise<ZoneUpdateResponse>;
  removeZone: (id: number) => Promise<ZoneRemoveResponse>;
}

export default function useZoneService(): ZoneService {
  const { get, post, patch, remove } = useApiService();

  const { get: getParklioInternal } = useApiService({
    withAuth: true,
    contentType: ContentType.JSON,
    isParklio: true,
  });

  const findOneZone = useCallback(
    async (id: number) => {
      const response = await get<ZoneResponse>(`${zoneBaseURL}/${id}`);

      const data = toZone(response.data);

      return data;
    },
    [get]
  );

  const findAllZones = useCallback(
    async (values: ZoneQuery) => {
      const query = toZoneQueryParams(values);

      const response = await get<ZoneResponse[], ZoneQueryParams>(
        `${zoneBaseURL}`,
        query
      );

      const data = response.data.map(toZone);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const findAllZonesParklioInternal = useCallback(
    async (values: ZoneQuery) => {
      const query = toZoneQueryParams(values);

      const response = await getParklioInternal<
        ZoneResponse[],
        ZoneQueryParams
      >(`${zoneBaseURL}`, query);

      const data = response.data.map(toZone);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [getParklioInternal]
  );

  const createZone = useCallback(
    async (values: ZoneFormValues, lotId: number) => {
      const requestBody = toZoneCreateRequest(lotId, values);
      const { data } = await post<ZoneCreateRequest, ZoneResponse>(
        `${zoneBaseURL}`,
        requestBody
      );

      return toZone(data);
    },
    [post]
  );

  const updateZone = useCallback(
    async (id: number, values: ZoneUpdateValues) => {
      const params = toZoneUpdateParams(values);
      const response = await patch<ZoneUpdateParams, ZoneUpdateResponse>(
        `${zoneBaseURL}/${id}`,
        params
      );

      return response;
    },
    [patch]
  );

  const removeZone = useCallback(
    async (id: number) => {
      const response = await remove<ZoneRemoveResponse>(`${zoneBaseURL}/${id}`);

      return response;
    },
    [remove]
  );

  return {
    findAllZones,
    findAllZonesParklioInternal,
    findOneZone,
    createZone,
    updateZone,
    removeZone,
  };
}
