const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const MILISECONDS_IN_SECOND = 1000;

export default function getTimeFromMiliseconds(miliseconds: number) {
  const initialSeconds = miliseconds / MILISECONDS_IN_SECOND;
  const days = Math.floor(initialSeconds / SECONDS_IN_DAY);
  const hours = Math.floor((initialSeconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR);
  const minutes = Math.floor(
    ((initialSeconds % SECONDS_IN_DAY) % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE
  );
  const seconds = Math.round(
    ((initialSeconds % SECONDS_IN_DAY) % SECONDS_IN_HOUR) % SECONDS_IN_MINUTE
  );

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}
