import type ZoneQuery from 'src/models/Parking/Zone/ZoneAllQuery';
import type ZoneQueryParams from 'src/models/Parking/Zone/ZoneQueryParams';

export default function toZoneQueryParams(data: ZoneQuery): ZoneQueryParams {
  const { lotId } = data;

  return {
    lot_id: lotId,
  };
}
