import type CameraCredentials from 'src/models/Parking/Anpr/LprCamera/CameraCredentials';
import type CameraCredentialsResponse from 'src/models/Parking/Anpr/LprCamera/CameraCredentialsResponse';

export default function toCameraCredentials(
  data: CameraCredentialsResponse
): CameraCredentials {
  const { server_url, server_port, password } = data;

  return {
    serverUrl: server_url,
    serverPort: server_port,
    password,
  };
}
