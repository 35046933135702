import type Gateway from '../../../models/Parking/Gateway/Gateway';
import type GatewayResponse from '../../../models/Parking/Gateway/GatewayResponse';

export default function toGateway(data: GatewayResponse): Gateway {
  const { uuid, name, id, is_blocked, status, firmware_version, ip_address } =
    data;

  return {
    id,
    uuid,
    name,
    isBlocked: is_blocked,
    status,
    firmwareVersion: firmware_version || '',
    ipAddress: ip_address || '',
  };
}
