import type DetectGatewayUpdateParams from 'src/models/Parking/DetectGateway/DetectGatewayUpdateParams';
import type DetectGatewayUpdateValues from 'src/models/Parking/DetectGateway/DetectGatewayUpdateValues';

export default function toDetectGatewayUpdateParams(
  values: DetectGatewayUpdateValues
): DetectGatewayUpdateParams {
  const { name, externalId, uuid } = values;

  return {
    name,
    uuid,
    external_id: externalId,
  };
}
