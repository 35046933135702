import type AccountInvoiceFormValues from 'src/models/Settings/AccountInvoiceFormValues';
import type AccountInfo from '../../models/Settings/AccountInfo';

export default function toAccountInvoiceByIdFormValues(
  data: AccountInfo
): AccountInvoiceFormValues | undefined {
  const { invoiceCountry, invoiceCurrency } = data;

  return {
    invoiceCountry: invoiceCountry
      ? { key: invoiceCountry.id, label: invoiceCountry.name }
      : null,
    invoiceCurrency: invoiceCurrency
      ? { key: invoiceCurrency.id, label: invoiceCurrency.name }
      : null,
  };
}
