import { useCallback } from 'react';
import toLicensePlateAllow from 'src/mappers/Parking/Anpr/toLicensePlateAllow';
import toLicensePlateAllowRequest from 'src/mappers/Parking/Anpr/toLicensePlateAllowRequest';
import toLicensePlateRemoveRequest from 'src/mappers/Parking/Anpr/toLicensePlateRemoveRequest';
import toLotWhitelist from 'src/mappers/Parking/Anpr/toLotWhitelist';
import toLotWhitelistQuery from 'src/mappers/Parking/Anpr/toLotWhitelistQuery';
import toMeta from 'src/mappers/Shared/toMeta';
import type LicensePlateAllow from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateAllow';
import type LicensePlateAllowRequest from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateAllowRequest';
import type LicensePlateAllowResponse from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateAllowResponse';
import type LicensePlateByIdRemoveResponse from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateByIdRemoveResponse';
import type LicensePlateRemoveRequest from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateRemoveRequest';
import type LicensePlateRemoveResponse from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateRemoveResponse';
import type LicensePlateRemoveValues from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateRemoveValues';
import type LotWhitelist from 'src/models/Parking/Anpr/LotWhitelist/LotWhitelist';
import type LotWhitelistQuery from 'src/models/Parking/Anpr/LotWhitelist/LotWhitelistQuery';
import type LotWhitelistQueryParams from 'src/models/Parking/Anpr/LotWhitelist/LotWhitelistQueryParams';
import type LotWhitelistResponse from 'src/models/Parking/Anpr/LotWhitelist/LotWhitelistResponse';
import type GetAllDetails from 'src/models/Shared/GetAllDetails';
import type AddLicensePlateFormValues from '../../models/Parking/Anpr/LotWhitelist/LicensePlateAllowFormValues';
import type LicensePlateAllowFormValues from '../../models/Parking/Anpr/LotWhitelist/LicensePlateAllowFormValues';
import useApiService from '../Shared/useApiService';

const lotWhitelistsURL = '/lot-whitelists';

interface LotWhitelistsService {
  allowLicensePlate: (
    lotId: number,
    values: AddLicensePlateFormValues
  ) => Promise<LicensePlateAllow[]>;
  listLotWhitelist: (
    values: LotWhitelistQueryParams
  ) => Promise<GetAllDetails<LotWhitelist>>;
  removeLicensePlates: (
    values: LicensePlateRemoveValues
  ) => Promise<LicensePlateRemoveResponse[]>;
  removeLicensePlateById: (
    id: number
  ) => Promise<LicensePlateByIdRemoveResponse>;
}

export default function useLotWhitelistsService(): LotWhitelistsService {
  const { post, get, remove } = useApiService();

  const allowLicensePlate = useCallback(
    async (lotId: number, values: LicensePlateAllowFormValues) => {
      const requestBody = toLicensePlateAllowRequest(lotId, values);

      const response = await post<
        LicensePlateAllowRequest,
        LicensePlateAllowResponse[]
      >(`${lotWhitelistsURL}`, requestBody);

      return response.data.map(toLicensePlateAllow);
    },
    [post]
  );

  const listLotWhitelist = useCallback(
    async (values: LotWhitelistQueryParams) => {
      const requestBody = toLotWhitelistQuery(values);

      const response = await get<LotWhitelistResponse[], LotWhitelistQuery>(
        `${lotWhitelistsURL}`,
        requestBody
      );

      const data = response.data.map(toLotWhitelist);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const removeLicensePlates = useCallback(
    async (ids: LicensePlateRemoveValues) => {
      const request = toLicensePlateRemoveRequest(ids);
      const response = await remove<
        LicensePlateRemoveResponse[],
        LicensePlateRemoveRequest
      >(`${lotWhitelistsURL}/bulk`, request);

      return response.data;
    },
    [remove]
  );

  const removeLicensePlateById = useCallback(
    async (id: number) => {
      const response = await remove<LicensePlateByIdRemoveResponse>(
        `${lotWhitelistsURL}/${id}`
      );

      return response.data;
    },
    [remove]
  );

  return {
    allowLicensePlate,
    listLotWhitelist,
    removeLicensePlates,
    removeLicensePlateById,
  };
}
