import type InvitationKeyUpdateByIdParams from 'src/models/KeySharing/InvitationKey/InvitationKeyUpdateByIdParams';
import type InvitationKeyUpdateByIdValues from 'src/models/KeySharing/InvitationKey/InvitationKeyUpdateByIdValues';

export default function toInvitationKeyUpdateByIdParams(
  data: InvitationKeyUpdateByIdValues
): InvitationKeyUpdateByIdParams {
  const { status } = data;

  return { status };
}
