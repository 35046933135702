import type RotaryParkingPlaceUpdateParams from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceUpdateParams';
import type RotaryParkingPlaceUpdateValues from 'src/models/Rotaries/RotaryParkingPlace/RotaryParkingPlaceUpdateValues';

export default function toRotaryParkingPlaceUpdateParams(
  data: RotaryParkingPlaceUpdateValues,
  rotaryId?: number
): RotaryParkingPlaceUpdateParams {
  const { name, isOccupied } = data;

  return {
    name,
    is_occupied: isOccupied,
    rotary_id: rotaryId,
  };
}
