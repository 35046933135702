import { useField } from 'formik';
import React, { useCallback, useRef } from 'react';
import ActionIcon from 'src/constants/Shared/ActionIcon';
import type Counter from 'src/models/Shared/Counter';
import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';
import Label from './Label';

interface CounterFieldProps {
  name: string;
  label: string;
  invisible?: boolean;
  initialValue?: Counter | undefined;
  children?: React.ReactNode;
}

export default function CounterField(props: CounterFieldProps) {
  const { label, name, invisible, children, initialValue } = props;

  const [field, , helpers] = useField({ name });
  const ref = useRef<React.ElementRef<'input'>>(null);
  const { onBlur, value } = field;
  const { setValue } = helpers;

  const focusInput = useCallback(() => {
    ref?.current?.focus();
  }, []);

  const addValue = useCallback(() => {
    if (invisible) return;
    setValue({ ...value, counter: value.counter + 1 });
  }, [value, setValue, invisible]);

  const substractValue = useCallback(() => {
    if (
      (initialValue && value.counter <= initialValue.counter) ||
      value.counter < 1 ||
      invisible
    )
      return;
    setValue({ ...value, counter: value.counter - 1 });
  }, [value, setValue, initialValue, invisible]);

  return (
    <StyledWrapper>
      <StyledText>
        <Label description onClick={focusInput}>
          {label}
        </Label>
        {children && <StyledDescription>{children}</StyledDescription>}
      </StyledText>
      <StyledAction>
        <ActionIcons
          className={ActionIcon.REMOVE}
          onClick={substractValue}
          disabled={
            initialValue
              ? value.counter <= initialValue?.counter
              : value.counter < 1
          }
          invisible={invisible}
          data-testid='minusIcon'
        />
        <StyledInput
          name={name}
          type='number'
          min='0'
          value={value.counter}
          ref={ref}
          onBlur={onBlur}
          readOnly
          disabled={invisible}
        />
        <ActionIcons
          className={ActionIcon.ADD}
          onClick={addValue}
          invisible={invisible}
          data-testid='addIcon'
        />
      </StyledAction>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 10px 0;
  align-items: center;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledInput = styled.input`
  padding: 5px;
  text-align: center;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  background-color: ${Color.BACKGROUND_LIGHTEST};
  font-size: 14px;

  :focus {
    outline: none;
  }

  :disabled {
    cursor: default;
    background-color: ${Color.BACKGROUND_LIGTH};
    border: 1px solid ${Color.BORDER_LIGHT};
    color: ${Color.TEXT_DARKER};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: auto;
  }
  [type='number'] {
    -moz-appearance: textfield;
  }
`;

const ActionIcons = styled.i<{ disabled?: boolean; invisible?: boolean }>`
  color: ${Color.PRIMARY_BRAND};
  font-size: 24px;
  cursor: pointer;

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        color: ${Color.TEXT_LIGHT};
        cursor: default;

        :hover {
          color: ${Color.TEXT_LIGHT};
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { invisible } = props;

    if (invisible) {
      return css`
        visibility: hidden;
      `;
    }
    return css``;
  }}
`;

const StyledAction = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledText = styled.div`
  flex-direction: row;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${Color.TEXT_DARKER};
  margin-right: 10px;

  @media (max-width: 500px) {
    margin-bottom: 5px;
  }
`;

const StyledDescription = styled.label`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: ${Color.TEXT_DARKER};
`;
