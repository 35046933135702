import styled, { css } from 'styled-components';
import Color from '../../constants/Shared/Color';

interface LabelProps {
  stacked?: boolean;
  hideLabel?: boolean;
  reverse?: boolean;
  small?: boolean;
  wider?: boolean;
  description?: boolean;
  zoneView?: boolean;
  mediumLabel?: boolean;
  tariffView?: boolean;
  smallLabel?: boolean;
  center?: boolean;
  tariffModal?: boolean;
  primary?: boolean;
  vehicleCount?: boolean;
  removeCursor?: boolean;
}

const Label = styled.label<LabelProps>`
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  flex-direction: row;
  align-items: center;
  color: ${Color.TEXT_DARKER};

  ${(props) => {
    const { removeCursor } = props;
    if (removeCursor) {
      return css`
        cursor: default;
      `;
    }
    return css`
      cursor: pointer;
    `;
  }}

  ${(props) => {
    const { stacked } = props;
    if (!stacked) {
      return css`
        width: 220px;
        height: 36px;
      `;
    }
    return css`
      width: 100%;
      height: 36px;
      display: flex;
    `;
  }}

  ${(props) => {
    const { vehicleCount } = props;
    if (vehicleCount) {
      return css`
        width: 100%;
        padding: 0 5px 0 5px;
        height: 36px;
        min-width: 120px;
        font-size: 16px;
      `;
    }
    return css``;
  }}
  
    ${(props) => {
    const { hideLabel } = props;

    if (hideLabel) {
      return css`
        display: none;
      `;
    }

    return css`
      display: flex;
    `;
  }}
    
    ${(props) => {
    const { reverse, small, mediumLabel, tariffView, smallLabel } = props;

    if (reverse) {
      return css`
        font-weight: none;
      `;
    }

    if (small) {
      return css`
        width: 20px;
        font-weight: none;
        margin-bottom: 0;
      `;
    }

    if (smallLabel) {
      return css`
        width: 80px;
        font-weight: bold;
        margin-bottom: 0;
      `;
    }

    if (mediumLabel) {
      return css`
        width: 150px;
        font-weight: bold;
        margin-bottom: 0;
      `;
    }

    if (tariffView) {
      return css`
        width: auto;
        font-weight: normal;
        margin-bottom: 0;
        margin-right: 10px;
        justify-content: center;
      `;
    }

    return css`
      font-weight: bold;
      justify-content: flex-start;
    `;
  }}
    
    ${(props) => {
    const { wider } = props;

    if (wider) {
      return css`
        margin-right: auto;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { center } = props;

    if (center) {
      return css`
        justify-content: center;
        @media (max-width: 600px) {
          justify-content: flex-start;
        }
      `;
    }
    return css``;
  }}
    
    ${(props) => {
    const { description } = props;

    if (description) {
      return css`
        margin-bottom: 0;
        height: auto;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { zoneView } = props;
    if (zoneView) {
      return css`
          width: 100px;
          height: 36px;
          color: ${Color.BACKGROUND_LIGHTER};
          display: flex;
          align-items: center;
          margin-bottom: 0;
      }
    `;
    }
    return css``;
  }}

  ${(props) => {
    const { primary } = props;
    if (primary) {
      return css`
        color: ${Color.PRIMARY_BRAND};
        font-weight: 600;
      `;
    }
    return css``;
  }}

    ${(props) => {
    const { tariffModal } = props;

    if (tariffModal) {
      return css`
        margin-bottom: 30px;
        font-size: 16px;
        width: auto;
      `;
    }
    return css``;
  }}
`;

export default Label;
