import React, { useCallback, useMemo, useRef, useState } from 'react';
import SignalIcon from 'src/constants/Parking/SignalIcon';
import SignalStrength from 'src/constants/Shared/SignalStrength';
import styled from 'styled-components';
import Color from '../../constants/Shared/Color';
import Dropdown from '../Shared/Dropdown';

interface SignalStatusProps {
  signalStatus: number | null;
  isAnyGatewayOnline?: boolean;
}

export default function SignalStatus(props: SignalStatusProps) {
  const { signalStatus, isAnyGatewayOnline } = props;
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const icon = useMemo(() => {
    if (signalStatus === null || !isAnyGatewayOnline) {
      return SignalIcon.NO;
    }

    if (signalStatus >= SignalStrength.HIGH) {
      return SignalIcon.HIGH;
    }

    if (signalStatus >= SignalStrength.MEDIUM) {
      return SignalIcon.MEDIUM;
    }

    if (signalStatus >= SignalStrength.LOW) {
      return SignalIcon.LOW;
    }

    return SignalIcon.LOWEST;
  }, [signalStatus, isAnyGatewayOnline]);

  const getSignal = useMemo(() => {
    if (signalStatus === null) return 'No signal';
    return `${signalStatus} dBm`;
  }, [signalStatus]);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const open = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  return (
    <StyledWrapper onMouseEnter={open} onMouseLeave={close} ref={wrapperRef}>
      <StyledImg src={icon} />
      {isDropdownOpen && (
        <Dropdown close={close} infoMessages>
          <StyledDescription>
            {isAnyGatewayOnline
              ? `Signal strength: ${getSignal}`
              : 'Gateway offline'}
          </StyledDescription>
        </Dropdown>
      )}
    </StyledWrapper>
  );
}

const StyledImg = styled.img`
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 27px;
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
`;

const StyledDescription = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${Color.TEXT_DARKER};
  margin: 10px;
`;
