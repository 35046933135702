import type KeyProduct from '../../models/KeySharing/KeyProduct';
import type KeyProductResponse from '../../models/KeySharing/KeyProductResponse';

export default function toKeyProduct(data: KeyProductResponse): KeyProduct {
  const { device_id, product_type_id, name } = data;

  return {
    deviceId: device_id,
    productTypeId: product_type_id,
    name,
  };
}
