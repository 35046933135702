import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';

export default function InProgress() {
  return (
    <StyledSpinner>
      <Spinner primary small />
      <StyledSpan>Processing...</StyledSpan>
    </StyledSpinner>
  );
}

const StyledSpinner = styled.div`
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  border-radius: 10px;
`;

const StyledSpan = styled.span`
  margin-left: 10px;
`;
