import type RemoteControlStatisticsProduct from 'src/models/Statistics/RemoteControl/RemoteControlStatisticsProduct';
import type RemoteControlStatisticsProductResponse from 'src/models/Statistics/RemoteControl/RemoteControlStatisticsProductResponse';

export default function toRemoteControlStatisticsProduct(
  data: RemoteControlStatisticsProductResponse
): RemoteControlStatisticsProduct {
  const { id, device_id, name } = data;

  return {
    id,
    deviceId: device_id,
    name,
  };
}
