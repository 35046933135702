import React from 'react';
import styled, { css } from 'styled-components';
import ButtonSize from '../../constants/Shared/ButtonSize';
import Color from '../../constants/Shared/Color';
import Spinner from './Spinner';

interface ButtonProps {
  size: ButtonSize;
  primary?: boolean;
  anpr?: boolean;
  style?: React.CSSProperties;
  isLoading?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  addVerticalMargin?: boolean;
  addTopMargin?: boolean;
  addLeftMargin?: number;
  addBottomMargin?: boolean;
  addZoneSize?: boolean;
  licenseView?: boolean;
  tariffView?: boolean;
  small?: boolean;
  centerAlign?: boolean;
  customHeight?: number;
  hideButton?: boolean;
  autofocus?: boolean;
  testId?: string;
}

export default function Button(props: ButtonProps) {
  const {
    size,
    primary,
    anpr,
    isLoading,
    style,
    onClick,
    type,
    children,
    disabled,
    className,
    addVerticalMargin,
    addTopMargin,
    addBottomMargin,
    addZoneSize,
    licenseView,
    tariffView,
    small,
    centerAlign,
    customHeight,
    hideButton,
    addLeftMargin,
    autofocus,
    testId,
  } = props;

  return (
    <StyledButton
      autoFocus={autofocus}
      disabled={disabled}
      size={size}
      primary={primary}
      anpr={anpr}
      onClick={onClick}
      type={type}
      className={className}
      addVerticalMargin={addVerticalMargin}
      addTopMargin={addTopMargin}
      addBottomMargin={addBottomMargin}
      style={style}
      addZoneSize={addZoneSize}
      licenseView={licenseView}
      tariffView={tariffView}
      small={small}
      centerAlign={centerAlign}
      customHeight={customHeight}
      hideButton={hideButton}
      addLeftMargin={addLeftMargin}
      data-testid={testId}
    >
      {isLoading && (
        <StyledLoading>
          <Spinner primary={!primary} small />
        </StyledLoading>
      )}
      {isLoading ? 'Processing...' : children}
    </StyledButton>
  );
}

const StyledButton = styled.button<{
  size: ButtonSize;
  primary?: boolean;
  anpr?: boolean;
  addVerticalMargin?: boolean;
  addTopMargin?: boolean;
  addBottomMargin?: boolean;
  addZoneSize?: boolean;
  licenseView?: boolean;
  tariffView?: boolean;
  small?: boolean;
  centerAlign?: boolean;
  customHeight?: number;
  hideButton?: boolean;
  addLeftMargin?: number;
}>`
  position: relative;
  font-family: Montserrat;
  font-style: normal;
  border-radius: 7px;
  padding: 10px 20px;
  width: 232px;
  cursor: pointer;

  :hover {
    background: ${Color.BACKGROUND_HOVER};
    text-decoration: none;
    transition: all 0.4s ease 0s;
    box-shadow: 0 10px 10px -10px ${Color.SHADOW_DARKEST_TRANSPARENT};
  }

  ${(props) => {
    const { disabled, primary, anpr } = props;

    if (disabled) {
      return css`
        background-color: ${Color.BACKGROUND_LIGTH};
        color: ${Color.TEXT_LIGHT};
        border: 1px solid ${Color.BACKGROUND_LIGTH};
        cursor: not-allowed;

        :hover {
          box-shadow: none;
          background-color: ${Color.BACKGROUND_LIGTH};
          color: ${Color.TEXT_LIGHT};
        }
      `;
    }
    if (primary) {
      return css`
        background-color: ${Color.PRIMARY_BRAND};
        color: ${Color.TEXT_LIGHTER};
        border: 1px solid ${Color.PRIMARY_BRAND};
        padding: 10px 25px;

        :hover {
          color: ${Color.BACKGROUND_LIGHTEST};
          background: ${Color.PRIMARY_HOVER};
          text-decoration: none;
          transition: all 0.4s ease 0s;
          box-shadow: 0 10px 10px -10px ${Color.SHADOW_DARKEST_TRANSPARENT};
        }

        :active {
          background: ${Color.PRIMARY_BRAND};
        }
      `;
    }

    if (anpr) {
      return css`
        background-color: transparent;
        color: ${Color.TEXT_DARKER};
        border: 1px solid ${Color.TEXT_DARKER};
        padding: 5px 15px;
        letter-spacing: 0.3rem;
        margin: 0 auto;

        :hover {
          text-decoration: none;
          transition: all 0.4s ease 0s;
          box-shadow: 0 10px 10px -10px ${Color.SHADOW_DARKEST_TRANSPARENT};
        }
      `;
    }

    return css`
      background-color: transparent;
      color: ${Color.TEXT_BRAND};
      border: 1px solid ${Color.BORDER_LIGHT};

      :hover {
        color: ${Color.PRIMARY_HOVER};
      }
    `;
  }}

  ${(props) => {
    const { size = ButtonSize.LARGE } = props;

    switch (size) {
      case ButtonSize.LARGE:
        return css`
          @media (max-width: 999px) {
            width: 100%;
          }
          margin: 40px 0px 0px 10px;
          font-size: 20px;
          line-height: 24px;
          font-weight: bold;

          :last-child {
            margin-right: 0px;
          }
        `;
      case ButtonSize.MIDDLE:
        return css`
          width: auto;
          font-size: 16px;
          line-height: 17px;
          font-weight: normal;
        `;
      case ButtonSize.SMALL:
        return css`
          width: 100%;
          font-size: 13px;
          line-height: 15px;
          font-weight: normal;
          padding: 10px 5px;
        `;
      case ButtonSize.MEDIUM:
        return css`
          width: 100%;
          font-size: 16px;
          line-height: 15px;
          font-weight: normal;
          padding: 10px 5px;
          @media (max-width: 500px) {
            width: 75%;
          }
        `;
      case ButtonSize.FULL:
        return css`
          width: 100%;
          font-size: 20px;
          line-height: 24px;
          font-weight: bold;
          margin: 20px 0px 20px 0px;

          :last-child {
            margin-bottom: 0px;
          }
        `;
      case ButtonSize.ANPR:
        return css`
          width: 200px;
          font-size: 18px;
          line-height: 22px;
          font-weight: 500;
          text-align: center;

          :last-child {
            margin-bottom: 0px;
          }
        `;
      case ButtonSize.DETECT:
        return css`
          width: auto;
          font-size: 16px;
          line-height: 13px;
          font-weight: normal;
          text-align: center;

          :last-child {
            margin-bottom: 0px;
          }
        `;
      default:
        return css``;
    }
  }}
    
    ${(props) => {
    const { addTopMargin } = props;

    if (addTopMargin) {
      return css`
        margin-top: 20px;
        font-size: 14px;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { licenseView } = props;

    if (licenseView) {
      return css`
        margin-left: 0;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { small } = props;

    if (small) {
      return css`
        height: auto;
        margin-left: 10px;
        width: auto;
        align-self: flex-end;
        min-width: 100px;

        @media (max-width: 600px) {
          align-self: center;
          margin-top: 30px;
        }

        @media (max-width: 500px) {
          align-self: flex-start;
          margin-left: 0px;
        }
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { tariffView } = props;

    if (tariffView) {
      return css`
        @media (max-width: 999px) {
          width: 232px;
        }
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { addBottomMargin } = props;

    if (addBottomMargin) {
      return css`
        margin-top: 0px;
        margin-bottom: 40px;
      `;
    }
    return css``;
  }}
  
  ${(props) => {
    const { addZoneSize } = props;

    if (addZoneSize) {
      return css`
        width: auto !important;
      `;
    }
    return css``;
  }}

    :focus {
    outline: none;
  }

  @media (max-width: 520px) {
    margin-bottom: 15px;

    :last-child {
      margin-bottom: 0px;
    }
  }

  ${(props) => {
    const { addVerticalMargin } = props;

    if (addVerticalMargin) {
      return css`
        margin-bottom: 15px;
        margin-top: 15px;

        :last-child {
          margin-left: 15px;
        }

        @media (max-width: 500px) {
          margin-bottom: 0px;

          :last-child {
            margin-bottom: 15px;
            margin-left: 0px;
          }
        }
      `;
    }
    return css``;
  }}
  ${(props) => {
    const { customHeight } = props;

    if (customHeight) {
      return css`
        height: ${customHeight}px;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { addLeftMargin } = props;

    if (addLeftMargin) {
      return css`
        margin-left: ${addLeftMargin}px;
      `;
    }
    return css``;
  }}


  ${(props) => {
    const { hideButton } = props;

    if (hideButton) {
      return css`
        display: none;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { centerAlign } = props;

    if (centerAlign) {
      return css`
        display: flex;
        align-items: center;
      `;
    }
    return css``;
  }}
`;

const StyledLoading = styled.div`
  margin: 0px;
  padding: 0px;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
