import type { FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';
import useIsMounted from 'src/hooks/Shared/useIsMounted';
import type LicensePlateAllowFormValues from 'src/models/Parking/Anpr/LotWhitelist/LicensePlateAllowFormValues';
import useLotWhitelistsService from 'src/services/Lpr/useLotWhitelistsService';
import useProductService from 'src/services/Parking/useProductService';
import { isNotString } from 'src/utils/checks';
import * as yup from 'yup';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Form from '../Shared/Form';
import { useGlobalFailureModal } from '../Shared/Modals/GlobalFailureModal';
import GlobalModal from '../Shared/Modals/GlobalModal';
import { useGlobalSuccessModal } from '../Shared/Modals/GlobalSuccessModal';
import AllowLicensePlateForm from './AllowLicensePlateForm';

const allowLicensePlateInitialValues: LicensePlateAllowFormValues = {
  licensePlates: '',
  email: '',
  name: '',
  barriers: [],
  startTime: null,
  endTime: null,
  isUnlimited: false,
};

const allowLicensePlateValidationSchema = yup.object().shape({
  licensePlates: yup.string().required('License Plate is a required field'),
  name: yup.string().notRequired(),
  email: yup.string().email().notRequired(),
  startTime: yup.date().nullable().required('Start Time is a required field'),
  isUnlimited: yup.boolean().notRequired(),
  endTime: yup
    .date()
    .nullable()
    .when('isUnlimited', {
      is: false,
      then: yup
        .date()
        .required('End Time is a required field')
        .min(yup.ref('startTime'), "End Time can't be before Start Time"),
    }),
});

interface AllowLicensePlateProps {
  lotId: number | undefined;
}

function AllowLicensePlate(props: AllowLicensePlateProps) {
  const { lotId } = props;
  const { allowLicensePlate } = useLotWhitelistsService();
  const { bindLicenses } = useProductService();
  const isMounted = useIsMounted();

  const [openAllowNewLicensePlateModal, closeAllowNewLicensePlateModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen>
        <Form
          name='AllowLicensePlate'
          initialValues={allowLicensePlateInitialValues}
          validationSchema={allowLicensePlateValidationSchema}
          onSubmit={onAllowLicensePlateSubmit}
        >
          <AllowLicensePlateForm
            closeParentModal={closeAllowNewLicensePlateModal}
            lotId={lotId}
          />
        </Form>
      </GlobalModal>
    ));

  const { openGlobalSuccessModal: openAllowLicensePlateSuccessModal } =
    useGlobalSuccessModal({
      closeParentModal: closeAllowNewLicensePlateModal,
      message: 'License plate was successfully added!',
    });

  const {
    openGlobalFailureModal: openAllowLicensePlateFailureModal,
    setMessage: setAllowLicencePlateMessage,
  } = useGlobalFailureModal({});

  const onAllowLicensePlateSubmit = useCallback(
    async (
      values: LicensePlateAllowFormValues,
      { setErrors, resetForm }: FormikHelpers<LicensePlateAllowFormValues>
    ) => {
      try {
        if (!lotId) {
          return;
        }
        const response = await allowLicensePlate(lotId, values);
        const products = values.barriers.map((barrier) => barrier.key);

        if (products.length > 0) {
          const allowedLicenses = response.map((license) => license.licenseId);

          await bindLicenses({
            productIds: products,
            licenseIds: allowedLicenses,
          });
        }

        if (isMounted()) {
          resetForm({});
          openAllowLicensePlateSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setAllowLicencePlateMessage(error);
          openAllowLicensePlateFailureModal();
        }
      }
    },
    [
      allowLicensePlate,
      isMounted,
      lotId,
      openAllowLicensePlateSuccessModal,
      openAllowLicensePlateFailureModal,
      setAllowLicencePlateMessage,
      bindLicenses,
    ]
  );

  return (
    <Card>
      <Button
        size={ButtonSize.MIDDLE}
        type='button'
        onClick={openAllowNewLicensePlateModal}
      >
        Allow lot access to plates
      </Button>
    </Card>
  );
}

export default AllowLicensePlate;
