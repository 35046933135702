import styled from 'styled-components';

const Sort = styled.i`
  font-size: 14px;
  width: 20px;
  height: 17px;
  line-height: 17px;
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
`;

export default Sort;
