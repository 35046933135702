import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PaginationSize from 'src/constants/Shared/DataSize';
import styled from 'styled-components';
import AllProductStatuses from '../../constants/Parking/AllProductStatuses';
import ProductStatus from '../../constants/Parking/ProductStatus';
import ActionIcon from '../../constants/Shared/ActionIcon';
import Color from '../../constants/Shared/Color';
import PaginationIcon from '../../constants/Shared/PaginationIcon';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import toProductTypeName from '../../mappers/Shared/toProductTypeName';
import type Error from '../../models/Parking/Error';
import useProductService from '../../services/Parking/useProductService';
import Card from '../Shared/Card';
import StyledNoData from '../Shared/StyledNoData';
import Table from '../Shared/Table';
import ProductErrorStatuses from './ProductErrorStatuses';
import ProductTableIcon from './ProductTableIcon';

export default function ProductErrorTable() {
  const [errors, setErrors] = useState<Error[]>([]);
  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const { findAllProductErrors } = useProductService();
  const { push } = useHistory();
  const linkToLot = useCallback(
    (id: number | null) => {
      if (!id) {
        return;
      }

      push(`/parking/lots/${id}/products`);
    },
    [push]
  );

  const isMounted = useIsMounted();

  const loadProductErrors = useCallback(async () => {
    try {
      if (isMounted()) {
        setAreDetailsLoading(true);
      }
      const data = await findAllProductErrors({
        page: 1,
        size: PaginationSize.STANDARD,
      });
      if (isMounted()) {
        setErrors(data);
        setAreDetailsLoading(false);
      }
    } catch (error) {
      if (isMounted()) {
        setAreDetailsLoading(false);
      }
      throw error;
    }
  }, [isMounted, findAllProductErrors]);

  useEffect(() => {
    loadProductErrors();
  }, [loadProductErrors]);

  const getErrors = (errors: Error | null) => {
    const allErrors = [];

    if (!errors) return [];

    if (errors.product.status === ProductStatus.OFFLINE) {
      allErrors.push(AllProductStatuses.OFFLINE);
      return allErrors;
    }

    const {
      jammed,
      sensorDirty,
      pinBroken,
      rtccInvalid,
      batteryEmpty,
      masterKeyNotSet,
    } = errors;

    if (jammed) allErrors.push(AllProductStatuses.JAMMED);

    if (sensorDirty) allErrors.push(AllProductStatuses.SENSOR_DIRTY);

    if (pinBroken) allErrors.push(AllProductStatuses.PIN_BROKEN);

    if (batteryEmpty) allErrors.push(AllProductStatuses.BATTERY_EMPTY);

    if (rtccInvalid) allErrors.push(AllProductStatuses.RTCC_INVALID);

    if (masterKeyNotSet) allErrors.push(AllProductStatuses.MASTER_KEY_NOT_SET);

    return allErrors;
  };

  return (
    <Card>
      <StyledDiv>
        <span>Refresh error list</span>
        <StyledIcon
          className={ActionIcon.REFRESH}
          onClick={loadProductErrors}
        />
      </StyledDiv>
      <Table isLoading={areDetailsLoading}>
        <Table.Body>
          {errors.length > 0 ? (
            errors.map((error) => {
              const allErrors = getErrors(error);
              const { id, product } = error;
              const { name, lotId, productTypeId, id: productId } = product;

              return (
                <Table.Row key={`${id}-${productId}`}>
                  <Table.Cell active>
                    <ProductTableIcon iconType={productTypeId} />
                    {name}
                  </Table.Cell>
                  <Table.Cell error={allErrors.length > 0}>
                    <ProductErrorStatuses
                      errors={allErrors}
                      productType={toProductTypeName(productTypeId)}
                    />
                  </Table.Cell>
                  <Table.Cell primary action={() => linkToLot(lotId)}>
                    <Table.Icon icon={PaginationIcon.LAST} />
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell>
                <StyledNoData>No errors found</StyledNoData>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Card>
  );
}

const StyledDiv = styled.div`
  justify-content: flex-end;
  margin: -15px -15px 15px 0;
  display: flex;
  align-items: center;
  color: ${Color.TEXT_LIGHT};
  font-size: 12px;
`;

const StyledIcon = styled.i`
  font-size: 20px;
  cursor: pointer;
  margin-left: 5px;
  color: ${Color.PRIMARY_BRAND};

  :hover {
    color: ${Color.PRIMARY_HOVER};
  }
`;
