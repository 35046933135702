export default function formatOperationTokenDates(date: string) {
  return new Date(date).getTime() === new Date(2038, 0, 1).getTime()
    ? 'Unlimited'
    : new Date(date).toLocaleString('en-GB', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
}
