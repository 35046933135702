import React, { useEffect, useState, useCallback, Fragment } from 'react';
import DetectCameraCards from 'src/components/Parking/Detection/DetectCameraCards';
import DetectGatewayCards from 'src/components/Parking/Detection/DetectGatewayCards';
import ParkingPlacesTable from 'src/components/Parking/Detection/ParkingPlacesTable';
import AddNewDetectCameraModal from 'src/components/Parking/Modals/AddNewDetectCameraModal';
import AddNewDetectGatewayModal from 'src/components/Parking/Modals/AddNewDetectGatewayModal';
import AddNewParkingPlaceModal from 'src/components/Parking/Modals/AddNewParkingPlaceModal';
import DetectCameraDetailsModal from 'src/components/Parking/Modals/DetectCameraDetailsModal';
import DetectGatewayDetailsModal from 'src/components/Parking/Modals/DetectGatewayDetailsModal ';
import ParkingPlaceDetailsModal from 'src/components/Parking/Modals/ParkingPlaceDetailsModal';
import AddParkingPlaceIcon from 'src/components/Shared/AddParkingPlaceIcon';
import Card from 'src/components/Shared/Card';
import Input from 'src/components/Shared/Input';
import Line from 'src/components/Shared/Line';
import GlobalModal from 'src/components/Shared/Modals/GlobalModal';
import Pagination from 'src/components/Shared/Pagination';
import Title from 'src/components/Shared/Title';
import ActionIcon from 'src/constants/Shared/ActionIcon';
import Color from 'src/constants/Shared/Color';
import PaginationSize from 'src/constants/Shared/DataSize';
import SearchTimer from 'src/constants/Shared/SearchTimer';
import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';
import type DetectCamera from 'src/models/Parking/DetectCamera/DetectCamera';
import type DetectGateway from 'src/models/Parking/DetectGateway/DetectGateway';
import type Lot from 'src/models/Parking/Lot/Lot';
import type ParkingPlace from 'src/models/Parking/ParkingPlace/ParkingPlace';
import type Zone from 'src/models/Parking/Zone/Zone';
import type Meta from 'src/models/Shared/Meta';
import useDetectCameraService from 'src/services/Parking/useDetectCameraService';
import useDetectGatewayService from 'src/services/Parking/useDetectGatewayService';
import useParkingPlaceService from 'src/services/Parking/useParkingPlaceService';
import useZoneService from 'src/services/Parking/useZoneService';
import debounce from 'src/utils/debounce';
import styled from 'styled-components';
import PaginationItemDisplay from '../../constants/Shared/PaginationItemDisplay';
import useIsMounted from '../../hooks/Shared/useIsMounted';

interface DetectLotProps {
  lot: Lot | undefined;
}

export default function DetectLot(props: DetectLotProps) {
  const { lot } = props;
  const [parkingPlaces, setParkingPlaces] = useState<ParkingPlace[]>([]);
  const [allParkingPlaces, setAllParkingPlaces] = useState<ParkingPlace[]>([]);
  const [parkingPlacesMeta, setParkingPlacesMeta] = useState<Meta>();
  const [detectGateways, setDetectGateways] = useState<DetectGateway[]>([]);
  const [detectCameras, setDetectCameras] = useState<DetectCamera[]>([]);
  const [zones, setZones] = useState<Zone[]>([]);
  const [activeParkingPlaceId, setActiveParkingPlaceId] = useState<
    number | undefined
  >(undefined);
  const [activeGatewayId, setActiveGatewayId] = useState<number | undefined>(
    undefined
  );
  const [activeCameraId, setActiveCameraId] = useState<number | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlaces, setIsLoadingPlaces] = useState(false);
  const [uuidLotInputValue, setUuidLotInputValue] = useState('');
  const [uuidLotSearchValue, setUuidLotSearchValue] = useState('');
  const uuidLotRefSearchValue = React.useRef<HTMLInputElement>(null);

  const {
    findAllParkingPlacesParklioInternal,
    findLimitlessParkingPlacesParklioInternal,
  } = useParkingPlaceService();
  const { findAllDetectGatewaysParklioInternal } = useDetectGatewayService();
  const { findAllDetectCamerasParklioInternal } = useDetectCameraService();
  const { findAllZonesParklioInternal } = useZoneService();
  const isMounted = useIsMounted();

  const onSearchManagerEmail = useCallback(async () => {
    if (uuidLotRefSearchValue.current === null) {
      return;
    }
    if (uuidLotInputValue !== uuidLotRefSearchValue.current.value) {
      return;
    }

    setUuidLotSearchValue(uuidLotInputValue);
    setIsLoadingPlaces(false);
  }, [uuidLotInputValue]);

  useEffect(() => {
    setIsLoadingPlaces(true);
    const search = debounce(onSearchManagerEmail, SearchTimer.INPUT_SEARCH);
    search();
  }, [onSearchManagerEmail]);

  const onChangeLotUuid = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUuidLotInputValue(event.target.value);
  };

  const getParkingPlacesData = useCallback(
    async (page: number) => {
      try {
        if (!lot) {
          return;
        }

        if (isMounted()) {
          setIsLoadingPlaces(true);
        }

        const { data, meta } = await findAllParkingPlacesParklioInternal({
          lotId: lot.id,
          page: page,
          size: PaginationSize.STANDARD,
          uuid: uuidLotSearchValue,
        });

        if (isMounted()) {
          setParkingPlaces(data);
          if (meta !== undefined) {
            setParkingPlacesMeta(meta);
          }
        }
      } finally {
        isMounted() && setIsLoadingPlaces(false);
      }
    },
    [isMounted, lot, findAllParkingPlacesParklioInternal, uuidLotSearchValue]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        if (!lot) {
          return;
        }

        if (isMounted()) {
          setIsLoading(true);
        }

        const gateways = findAllDetectGatewaysParklioInternal({
          lotId: lot.id,
        });
        const cameras = findAllDetectCamerasParklioInternal({
          lotId: lot.id,
        });
        const zones = findAllZonesParklioInternal({ lotId: lot.id });

        const response = await Promise.all([gateways, cameras, zones]);

        const [gatewaysData, cameraData, zoneData] = response;

        if (isMounted()) {
          setDetectGateways(gatewaysData.data);
          setDetectCameras(cameraData.data);
          setZones(zoneData.data);
        }
      } finally {
        isMounted() && setIsLoading(false);
      }
    };
    getData();
  }, [
    isMounted,
    lot,
    findAllDetectGatewaysParklioInternal,
    findAllDetectCamerasParklioInternal,
    findAllZonesParklioInternal,
  ]);

  useEffect(() => {
    getParkingPlacesData(1);
  }, [getParkingPlacesData]);

  useEffect(() => {
    const getAllPlaces = async () => {
      if (!lot) {
        return;
      }

      const response = await findLimitlessParkingPlacesParklioInternal({
        lotId: lot.id,
      });

      if (isMounted()) {
        setAllParkingPlaces(response);
      }
    };

    getAllPlaces();
  }, [isMounted, lot, findLimitlessParkingPlacesParklioInternal]);

  const onPlaceNameChange = useCallback((name: string, id: number) => {
    if (name === '' || id === 0) return;
    setParkingPlaces((oldData) => {
      const index = oldData.findIndex((place) => place.id === id);

      if (index === -1) return oldData;

      oldData[index].name = name;
      return [...oldData];
    });

    setAllParkingPlaces((oldData) => {
      const index = oldData.findIndex((place) => place.id === id);

      if (index === -1) return oldData;

      oldData[index].name = name;
      return [...oldData];
    });
  }, []);

  const onGatewayNameChange = useCallback((name: string, id: number) => {
    if (name === '' || id === 0) return;
    setDetectGateways((oldData) => {
      const index = oldData.findIndex((gateway) => gateway.id === id);
      if (index === -1) return oldData;

      oldData[index].name = name;
      return [...oldData];
    });
  }, []);

  const onCameraNameChange = useCallback((name: string, id: number) => {
    if (name === '' || id === 0) return;
    setDetectCameras((oldData) => {
      const index = oldData.findIndex((camera) => camera.id === id);

      if (index === -1) return oldData;

      oldData[index].name = name;
      return [...oldData];
    });
  }, []);

  const onRemoveDetectGateway = useCallback((id: number) => {
    setDetectGateways((oldData) =>
      oldData.filter((gateway) => gateway.id !== id)
    );
  }, []);

  const onRemoveDetectCamera = useCallback((id: number) => {
    setDetectCameras((oldData) => oldData.filter((camera) => camera.id !== id));
  }, []);

  const onRemoveParkingPlace = useCallback((id: number) => {
    setParkingPlaces((oldData) => oldData.filter((place) => place.id !== id));
  }, []);

  const [openParkingPlaceDetailsModal, closeParkingPlaceDetailsModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen>
        <ParkingPlaceDetailsModal
          closeParentModal={closeParkingPlaceModal}
          id={activeParkingPlaceId}
          onPlaceNameChange={onPlaceNameChange}
          onRemoveParkingPlace={onRemoveParkingPlace}
          lotDetectionStatus={lot?.detectionStatus}
          zones={zones}
          userIsParklioInternal
        />
      </GlobalModal>
    ));

  const [openDetectGatewayDetailsModal, closeDetectGatewayDetailsModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen>
        <DetectGatewayDetailsModal
          closeParentModal={closeGatewayModal}
          id={activeGatewayId}
          onGatewayNameChange={onGatewayNameChange}
          onRemoveDetectGateway={onRemoveDetectGateway}
        />
      </GlobalModal>
    ));

  const [openDetectCameraDetailsModal, closeDetectCameraDetailsModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen>
        <DetectCameraDetailsModal
          closeParentModal={closeCameraModal}
          id={activeCameraId}
          onCameraNameChange={onCameraNameChange}
          gateways={detectGateways}
          lotDetectionStatus={lot?.detectionStatus}
          onRemoveDetectCamera={onRemoveDetectCamera}
          parkingPlaces={allParkingPlaces}
        />
      </GlobalModal>
    ));

  const openParkingPlaceModal = useCallback(
    (data?: ParkingPlace) => {
      setActiveParkingPlaceId(data?.id);
      openParkingPlaceDetailsModal();
    },
    [openParkingPlaceDetailsModal]
  );

  const closeParkingPlaceModal = useCallback(() => {
    setActiveParkingPlaceId(undefined);
    closeParkingPlaceDetailsModal();
  }, [closeParkingPlaceDetailsModal]);

  const openGatewayModal = useCallback(
    (id: number) => {
      setActiveGatewayId(id);
      openDetectGatewayDetailsModal();
    },
    [openDetectGatewayDetailsModal]
  );

  const closeGatewayModal = useCallback(() => {
    setActiveGatewayId(undefined);
    closeDetectGatewayDetailsModal();
  }, [closeDetectGatewayDetailsModal]);

  const openCameraModal = useCallback(
    (id: number) => {
      setActiveCameraId(id);
      openDetectCameraDetailsModal();
    },
    [openDetectCameraDetailsModal]
  );

  const closeCameraModal = useCallback(() => {
    setActiveCameraId(undefined);
    closeDetectCameraDetailsModal();
  }, [closeDetectCameraDetailsModal]);

  const onAddNewDetectGateway = useCallback((data: DetectGateway) => {
    setDetectGateways((oldData) => [...oldData, data]);
  }, []);

  const onAddNewDetectCamera = useCallback((data: DetectCamera) => {
    setDetectCameras((oldData) => [...oldData, data]);
  }, []);

  const onAddNewParkingPlace = useCallback(
    (data: ParkingPlace | ParkingPlace[]) => {
      if (Array.isArray(data)) {
        setParkingPlaces((oldData) => [...oldData, ...data]);
      } else {
        setParkingPlaces((oldData) => [...oldData, data]);
      }
    },
    []
  );

  const [openAddNewDetectGatewayModal, closeAddNewDetectGatewayModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen>
        <AddNewDetectGatewayModal
          closeParentModal={closeAddNewDetectGatewayModal}
          onAddNewDetectGateway={onAddNewDetectGateway}
          lotId={lot?.id}
        />
      </GlobalModal>
    ));

  const [openAddNewDetectCameraModal, closeAddNewDetectCameraModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen>
        <AddNewDetectCameraModal
          closeParentModal={closeAddNewDetectCameraModal}
          onAddNewDetectCamera={onAddNewDetectCamera}
          gateways={detectGateways}
        />
      </GlobalModal>
    ));

  const [openAddNewParkingPlaceModal, closeAddNewParkingPlaceModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen>
        <AddNewParkingPlaceModal
          closeParentModal={closeAddNewParkingPlaceModal}
          onAddNewParkingPlace={onAddNewParkingPlace}
          zones={zones}
        />
      </GlobalModal>
    ));

  return (
    <React.Fragment>
      <Title>Gateways</Title>
      <DetectGatewayCards
        data={detectGateways}
        isLoading={isLoading}
        openEditModal={openGatewayModal}
        openAddNewModal={openAddNewDetectGatewayModal}
      />
      <Title>Cameras</Title>
      <DetectCameraCards
        data={detectCameras}
        isLoading={isLoading}
        openEditModal={openCameraModal}
        openAddNewModal={openAddNewDetectCameraModal}
      />
      <SpaceBetweenDiv>
        <Title>Places</Title>
        <Title>
          <AddParkingPlaceIcon
            backgroundColor={Color.TEXT_LIGHTEST}
            className={ActionIcon.ADD}
            onClick={openAddNewParkingPlaceModal}
          />
        </Title>
      </SpaceBetweenDiv>
      <Line />
      <Fragment>
        <Card>
          <Input
            placeholder='Search by user id'
            type='text'
            ref={uuidLotRefSearchValue}
            value={uuidLotInputValue}
            onChange={onChangeLotUuid}
            removeMaring
          />
        </Card>

        <ParkingPlacesTable
          parkingPlaces={parkingPlaces}
          detectionStatus={lot?.detectionStatus}
          isLoading={isLoadingPlaces}
          openEditModal={openParkingPlaceModal}
          userIsParklioInternal
        />
        {parkingPlacesMeta &&
          parkingPlacesMeta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
            <Pagination
              meta={parkingPlacesMeta}
              getData={getParkingPlacesData}
            />
          )}
      </Fragment>
    </React.Fragment>
  );
}
const SpaceBetweenDiv = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;
