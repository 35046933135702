import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import Content from 'src/components/Shared/Content';
import Main from 'src/components/Shared/Main';
import TimeZone from 'src/constants/Shared/TimeZone';
import type Lot from 'src/models/Parking/Lot/Lot';
import * as yup from 'yup';
import LotForm from '../../components/Parking/LotForm';
import LotTable from '../../components/Parking/LotTable';
import Form from '../../components/Shared/Form';
import { useGlobalConfirmationModal } from '../../components/Shared/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from '../../components/Shared/Modals/GlobalFailureModal';
import GlobalModal from '../../components/Shared/Modals/GlobalModal';
import { useGlobalSuccessModal } from '../../components/Shared/Modals/GlobalSuccessModal';
import { useGlobalModal } from '../../hooks/Shared/useGlobalModal';
import useIsMounted from '../../hooks/Shared/useIsMounted';
import type LotFormValues from '../../models/Parking/Lot/LotFormValues';
import useLotService from '../../services/Parking/useLotService';
import { isNotString } from '../../utils/checks';

const timeZoneKey = new Date().getTimezoneOffset() / -60;

const initialValues: LotFormValues = {
  name: '',
  address: '',
  description: '',
  timeZone: TimeZone.find((zone) => zone.key === timeZoneKey) || null,
  latitude: '',
  longitude: '',
  isDetect: false,
  detectionImplementationStatus: null,
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is a required field')
    .min(3, 'Name should be at least 3 characters long'),
  address: yup.string().required('Address is a required field'),
  timeZone: yup.object().nullable().required('Time Zone is a required field'),
  latitude: yup.string().required('Latitude is a required field'),
  longitude: yup.string().required('Longitude is a required field'),
  isDetect: yup.boolean().notRequired(),
  detectionImplementationStatus: yup.object().nullable(),
});

export default function LotList() {
  const [data, setData] = useState<Lot[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [activeId, setActiveId] = useState<number | undefined>();
  const { findAllLots, createLot, removeLot } = useLotService();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data } = await findAllLots();

        if (isMounted()) {
          setData(data);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }

        throw error;
      }
    };

    getData();
  }, [findAllLots, isMounted]);

  const [openCreateModal, closeCreateModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <Form
        name='lot'
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <LotForm closeParentModal={closeCreateModal} />
      </Form>
    </GlobalModal>
  ));

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal: closeCreateModal,
    message: messageSuccess,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onSubmit = useCallback(
    async (
      values: LotFormValues,
      { setErrors }: FormikHelpers<LotFormValues>
    ) => {
      try {
        await createLot(values);

        const { data } = await findAllLots();

        if (isMounted()) {
          setData(data);
          setMessageSuccess('Successfuly created new lot!');
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      isMounted,
      createLot,
      findAllLots,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  const onRemove = useCallback(async () => {
    try {
      if (activeId) {
        await removeLot(activeId);

        const { data } = await findAllLots();

        if (isMounted()) {
          setData(data);
          setActiveId(undefined);
          setMessageSuccess('Lot deleted successfully!');
          openGlobalSuccessModal();
        }
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    setData,
    findAllLots,
    removeLot,
    isMounted,
    activeId,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onRemove,
    message: 'Are you sure you want to remove this lot?',
  });

  const openRemoveModal = useCallback(
    (id: number) => {
      openGlobalConfirmationModal();
      setActiveId(id);
    },
    [openGlobalConfirmationModal]
  );

  return (
    <Main small>
      <Content>
        <LotTable
          isLoading={isDataLoading}
          openCreateModal={openCreateModal}
          openRemoveModal={openRemoveModal}
          data={data}
        />
      </Content>
    </Main>
  );
}
