import type LicenseInfoRequest from 'src/models/License/LicenseInfoRequest';
import type Counter from 'src/models/Shared/Counter';

export default function toLicenseInfoRequest(
  data: Counter
): LicenseInfoRequest {
  const { pricingId, counter } = data;

  return {
    license_pricing_id: pricingId,
    purchased_count: counter,
  };
}
