import type LotChangeEventBollard from 'src/models/Parking/Lot/SocketEvents/LotChangeEvent/LotChangeEventBollard';
import type LotChangeEventBollardPayload from 'src/models/Parking/Lot/SocketEvents/LotChangeEvent/LotChangeEventBollardPayload';

export default function toLotChangeEventBollard(
  data: LotChangeEventBollardPayload
): LotChangeEventBollard {
  const { state } = data;

  return {
    state,
  };
}
