import React, { useCallback, useState } from 'react';
import AllProductStatuses from 'src/constants/Parking/AllProductStatuses';
import LprCameraStatus from 'src/constants/Parking/LprCameraStatus';
import OperationIcon from 'src/constants/Parking/OperationIcon';
import ProductOperations from 'src/constants/Parking/ProductOperations';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import { useGlobalModal } from 'src/hooks/Shared/useGlobalModal';
import type LprCamera from 'src/models/Parking/Anpr/LprCamera/LprCamera';
import useLprCamerasService from 'src/services/Lpr/useLprCamerasService';
import DeviceTypes from '../../constants/Parking/DeviceTypes';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import IconRow from '../Shared/IconRow';
import GlobalModal from '../Shared/Modals/GlobalModal';
import StyledNoData from '../Shared/StyledNoData';
import Table from '../Shared/Table';
import CameraDetailsModal from './Modals/CameraDetailsModal';

interface CameraTableProps {
  isCameraDataLoading: boolean;
  cameraData: LprCamera[];
  onCameraNameChange: (name: string, id: number) => void;
  showToast: (msg: string, device?: string) => void;
}

function CameraTable(props: CameraTableProps) {
  const { isCameraDataLoading, cameraData, onCameraNameChange, showToast } =
    props;
  const [activeCameraId, setActiveCameraId] = useState<number | undefined>(
    undefined
  );
  const { changeLprCameraState } = useLprCamerasService();

  const getCameraStatus = useCallback((status: LprCameraStatus) => {
    if (status === LprCameraStatus.OFFLINE) {
      return AllProductStatuses.OFFLINE;
    }

    return AllProductStatuses.ONLINE;
  }, []);

  const [openCameraDetailsModal, closeCameraDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <CameraDetailsModal
          closeParentModal={closeModal}
          id={activeCameraId}
          onCameraNameChange={onCameraNameChange}
          showToast={showToast}
        />
      </GlobalModal>
    )
  );

  const closeModal = useCallback(() => {
    setActiveCameraId(undefined);
    closeCameraDetailsModal();
  }, [closeCameraDetailsModal]);

  const onClick = useCallback(
    async (productId: number, operation: ProductOperations) => {
      await changeLprCameraState(productId, { operation });
    },
    [changeLprCameraState]
  );

  return (
    <Card>
      <Table isLoading={isCameraDataLoading}>
        <Table.Colgroup>
          <col span={1} style={{ width: '5%' }} />
          <col span={1} style={{ width: '35%' }} />
          <col span={1} style={{ width: '25%' }} />
          <col span={1} style={{ width: '35%' }} />
        </Table.Colgroup>
        <Table.Head>
          <Table.Row>
            <Table.Header colSpan={2}>PRODUCT</Table.Header>
            <Table.Header center>STATUS</Table.Header>
            <Table.Header center>REMOTE CONTROL</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {cameraData.length > 0 ? (
            cameraData.map((camera) => {
              const { id, lprPosition, name, status, connectedDeviceType } =
                camera;

              const openCameraModal = () => {
                setActiveCameraId(id);
                openCameraDetailsModal();
              };

              const cameraNotPaired =
                connectedDeviceType === DeviceTypes.STANDALONE ||
                connectedDeviceType === null;

              return (
                <Table.Row key={id}>
                  <Table.Cell>{lprPosition.toUpperCase()}</Table.Cell>
                  <Table.Cell primary action={openCameraModal}>
                    {name}
                  </Table.Cell>
                  <Table.Cell error={status === LprCameraStatus.OFFLINE} center>
                    {getCameraStatus(status)}
                  </Table.Cell>
                  <Table.Cell>
                    {cameraNotPaired ? (
                      <StyledNoData>Not available</StyledNoData>
                    ) : (
                      <Button
                        primary
                        size={ButtonSize.SMALL}
                        type='button'
                        onClick={() => onClick(id, ProductOperations.OPEN)}
                        disabled={status === LprCameraStatus.OFFLINE}
                      >
                        <IconRow className={OperationIcon.UP} />
                        {AllProductStatuses.OPEN}
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell merged={5}>
                <StyledNoData>No cameras added.</StyledNoData>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Card>
  );
}

export default CameraTable;
