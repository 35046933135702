import React from 'react';
import { GetLicensePurchaseDescription } from 'src/components/License/GetLicenseDescription';
import ButtonSize from 'src/constants/Shared/ButtonSize';
import Color from 'src/constants/Shared/Color';
import useLicenseContext from 'src/hooks/Shared/useLicenseContext';
import styled from 'styled-components';
import Button from '../Shared/Button';
import Card from '../Shared/Card';
import Table from '../Shared/Table';
import Title from '../Shared/Title';

interface PmsLicenseTableProps {
  openSendInquiryModal: () => void;
}

export default function PmsLicenseTable(props: PmsLicenseTableProps) {
  const { openSendInquiryModal } = props;
  const { purchasedLicenses, isLoading } = useLicenseContext();

  return (
    <>
      <Title>Licenses</Title>
      <Card>
        <Table isLoading={isLoading}>
          <Table.Body>
            {purchasedLicenses.map((license) => {
              const { id } = license;

              return (
                <Table.Row key={id}>
                  <Table.Cell smallText>
                    {GetLicensePurchaseDescription(license)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Card>
      <StyledDiv>
        <StyledText>
          For an upgrade of your license plan, contact our customer support:
        </StyledText>
        <Button size={ButtonSize.MIDDLE} onClick={openSendInquiryModal}>
          Send inquiry
        </Button>
      </StyledDiv>
    </>
  );
}

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledText = styled.p`
  color: ${Color.TEXT_DARKER};
  font-size: 14px;
`;
