import type AccountCreateRequest from '../../models/Home/AccountCreateRequest';
import type AccountWizardValues from '../../models/Home/AccountWizardValues';

export default function toAccountCreateRequest(
  data: AccountWizardValues
): AccountCreateRequest {
  const {
    name,
    contactName,
    contactEmail,
    contactPhone,
    loginName,
    loginEmail,
    accountRole,
  } = data;

  return {
    name,
    contact_name: contactName,
    contact_email: contactEmail,
    contact_phone: contactPhone.replace(/\s/g, ''),
    login_name: loginName,
    login_email: loginEmail,
    account_role_id: accountRole?.key,
  };
}
